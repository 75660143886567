// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const gnr_leiOrganica_2: Topic = {
  topic: 'GNR - Lei Orgânica (2)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "As atribuições da Guarda:",
      "choices": [
        "São prosseguidas em todos os municípios com menos de 100 mil habitantes.",
        "São prosseguidas em todo o território nacional e no mar territorial.",
        "São prosseguidas em todos os municípios com mais de 100 mil habitantes.",
        "Não podem ser prosseguidas fora do território nacional."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "São prosseguidas em todo o território nacional e no mar territorial."
      ],
      "score": 10
    },
    {
      "question": "Nos termos da Lei Orgânica, a Guarda tem direito a:",
      "choices": [
        "A bandeira heráldica, Banda de Música, selo branco e condecoração pública.",
        "A bandeira heráldica, Banda de Música, hino, marcha, selo branco e condecoração privativa.",
        "Brasão de armas, bandeira heráldica, hino, marcha, selo branco e condecoração pública.",
        "Brasão de armas, bandeira heráldica, hino, marcha, selo branco e condecoração privativa."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Brasão de armas, bandeira heráldica, hino, marcha, selo branco e condecoração privativa."
      ],
      "score": 10
    },
    {
      "question": "O Dia da Guarda é comemorado a:",
      "choices": [
        "10 de junho, em evocação da lei que criou a atual instituição nacional, em 1911.",
        "25 de abril, em evocação da transformação ocorrida na terceira república, em 1974.",
        "3 de maio, em evocação da lei que criou a atual instituição nacional, em 1911.",
        "5 de outubro, em evocação da instauração da primeira república, em 1910."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "3 de maio, em evocação da lei que criou a atual instituição nacional, em 1911."
      ],
      "score": 10
    },
    {
      "question": "A estrutura de comando da Guarda compreende:",
      "choices": [
        "O Comando da Guarda; os órgãos superiores de comando e direção.",
        "A estrutura de comando, as unidades e o estabelecimento de ensino.",
        "Comando Operacional, o Comando da Administração dos Recursos Internos e o Comando da Doutrina e Formação.",
        "O órgão de inspeção, os órgãos de conselho e a Secretaria-Geral."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A estrutura de comando, as unidades e o estabelecimento de ensino."
      ],
      "score": 10
    },
    {
      "question": "São órgãos superiores de comando e direção:",
      "choices": [
        "O Comando Operacional, o Comando da Administração dos Recursos Internos, o Comando da Doutrina e Formação e a Escola da Guarda.",
        "O Comando Operacional, o Comando da Administração dos Recursos Internos, o Comando da Doutrina e Formação e a Unidade de Serviços e Honras do Estado.",
        "O Comando Operacional, o Comando da Administração dos Recursos Internos e o Comando da Doutrina e Formação.",
        "O Comando Operacional, o Comando da Administração dos Recursos Internos, o Comando da Doutrina e Formação e as Unidades especializadas, de representação e de intervenção e reserva."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Comando Operacional, o Comando da Administração dos Recursos Internos e o Comando da Doutrina e Formação."
      ],
      "score": 10
    },
    {
      "question": "A Unidade de representação existente na Guarda é a:",
      "choices": [
        "Unidade de Controlo Costeiro.",
        "Unidade de Intervenção.",
        "Unidade de Segurança e Honras de Estado.",
        "Unidade de Protocolo e Relações Internacionais."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Unidade de Segurança e Honras de Estado."
      ],
      "score": 10
    },
    {
      "question": "O comandante do Comando da Administração dos Recursos Internos é:",
      "choices": [
        "Um tenente-general.",
        "Um oficial superior.",
        "Um major-general.",
        "Um coronel, com habilitações de Revisor Oficial de Contas e idoneidade atestada pelo Banco de Portugal."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Um major-general."
      ],
      "score": 10
    },
    {
      "question": "A Região Autónoma da Açores tem o seguinte dispositivo:",
      "choices": [
        "Uma Unidade de Controlo Costeiro.",
        "Uma Unidade de Marinha às ordens do Comandante-Operacional.",
        "Um Comando Territorial com sede em Ponta Delgada.",
        "Uma Unidade composta por meios aéreos, navais e de socorro."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Um Comando Territorial com sede em Ponta Delgada."
      ],
      "score": 10
    },
    {
      "question": "A Unidade de Segurança e Honras de Estado é uma unidade de representação responsável pela protecção e segurança às instalações dos órgãos de soberania e de outras entidades que lhe sejam confiadas e pela prestação de honras de Estado. Articula-se em:",
      "choices": [
        "Esquadrão Presidencial, Esquadrão da Assembleia da República e Esquadrão de Intervenção aero-naval.",
        "Esquadrão Presidencial, subunidade de honras de Estado e subunidade de segurança.",
        "Esquadrão Presidencial, Esquadrão da Assembleia da República e Esquadrão Motorizado.",
        "Esquadrão Presidencial, Esquadrão da Assembleia da República e Esquadrão de Reconhecimento."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Esquadrão Presidencial, subunidade de honras de Estado e subunidade de segurança."
      ],
      "score": 10
    },
    {
      "question": "A Unidade de Segurança e Honras de Estado integra, ainda:",
      "choices": [
        "Grupo de Operações Especiais.",
        "Charanga a Cavalo e a Banda da Guarda.",
        "Grupo de Segurança Pessoal e Contra-Informação.",
        "A Equipa de Eletrónica e Pesquisa."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Charanga a Cavalo e a Banda da Guarda."
      ],
      "score": 10
    },
    {
      "question": "A Escola da Guarda é:",
      "choices": [
        "Comandada por um tenente-general e coadjuvado por um diretor de formação.",
        "Comandada por um major-general, coadjuvado por um 2º comandante.",
        "Comandada por um oficial superior e coadjuvado por um 2º comandante.",
        "Comandada por um major-general, coadjuvado por um diretor de formação."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Comandada por um major-general, coadjuvado por um 2º comandante."
      ],
      "score": 10
    },

  //2021_1_chamada
    {
      "question": "Os militares da Guarda no exercício do comando de forças têm a categoria de comandantes de força pública. Considera-se força pública, para efeitos do número anterior, o efetivo mínimo de:",
      "choices": [
        "Um pelotão de Ordem Pública.",
        "Dois militares em missão de serviço.",
        "Sempre que o comandante é um sargento.",
        "Sempre que o comandante é um oficial."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Dois militares em missão de serviço."
      ],
      "score": 10
    },
    {
      "question": "Os comandos territoriais articulam-se em:",
      "choices": [
        "Estado-maior e subunidades.",
        "Comando e Destacamentos Territoriais.",
        "Comando, serviços e subunidades operacionais.",
        "Estado-maior, serviços e subunidades operacionais."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Comando, serviços e subunidades operacionais."
      ],
      "score": 10
    },
    {
      "question": "Nos termos da Lei Orgânica, a Guarda tem direito a:",
      "choices": [
        "A bandeira heráldica, Banda de Música, selo branco e condecoração pública.",
        "A bandeira heráldica, Banda de Música, hino, marcha, selo branco e condecoração privativa.",
        "Brasão de armas, bandeira heráldica, hino, marcha, selo branco e condecoração pública.",
        "Brasão de armas, bandeira heráldica, hino, marcha, selo branco e condecoração privativa."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Brasão de armas, bandeira heráldica, hino, marcha, selo branco e condecoração privativa."
      ],
      "score": 10
    },
    {
      "question": "Para efeitos do Código de Processo Penal, consideram-se órgãos de polícia criminal:",
      "choices": [
        "Todos os guardas, exceto os músicos e militares do serviço de saúde.",
        "Apenas os oficiais da Guarda, quando no exercício de funções de comando ou chefia operacional.",
        "Os militares da Guarda incumbidos de realizar quaisquer atos ordenados por autoridade judiciária ou determinados pelo Código de Processo Penal.",
        "Os oficiais da Guarda, quando no exercício de funções de comando operacional de âmbito tributário."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Os militares da Guarda incumbidos de realizar quaisquer atos ordenados por autoridade judiciária ou determinados pelo Código de Processo Penal."
      ],
      "score": 10
    },
    {
      "question": "A estrutura geral da Guarda compreende:",
      "choices": [
        "O Comando Operacional, o Comando da Administração dos Recursos Internos e o Comando da Doutrina e Formação.",
        "A estrutura de comando, as unidades e o estabelecimento de ensino.",
        "O diretor-geral, o 2º diretor-geral, o órgão de inspeção, os órgãos de conselho e a Secretaria - Geral.",
        "O Comando da Guarda e os órgãos superiores de comando e direção."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A estrutura de comando, as unidades e o estabelecimento de ensino."
      ],
      "score": 10
    },
    {
      "question": "São órgãos superiores de comando e direção: ",
      "choices": [
        "O Comando Operacional, o Comando da Administração dos Recursos Internos, o Comando da Doutrina e Formação e a Escola da Guarda.",
        "O Comando Operacional, o Comando da Administração dos Recursos Internos, o Comando da Doutrina e Formação e a Unidade de Serviços e Honras do Estado.",
        "O Comando Operacional, o Comando da Administração dos Recursos Internos e o Comando da Doutrina e Formação.",
        "O Comando Operacional, o Comando da Administração dos Recursos Internos, o Comando da Doutrina e Formação e as Unidades especializadas, de representação e de intervenção e reserva."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Comando Operacional, o Comando da Administração dos Recursos Internos e o Comando da Doutrina e Formação."
      ],
      "score": 10
    },
    {
      "question": "O Conselho Superior da Guarda é o órgão máximo de consulta do comandante-geral. Em composição restrita é presidido pelo:",
      "choices": [
        "Diretor-Geral Comandante.",
        "Juiz ou Procurador.",
        "Comandante-geral.",
        "Pessoa de reconhecida idoneidade, licenciada em direito, sociologia ou antropologia."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Comandante-geral."
      ],
      "score": 10
    },
    {
      "question": "O Comando-Geral é comandado pelo:",
      "choices": [
        "Comandante-geral. ",
        "Comandante operacional. ",
        "Chefe da Secretaria-Geral da Guarda",
        "2º Comandante-geral. "
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Chefe da Secretaria-Geral da Guarda"
      ],
      "score": 10
    },
    {
      "question": "A Unidade de Segurança e Honras de Estado integra, ainda, a:",
      "choices": [
        "Grupo de Operações Especiais.",
        "Charanga a Cavalo e a Banda da Guarda.",
        "Grupo de Segurança Pessoal e Contra-Informação.",
        "Equipa de Eletrónica e Pesquisa."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Charanga a Cavalo e a Banda da Guarda."
      ],
      "score": 10
    }
  ],
}

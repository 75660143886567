// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

import a11  from '../../../assets/images/psicotecnicos/Raciocinio_Espacial/a11.png'
import a12  from '../../../assets/images/psicotecnicos/Raciocinio_Espacial/a12.png'
import a13  from '../../../assets/images/psicotecnicos/Raciocinio_Espacial/a13.png'
import a14  from '../../../assets/images/psicotecnicos/Raciocinio_Espacial/a14.png'
import a15  from '../../../assets/images/psicotecnicos/Raciocinio_Espacial/a15.png'
import a16  from '../../../assets/images/psicotecnicos/Raciocinio_Espacial/a16.png'
import a17  from '../../../assets/images/psicotecnicos/Raciocinio_Espacial/a17.png'
import a18  from '../../../assets/images/psicotecnicos/Raciocinio_Espacial/a18.png'
import a19  from '../../../assets/images/psicotecnicos/Raciocinio_Espacial/a19.png'
import a20  from '../../../assets/images/psicotecnicos/Raciocinio_Espacial/a20.png'







export const raciocinio_espacial_2: Topic = {
  topic: 'Raciocínio Espacial (2)',
  level: 'Advanced',
  totalQuestions: 10,
  totalScore: 100,
  totalTime: 600,
  questions: [
    {
      "question": "Assinale a resposta correta.",
      image: a11,
      "choices": [
      "A",
      "B",
      "C",
      "D",
      "E"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "D"
      ],
      "score": 10
  },
  {
    "question": "Assinale a resposta correta.",
    image: a12,
    "choices": [
    "A",
    "B",
    "C",
    "D",
    "E"
    ],
    "type": "MCQs",
    "correctAnswers": [
    "A"
    ],
    "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: a13,
  "choices": [
  "A",
  "B",
  "C",
  "D",
  "E"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "D"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: a14,
  "choices": [
  "A",
  "B",
  "C",
  "D",
  "E"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "B"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: a15,
  "choices": [
  "A",
  "B",
  "C",
  "D",
  "E"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "D"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: a16,
  "choices": [
  "A",
  "B",
  "C",
  "D",
  "E"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "E"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: a17,
  "choices": [
  "A",
  "B",
  "C",
  "D",
  "E"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "B"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: a18,
  "choices": [
  "A",
  "B",
  "C",
  "D",
  "E"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "A"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: a19,
  "choices": [
  "A",
  "B",
  "C",
  "D",
  "E"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "D"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: a20,
  "choices": [
  "A",
  "B",
  "C",
  "D",
  "E"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "E"
  ],
  "score": 10
}
  
  
  
  
  ],
}

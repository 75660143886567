// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const crp_3: Topic = {
  topic: 'CRP (3)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "O Conselho de Ministros é constituído",
      "choices": [
        "pelo Primeiro-Ministro, pelos Vice-Primeiros-Ministros, se os houver, e pelos Ministros",
        "pelo Primeiro-Ministro, pelos Ministros e Secretários de Estado",
        "pelo Primeiro-Ministro e pelos Ministros",
        "pelo Primeiro-Ministro, pelos Ministros, Secretários e Subsecretários de Estado"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "pelo Primeiro-Ministro, pelos Vice-Primeiros-Ministros, se os houver, e pelos Ministros"
      ],
      "score": 10
    },
    {
      "question": "Compete ao Governo, no exercício de funções legislativas",
      "choices": [
        "fazer leis e decretos-lei em matérias não reservadas à Assembleia da República",
        "fazer decretos-lei em matérias não reservadas à Assembleia da República e ao Governo",
        "fazer regulamentos em matérias não reservadas à Assembleia da República",
        "fazer decretos-lei em matérias não reservadas à Assembleia da República"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "fazer decretos-lei em matérias não reservadas à Assembleia da República"
      ],
      "score": 10
    },
    {
      "question": "A câmara municipal é",
      "choices": [
        "o edifício do município",
        "o órgão executivo colegial do município",
        "o órgão executivo colegial do município e assembleia municipal",
        "o órgão municipal do concelho"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "o órgão executivo colegial do município"
      ],
      "score": 10
    },
    {
      "question": "Portugal é uma República soberana",
      "choices": [
        "baseada na vontade individual e empenhada na construção de uma sociedade livre e justa para a maioria da população",
        "baseada na dignidade da pessoa humana e na vontade dos partidos políticos e associações sindicais e empenhada na construção de uma sociedade livre, justa e solidária",
        "baseada na dignidade da pessoa humana e na vontade popular e empenhada na construção de uma sociedade livre, justa e solidária",
        "baseada na dignidade da pessoa humana, na vontade dos deputados e dos representantes das associações sindicais e empenhada na construção de uma sociedade livre e solidária"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "baseada na dignidade da pessoa humana e na vontade popular e empenhada na construção de uma sociedade livre, justa e solidária"
      ],
      "score": 10
    },
    {
      "question": "Os partidos políticos concorrem para a organização e para a expressão da vontade popular, no respeito pelos princípios da independência nacional",
      "choices": [
        "da unidade do Estado e da democracia política",
        "da unidade do Estado e dos interesses dos partidos políticos",
        "da democracia política e nos interesses dos partidos políticos e dos sindicatos",
        "da unidade do Estado, da democracia política e no interesse dos partidos políticos e da associação nacional de municípios"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "da unidade do Estado e da democracia política"
      ],
      "score": 10
    },
    {
      "question": "Os filhos não podem ser separados dos pais, salvo quando estes não cumpram",
      "choices": [
        "os seus deveres conjugais para com eles e sempre mediante decisão do conselho de família",
        "os seus deveres fundamentais para com eles e sempre mediante decisão judicial",
        "os seus deveres fundamentais para com eles e sempre mediante decisão favorável do conselho de família",
        "os seus deveres fundamentais para com eles e sempre mediante decisão administrativa"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "os seus deveres fundamentais para com eles e sempre mediante decisão judicial"
      ],
      "score": 10
    },
    {
      "question": "A detenção será submetida, no prazo máximo de",
      "choices": [
        "24 horas",
        "48 horas",
        "72 horas",
        "8 horas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "48 horas"
      ],
      "score": 10
    },
    {
      "question": "Têm direito de sufrágio todos os cidadãos",
      "choices": [
        "maiores de 21 anos",
        "maiores de 14 anos",
        "maiores de 16 anos",
        "maiores de 18 anos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "maiores de 18 anos"
      ],
      "score": 10
    },
    {
      "question": "A Assembleia Legislativa é eleita por",
      "choices": [
        "sufrágio universal, indireto e secreto, de harmonia com o princípio da representação proporcional",
        "sufrágio universal, direto e público, de harmonia com o princípio da representação proporcional",
        "sufrágio universal, direto e secreto, de harmonia com o princípio da representação proporcional",
        "sufrágio universal, indireto e público, de harmonia com o princípio da representação proporcional"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "sufrágio universal, direto e secreto, de harmonia com o princípio da representação proporcional"
      ],
      "score": 10
    },
    {
      "question": "A constituição da república portuguesa de 1976 (atual constituição portuguesa) foi redigida",
      "choices": [
        "Pela assembleia constituinte nomeada",
        "Por um comitê de peritos",
        "Pelo governo da república",
        "Pela assembleia constituinte eleita"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Pela assembleia constituinte eleita"
      ],
      "score": 10
    },
    {
      "question": "A assembleia da república tem",
      "choices": [
        "O mínimo de 200 e o máximo de 230 deputados, nos termos da lei eleitoral",
        "O máximo de 250 deputados, nos termos da lei eleitoral",
        "O mínimo de 180 e o máximo de 280 deputados, nos termos da lei eleitoral",
        "O mínimo de 180 e o máximo de 230 deputados, nos termos da lei eleitoral"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O mínimo de 180 e o máximo de 230 deputados, nos termos da lei eleitoral"
      ],
      "score": 10
    },
    {
      "question": "A legislatura da assembleia da república tem duração de",
      "choices": [
        "5 sessões legislativas",
        "4 sessões legislativa",
        "4 anos civis",
        "5 anos civis"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "4 sessões legislativa"
      ],
      "score": 10
    },
    {
      "question": "O governo é constituído",
      "choices": [
        "Pelo primeiro-ministro, pelos ministros e pelos secretários e subsecretários de estado",
        "Pelos ministros e pelos secretários e subsecretários de estado",
        "Pelo primeiro ministro e pelos secretários e subsecretários de estado",
        "Pelo primeiro ministro e subsecretários de estado"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Pelo primeiro-ministro, pelos ministros e pelos secretários e subsecretários de estado"
      ],
      "score": 10
    },
    {
      "question": "A interpretação dos preceitos constitucionais relativos aos direitos fundamentais, deve ser feita",
      "choices": [
        "De harmonia com os princípios gerais de direito",
        "De harmonia com a Declaração Universal dos Direitos Humanos",
        "De harmonia com a Declaração Europeia dos Direitos Humanos",
        "De harmonia com a Convenção Europeia dos Direitos Humanos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "De harmonia com a Declaração Universal dos Direitos Humanos"
      ],
      "score": 10
    },
    {
      "question": "Os direitos, liberdades e garantias podem",
      "choices": [
        "Ser objeto de restrição nos casos previstos na CRP e na Lei",
        "Ser sempre objeto de restrição",
        "Nunca podem ser objeto de restrição",
        "Ser objeto de restrição mas apenas, para salvaguarda doutros direitos e deveres e nos casos em que a constituição o preveja"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ser objeto de restrição mas apenas, para salvaguarda doutros direitos e deveres e nos casos em que a constituição o preveja"
      ],
      "score": 10
    },
    {
      "question": "Segundo a CRP, os Direitos Fundamentais são",
      "choices": [
        "Aqueles que se encontram plasmados na CRP, os reconhecidos pelo Direito Internacional, bem como aqueles que se encontram em legislação ordinária",
        "Aqueles que se encontram plasmados na CRP e na legislação ordinária",
        "Aqueles que se encontram plasmados na CRP, na legislação extravagante e todos os reconhecidos pelo Direito Internacional Privado",
        "Aqueles que foram convencionados pelo Estado Português e que tenham força de reserva de lei"
      ],
      "type": "MCQs",
      "correctAnswers": [],
      "score": 10
    },
    {
      "question": "O Princípio da Proporcionalidade ou da Proibição do Excesso tem como requisitos",
      "choices": [
        "A necessidade, e a clareza das ações por parte dos Órgãos do Governo",
        "A precisão e a determinabilidade das leis, quanto aos seus limites formais e materiais",
        "Proporcionalidade quanto à Proteção da Confiança em sentido estrito, bem como a exigibilidade de uma atuação de acordo com a lei fundamental",
        "A necessidade, exigibilidade; adequação e proporcionalidade"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A necessidade, exigibilidade; adequação e proporcionalidade"
      ],
      "score": 10
    },
    {
      "question": "A declaração do estado de sítio ou do estado de emergência terá que",
      "choices": [
        "Ser fundamentada, especificar os Direitos Liberdades e Garantias que são suspensos, especificar o tempo de duração, que não poderá exceder 15 dias",
        "Ser fundamentada, especificar os D.L.G. que são suspensos",
        "Especificar o tempo de duração que não poderá exceder 15 dias",
        "Ser publicada no Diário da República, especificar os D.L.G. que são suspensos, e a sua duração que não poderá exceder 15 dias"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ser fundamentada, especificar os Direitos Liberdades e Garantias que são suspensos, especificar o tempo de duração, que não poderá exceder 15 dias"
      ],
      "score": 10
    },
    {
      "question": "Segundo a alínea a) do n.o 2 do artigo 64.o da CRP, o direito à proteção da saúde é realizado",
      "choices": [
        "Através de um serviço nacional de saúde universal e geral e, tendo em conta as condições económicas e sociais dos cidadãos, gratuito",
        "Através de um serviço nacional de saúde universal e geral e, tendo em conta as condições",
        "Através de um serviço nacional de saúde local e restrito e, tendo em conta as condições económicas e sociais dos cidadãos, tendencialmente gratuito",
        "Através de um serviço nacional de saúde local e restrito e, tendo em conta as condições económicas e sociais dos cidadãos, gratuito"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Através de um serviço nacional de saúde universal e geral e, tendo em conta as condições"
      ],
      "score": 10
    },
    {
      "question": "A Constituição da República Portuguesa confere aos jovens o gozo de proteção especial para efetivação dos seus direitos económicos, sociais e culturais, nomeadamente",
      "choices": [
        "No ensino, na formação profissional e na cultura",
        "Na educação física e no desporto",
        "No aproveitamento dos tempos livres",
        "Todas as opções estão corretas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as opções estão corretas"
      ],
      "score": 10
    }
  ],
}

// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const dudh_1: Topic = {
  topic: 'DUDH (1)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "Todos os seres humanos nascem livres e iguais em dignidade e direitos. O que isso significa?",
      "choices": [
        "Que todos têm os mesmos direitos, independentemente de suas diferenças",
        "Que alguns têm mais direitos dependendo de sua classe social",
        "Que apenas os cidadãos de um país têm direitos",
        "Que os direitos são garantidos apenas para quem respeita as leis"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Que todos têm os mesmos direitos, independentemente de suas diferenças"
      ],
      "score": 10
    },
    {
      "question": "Qual é o significado de 'direito à vida' na DUDH?",
      "choices": [
        "O direito de viver em condições dignas",
        "O direito de viver sem nunca ser preso",
        "O direito de trabalhar onde quiser",
        "O direito de fazer o que quiser com a sua vida"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O direito de viver em condições dignas"
      ],
      "score": 10
    },
    {
      "question": "O que é o direito à liberdade de expressão?",
      "choices": [
        "O direito de falar o que pensa sem medo de ser punido",
        "O direito de ofender e humilhar os outros",
        "O direito de mentir sem consequências",
        "O direito de falar apenas sobre assuntos permitidos pelo governo"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O direito de falar o que pensa sem medo de ser punido"
      ],
      "score": 10
    },
    {
      "question": "Qual é o objetivo principal da Declaração Universal dos Direitos Humanos?",
      "choices": [
        "Garantir direitos iguais para todas as pessoas, em todos os lugares",
        "Criar leis para proteger os ricos",
        "Estabelecer regras para os países mais pobres",
        "Impor um sistema de governo único para todos os países"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Garantir direitos iguais para todas as pessoas, em todos os lugares"
      ],
      "score": 10
    },
    {
      "question": "O que significa 'direito ao trabalho' na DUDH?",
      "choices": [
        "O direito de escolher o seu emprego livremente",
        "O direito de nunca ser demitido",
        "O direito de trabalhar apenas para o governo",
        "O direito de não precisar trabalhar"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O direito de escolher o seu emprego livremente"
      ],
      "score": 10
    },
    {
      "question": "De acordo com a DUDH, o que é a 'dignidade humana'?",
      "choices": [
        "O respeito e valor que todas as pessoas merecem por serem humanas",
        "O respeito apenas por pessoas de boa moral",
        "A recompensa que se ganha ao ajudar os outros",
        "Um direito apenas para os cidadãos de países ricos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O respeito e valor que todas as pessoas merecem por serem humanas"
      ],
      "score": 10
    },
    {
      "question": "O que significa o direito à privacidade na DUDH?",
      "choices": [
        "Que ninguém pode interferir na vida pessoal ou na correspondência de alguém sem motivo",
        "Que ninguém pode nunca ser investigado",
        "Que o governo pode vigiar todas as comunicações",
        "Que só pessoas famosas têm direito à privacidade"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Que ninguém pode interferir na vida pessoal ou na correspondência de alguém sem motivo"
      ],
      "score": 10
    },
    {
      "question": "Segundo a DUDH, o que é o direito à educação?",
      "choices": [
        "O direito de acessar a educação básica de forma gratuita e de qualidade",
        "O direito de frequentar apenas universidades caras",
        "O direito de escolher não estudar",
        "O direito de estudar apenas se a família puder pagar"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O direito de acessar a educação básica de forma gratuita e de qualidade"
      ],
      "score": 10
    },
    {
      "question": "Por que a liberdade de religião é importante segundo a DUDH?",
      "choices": [
        "Porque cada pessoa deve ser livre para escolher e praticar sua própria religião",
        "Porque a religião deve ser imposta pelo governo",
        "Porque as pessoas só têm direitos se forem religiosas",
        "Porque a DUDH exige que todos sigam uma religião específica"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Porque cada pessoa deve ser livre para escolher e praticar sua própria religião"
      ],
      "score": 10
    },
    {
      "question": "O que significa igualdade perante a lei, de acordo com a DUDH?",
      "choices": [
        "Que todas as pessoas devem ser tratadas igualmente pela lei, sem discriminação",
        "Que as pessoas ricas têm mais direitos",
        "Que apenas os cidadãos do país têm direitos",
        "Que as leis são diferentes para cada grupo social"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Que todas as pessoas devem ser tratadas igualmente pela lei, sem discriminação"
      ],
      "score": 10
    },
    {
      "question": "Todos os seres humanos nascem livres e iguais em dignidade e direitos. O que isso significa?",
      "choices": [
        "Que todos têm os mesmos direitos, independentemente de suas diferenças",
        "Que alguns têm mais direitos dependendo de sua classe social",
        "Que apenas os cidadãos de um país têm direitos",
        "Que os direitos são garantidos apenas para quem respeita as leis"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Que todos têm os mesmos direitos, independentemente de suas diferenças"
      ],
      "score": 10
    },
    {
      "question": "Qual é o significado de 'direito à vida' na DUDH?",
      "choices": [
        "O direito de viver em condições dignas",
        "O direito de viver sem nunca ser preso",
        "O direito de trabalhar onde quiser",
        "O direito de fazer o que quiser com a sua vida"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O direito de viver em condições dignas"
      ],
      "score": 10
    },
    {
      "question": "O que é o direito à liberdade de expressão?",
      "choices": [
        "O direito de falar o que pensa sem medo de ser punido",
        "O direito de ofender e humilhar os outros",
        "O direito de mentir sem consequências",
        "O direito de falar apenas sobre assuntos permitidos pelo governo"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O direito de falar o que pensa sem medo de ser punido"
      ],
      "score": 10
    },
    {
      "question": "Qual é o objetivo principal da Declaração Universal dos Direitos Humanos?",
      "choices": [
        "Garantir direitos iguais para todas as pessoas, em todos os lugares",
        "Criar leis para proteger os ricos",
        "Estabelecer regras para os países mais pobres",
        "Impor um sistema de governo único para todos os países"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Garantir direitos iguais para todas as pessoas, em todos os lugares"
      ],
      "score": 10
    },
    {
      "question": "O que significa 'direito ao trabalho' na DUDH?",
      "choices": [
        "O direito de escolher o seu emprego livremente",
        "O direito de nunca ser demitido",
        "O direito de trabalhar apenas para o governo",
        "O direito de não precisar trabalhar"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O direito de escolher o seu emprego livremente"
      ],
      "score": 10
    },
    {
      "question": "De acordo com a DUDH, o que é a 'dignidade humana'?",
      "choices": [
        "O respeito e valor que todas as pessoas merecem por serem humanas",
        "O respeito apenas por pessoas de boa moral",
        "A recompensa que se ganha ao ajudar os outros",
        "Um direito apenas para os cidadãos de países ricos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O respeito e valor que todas as pessoas merecem por serem humanas"
      ],
      "score": 10
    },
    {
      "question": "O que significa o direito à privacidade na DUDH?",
      "choices": [
        "Que ninguém pode interferir na vida pessoal ou na correspondência de alguém sem motivo",
        "Que ninguém pode nunca ser investigado",
        "Que o governo pode vigiar todas as comunicações",
        "Que só pessoas famosas têm direito à privacidade"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Que ninguém pode interferir na vida pessoal ou na correspondência de alguém sem motivo"
      ],
      "score": 10
    },
    {
      "question": "Segundo a DUDH, o que é o direito à educação?",
      "choices": [
        "O direito de acessar a educação básica de forma gratuita e de qualidade",
        "O direito de frequentar apenas universidades caras",
        "O direito de escolher não estudar",
        "O direito de estudar apenas se a família puder pagar"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O direito de acessar a educação básica de forma gratuita e de qualidade"
      ],
      "score": 10
    },
    {
      "question": "Por que a liberdade de religião é importante segundo a DUDH?",
      "choices": [
        "Porque cada pessoa deve ser livre para escolher e praticar sua própria religião",
        "Porque a religião deve ser imposta pelo governo",
        "Porque as pessoas só têm direitos se forem religiosas",
        "Porque a DUDH exige que todos sigam uma religião específica"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Porque cada pessoa deve ser livre para escolher e praticar sua própria religião"
      ],
      "score": 10
    },
    {
      "question": "O que significa igualdade perante a lei, de acordo com a DUDH?",
      "choices": [
        "Que todas as pessoas devem ser tratadas igualmente pela lei, sem discriminação",
        "Que as pessoas ricas têm mais direitos",
        "Que apenas os cidadãos do país têm direitos",
        "Que as leis são diferentes para cada grupo social"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Que todas as pessoas devem ser tratadas igualmente pela lei, sem discriminação"
      ],
      "score": 10
    }
  ],
}

// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const gnr_provaCompleta_4: Topic = {
  topic: 'GNR - Prova Completa (4)',
  level: 'Advanced',
  totalQuestions: 50,
  totalScore: 500,
  totalTime: 1800,
  questions: [
    {
      "question": "A nomeação e a colocação de militares obedecem aos seguintes princípios: ",
      "choices": [
        "Satisfação das necessidades ou o interesse do serviço.",
        "Satisfação das condições individuais de promoção.",
        "Aproveitamento da capacidade intelectual em função da experiência revelada.",
        "Primado dos interesses pessoais aos do serviço."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Satisfação das necessidades ou o interesse do serviço."
      ],
      "score": 10
    },
    {
      "question": "Pode transitar para a situação de reserva o militar da Guarda na situação de ativo que preencha uma das seguintes condições:",
      "choices": [
      "Atinja o limite de idade estabelecido para o respetivo posto",
      "Declare, por escrito, desejar passar à reserva depois de completar 36 anos de tempo de serviço militar e 50 anos de idade",
      "Declare, por escrito, desejar passar à reserva depois de completar 30 anos de tempo de serviço militar e 55 anos de idade",
      "Seja promovido a guarda-principal"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "Atinja o limite de idade estabelecido para o respetivo posto"
      ],
      "score": 10
  },
  {
      "question": "Os limites máximos de idade de passagem à reserva, na categoria de Guardas, são os seguintes:",
      "choices": [
      "Cabo-mor - 62 anos; Restantes postos - 52 anos",
      "Cabo-mor - 60 anos; Restantes postos - 52 anos",
      "Cabo-mor - 60 anos; Restantes postos - 55 anos",
      "Cabo-mor - 60 anos; Restantes postos - 57 anos"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "Cabo-mor - 60 anos; Restantes postos - 57 anos"
      ],
      "score": 10
  },
  {
    "question": "Para o ingresso na categoria de sargentos é exigido aproveitamento no curso de formação de sargentos da Guarda:",
    "choices": [
      "Ao qual é atribuído o nível 5 de qualificação do Sistema Nacional de Qualificações.",
      "Ao qual é atribuído o nível 3 de qualificação do Sistema Nacional de Qualificações.",
      "Ao qual é atribuído o nível 7 de qualificação do Sistema Nacional de Qualificações.",
      "Ao qual é atribuído o nível 4 de qualificação do Sistema Nacional de Qualificações."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Ao qual é atribuído o nível 5 de qualificação do Sistema Nacional de Qualificações."
    ],
    "score": 10
  },
  {
    "question": "A concessão de licença de férias obedece às seguintes regras:",
    "choices": [
      "Ter mais de um ano de serviço efetivo exceto no ano civil de ingresso.",
      "Pode sobrepor-se à frequência de estágios.",
      "Pode prejudicar a tramitação de processo disciplinar ou criminal em curso.",
      "Não pode ser interrompida por qualquer motivo."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Ter mais de um ano de serviço efetivo exceto no ano civil de ingresso."
    ],
    "score": 10
  },
  {
    "question": "O segundo-sargento desempenha as funções de comando do:",
    "choices": [
      "Posto tipo C, adjunto de comando de posto tipo A, B e C, de comando de subunidades elementares operacionais, atividades de formação, administrativas, logísticas ou técnicas, e outras de natureza equivalente.",
      "Posto tipo B, adjunto de comando de posto tipo A, D e €, de comando de subunidades elementares operacionais, atividades de formação, administrativas, logísticas ou técnicas, e outras de natureza equivalente.",
      "Posto tipo C, adjunto de comando de posto tipo A, B e D, de comando de subunidades elementares operacionais, atividades de formação, administrativas, logísticas ou técnicas, e outras de natureza equivalente.",
      "Posto tipo A, adjunto de comando de posto tipo B, C e D, de comando de subunidades elementares operacionais. atividades de formação, administrativas, logísticas ou técnicas, e outras de natureza equivalente."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Posto tipo C, adjunto de comando de posto tipo A, B e C, de comando de subunidades elementares operacionais, atividades de formação, administrativas, logísticas ou técnicas, e outras de natureza equivalente."
    ],
    "score": 10
  },
  {
    "question": "O militar da Guarda na situação de ativo ocupa um lugar na lista de antiguidade do quadro a que pertence, sendo inscrito no respetivo posto de ingresso por ordem decrescente: ",
    "choices": [
      "De classificação no respetivo curso.",
      "Da menor idade que tinha no fim do curso.",
      "Da maior habilitação literária no fim do curso.",
      "De ter menos tempo de serviço efetivo."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "De classificação no respetivo curso."
    ],
    "score": 10
  },
  {
    "question": "Considera-se na efetividade de serviço o militar da Guarda, no ativo, que se encontre:",
    "choices": [
      "Em comissão especial.",
      "No cumprimento de pena a que a legislação penal ou disciplinar atribua esse efeito.",
      "Suspenso de funções, enquanto medida administrativa do EMGNR.",
      "De licença sem direito a remuneração."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Suspenso de funções, enquanto medida administrativa do EMGNR."
    ],
    "score": 10
  },
  {
    "question": "O ingresso na categoria de Guardas da Guarda Nacional Republicana faz-se após conclusão com aproveitamento do curso de formação de guardas, havendo lugar a um período probatório, após a conclusão do curso, com a duração:",
    "choices": [
      "De nove meses e a forma de avaliação é fixada por despacho do diretor-geral.",
      "De um ano e a forma de avaliação é fixada por despacho do ministro da administração interna.",
      "De um ano e a forma de avaliação é fixada por despacho do comandante-geral.",
      "De seis meses e a forma de avaliação é fixada por despacho do ministro da administração interna sob proposta do diretor-geral."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "De um ano e a forma de avaliação é fixada por despacho do comandante-geral."
    ],
    "score": 10
  },
  {
    "question": "A promoção que se efetua por ordem de cursos e, dentro do mesmo curso, por ordem decrescente de classificação obtida neste, é uma promoção por:",
    "choices": [
      "Habilitação por formação.",
      "Distinção.",
      "Mérito escolar.",
      "Habilitação com curso adequado."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Habilitação com curso adequado."
    ],
    "score": 10
  },
  {
    "question": "São condições especiais de promoção:",
    "choices": [
      "Avaliação do desempenho efetuada, em regra, pelos superiores hierárquicos imediatos, nos moldes previstos no presente Estatuto.",
      "Atos heroicos que fortaleçam a imagem da Guarda.",
      "Tempo mínimo de antiguidade no posto.",
      "Ficha curricular com registos distintos dos demais."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Tempo mínimo de antiguidade no posto."
    ],
    "score": 10
  },
  {
    "question": "Constituem deveres dos militares da Guarda Nacional Republicana os constantes:",
    "choices": [
      "No Estatuto da respetiva lei orgânica.",
      "No regulamento de disciplina.",
      "No Estatuto, na respetiva lei orgânica, no regulamento de disciplina da Guarda Nacional Republicana e demais legislação em vigor.",
      "Todas as alíneas estão erradas."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "No Estatuto, na respetiva lei orgânica, no regulamento de disciplina da Guarda Nacional Republicana e demais legislação em vigor."
    ],
    "score": 10
  },
  {
    "question": "O documento de encarte, consoante a categoria a que o militar pertence, designa-se por:",
    "choices": [
      "Carta-patente, para oficiais.",
      "Diploma de encarte, para sargentos.",
      "Certificado de encarte, para guardas.",
      "Todas as alíneas estão corretas."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Todas as alíneas estão corretas."
    ],
    "score": 10
  },
  {
    "question": "O militar da Guarda Nacional Republicana encontra-se numa das seguintes situações:",
    "choices": [
      "Ativo, inativo ou reforma.",
      "Ativo, de doença ou reforma.",
      "Ativo, reserva ou reforma.",
      "Ativo ou inativo."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Ativo, reserva ou reforma."
    ],
    "score": 10
  },
  {
    "question": "O militar da Guarda Nacional Republicana, no exercício das suas funções está:",
    "choices": [
      "Exclusivamente ao serviço do interesse público.",
      "Ao serviço de interesses corporativos.",
      "Ao serviço de interesses particulares.",
      "Exclusivamente ao serviço do interesse privado."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Exclusivamente ao serviço do interesse público."
    ],
    "score": 10
  },
  {
    "question": "O Comandante da Unidade de Controlo Costeiro tem o posto de:",
    "choices": [
      "Tenente-General ou Major-General",
      "Vice-Almirante ou Contra-Almirante",
      "Tenente-General ou Vice-Almirante",
      "Major-General ou Contra-Almirante"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Major-General ou Contra-Almirante"
    ],
    "score": 10
  },
  {
    "question": "As atribuições da Guarda:",
    "choices": [
      "Não podem ser prosseguidas fora do território nacional.",
      "São prosseguidas em todos os municípios com menos de 100 mil habitantes.",
      "São prosseguidas em todos os municípios com mais de 100 mil habitantes.",
      "São prosseguidas em todo o território nacional e no mar territorial"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "São prosseguidas em todo o território nacional e no mar territorial"
    ],
    "score": 10
  },
  {
    "question": "A Unidade de representação existente na Guarda é a:",
    "choices": [
      "Unidade de Controlo Costeiro.",
      "Unidade de Intervenção.",
      "Unidade de Segurança e Honras de Estado.",
      "Unidade de Protocolo e Relações Internacionais."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Unidade de Segurança e Honras de Estado."
    ],
    "score": 10
  },
  {
    "question": "O comandante do Comando da Administração dos Recursos Internos é:",
    "choices": [
      "Um tenente-general.",
      "Um oficial superior.",
      "Um major-general.",
      "Um coronel, com habilitações de Revisor Oficial de Contas e idoneidade atestada pelo Banco de Portugal."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Um major-general."
    ],
    "score": 10
  },
  {
    "question": "As atribuições da Guarda são prosseguidas em:",
    "choices": [
      "Todo o território nacional e no mar territorial.",
      "Todo o território nacional, exceto as ilhas, e no mat territorial.",
      "Todo o território nacional, exceto o mar territorial.",
      "Todas as anteriores estão erradas."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Todo o território nacional e no mar territorial."
    ],
    "score": 10
  },
  {
    "question": "A Guarda Nacional Republicana depende:",
    "choices": [
      "Do membro do Governo responsável pela área da administração interna.",
      "Do Presidente da República.",
      "Do Ministro das Finanças.",
      "Todas as alíneas anteriores estão erradas."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Do membro do Governo responsável pela área da administração interna."
    ],
    "score": 10
  },
  {
    "question": "São consideradas autoridades de polícia:",
    "choices": [
      "O comandante-geral.",
      "O 2.º comandante-geral.",
      "O comandante do Comando Operacional da Guarda Nacional Republicana.",
      "Todas as alíneas anteriores estão corretas."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Todas as alíneas anteriores estão corretas."
    ],
    "score": 10
  },
  {
    "question": "No âmbito das suas atribuições, a Guarda Nacional Republicana utiliza as medidas de polícia legalmente previstas:",
    "choices": [
      "Nas condições e termos da Constituição e da lei de segurança interna.",
      "Nas condições e termos da Constituição e da lei de segurança externa.",
      "Nas condições e termos do orçamento de estado e da lei de segurança interna.",
      "Nas condições e termos do orçamento de estado."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Nas condições e termos da Constituição e da lei de segurança interna."
    ],
    "score": 10
  },
  {
    "question": "A Escola da Guarda é uma unidade especialmente vocacionada para:",
    "choices": [
      "A formação cultural.",
      "A formação militar e técnico-profissional dos militares da Guarda Nacional Republicana.",
      "A formação moral.",
      "Todas as alíneas estão corretas."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Todas as alíneas estão corretas."
    ],
    "score": 10
  },
  {
    "question": "A Guarda Nacional Republicana, sem prejuízo das prioridades legais da sua atuação, coopera:",
    "choices": [
      "Com as demais forças.",
      "Com as autoridades públicas.",
      "Com os serviços de segurança.",
      "Todas as alíneas estão corretas."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Todas as alíneas estão corretas."
    ],
    "score": 10
  },
  {
    "question": "As subunidades operacionais dos comandos territoriais são:",
    "choices": [
      "Os destacamentos, que se articulam localmente em subdestacamentos ou postos.",
      "Os destacamentos, que se articulam localmente em superesquadras ou postos.",
      "Os grupos, que se articulam localmente em subdestacamentos ou postos.",
      "Os destaçamentos, que se articulam localmente em subdestacamentos ou esquadras."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Os destacamentos, que se articulam localmente em subdestacamentos ou postos."
    ],
    "score": 10
  },
  {
    "question": "A categoria profissional de guardas compreende os seguintes postos:",
    "choices": [
      "Cabo-adjunto.",
      "Primeiro-cabo.",
      "Segundo-cabo.",
      "Todas as alíneas estão incorretas."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Todas as alíneas estão incorretas."
    ],
    "score": 10
  },
  {
    "question": "Na Guarda existem as seguintes unidades:",
    "choices": [
      "O Comando-Geral.",
      "De representação, a Unidade de Segurança e Honras de Estado.",
      "Teritoriais, os comandos territoriais.",
      "Todas as alíneas estão corretas."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Todas as alíneas estão corretas."
    ],
    "score": 10
  },
  {
    "question": "Constituem atribuições da Guarda Nacional Republicana:",
    "choices": [
      "Prevenir situações de tráfico e consumo de estupefacientes ou outras substâncias proibidas.",
      "Detectar situações de tráfico e consumo de estupefacientes ou outras substâncias proibidas.",
      "Prevenir e detectar situações de tráfico e consumo de estupefacientes ou outras substâncias proibidas.",
      "Todas as alíneas estão erradas."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Prevenir e detectar situações de tráfico e consumo de estupefacientes ou outras substâncias proibidas."
    ],
    "score": 10
  },
  {
    "question": "A Guarda Nacional Republicana e as suas unidades, incluindo as unidades constituídas para actuar fora do território nacional e o estabelecimento de ensino, têm direito ao uso:",
    "choices": [
      "Estandarte nacional.",
      "Galhardete nacional.",
      "Mascote nacional.",
      "Todas as alíneas estão incorretas."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Estandarte nacional."
    ],
    "score": 10
  },
  {
    "question": "De quem é a famosa frase 'Penso, logo existo'?",
    "choices": [
      "Platão",
      "Galileu Galilei",
      "Descartes",
      "Sócrates"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Descartes"
    ],
    "score": 10
  },
  {
    "question": "Em que país foi inventado o chuveiro elétrico?",
    "choices": [
      "França",
      "Inglaterra",
      "Brasil",
      "Austrália"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Brasil"
    ],
    "score": 10
  },
  {
    "question": "Quais o menor e o maior país do mundo?",
    "choices": [
      "Vaticano e Rússia",
      "Nauru e China",
      "Mónaco e Canadá",
      "Malta e Estados Unidos"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Vaticano e Rússia"
    ],
    "score": 10
  },
  {
    "question": "Quanto tempo demora a Lua a dar a volta à Terra (período orbital)?",
    "choices": [
      "27 dias e 8 horas",
      "25 dias",
      "30 dias",
      "29 dias e 12 horas"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "27 dias e 8 horas"
    ],
    "score": 10
  },
  {
    "question": "Quem recebeu um prémio Nobel devido ao desenvolvimento da encefalografia arterial?",
    "choices": [
      "Júlio de Matos",
      "Egas Moniz",
      "Michael Faraday",
      "Sousa Martins"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Egas Moniz"
    ],
    "score": 10
  },
  {
    "question": "Segundo a Declaração Universal dos Direitos Humanos, ninguém pode ser arbitrariamente:",
    "choices": [
      "Preso.",
      "Detido.",
      "Exilado.",
      "Todas as alíneas estão corretas."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Todas as alíneas estão corretas."
    ],
    "score": 10
  },
  {
    "question": "O Tratado que em 1951 instituiu a Comunidade Europeia do Carvão e do Aço foi:",
    "choices": [
      "O Tratado de Maastricht.",
      "O Tratado de Lisboa.",
      "O Tratado de Amesterdão.",
      "O Tratado de Paris."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "O Tratado de Paris."
    ],
    "score": 10
  },
  {
    "question": "São objetivos da União Europeia no interior das suas fronteiras:",
    "choices": [
      "Promover a paz, os seus valores e o bem-estar dos seus cidadãos.",
      "Combater a exclusão social e a discriminação.",
      "Promover a justiça e a proteção sociais, a igualdade entre homens e mulher e a proteção dos direitos da criança. ",
      "Todas as alíneas estão corretas."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Todas as alíneas estão corretas."
    ],
    "score": 10
  },
  {
    "question": "De acordo com a Declaração Universal dos Direitos Humanos, toda a pessoa tem o direito de:",
    "choices": [
      "Circular livremente.",
      "Escolher a sua residência no interior de um Estado.",
      "Abandonar o país em que se encontra.",
      "Todas as alíneas estão corretas."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Todas as alíneas estão corretas."
    ],
    "score": 10
  },
  {
    "question": "De acordo com a Declaração Universal dos Direitos Humanos:",
    "choices": [
      "Todo o ser humano pode ser condenado por acções ou omissões que, no momento da sua prática, não constituíam acto delituoso à face do direito interno ou internacional.",
      "Todo o ser humano pode sofrer intromissões arbitrárias na sua vida privada, na sua família, no seu domicílio ou na sua correspondência.",
      "Todo o ser humano pode ser obrigado a fazer parte de uma associação.",
      "Todo o ser humano tem direito de acesso, em condições de igualdade, às funções públicas do seu país."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Todo o ser humano tem direito de acesso, em condições de igualdade, às funções públicas do seu país."
    ],
    "score": 10
  },
  {
    "question": "Qual é a função sintática do termo 'de leitura' na frase: 'Ele gosta de leitura.'?",
    "choices": [
      "Objeto direto",
      "Objeto indireto",
      "Complemento oblíquo",
      "Adjunto adnominal"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Complemento oblíquo"
    ],
    "score": 10
  },

  {
    "question": "Qual das seguintes palavras é um pronome relativo?",
    "choices": [
      "Que",
      "Ele",
      "Nosso",
      "Aquele"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Que"
    ],
    "score": 10
  },
  {
    "question": "Em qual das frases há um erro de regência nominal?",
    "choices": [
      "Tenho necessidade de apoio.",
      "Estou orgulhoso do meu trabalho.",
      "Ele é responsável por tudo.",
      "Ele é indiferente à opinião dos outros."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Estou orgulhoso do meu trabalho."
    ],
    "score": 10
  },
  {
    "question": "Qual é o tempo verbal da frase: 'Se eu soubesse, teria ajudado.'?",
    "choices": [
      "Pretérito perfeito do indicativo",
      "Pretérito imperfeito do subjuntivo",
      "Futuro do presente do indicativo",
      "Pretérito mais-que-perfeito do indicativo"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Pretérito imperfeito do subjuntivo"
    ],
    "score": 10
  },
  {
    "question": "Na frase 'Eles chegaram a tempo', o termo 'a tempo' exerce a função de:",
    "choices": [
      "Objeto direto",
      "Adjunto adverbial de tempo",
      "Complemento nominal",
      "Predicativo do sujeito"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Adjunto adverbial de tempo"
    ],
    "score": 10
  },
  {
    "question": "Qual das frases contém uma locução adverbial?",
    "choices": [
      "Ele estudou com afinco.",
      "Ele corre rapidamente.",
      "Ela estuda muito.",
      "Nós lemos o livro."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Ele estudou com afinco."
    ],
    "score": 10
  },
  {
    "question": "Em qual das frases o termo sublinhado é um adjunto adnominal?",
    "choices": [
      "O livro _do Pedro_ está em cima da mesa.",
      "Ela _chegou cedo_ à festa.",
      "Fizemos o trabalho _com cuidado_.",
      "Ele comprou _um carro novo_."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "O livro _do Pedro_ está em cima da mesa."
    ],
    "score": 10
  },
  {
    "question": "Qual é a classificação da oração em destaque na frase: 'Eles disseram _que iam ao cinema_'?",
    "choices": [
      "Oração subordinada adverbial causal",
      "Oração subordinada substantiva objetiva direta",
      "Oração subordinada adjetiva restritiva",
      "Oração subordinada adverbial condicional"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Oração subordinada substantiva objetiva direta"
    ],
    "score": 10
  },
  {
    "question": "Em qual das frases o verbo 'ter' está no pretérito mais-que-perfeito do indicativo?",
    "choices": [
      "Eu tivera um sonho estranho.",
      "Tenho tido sorte.",
      "Terei um novo emprego.",
      "Eu tive uma boa notícia ontem."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Eu tivera um sonho estranho."
    ],
    "score": 10
  },
  {
    "question": "Qual das opções contém uma metáfora?",
    "choices": [
      "O tempo é ouro.",
      "Correu como o vento.",
      "A árvore estava cheia de frutos.",
      "Ele estuda bastante."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "O tempo é ouro."
    ],
    "score": 10
  }

  ],
}

// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const crp_1: Topic = {
  topic: 'CRP (1)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "O mandato do Presidente da República tem a duração de quantos anos?",
      "choices": [
        "4",
        "5",
        "6",
        "8"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "5"
      ],
      "score": 10
    },
    {
      "question": "São elegíveis os cidadãos eleitores para Presidente da República, portugueses de origem, a partir de que idade?",
      "choices": [
        "Maior que 33",
        "Maior que 34",
        "Maior que 35",
        "Maior que 36"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Maior que 35"
      ],
      "score": 10
    },
    {
      "question": "Quantos mandatos consecutivos pode exercer o Presidente da República?",
      "choices": [
        "1",
        "2",
        "3",
        "Sem limite"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "2"
      ],
      "score": 10
    },
    {
      "question": "Quais são os atos legislativos existentes?",
      "choices": [
        "as leis, os decretos-leis e os decretos legislativos regionais",
        "as leis, os decretos-leis",
        "as leis, regulamentos e decretos legislativos regionais",
        "as leis, os decretos-leis, regulamentos e decretos legislativos regionais"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "as leis, os decretos-leis e os decretos legislativos regionais"
      ],
      "score": 10
    },
    {
      "question": "Qual o número mínimo de deputados na AR?",
      "choices": [
        "160",
        "170",
        "180",
        "190"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "180"
      ],
      "score": 10
    },
    {
      "question": "Qual o número máximo de deputados na AR?",
      "choices": [
        "210",
        "220",
        "230",
        "240"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "230"
      ],
      "score": 10
    },
    {
      "question": "O Conselho de Ministros é constituído por:",
      "choices": [
        "Primeiro-Ministro, pelos Vice-Primeiros-Ministros, Secretários e Subsecretários de Estado.",
        "Primeiro-Ministro e pelos Ministros.",
        "Primeiro-Ministro, pelos Vice-Primeiros-Ministros, se os houver, e pelos Ministros.",
        "Primeiro-Ministro, Secretários e Subsecretários de Estado."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Primeiro-Ministro, pelos Vice-Primeiros-Ministros, se os houver, e pelos Ministros."
      ],
      "score": 10
    },
    {
      "question": "O Governo é responsável perante quem?",
      "choices": [
        "Presidente da República, Tribunais e a Assembleia da República",
        "Assembleia da República",
        "Presidente da República, Tribunais",
        "Presidente da República e a Assembleia da República"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Presidente da República e a Assembleia da República"
      ],
      "score": 10
    },
    {
      "question": "Qual é o órgão de condução da política geral do país e o órgão superior da administração pública?",
      "choices": [
        "Presidente da República",
        "Assembleia da República",
        "Governo",
        "Tribunais"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Governo"
      ],
      "score": 10
    },
    {
      "question": "Qual o órgão superior do Ministério Público?",
      "choices": [
        "Presidente da República",
        "PGR (Procuradoria Geral da República)",
        "Assembleia da República",
        "Governo"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "PGR (Procuradoria Geral da República)"
      ],
      "score": 10
    },
    {
      "question": "No continente quais são as autarquias locais?",
      "choices": [
        "Freguesias, os municípios e as regiões administrativas",
        "Freguesias e os municípios",
        "Municípios e as regiões administrativas",
        "Freguesias e as regiões administrativas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Freguesias, os municípios e as regiões administrativas"
      ],
      "score": 10
    },
    {
      "question": "Quem tem o poder de vetar?",
      "choices": [
        "Assembleia da República",
        "Governo",
        "Primeiro Ministro",
        "Presidente da República"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Presidente da República"
      ],
      "score": 10
    },
    {
      "question": "A câmara municipal é o órgão executivo colegial do freguesia.",
      "choices": [
        "Verdadeiro",
        "Verdadeiro",
        "Falso",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "Cessa o dever de obediência sempre que o cumprimento das ordens ou instruções implique a prática de qualquer crime.",
      "choices": [
        "Verdadeiro",
        "Verdadeiro",
        "Falso",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "A câmara municipal é:",
      "choices": [
        "O edifício do município",
        "O órgão executivo colegial do município",
        "O órgão executivo colegial do município e assembleia municipal",
        "O órgão municipal do concelho"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O órgão executivo colegial do município"
      ],
      "score": 10
    },
    {
      "question": "Os partidos políticos concorrem para a organização e para a expressão da vontade popular, no respeito pelos princípios da independência nacional:",
      "choices": [
        "Da unidade do Estado e da democracia política",
        "Da unidade do Estado e dos interesses dos partidos políticos",
        "Da democracia política e nos interesses dos partidos políticos e dos sindicatos",
        "Da unidade do Estado, da democracia política e no interesse dos partidos políticos e da associação nacional de municípios"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Da unidade do Estado e da democracia política"
      ],
      "score": 10
    },
    {
      "question": "Em Portugal os Órgãos de Soberania são:",
      "choices": [
        "A Assembleia da República, o Governo e os Tribunais",
        "O Presidente da República, a Assembleia da República, o Governo e os Tribunais",
        "O Presidente da República e a Assembleia da República",
        "A assembleia da República"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Presidente da República, a Assembleia da República, o Governo e os Tribunais"
      ],
      "score": 10
    },
    {
      "question": "O Órgão de Soberania que garante a independência nacional e a unidade do Estado é o Presidente da República?",
      "choices": [
        "Não, é o Governo",
        "É o Presidente da República e a Assembleia da República",
        "Sim, e representa a República Portuguesa",
        "A assembleia da República"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Sim, e representa a República Portuguesa"
      ],
      "score": 10
    },
    {
      "question": "O Órgão de Soberania que assegura a condução da política geral do país é:",
      "choices": [
        "Assembleia da República",
        "O Governo",
        "Os órgãos de soberania em conjunto",
        "Os deputados"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Governo"
      ],
      "score": 10
    },
    {
      "question": "O Presidente da República é o Comandante Geral das Forças Armadas?",
      "choices": [
        "Sim, o Presidente da República é por inerência o Comandante Geral das Forças Armadas",
        "Não, é o Ministro da Defesa",
        "Sim, com a concordância dos Tribunais",
        "Não, é o Ministro da Administração Interna"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Sim, o Presidente da República é por inerência o Comandante Geral das Forças Armadas"
      ],
      "score": 10
    }
  ],
}

import React, { useState } from 'react';
import styled from 'styled-components';
import { auth } from '../firebase/firebaseConfig';
import updatePurchaseStatus from '../firebase/updatePurchaseStatus';
import { Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; // Importa o ícone do WhatsApp


const WhatsAppButton = styled(Button)`
  background-color: #25D366 !important;  /* Verde do WhatsApp */
  color: white !important;
  margin: 10px !important;
  font-size: 0.7rem !important;
  &:hover {
    background-color: #22bb5c !important;  /* Cor mais escura ao passar o mouse */
  }
`;

// Estilização do Container
const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  min-height: 100vh;
  margin-top: 80px;
  background-color: #f9f9f9;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const StyledTypography = styled(Typography)`
  margin-bottom: 20px;
  color: #333;
`;

const InstructionText = styled.p`
  font-size: 1.5rem;
  color: #555;
  margin: 10px 0;
  text-align: left;
  


  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const FileInput = styled.input`
  margin: 20px 0;
  padding: 10px;
  font-size: 1rem;
  width: 100%;
  max-width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 0.9rem;
  margin-top: 20px;
`;



const Mbway = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false); // Estado de carregamento
  const [error, setError] = useState(null); // Estado de erro


  // Função para converter a imagem para base64
  const encodeImageFileAsURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]); // Obtém a string base64 sem o prefixo
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handlePayPalApprove = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
        await updatePurchaseStatus(userId); // Atualiza o status do usuário para premium
        alert("Status premium ativado com sucesso!");
        navigate('/dashboard');
      } else {
        alert("Usuário não autenticado. Faça login novamente.");
        navigate('/login');
      }
    } catch (error) {
      console.error("Erro ao processar o status premium: ", error);
      alert("Ocorreu um erro ao ativar o status premium.");
    }
  };

  const handleFileUpload = async () => {
    if (!file) {
      alert("Por favor, carregue uma foto.");
      return;
    }

    setLoading(true); // Inicia o estado de carregamento
    setError(null); // Limpa o estado de erro anterior

    try {
      // Codifica a imagem em base64
      const base64Image = await encodeImageFileAsURL(file);

      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer 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`
      };

      const payload = {
        model: "gpt-4o", // Exemplo de modelo, ajuste conforme necessário
        messages: [
          {
            role: "user",
            content: [
              {
                type: "text",
                text: 'É uma transferencia mbway?. Preciso que respondas "true" or "false" apenas, nada mais'
              },
              {
                type: "image_url",
                image_url: {
                  url: `data:image/jpeg;base64,${base64Image}`
                }
              }
            ]
          }
        ],
        max_tokens: 300
      };

      const response = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Erro ao carregar ficheiro. Por favor contactar suporte");
      }

      const data = await response.json();
      setResult(data); // Define o resultado da API

      console.log(data.choices[0].message.content)

      // Verifica se a resposta contém "true" para isMbwayTransferValid
      const completionText = data.choices[0].message.content.trim().toLowerCase();
      if (completionText == 'True' || completionText == 'true' || completionText == 'True.') {
        alert("Comprovativo registado. Verificação até 5 minutos");
        handlePayPalApprove(); // Ativa o status premium após a validação
      } else {
        alert("Transferência MBWay inválida. Contactar Suport");
      }

    } catch (error) {
      console.error("Erro ao validar a foto: ", error);
      setError(error.message); // Define a mensagem de erro
    } finally {
      setLoading(false); // Termina o estado de carregamento
    }
  };

  return (
    <StyledContainer>
      <StyledTypography variant="h4" gutterBottom>
        Pagamento MBWAY
      </StyledTypography>
      <WhatsAppButton
        variant="contained"
        startIcon={<WhatsAppIcon />}
        href="https://wa.me/351913450846"  // Substitua pelo link real de contato no WhatsApp
        target="_blank"
      >
        Suporte
      </WhatsAppButton>
      <br />
      <InstructionText><p><b>Passo 1: </b>Enviar 3,99 para o numero 913 450 846</p></InstructionText>
      <InstructionText><p><b>Passo 2: </b> Tirar print ou gravar comprovativo</p></InstructionText>
      <InstructionText><p><b>Passo 3: </b> Enviar comprovativo pela caixa de seleção</p></InstructionText>
      <br />
      <InstructionText><p>A verificação poderá demorar até 5 minutos</p></InstructionText>
      <br />
      <FileInput type="file" accept="image/*" onChange={handleFileChange} />
      <br />
      <Button variant="contained" onClick={handleFileUpload} style={{ marginTop: '20px' }} disabled={loading}>
        {loading ? 'Carregando...' : 'Enviar'}
      </Button>
      <br />
      
      {error && <div style={{ color: 'red' }}>Algo correu mal. Por favor contactar suporte </div>}

    

      <br />
     
    </StyledContainer>
  );
};

export default Mbway;

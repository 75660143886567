// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const gnr_provaCompleta_1: Topic = {
  topic: 'GNR - Prova Completa (1)',
  level: 'Advanced',
  totalQuestions: 50,
  totalScore: 500,
  totalTime: 1800,
  questions: [
    {
      "question": "No ato de ingresso, na respetiva categoria, é emitido e entregue ao militar da Guarda um documento de encarte onde conste o posto que sucessivamente ocupe. O documento de encarte, para a categoria de guardas, designa-se:",
      "choices": [
      "Carta-patente",
      "Diploma de encarte",
      "Certificado de encarte",
      "Diploma de curso"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "Certificado de encarte"
      ],
      "score": 10
  },
  {
      "question": "O regime de utilização dos transportes públicos coletivos pelos militares da Guarda é fixado em diploma próprio, tendo direito à sua utilização gratuita nas deslocações em serviço dentro da área de circunscrição em que exerce funções e entre a sua residência habitual e a localidade em que presta serviço, até à distância de:",
      "choices": [
      "100 km",
      "50 km",
      "25 km",
      "75 km"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "50 km"
      ],
      "score": 10
  },
  {
      "question": "Pode transitar para a situação de reserva o militar da Guarda na situação de ativo que preencha uma das seguintes condições:",
      "choices": [
      "Atinja o limite de idade estabelecido para o respetivo posto",
      "Declare, por escrito, desejar passar à reserva depois de completar 36 anos de tempo de serviço militar e 50 anos de idade",
      "Declare, por escrito, desejar passar à reserva depois de completar 30 anos de tempo de serviço militar e 55 anos de idade",
      "Seja promovido a guarda-principal"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "Atinja o limite de idade estabelecido para o respetivo posto"
      ],
      "score": 10
  },
  {
      "question": "Os limites máximos de idade de passagem à reserva, na categoria de Guardas, são os seguintes:",
      "choices": [
      "Cabo-mor - 62 anos; Restantes postos - 52 anos",
      "Cabo-mor - 60 anos; Restantes postos - 52 anos",
      "Cabo-mor - 60 anos; Restantes postos - 55 anos",
      "Cabo-mor - 60 anos; Restantes postos - 57 anos"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "Cabo-mor - 60 anos; Restantes postos - 57 anos"
      ],
      "score": 10
  },
  {
      "question": "O acesso ao posto imediato, mediante a existência de vaga no quadro a que pertence e a satisfação das condições de promoção, mantendo-se a antiguidade relativa em cada quadro corresponde a uma promoção:",
      "choices": [
      "Por escolha",
      "Por distinção",
      "Por antiguidade",
      "A título excecional"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "Por antiguidade"
      ],
      "score": 10
  },
  {
    "question": "O comandante-geral pode autorizar o adiamento ou suspensão da frequência do curso de promoção:",
    "choices": [
    "Por exigências de serviço, devidamente fundamentadas, e com a anuência do militar, por três vezes",
    "Por exigências de serviço, devidamente fundamentadas, e com a anuência do militar, por duas vezes",
    "A requerimento do interessado, por motivos de ordem pessoal, por duas vezes",
    "A requerimento do interessado, por motivos de ordem pessoal, por três vezes"
    ],
    "type": "MCQs",
    "correctAnswers": [
    "Por exigências de serviço, devidamente fundamentadas, e com a anuência do militar, por duas vezes"
    ],
    "score": 10
},
{
    "question": "Sempre que o curso de formação inicial tenha uma duração inferior a três anos, o militar é sujeito a avaliação a ter lugar em período probatório logo após a conclusão do curso. O período probatório tem a duração de:",
    "choices": [
    "Um ano e a forma de avaliação é fixada por despacho do ministro da tutela",
    "Um ano e a forma de avaliação é fixada por despacho do comandante-geral",
    "Dois anos e a forma de avaliação é fixada por despacho do comandante-geral",
    "Dois anos e a forma de avaliação é fixada por despacho do ministro da tutela sob proposta do comandante-geral"
    ],
    "type": "MCQs",
    "correctAnswers": [
    "Um ano e a forma de avaliação é fixada por despacho do comandante-geral"
    ],
    "score": 10
},

//2020_1_2_3 (1)

  {
    "question": "O militar da Guarda, no exercício das suas funções, é: ",
    "choices": [
      "Agente de segurança, nos termos da Lei Orgânica da Guarda Nacional Republicana.",
      "Agente da força pública, autoridade e órgão de polícia, quando não lhe deva ser atribuída qualidade superior, nos termos da Lei Orgânica da Guarda Nacional Republicana.",
      "Autoridade judiciária e órgão de polícia, nos termos da Lei Orgânica da Guarda Nacional Republicana.",
      "Agente de segurança e órgão de polícia, nos termos da Lei Orgânica da Guarda Nacional Republicana."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Agente da força pública, autoridade e órgão de polícia, quando não lhe deva ser atribuída qualidade superior, nos termos da Lei Orgânica da Guarda Nacional Republicana."
    ],
    "score": 10
  },
  {
    "question": "O documento de encarte, na categoria de guarda, designa-se:",
    "choices": [
      "Distintivo profissional, para todas as categorias.",
      "Carta-marear, para os militares do serviço marítimo.",
      "Carta-Brevet, para os militares do GIPS.",
      "Certificado de encarte, para os guardas."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Certificado de encarte, para os guardas."
    ],
    "score": 10
  },
  {
    "question": "A adequação, necessidade e proporcionalidade do uso da força significa que:",
    "choices": [
      "Deve ser usada sempre que possível para manter o respeito e a dignidade dos polícias.",
      "Usa os meios coercivos adequados e necessários à reposição da legalidade e da ordem, segurança e tranquilidade públicas quando estes se mostrem indispensáveis, necessários e suficientes ao bom cumprimento das suas funções e estejam esgotados os meios de persuasão.",
      "Os meliantes estavam a pedir uma boa dose de educação que lhes tem faltado e que os polícias devem dar para resolver a coisa.",
      "À falta de melhor solução, resta atuar em conformidade com os padrões morais adequados à boa educação geral."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Usa os meios coercivos adequados e necessários à reposição da legalidade e da ordem, segurança e tranquilidade públicas quando estes se mostrem indispensáveis, necessários e suficientes ao bom cumprimento das suas funções e estejam esgotados os meios de persuasão."
    ],
    "score": 10
  },
  {
    "question": "São condições especiais de promoção:",
    "choices": [
      "Avaliação do desempenho efetuada, em regra, pelos superiores hierárquicos imediatos, nos moldes previstos no presente Estatuto.",
      "Atos heroicos que fortaleçam a imagem da Guarda.",
      "Tempo mínimo de antiguidade no posto.",
      "Ficha curricular com registos distintos dos demais."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Tempo mínimo de antiguidade no posto."
    ],
    "score": 10
  },
  {
    "question": "A modalidade de curso que se destina a habilitar o militar para o desempenho de funções de nível e responsabilidade mais elevados é",
    "choices": [
      "Curso de formação inicial.",
      "Curso de promoção.",
      "Curso de especialização.",
      "Curso de qualificação."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Curso de promoção."
    ],
    "score": 10
  },
  {
    "question": "Os militares da Guarda Nacional Republicana agrupam-se por ordem decrescente de hierarquia:",
    "choices": [
      "Nas categorias de oficiais, sargentos e agentes.",
      "Nas categorias de oficiais, sargentos e guardas.",
      "Nas categorias de comissários, sargentos e guardas.",
      "Nas categorias de oficiais, agentes e guardas."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Nas categorias de oficiais, sargentos e guardas."
    ],
    "score": 10
  },
  {
    "question": "As funções do militar da Guarda Nacional Republicana classificam-se em:",
    "choices": [
      "Comando.",
      "Direção ou chefia.",
      "Execução.",
      "Todas as alíneas estão corretas."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Todas as alíneas estão corretas."
    ],
    "score": 10
  },
  {
    "question": "O militar da Guarda Nacional Republicana, no exercício das suas funções está:",
    "choices": [
      "Exclusivamente ao serviço do interesse público.",
      "Ao serviço de interesses corporativos.",
      "Ao serviço de interesses particulares.",
      "Exclusivamente ao serviço do interesse privado."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Exclusivamente ao serviço do interesse público."
    ],
    "score": 10
  },
  {
    "question": "O comando territorial é responsável pelo cumprimento da missão da Guarda na área de responsabilidade que lhe for atribuída, na dependência direta:",
    "choices": [
      "Do Comandante-geral",
      "Do Comandante operacional",
      "Do Comandante operacional ouvido o Comandante-geral",
      "Do Comandante-geral ouvido o Ministro da Administração Interna"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Do Comandante-geral"
    ],
    "score": 10
  },
  {
    "question": "O Comando Geral é comandado pelo:",
    "choices": [
      "Comandante operacional",
      "Chefe da secretaria-geral da Guarda",
      "Comandante-geral",
      "2º Comandante-geral"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Chefe da secretaria-geral da Guarda"
    ],
    "score": 10
  },
  {
    "question": "Podem ser destacadas ou colocadas com carácter permanente, forças da Unidade de Intervenção na dependência Orgânica dos Comandos Territoriais:",
    "choices": [
      "Por determinação do Comandante operacional",
      "Por decreto regulamentar do Ministro da Tutela",
      "Por determinação do Comandante-geral",
      "Por despacho do Ministro da Tutela"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Por despacho do Ministro da Tutela"
    ],
    "score": 10
  },
  {
    "question": "As atribuições da Guarda são prosseguidas em todo o território nacional e no mar territorial. No caso de atribuições cometidas simultaneamente à Polícia de Segurança Pública, a área de responsabilidade da Guarda é definida por portaria do:",
    "choices": [
      "Ministro da Tutela",
      "Ministro da Justiça",
      "Ministro das Finanças",
      "Ministro do Mar"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Ministro da Tutela"
    ],
    "score": 10
  },
  {
    "question": "O Dia da Guarda é comemorado a:",
    "choices": [
      "10 de junho, em evocação da lei que criou a atual instituição nacional, em 1911",
      "25 de abril, em evocação da lei que criou a atual instituição nacional, em 1921",
      "3 de maio, em evocação da lei que criou a atual instituição nacional, em 1911",
      "5 de outubro, em evocação da lei que criou a atual instituição nacional, em 1911"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "3 de maio, em evocação da lei que criou a atual instituição nacional, em 1911"
    ],
    "score": 10
  },
  {
    "question": "Nos termos da Lei Orgânica da GNR e no âmbito territorial, fora da sua área de responsabilidade, a intervenção da Guarda depende:",
    "choices": [
      "Do pedido de outra força de segurança; de ordem autárquica; ou de imposição legal.",
      "De imposição estatutária; de ordem especial; ou de imposição legal.",
      "Do pedido de outra força de segurança; de ordem especial; ou de imposição legal.",
      "De ordem ordinária; de ordem especial; ou de imposição legal."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Do pedido de outra força de segurança; de ordem especial; ou de imposição legal."
    ],
    "score": 10
  },
  {
    "question": "Os militares da Guarda são considerados:",
    "choices": [
      "Oficiais da força pública e de autoridade quando lhes não deva ser atribuída qualidade superior.",
      "Agentes da força pública e de imposição da lei quando lhes não deva ser atribuída qualidade superior.",
      "Agentes da força pública e de autoridade quando lhes não deva ser atribuída qualidade superior.",
      "Oficiais da força pública e de imposição da lei quando lhes não deva ser atribuída qualidade superior."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Agentes da força pública e de autoridade quando lhes não deva ser atribuída qualidade superior."
    ],
    "score": 10
  },
  {
    "question": "A UNT é a unidade especializada, no âmbito da fiscalização ordenamento e disciplina do trânsito e é comandada por:",
    "choices": [
      "Comandada por um tenente-general e coadjuvado por um diretor de formação.",
      "Comandada por um oficial general superior e coadjuvado por um 2º comandante.",
      "Comandada por coronel, coadjuvado por um 2.º comandante.",
      "Comandada por um major-general, coadjuvado por um diretor de formação de trânsito."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Comandada por coronel, coadjuvado por um 2.º comandante."
    ],
    "score": 10
  },
  {
    "question": "A estrutura de comando da Guarda compreende:",
    "choices": [
      "O Comando da Guarda; os órgãos superiores de comando e direção.",
      "A estrutura de comando, as unidades e o estabelecimento de ensino.",
      "Comando Operacional, o Comando da Administração dos Recursos Internos e o Comando da Doutrina e Formação.",
      "O órgão de inspeção, os órgãos de conselho e a Secretaria-Geral."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "A estrutura de comando, as unidades e o estabelecimento de ensino."
    ],
    "score": 10
  },
  {
    "question": "São órgãos superiores de comando e direção:",
    "choices": [
      "O Comando Operacional, o Comando da Administração dos Recursos Internos, o Comando da Doutrina e Formação e a Escola da Guarda.",
      "O Comando Operacional, o Comando da Administração dos Recursos Internos, o Comando da Doutrina e Formação e a Unidade de Serviços e Honras do Estado.",
      "O Comando Operacional, o Comando da Administração dos Recursos Internos e o Comando da Doutrina e Formação.",
      "O Comando Operacional, o Comando da Administração dos Recursos Internos, o Comando da Doutrina e Formação e as Unidades especializadas, de representação e de intervenção e reserva."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "O Comando Operacional, o Comando da Administração dos Recursos Internos e o Comando da Doutrina e Formação."
    ],
    "score": 10
  },
  {
    "question": "A Unidade de representação existente na Guarda é a:",
    "choices": [
      "Unidade de Controlo Costeiro.",
      "Unidade de Intervenção.",
      "Unidade de Segurança e Honras de Estado.",
      "Unidade de Protocolo e Relações Internacionais."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Unidade de Segurança e Honras de Estado."
    ],
    "score": 10
  },
  {
    "question": "O comandante do Comando da Administração dos Recursos Internos é:",
    "choices": [
      "Um tenente-general.",
      "Um oficial superior.",
      "Um major-general.",
      "Um coronel, com habilitações de Revisor Oficial de Contas e idoneidade atestada pelo Banco de Portugal."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Um major-general."
    ],
    "score": 10
  },
  {
    "question": "A Guarda Nacional Republicana e as suas unidades, incluindo as unidades constituídas para actuar fora do território nacional e o estabelecimento de ensino, têm direito ao uso:",
    "choices": [
      "Estandarte nacional.",
      "Galhardete nacional.",
      "Mascote nacional.",
      "Todas as alíneas estão erradas."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Estandarte nacional."
    ],
    "score": 10
  },
  {
    "question": "A Unidade de representação existente na Guarda Nacional Republicana é a:",
    "choices": [
      "Unidade de Controlo Fiscal.",
      "Unidade de Intervenção.",
      "Unidade de Segurança e Honras de Estado.",
      "Comando Local de Trânsito."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Unidade de Segurança e Honras de Estado."
    ],
    "score": 10
  },
  {
    "question": "Na Guarda Nacional Republicana existem as seguintes unidades:",
    "choices": [
      "O Comando-Geral.",
      "De representação, a Unidade de Segurança e Honras de Estado.",
      "Territoriais, os comandos territoriais.",
      "Todas as alíneas estão corretas."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Todas as alíneas estão corretas."
    ],
    "score": 10
  },
  {
    "question": "Os diamantes são constituídos por:",
    "choices": [
      "Grafite",
      "Berílio",
      "Óxido de alumínio",
      "Carbono"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Carbono"
    ],
    "score": 10
  },
  {
    "question": "Segundo o tratado assinado em 1971, que região do planeta ficou interdita a armas nucleares?",
    "choices": [
      "Sara",
      "África",
      "Antártida",
      "Bahamas"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Antártida"
    ],
    "score": 10
  },
  {
    "question": "Que expressão alemã significa 'guerra-relâmpago'?",
    "choices": [
      "Sauerkraut",
      "Glasnost",
      "Reichstag",
      "Blitzkrieg"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Blitzkrieg"
    ],
    "score": 10
  },
  {
    "question": "Por quantas estrelas é composta a bandeira da União Europeia?",
    "choices": [
      "8",
      "9",
      "10",
      "12"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "12"
    ],
    "score": 10
  },
  {
    "question": "Onde se situa o Banco Central Europeu?",
    "choices": [
      "Bruxelas",
      "Estrasburgo",
      "Genebra",
      "Frankfurt"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Frankfurt"
    ],
    "score": 10
  },
  {
    "question": ". A defesa dos interesses gerais da UE, mediante a apresentação de propostas legislativas e a execução da legislação, das políticas e do orçamento da EU, é função de:",
    "choices": [
      "Comissão Europeia.",
      "Conselho da União Europeia.",
      "Parlamento Europeu.",
      "Conselho Europeu."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Comissão Europeia."
    ],
    "score": 10
  },
  {
    "question": "As quatro principais instituições de decisão que dirigem a administração da UE são:",
    "choices": [
      "Parlamento Europeu, Conselho Europeu, Tribunal de Justiça da União Europeia e Comissão Europeia.",
      "Parlamento Europeu, Conselho Europeu, Conselho da União Europeia e Comissão Europeia.",
      "Banco Central Europeu, Conselho Europeu, Conselho da União Europeia e Comissão Europeia.",
      "Parlamento Europeu, Tribunal de Contas, Conselho da União Europeia e Comissão Europeia."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Parlamento Europeu, Conselho Europeu, Conselho da União Europeia e Comissão Europeia."
    ],
    "score": 10
  },
  {
    "question": "Os símbolos da União Europeia são:",
    "choices": [
      "O hino europeu, a bandeira europeia, o lema da União Europeia.",
      "O hino europeu, o Dia da Europa, a bandeira europeia, a divisa da União Europeia.",
      "O hino europeu, a constituição europeia, a bandeira europeia.",
      "O hino europeu, o Dia da Europa, a bandeira europeia, a constituição europeia."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "O hino europeu, o Dia da Europa, a bandeira europeia, a divisa da União Europeia."
    ],
    "score": 10
  },
  {
    "question": "Segundo a Constituição da República Portuguesa, os estrangeiros e os apátridas que se encontrem ou residam em Portugal: ",
    "choices": [
      "Gozam dos direitos e estão sujeitos aos deveres do cidadão português.",
      "Não gozam dos direitos e não estão sujeitos aos deveres do cidadão português.",
      "Apenas gozam dos direitos e deveres do seu país de origem.",
      "Não gozam dos direitos, mas estão sujeitos aos deveres do cidadão português."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Gozam dos direitos e estão sujeitos aos deveres do cidadão português."
    ],
    "score": 10
  },
  {
    "question": "Segundo a Constituição da República Portuguesa:",
    "choices": [
      "Todos os cidadãos têm direito à liberdade e segurança.",
      "Toda a pessoa privada da liberdade deve ser informada previamente e de forma compreensível das razões da sua prisão ou detenção e dos seus direitos.",
      "A privação da sua liberdade contra o disposto na Constituição e na lei constitui o Estado no dever de indemnizar o lesado nos termos que a lei estabelecer.",
      "Todas as alíneas estão corretas."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Todas as alíneas estão corretas."
    ],
    "score": 10
  },
  {
    "question": "Qual das frases está corretamente pontuada?",
    "choices": [
      "Ele disse: que ia chegar tarde.",
      "Ele disse que: ia chegar tarde.",
      "Ele disse que ia chegar tarde.",
      "Ele disse, que ia chegar tarde."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Ele disse que ia chegar tarde."
    ],
    "score": 10
  },
  {
    "question": "Qual das seguintes opções é um exemplo de oração subordinada adjetiva relativa restritiva?",
    "choices": [
      "O livro que me emprestaste é muito interessante.",
      "Embora chovesse, fomos ao parque.",
      "O Pedro, que é meu amigo, ajudou-me nos estudos.",
      "Tenho medo de que chova hoje."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "O livro que me emprestaste é muito interessante."
    ],
    "score": 10
  },
  {
    "question": "Em qual das frases o verbo está no futuro do conjuntivo?",
    "choices": [
      "Quando fores a Lisboa, visita-me.",
      "Se eu fosse rico, comprava uma casa.",
      "Vou a Lisboa no próximo fim de semana.",
      "Ele tinha ido ao mercado."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Quando fores a Lisboa, visita-me."
    ],
    "score": 10
  },
  {
    "question": "Que figura de estilo está presente na frase: 'O silêncio gritava na sala'?",
    "choices": [
      "Metáfora",
      "Hipérbole",
      "Personificação",
      "Eufemismo"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Personificação"
    ],
    "score": 10
  },
  {
    "question": "Qual das seguintes frases contém uma perífrase verbal?",
    "choices": [
      "Ele está a estudar para o exame.",
      "Eu estudo todos os dias.",
      "Eles viajaram no verão passado.",
      "Ela estuda há muitas horas."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Ele está a estudar para o exame."
    ],
    "score": 10
  },
  {
    "question": "Qual é o sujeito da frase: 'Chegaram atrasados ao concerto'?",
    "choices": [
      "Concerto",
      "Atrasados",
      "Eles",
      "Não tem sujeito"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Eles"
    ],
    "score": 10
  },
  {
    "question": "Na frase 'A casa foi destruída pelo fogo', o termo 'pelo fogo' desempenha a função de:",
    "choices": [
      "Sujeito",
      "Objeto direto",
      "Adjunto adverbial de modo",
      "Agente da passiva"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Agente da passiva"
    ],
    "score": 10
  },
  {
    "question": "Qual das seguintes palavras é uma forma verbal no infinitivo pessoal?",
    "choices": [
      "Comer",
      "Comermos",
      "Comendo",
      "Comia"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Comermos"
    ],
    "score": 10
  },
  {
    "question": "Qual das seguintes opções é um exemplo de frase complexa?",
    "choices": [
      "Estudei muito para o exame.",
      "Gosto de ler e de ouvir música.",
      "Cheguei cedo ao trabalho.",
      "Ele está cansado."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Gosto de ler e de ouvir música."
    ],
    "score": 10
  },
  {
    "question": "Em qual das frases a crase está corretamente utilizada?",
    "choices": [
      "Vou à festa amanhã.",
      "Gostaria de ir à a reunião.",
      "Eles foram à praia ao domingo.",
      "Ele está à estudar."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Vou à festa amanhã."
    ],
    "score": 10
  }

  ],
}

// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'



export const raciocinio_verbal_1: Topic = {
  topic: 'Raciocínio Verbal (1)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "Antecedente está para Consequente como Presente está para:",
      "choices": [
          "futuro",
          "agora",
          "hoje",
          "desenvolvimento",
          "actualidade"
      ],
      "type": "MCQs",
      "correctAnswers": [
          "futuro"
      ],
      "score": 10
  },
  {
      "question": "Calor está para Frio como Dia está para:",
      "choices": [
          "claro",
          "luz",
          "noite",
          "sombras",
          "manhã"
      ],
      "type": "MCQs",
      "correctAnswers": [
          "noite"
      ],
      "score": 10
  },
  {
      "question": "Vitória está para Derrota como Verdade está para:",
      "choices": [
          "mentira",
          "falso",
          "ilusão",
          "fantasia",
          "meia-verdade"
      ],
      "type": "MCQs",
      "correctAnswers": [
          "mentira"
      ],
      "score": 10
  },
  {
      "question": "Escritor está para Livro como Arquitecto está para:",
      "choices": [
          "construção",
          "planta",
          "tijolo",
          "desenho",
          "projecto"
      ],
      "type": "MCQs",
      "correctAnswers": [
          "projecto"
      ],
      "score": 10
  },
  {
      "question": "Sol está para Claro como Noite está para:",
      "choices": [
          "tarde",
          "luz",
          "escuridão",
          "manhã",
          "paz"
      ],
      "type": "MCQs",
      "correctAnswers": [
          "escuridão"
      ],
      "score": 10
  },
  {
      "question": "Ensinar está para Aprender como Vender está para:",
      "choices": [
          "oferecer",
          "comprar",
          "entregar",
          "negociar",
          "persuadir"
      ],
      "type": "MCQs",
      "correctAnswers": [
          "comprar"
      ],
      "score": 10
  },
  {
      "question": "Árvore está para Raiz como Casa está para:",
      "choices": [
          "porta",
          "telhado",
          "fundação",
          "paredes",
          "jardim"
      ],
      "type": "MCQs",
      "correctAnswers": [
          "fundação"
      ],
      "score": 10
  },
  {
      "question": "Pai está para Filho como Professor está para:",
      "choices": [
          "mentor",
          "aluno",
          "discípulo",
          "amigo",
          "colega"
      ],
      "type": "MCQs",
      "correctAnswers": [
          "aluno"
      ],
      "score": 10
  },
  {
      "question": "Cavalo está para Cavalariça como Avião está para:",
      "choices": [
          "pista",
          "aeroporto",
          "hangar",
          "terminal",
          "hangar de espera"
      ],
      "type": "MCQs",
      "correctAnswers": [
          "hangar"
      ],
      "score": 10
  },
  {
      "question": "Doce está para Açúcar como Salgado está para:",
      "choices": [
          "sal",
          "comida",
          "temperos",
          "pimenta",
          "arroz"
      ],
      "type": "MCQs",
      "correctAnswers": [
          "sal"
      ],
      "score": 10
  },
  {
    "question": "Dia está para Sol como Noite está para:",
    "choices": [
        "lua",
        "estrelas",
        "planeta",
        "sombra",
        "escuridão"
    ],
    "type": "MCQs",
    "correctAnswers": [
        "lua"
    ],
    "score": 10
},
{
    "question": "Livro está para Ler como Comida está para:",
    "choices": [
        "beber",
        "comer",
        "saborear",
        "olhar",
        "provar"
    ],
    "type": "MCQs",
    "correctAnswers": [
        "comer"
    ],
    "score": 10
},
{
    "question": "Palhaço está para Circo como Actor está para:",
    "choices": [
        "palco",
        "plateia",
        "filme",
        "camarim",
        "director"
    ],
    "type": "MCQs",
    "correctAnswers": [
        "palco"
    ],
    "score": 10
},
{
    "question": "Medo está para Coragem como Tristeza está para:",
    "choices": [
        "alegria",
        "satisfação",
        "contentamento",
        "paz",
        "ansiedade"
    ],
    "type": "MCQs",
    "correctAnswers": [
        "alegria"
    ],
    "score": 10
},
{
    "question": "Casa está para Abrigo como Escola está para:",
    "choices": [
        "saber",
        "aprendizagem",
        "ensino",
        "educação",
        "conhecimento"
    ],
    "type": "MCQs",
    "correctAnswers": [
        "educação"
    ],
    "score": 10
},
{
    "question": "Música está para Ouvido como Aroma está para:",
    "choices": [
        "nariz",
        "olfacto",
        "gosto",
        "paladar",
        "visão"
    ],
    "type": "MCQs",
    "correctAnswers": [
        "nariz"
    ],
    "score": 10
},
{
    "question": "Perdão está para Culpa como Remédio está para:",
    "choices": [
        "cura",
        "doença",
        "bem-estar",
        "prevenção",
        "tratamento"
    ],
    "type": "MCQs",
    "correctAnswers": [
        "doença"
    ],
    "score": 10
},
{
    "question": "Porto está para Barco como Estação está para:",
    "choices": [
        "carruagem",
        "comboio",
        "trilho",
        "via",
        "paragem"
    ],
    "type": "MCQs",
    "correctAnswers": [
        "comboio"
    ],
    "score": 10
},
{
    "question": "Cozinha está para Comer como Quarto está para:",
    "choices": [
        "dormir",
        "descanso",
        "silêncio",
        "privacidade",
        "conforto"
    ],
    "type": "MCQs",
    "correctAnswers": [
        "dormir"
    ],
    "score": 10
},
{
    "question": "Estrela está para Céu como Peixe está para:",
    "choices": [
        "oceano",
        "rio",
        "água",
        "lago",
        "mar"
    ],
    "type": "MCQs",
    "correctAnswers": [
        "água"
    ],
    "score": 10
}

    
  
  ],
}

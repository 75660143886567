// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'
import a1 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/a1.png'
import a2 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/a2.png'
import a3 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/a3.png'
import a4 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/a4.png'
import a5 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/a5.png'
import a6 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/a6.png'
import a7 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/a7.png'
import a8 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/a8.png'
import a9 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/a9.png'
import a10 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/a10.png'
import a11 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/a11.png'
import a12 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/a12.png'

import b1 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/b1.png'
import b2 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/b2.png'
import b3 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/b3.png'
import b4 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/b4.png'
import b5 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/b5.png'
import b6 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/b6.png'
import b7 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/b7.png'
import b8 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/b8.png'




export const matrizes_progressivas_1: Topic = {
  topic: 'Matrizes Progressivas (1)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "Assinale a resposta correta.",
      image: a1,
      "choices": [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "4"
      ],
      "score": 10
  },
  {
    "question": "Assinale a resposta correta.",
    image: a2,
    "choices": [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6"
    ],
    "type": "MCQs",
    "correctAnswers": [
    "5"
    ],
    "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: a3,
  "choices": [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "1"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: a4,
  "choices": [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "2"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: a5,
  "choices": [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "6"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: a6,
 "choices": [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "3"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: a7,
  "choices": [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "6"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: a8,
  "choices": [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "2"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: a9,
  "choices": [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "1"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: a10,
 "choices": [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "3"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: a11,
 "choices": [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "5"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: a12,
 "choices": [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "4"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: b1,
 "choices": [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "2"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: b2,
 "choices": [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "6"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: b3,
 "choices": [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "1"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: b4,
 "choices": [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "2"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: b5,
 "choices": [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "1"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: b6,
 "choices": [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "3"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: b7,
 "choices": [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "5"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: b8,
 "choices": [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "6"
  ],
  "score": 10
},
  
  ],
}

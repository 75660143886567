// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const gnr_estatutos_1: Topic = {
  topic: 'GNR - Estatutos (1)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "No ato de ingresso, na respetiva categoria, é emitido e entregue ao militar da Guarda um documento de encarte onde conste o posto que sucessivamente ocupe. O documento de encarte, para a categoria de guardas, designa-se:",
      "choices": [
      "Carta-patente",
      "Diploma de encarte",
      "Certificado de encarte",
      "Diploma de curso"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "Certificado de encarte"
      ],
      "score": 10
  },
  {
      "question": "O regime de utilização dos transportes públicos coletivos pelos militares da Guarda é fixado em diploma próprio, tendo direito à sua utilização gratuita nas deslocações em serviço dentro da área de circunscrição em que exerce funções e entre a sua residência habitual e a localidade em que presta serviço, até à distância de:",
      "choices": [
      "100 km",
      "50 km",
      "25 km",
      "75 km"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "50 km"
      ],
      "score": 10
  },
  {
      "question": "Pode transitar para a situação de reserva o militar da Guarda na situação de ativo que preencha uma das seguintes condições:",
      "choices": [
      "Atinja o limite de idade estabelecido para o respetivo posto",
      "Declare, por escrito, desejar passar à reserva depois de completar 36 anos de tempo de serviço militar e 50 anos de idade",
      "Declare, por escrito, desejar passar à reserva depois de completar 30 anos de tempo de serviço militar e 55 anos de idade",
      "Seja promovido a guarda-principal"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "Atinja o limite de idade estabelecido para o respetivo posto"
      ],
      "score": 10
  },
  {
      "question": "Os limites máximos de idade de passagem à reserva, na categoria de Guardas, são os seguintes:",
      "choices": [
      "Cabo-mor - 62 anos; Restantes postos - 52 anos",
      "Cabo-mor - 60 anos; Restantes postos - 52 anos",
      "Cabo-mor - 60 anos; Restantes postos - 55 anos",
      "Cabo-mor - 60 anos; Restantes postos - 57 anos"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "Cabo-mor - 60 anos; Restantes postos - 57 anos"
      ],
      "score": 10
  },
  {
      "question": "O acesso ao posto imediato, mediante a existência de vaga no quadro a que pertence e a satisfação das condições de promoção, mantendo-se a antiguidade relativa em cada quadro corresponde a uma promoção:",
      "choices": [
      "Por escolha",
      "Por distinção",
      "Por antiguidade",
      "A título excecional"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "Por antiguidade"
      ],
      "score": 10
  },
  {
      "question": "O militar da Guarda na situação de ativo ocupa um lugar na lista de antiguidade do quadro a que pertence, sendo inscrito no respetivo posto de ingresso por ordem decrescente de classificação no respetivo curso. Em caso de igualdade de classificação, a inscrição na lista de antiguidade do posto de ingresso de cada quadro obedece às seguintes prioridades:",
      "choices": [
      "1ª Maior idade; 2ª Maior antiguidade no posto anterior; 3ª Mais tempo de serviço efetivo; 4ª Maior graduação anterior",
      "1ª Maior antiguidade no posto anterior; 2ª Maior graduação anterior; 3ª Mais tempo de serviço efetivo; 4ª Maior idade",
      "1ª Maior idade; 2ª Maior graduação anterior; 3ª Mais tempo de serviço efetivo; 4ª Maior antiguidade no posto anterior",
      "1ª Maior graduação anterior; 2ª Maior antiguidade no posto anterior; 3ª Mais tempo de serviço efetivo; 4ª Maior idade"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "1ª Maior graduação anterior; 2ª Maior antiguidade no posto anterior; 3ª Mais tempo de serviço efetivo; 4ª Maior idade"
      ],
      "score": 10
  },
  {
      "question": "Os militares da categoria de guardas distribuem-se por armas e por serviços. As armas são constituídas pelos quadros de:",
      "choices": [
      "Infantaria e artilharia",
      "Infantaria e administração",
      "Infantaria e cavalaria",
      "Infantaria, cavalaria e engenharia"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "Infantaria e cavalaria"
      ],
      "score": 10
  },
  {
      "question": "O militar da Guarda, para ser promovido, tem de satisfazer as condições gerais e especiais de promoção. São, entre outras, condições gerais de promoção, comuns a todos os militares:",
      "choices": [
      "Aptidão física e psíquica adequadas; Estar colocado na primeira ou segunda classe de comportamento",
      "Aptidão física e psíquica adequadas; Estar colocado na primeira, na segunda ou na terceira classe de comportamento",
      "Desempenho com eficiência das funções do seu posto; Estar colocado na primeira, na segunda ou na terceira classe de comportamento",
      "Desempenho com eficiência das funções do seu posto; Dedicação ao serviço"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "Aptidão física e psíquica adequadas; Estar colocado na primeira ou segunda classe de comportamento"
      ],
      "score": 10
  },
  {
      "question": "O militar da Guarda pode ser excluído temporariamente da promoção, ficando na situação de demorado ou preterido. A demora na promoção tem lugar quando o militar da Guarda:",
      "choices": [
      "Aguardar decisão do comandante-geral sobre parecer do órgão de conselho respetivo.",
      "Aguardar decisão do comandante do comando da administração dos recursos internos sobre parecer do órgão de conselho respetivo",
      "Aguardar decisão do comandante operacional sobre parecer do comandante do comando da administração dos recursos internos",
      "Aguardar decisão do ministro da tutela sobre parecer do comandante do comando da administração dos recursos internos"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "Aguardar decisão do comandante-geral sobre parecer do órgão de conselho respetivo."
      ],
      "score": 10
  },
  {
      "question": "A reclamação de um ato administrativo é facultativa, individual, dirigida por escrito, através das vias competentes, ao autor do ato, no prazo de:",
      "choices": [
      "30 dias, contados a partir do seu conhecimento pelo reclamante",
      "25 dias, contados a partir do seu conhecimento pelo reclamante",
      "20 dias, contados a partir do seu conhecimento pelo reclamante",
      "15 dias, contados a partir do seu conhecimento pelo reclamante"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "15 dias, contados a partir do seu conhecimento pelo reclamante"
      ],
      "score": 10
  },
  {
      "question": "O comandante-geral pode autorizar o adiamento ou suspensão da frequência do curso de promoção:",
      "choices": [
      "Por exigências de serviço, devidamente fundamentadas, e com a anuência do militar, por três vezes",
      "Por exigências de serviço, devidamente fundamentadas, e com a anuência do militar, por duas vezes",
      "A requerimento do interessado, por motivos de ordem pessoal, por duas vezes",
      "A requerimento do interessado, por motivos de ordem pessoal, por três vezes"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "Por exigências de serviço, devidamente fundamentadas, e com a anuência do militar, por duas vezes"
      ],
      "score": 10
  },
  {
      "question": "Sempre que o curso de formação inicial tenha uma duração inferior a três anos, o militar é sujeito a avaliação a ter lugar em período probatório logo após a conclusão do curso. O período probatório tem a duração de:",
      "choices": [
      "Um ano e a forma de avaliação é fixada por despacho do ministro da tutela",
      "Um ano e a forma de avaliação é fixada por despacho do comandante-geral",
      "Dois anos e a forma de avaliação é fixada por despacho do comandante-geral",
      "Dois anos e a forma de avaliação é fixada por despacho do ministro da tutela sob proposta do comandante-geral"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "Um ano e a forma de avaliação é fixada por despacho do comandante-geral"
      ],
      "score": 10
  },

  //2020_1_2_3 (1)

    {
      "question": "O militar da Guarda, no exercício das suas funções, é: ",
      "choices": [
        "Agente de segurança, nos termos da Lei Orgânica da Guarda Nacional Republicana.",
        "Agente da força pública, autoridade e órgão de polícia, quando não lhe deva ser atribuída qualidade superior, nos termos da Lei Orgânica da Guarda Nacional Republicana.",
        "Autoridade judiciária e órgão de polícia, nos termos da Lei Orgânica da Guarda Nacional Republicana.",
        "Agente de segurança e órgão de polícia, nos termos da Lei Orgânica da Guarda Nacional Republicana."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Agente da força pública, autoridade e órgão de polícia, quando não lhe deva ser atribuída qualidade superior, nos termos da Lei Orgânica da Guarda Nacional Republicana."
      ],
      "score": 10
    },
    {
      "question": "O documento de encarte, na categoria de guarda, designa-se:",
      "choices": [
        "Distintivo profissional, para todas as categorias.",
        "Carta-marear, para os militares do serviço marítimo.",
        "Carta-Brevet, para os militares do GIPS.",
        "Certificado de encarte, para os guardas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Certificado de encarte, para os guardas."
      ],
      "score": 10
    },
    {
      "question": "A adequação, necessidade e proporcionalidade do uso da força significa que:",
      "choices": [
        "Deve ser usada sempre que possível para manter o respeito e a dignidade dos polícias.",
        "Usa os meios coercivos adequados e necessários à reposição da legalidade e da ordem, segurança e tranquilidade públicas quando estes se mostrem indispensáveis, necessários e suficientes ao bom cumprimento das suas funções e estejam esgotados os meios de persuasão.",
        "Os meliantes estavam a pedir uma boa dose de educação que lhes tem faltado e que os polícias devem dar para resolver a coisa.",
        "À falta de melhor solução, resta atuar em conformidade com os padrões morais adequados à boa educação geral."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Usa os meios coercivos adequados e necessários à reposição da legalidade e da ordem, segurança e tranquilidade públicas quando estes se mostrem indispensáveis, necessários e suficientes ao bom cumprimento das suas funções e estejam esgotados os meios de persuasão."
      ],
      "score": 10
    },
    {
      "question": "Constitui um dos direitos do militar da Guarda:",
      "choices": [
        "Identificar-se mediante a exibição do bilhete de identidade militar da Guarda, documento que, no entanto, não substituí, o bilhete de identidade de cidadão nacional.",
        "Identificar-se mediante a exibição do bilhete de identidade militar da Guarda, documento que substitui, para todos os efeitos legais, fora de território nacional, o bilhete de identidade de cidadão nacional.",
        "Identificar-se mediante a exibição do bilhete de identidade militar da Guarda, documento que substitui, para todos os efeitos legais, em território nacional, o bilhete de identidade de cidadão nacional.",
        "Identificar-se mediante a exibição do bilhete de identidade militar da Guarda, documento que substitui, para todos os efeitos legais o bilhete de identidade de cidadão nacional, sempre que quiser assistir gratuitamente a espetáculos desportivos."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Identificar-se mediante a exibição do bilhete de identidade militar da Guarda, documento que substitui, para todos os efeitos legais, em território nacional, o bilhete de identidade de cidadão nacional."
      ],
      "score": 10
    },
    {
      "question": "A nomeação e a colocação de militares obedecem aos seguintes princípios: ",
      "choices": [
        "Satisfação das necessidades ou o interesse do serviço.",
        "Satisfação das condições individuais de promoção.",
        "Aproveitamento da capacidade intelectual em função da experiência revelada.",
        "Primado dos interesses pessoais aos do serviço."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Satisfação das necessidades ou o interesse do serviço."
      ],
      "score": 10
    },
    {
      "question": "A idade geral de passagem à reserva é de:",
      "choices": [
        "Satisfação das necessidades ou o interesse do serviço.",
        "Satisfação das condições individuais de promoção.",
        "Aproveitamento da capacidade intelectual em função da experiência revelada.",
        "Primado dos interesses pessoais aos do serviço."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Satisfação das necessidades ou o interesse do serviço."
      ],
      "score": 10
    },
    {
      "question": "A idade geral de passagem à reserva é de:",
      "choices": [
        "55 anos.",
        "60 anos.",
        "65 anos.",
        "68 anos."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "55 anos."
      ],
      "score": 10
    },
    {
      "question": "A promoção que se efetua por ordem de cursos e, dentro do mesmo curso, por ordem decrescente de classificação obtida neste, é uma promoção por:",
      "choices": [
        "Antiguidade.",
        "Habilitação com curso adequado.",
        "Distinção.",
        "Escolha dos melhores."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Habilitação com curso adequado."
      ],
      "score": 10
    }
  ],
}

// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const linguaPortuguesa_3: Topic = {
  topic: 'Língua Portugesa (3)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "Qual das frases abaixo apresenta uma comparação?",
      "choices": [
        "Ele corre rápido como um leopardo.",
        "Aquele é o carro dele.",
        "Vamos ao cinema mais tarde.",
        "O trabalho foi concluído."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ele corre rápido como um leopardo."
      ],
      "score": 10
    },
    {
      "question": "Identifique a voz passiva na frase:",
      "choices": [
        "O relatório foi escrito pelo aluno.",
        "O aluno escreve o relatório.",
        "Escreveu-se um relatório.",
        "Os alunos escreveram o relatório."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O relatório foi escrito pelo aluno."
      ],
      "score": 10
    },
    {
      "question": "Qual das seguintes frases contém uma metáfora?",
      "choices": [
        "O João é uma raposa.",
        "Ele corre como um vento.",
        "O bolo foi partido.",
        "Ele leu o livro todo ontem."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O João é uma raposa."
      ],
      "score": 10
    },
    {
      "question": "Na frase 'Eu gosto de estudar à noite', o termo 'de estudar' desempenha a função de:",
      "choices": [
        "Complemento direto",
        "Objeto indireto",
        "Complemento oblíquo",
        "Adjunto adverbial"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Complemento oblíquo"
      ],
      "score": 10
    },
    {
      "question": "Qual é o valor semântico da conjunção 'para que' na frase: 'Ela estuda para que passe no exame'?",
      "choices": [
        "Causa",
        "Condição",
        "Finalidade",
        "Concessão"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Finalidade"
      ],
      "score": 10
    },
    {
      "question": "Em qual das frases o verbo está no pretérito imperfeito do indicativo?",
      "choices": [
        "Eu corria todos os dias.",
        "Eu corri ontem.",
        "Eu terei corrido amanhã.",
        "Eu correria se pudesse."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Eu corria todos os dias."
      ],
      "score": 10
    },
    {
      "question": "Qual é a função sintática do termo 'feliz' na frase: 'Ela parece feliz.'?",
      "choices": [
        "Predicativo do sujeito",
        "Sujeito",
        "Complemento direto",
        "Adjunto adverbial"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Predicativo do sujeito"
      ],
      "score": 10
    },
    {
      "question": "Em qual das frases há uma oração subordinada adverbial condicional?",
      "choices": [
        "Se ele vier, vamos à praia.",
        "Embora estivesse cansado, foi ao trabalho.",
        "Vou ao cinema porque gosto de filmes.",
        "Ela estuda tanto quanto ele."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Se ele vier, vamos à praia."
      ],
      "score": 10
    },
    {
      "question": "Qual das seguintes palavras é um advérbio?",
      "choices": [
        "Felizmente",
        "Feliz",
        "Felicidade",
        "Felicitar"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Felizmente"
      ],
      "score": 10
    },
    {
      "question": "Qual das opções apresenta uma oração subordinada adjetiva explicativa?",
      "choices": [
        "O carro, que está estacionado, é meu.",
        "O carro que comprei é vermelho.",
        "Vou ao cinema se terminar o trabalho.",
        "Embora esteja cansado, vou sair."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O carro, que está estacionado, é meu."
      ],
      "score": 10
    },
    {
      "question": "Qual das seguintes opções apresenta um sujeito composto?",
      "choices": [
        "O Pedro e o João foram ao cinema.",
        "Ela estuda todos os dias.",
        "Nós fomos ao parque.",
        "Ele foi à escola."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Pedro e o João foram ao cinema."
      ],
      "score": 10
    },
    {
      "question": "Identifique o objeto direto na frase: 'O João leu o livro.'",
      "choices": [
        "O João",
        "Leu",
        "O livro",
        "Nenhum"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O livro"
      ],
      "score": 10
    },
    {
      "question": "Qual das frases está no futuro do indicativo?",
      "choices": [
        "Eu irei à festa amanhã.",
        "Eu fui à festa ontem.",
        "Eu iria à festa, se pudesse.",
        "Eu tinha ido à festa."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Eu irei à festa amanhã."
      ],
      "score": 10
    },
    {
      "question": "Na frase 'Ele saiu rapidamente da sala', o termo 'rapidamente' é um:",
      "choices": [
        "Adjetivo",
        "Advérbio de modo",
        "Pronome",
        "Substantivo"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Advérbio de modo"
      ],
      "score": 10
    },
    {
      "question": "Em qual das frases o sujeito está corretamente identificado?",
      "choices": [
        "O livro foi lido pelo João. (Sujeito: O livro)",
        "Ela lê muitos livros. (Sujeito: livros)",
        "Foram ao cinema. (Sujeito: cinema)",
        "Caiu um copo. (Sujeito: um copo)"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O livro foi lido pelo João. (Sujeito: O livro)"
      ],
      "score": 10
    },
    {
      "question": "Qual das seguintes frases apresenta uma oração subordinada substantiva objetiva direta?",
      "choices": [
        "Ele disse que estava cansado.",
        "Ela foi ao cinema.",
        "O filme é interessante.",
        "Ele pediu um café."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ele disse que estava cansado."
      ],
      "score": 10
    },
    {
      "question": "Na frase 'O gato subiu no telhado', o termo 'no telhado' é um:",
      "choices": [
        "Complemento direto",
        "Adjunto adverbial de lugar",
        "Predicativo do sujeito",
        "Objeto indireto"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Adjunto adverbial de lugar"
      ],
      "score": 10
    },
    {
      "question": "Qual das frases contém uma oração subordinada adverbial concessiva?",
      "choices": [
        "Embora estivesse chovendo, fomos ao parque.",
        "Se eu soubesse, teria ajudado.",
        "Ele chegou cedo para poder estudar.",
        "Ele é tão inteligente quanto ela."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Embora estivesse chovendo, fomos ao parque."
      ],
      "score": 10
    },
    {
      "question": "Em qual das frases o verbo está na voz reflexiva?",
      "choices": [
        "O menino vestiu-se rapidamente.",
        "O professor corrigiu as provas.",
        "O bolo foi feito pela avó.",
        "Eles ajudaram-se mutuamente."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O menino vestiu-se rapidamente."
      ],
      "score": 10
    },
    {
      "question": "Qual das seguintes palavras é um adjetivo?",
      "choices": [
        "Bonito",
        "Bonitamente",
        "Beleza",
        "Alegra"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Bonito"
      ],
      "score": 10
    }
  ],
}

// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const dudh_3: Topic = {
  topic: 'DUDH (3)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
      {
        "question": "O que significa o direito à alimentação adequada segundo a DUDH?",
        "choices": [
          "Que toda pessoa tem direito a uma alimentação suficiente e nutritiva",
          "Que o governo deve fornecer comida para todos",
          "Que apenas as crianças têm esse direito",
          "Que o direito à alimentação se aplica apenas a países em desenvolvimento"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "Que toda pessoa tem direito a uma alimentação suficiente e nutritiva"
        ],
        "score": 10
      },
      {
        "question": "O que significa o direito à moradia adequada na DUDH?",
        "choices": [
          "O direito de toda pessoa viver em uma moradia segura e digna",
          "O direito de morar onde quiser sem pagar",
          "O direito de construir moradia em qualquer lugar",
          "O direito de compartilhar moradia com a comunidade"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "O direito de toda pessoa viver em uma moradia segura e digna"
        ],
        "score": 10
      },
      {
        "question": "O que significa o direito à liberdade de opinião e expressão?",
        "choices": [
          "O direito de expressar suas opiniões livremente, sem interferência",
          "O direito de criticar o governo sem consequências",
          "O direito de impor suas opiniões aos outros",
          "O direito de expressar opinião apenas em ambientes privados"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "O direito de expressar suas opiniões livremente, sem interferência"
        ],
        "score": 10
      },
      {
        "question": "O que significa o direito à liberdade de crença religiosa na DUDH?",
        "choices": [
          "O direito de escolher, mudar e praticar sua religião livremente",
          "O direito de participar apenas da religião oficial do país",
          "O direito de ser forçado a seguir uma religião",
          "O direito de não ter nenhuma crença"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "O direito de escolher, mudar e praticar sua religião livremente"
        ],
        "score": 10
      },
      {
        "question": "O que é o direito à justiça, conforme estabelecido na DUDH?",
        "choices": [
          "O direito de ter acesso a tribunais justos e imparciais",
          "O direito de ser julgado apenas por seus pares",
          "O direito de evitar julgamento se achar que é inocente",
          "O direito de ter um julgamento demorado"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "O direito de ter acesso a tribunais justos e imparciais"
        ],
        "score": 10
      },
      {
        "question": "O que significa o direito ao salário justo e favorável segundo a DUDH?",
        "choices": [
          "O direito de receber remuneração suficiente para garantir uma vida digna",
          "O direito de ser promovido regularmente",
          "O direito de receber o mesmo salário, independentemente do trabalho realizado",
          "O direito de receber mais do que o necessário"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "O direito de receber remuneração suficiente para garantir uma vida digna"
        ],
        "score": 10
      },
      {
        "question": "O que significa o direito à proteção da família na DUDH?",
        "choices": [
          "O direito de receber apoio e proteção para garantir a unidade e o bem-estar familiar",
          "O direito de ter um grande número de filhos",
          "O direito de ser apoiado financeiramente pelo governo",
          "O direito de impor os valores familiares aos outros"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "O direito de receber apoio e proteção para garantir a unidade e o bem-estar familiar"
        ],
        "score": 10
      },
      {
        "question": "O que significa o direito à educação na DUDH?",
        "choices": [
          "O direito de ter acesso à educação gratuita nos níveis elementares",
          "O direito de frequentar qualquer universidade de sua escolha",
          "O direito de não frequentar a escola se desejar",
          "O direito de ser educado apenas em casa"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "O direito de ter acesso à educação gratuita nos níveis elementares"
        ],
        "score": 10
      },
      {
        "question": "De acordo com a DUDH, o que é o direito à proteção contra a violência?",
        "choices": [
          "O direito de toda pessoa viver livre de ameaças ou atos de violência",
          "O direito de ser protegido apenas contra violência física",
          "O direito de ser protegido apenas em casa",
          "O direito de ser protegido apenas em público"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "O direito de toda pessoa viver livre de ameaças ou atos de violência"
        ],
        "score": 10
      },
      {
        "question": "O que significa o direito à proteção do meio ambiente segundo a DUDH?",
        "choices": [
          "O direito de viver em um ambiente saudável e equilibrado",
          "O direito de explorar livremente os recursos naturais",
          "O direito de não ser afetado pelas mudanças climáticas",
          "O direito de poluir se for para o progresso econômico"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "O direito de viver em um ambiente saudável e equilibrado"
        ],
        "score": 10
      },
      {
        "question": "O que significa o direito à igualdade de remuneração?",
        "choices": [
          "O direito de receber o mesmo salário por trabalho de igual valor",
          "O direito de receber o mesmo salário, independentemente da produtividade",
          "O direito de ser pago de acordo com as preferências pessoais",
          "O direito de exigir salário mais alto em qualquer circunstância"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "O direito de receber o mesmo salário por trabalho de igual valor"
        ],
        "score": 10
      },
      {
        "question": "O que é o direito à liberdade de circulação segundo a DUDH?",
        "choices": [
          "O direito de se deslocar livremente dentro de um país e de escolher onde morar",
          "O direito de cruzar fronteiras sem documentação",
          "O direito de viajar gratuitamente",
          "O direito de se mudar de um país para outro sem restrições"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "O direito de se deslocar livremente dentro de um país e de escolher onde morar"
        ],
        "score": 10
      },
      {
        "question": "O que significa o direito à vida privada na DUDH?",
        "choices": [
          "O direito de não ser alvo de interferências arbitrárias na vida pessoal ou familiar",
          "O direito de viver isolado da sociedade",
          "O direito de não ter vizinhos",
          "O direito de não ser incomodado por familiares"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "O direito de não ser alvo de interferências arbitrárias na vida pessoal ou familiar"
        ],
        "score": 10
      },
      {
        "question": "O que significa o direito à participação cultural segundo a DUDH?",
        "choices": [
          "O direito de toda pessoa participar livremente da vida cultural da comunidade",
          "O direito de impor sua cultura aos outros",
          "O direito de promover apenas sua própria cultura",
          "O direito de criar uma nova cultura e exigir que seja seguida"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "O direito de toda pessoa participar livremente da vida cultural da comunidade"
        ],
        "score": 10
      },
      {
        "question": "De acordo com a DUDH, o que significa o direito ao bem-estar social?",
        "choices": [
          "O direito de toda pessoa ter acesso a recursos e serviços que garantam uma vida digna",
          "O direito de receber assistência financeira independentemente das circunstâncias",
          "O direito de ser mantido pelo governo em qualquer situação",
          "O direito de solicitar apoio financeiro sempre que desejar"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "O direito de toda pessoa ter acesso a recursos e serviços que garantam uma vida digna"
        ],
        "score": 10
      },
      {
        "question": "O que significa o direito à participação em eleições livres?",
        "choices": [
          "O direito de votar livremente e sem intimidação em eleições periódicas",
          "O direito de escolher os candidatos sem consulta popular",
          "O direito de evitar votar em determinadas situações",
          "O direito de votar apenas em eleições locais"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "O direito de votar livremente e sem intimidação em eleições periódicas"
        ],
        "score": 10
      },
      {
        "question": "O que significa o direito à proteção de minorias segundo a DUDH?",
        "choices": [
          "O direito das minorias de preservarem sua cultura, língua e tradições",
          "O direito das minorias de serem governadas por leis diferentes",
          "O direito de as minorias se separarem da sociedade maior",
          "O direito de as minorias terem privilégios especiais"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "O direito das minorias de preservarem sua cultura, língua e tradições"
        ],
        "score": 10
      },
      {
        "question": "O que significa o direito à proteção contra discriminação racial?",
        "choices": [
          "O direito de toda pessoa ser tratada de maneira igual, independentemente de sua raça",
          "O direito de algumas raças receberem tratamento preferencial",
          "O direito de discriminar como forma de preservar a identidade cultural",
          "O direito de exigir tratamento especial com base na raça"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "O direito de toda pessoa ser tratada de maneira igual, independentemente de sua raça"
        ],
        "score": 10
      },
      {
        "question": "O que significa o direito ao acesso a cuidados médicos segundo a DUDH?",
        "choices": [
          "O direito de toda pessoa ter acesso a serviços de saúde e medicamentos essenciais",
          "O direito de ser tratado apenas em hospitais privados",
          "O direito de exigir cuidados médicos sem pagamento",
          "O direito de cuidar da própria saúde sem assistência"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "O direito de toda pessoa ter acesso a serviços de saúde e medicamentos essenciais"
        ],
        "score": 10
      },
      {
        "question": "O que significa o direito à liberdade de imprensa segundo a DUDH?",
        "choices": [
          "O direito de toda pessoa acessar e divulgar informações sem censura",
          "O direito de publicar qualquer informação, mesmo que não seja verdadeira",
          "O direito de controlar a mídia para fins políticos",
          "O direito de os jornalistas escreverem sem responsabilidade"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "O direito de toda pessoa acessar e divulgar informações sem censura"
        ],
        "score": 10
      }
    
    
  ],
}

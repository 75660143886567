// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const gnr_estatutos_5: Topic = {
  topic: 'GNR - Estatutos (5)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "Os militares da Guarda Nacional Republicana agrupam-se por ordem decrescente de lmerarquia:",
      "choices": [
        "Nas categorias de oficiais, sargentos e agentes.",
        "Nas categorias de oficiais, sargentos e guardas.",
        "Nas categorias de comissários, sargentos e guardas.",
        "Nas categorias de oficiais, agentes e guardas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Nas categorias de oficiais, sargentos e guardas."
      ],
      "score": 10
    },
    {
      "question": "As funções do militar da Guarda Nacional Republicana classificam-se em:",
      "choices": [
        "Comando.",
        "Direção ou chefia.",
        "Execução.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda Nacional Republicana, no exercício das suas funções está:",
      "choices": [
        "Exclusivamente ao serviço do interesse público.",
        "Ao serviço de interesses corporativos.",
        "Ao serviço de interesses particulares.",
        "Exclusivamente ao serviço do interesse privado."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Exclusivamente ao serviço do interesse público."
      ],
      "score": 10
    },
    {
      "question": "As funções cometidas aos militares com o posto de Guarda e Guarda-principal são:",
      "choices": [
        "Adjunto Comandante de Posto.",
        "Comandante de Destacamento.",
        "Comandante de Posto.",
        "Funções executivas que lhe sejam determinadas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Funções executivas que lhe sejam determinadas."
      ],
      "score": 10
    },
    {
      "question": "O sistema de formação da Guarda Nactonal Republicana prevê as seguintes modalidades de curso:",
      "choices": [
        "Cursos de promoção e cursos de formação profissional.",
        "Cursos de formação inicial, cursos de promoção, cursos de especialização e cursos de qualificação.",
        "Cursos de formação inicial, estágios de promoção e cursos de especialidades.",
        "Cursos de formação inicial, estágios de promoção e cursos de especialização ou qualificação."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Cursos de formação inicial, cursos de promoção, cursos de especialização e cursos de qualificação."
      ],
      "score": 10
    },
    {
      "question": "A aptidão física e psíquica dos militares da Guarda Nacional Republicana é apreciada por meio de:",
      "choices": [
        "Inspeções psicológicas, juntas médicas, provas de aptidão física e exames psicotécnicos.",
        "Inspeções médicas, juntas médicas, provas de aptidão física e exames psicotécnicos.",
        "Inspeções psicológicas, inspeções médicas, provas de aptidão física e exames psicotécnicos.",
        "Inspeções médicas, provas de aptidão física e exames toxicológicos."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Inspeções médicas, juntas médicas, provas de aptidão física e exames psicotécnicos."
      ],
      "score": 10
    },
    {
      "question": "As promoções aos postos da categoria de guardas realizam-se mediante as seguintes modalidades:",
      "choices": [
        "A Guarda por habilitação com curso.",
        "A Cabo-chefe por habilitação com curso adequado.",
        "A Cabo-mor por antiguidade.",
        "A Guarda-principal por escolha."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A Guarda por habilitação com curso."
      ],
      "score": 10
    },

  // PC 2023 (1 chamada)

    {
      "question": "Designam-se por guardas provisórios:",
      "choices": [
        "Os candidatos admitidos ao curso de formação de praças.",
        "Os candidatos admitidos ao curso de formação de guardas.",
        "Os candidatos admitidos ao curso de promoção de guardas provisórios.",
        "Os formandos admitidos ao curso de formação de agentes."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Os candidatos admitidos ao curso de formação de guardas."
      ],
      "score": 10
    },
    {
      "question": "Ao militar da Guarda Nacional Republicana, na efetividade do serviço, é atribuído:",
      "choices": [
        "Bilhete de identidade de militar da Guarda Nacional Republicana.",
        "Distintivo profissional.",
        "O documento de encarte.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "Enquadrado nos princípios fundamentais, o militar da Guarda Nacional Republicana deve:",
      "choices": [
        "Acudir com rapidez e prestar auxílio em situações de catástrofe ou calamidade pública.",
        "Manifestar todo o empenho no socorro dos sinistrados e na atenuação dos danos.",
        "Promover a informação adequada à entidade de que depende.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "Constituem deveres dos militares da Guarda Nacional Republicana os constantes:",
      "choices": [
        "No Estatuto da respetiva lei orgânica.",
        "No regulamento de disciplina.",
        "No Estatuto, na respetiva lei orgânica, no regulamento de disciplina da Guarda Nacional Republicana e demais legislação em vigor.",
        "Todas as alíneas estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "No Estatuto, na respetiva lei orgânica, no regulamento de disciplina da Guarda Nacional Republicana e demais legislação em vigor."
      ],
      "score": 10
    },
    {
      "question": "O documento de encarte, consoante a categoria a que o militar pertence, designa-se por:",
      "choices": [
        "Carta-patente, para oficiais.",
        "Diploma de encarte, para sargentos.",
        "Certificado de encarte, para guardas.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "Em especial, o Regulamento de Disciplina da Guarda Nacional Republicana caracteriza, entre outros, o seguinte dever:",
      "choices": [
        "Dever de observação.",
        "Dever de parcialidade.",
        "Dever de atenção.",
        "Dever de lealdade."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Dever de lealdade."
      ],
      "score": 10
    },
    {
      "question": "O sistema de formação da Guarda Nacional Republicana prevê as seguintes modalidades de curso:",
      "choices": [
        "Cursos de promoção € cursos de formação profissional.",
        "Cursos de formação inicial, cursos de promoção, cursos de especialização e cursos de qualificação.",
        "Cursos de promoção inicial, estágios de promoção e cursos de especialidades.",
        "Cursos de formação inicial e cursos de formação profissional."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Cursos de formação inicial, cursos de promoção, cursos de especialização e cursos de qualificação."
      ],
      "score": 10
    },
    {
      "question": "Genericamente, as funções cometidas ao guarda-principal e ao guarda são as seguintes:",
      "choices": [
        "Apenas funções de comando.",
        "Comandante de esquadra, chefe de equipa, comandante de patrulha, de natureza executiva e atividades específicas do seu quadro e especialidade.",
        "Desempenho de atividades administrativas de elevada complexidade.",
        "Funções executivas que lhe sejam determinadas, especificas do seu quadro e especialidade."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Funções executivas que lhe sejam determinadas, especificas do seu quadro e especialidade."
      ],
      "score": 10
    },
    {
      "question": "A categoria de Guardas compreende os postos de:",
      "choices": [
        "Alferes, Cabo-chefe, Cabo, Guarda-principal e Guarda.",
        "Cabo-chefe, Cabo, Guarda-principal, Guarda e Guarda-Florestal.",
        "Cabo-chefe, Cabo, Guarda-principal, Cabo-mor e Guarda.",
        "Mestre-Florestal, Cabo-chefe, Guarda-principal e Guarda."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Cabo-chefe, Cabo, Guarda-principal, Cabo-mor e Guarda."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda Nacional Republicana deve adotar, em todas as situações:",
      "choices": [
        "Uma conduta estética.",
        "Uma conduta ética.",
        "Uma conduta imoral.",
        "Uma conduta pouco integra."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Uma conduta ética."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda Nacional Republicana:",
      "choices": [
        "Está permanentemente disponível para o serviço, desde que não sacrifique os interesses pessoais.",
        "Está parcialmente disponível para o serviço, ainda que com o sacrifício dos interesses pessoais.",
        "Está permanentemente disponível para o serviço, ainda que com o sacrifício dos interesses pessoais.",
        "Está parcialmente ausente no serviço, salvaguardando os interesses pessoais."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Está permanentemente disponível para o serviço, ainda que com o sacrifício dos interesses pessoais."
      ],
      "score": 10
    },
    {
      "question": "0 Estatuto dos Militares da GNR (EMGNR) aplica-se: ",
      "choices": [
        "Apenas aos oficiais, sargentos, guardas e funcionários civis que prestam serviço na GNR, que se encontrem na situação de ativo.",
        "Aos oficiais, sargentos, guardas, mestres-florestais e funcionários civis que prestam serviço na GNR, em qualquer situação.",
        "Apenas aos oficiais, sargentos e guardas-florestais que se encontrem na situação de ativo.",
        "Aos oficiais, sargentos e guardas, em qualquer situação."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Aos oficiais, sargentos e guardas, em qualquer situação."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda Nacional Republicana deve desenvolver permanentemente:",
      "choices": [
        "As qualidades pessoais, aptidões fisica e psíquica.",
        "As competências necessárias ao pleno exercício das funções.",
        "As competências necessárias ao cumprimento das missões atribuídas.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    }
  ],
}

// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const gnr_estatutos_2: Topic = {
  topic: 'GNR - Estatutos (2)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "São condições especiais de promoção:",
      "choices": [
        "Avaliação do desempenho efetuada, em regra, pelos superiores hierárquicos imediatos, nos moldes previstos no presente Estatuto.",
        "Atos heroicos que fortaleçam a imagem da Guarda.",
        "Tempo mínimo de antiguidade no posto.",
        "Ficha curricular com registos distintos dos demais."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Tempo mínimo de antiguidade no posto."
      ],
      "score": 10
    },
    {
      "question": "A modalidade de curso que se destina a habilitar o militar para o desempenho de funções de nível e responsabilidade mais elevados é",
      "choices": [
        "Curso de formação inicial.",
        "Curso de promoção.",
        "Curso de especialização.",
        "Curso de qualificação."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Curso de promoção."
      ],
      "score": 10
    },
    {
      "question": "A concessão de licença de férias obedece às seguintes regras:",
      "choices": [
        "Ter mais de um ano de serviço efetivo exceto no ano civil de ingresso.",
        "Pode sobrepor-se à frequência de estágios.",
        "Pode prejudicar a tramitação de processo disciplinar ou criminal em curso.",
        "Não pode ser interrompida por qualquer motivo."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ter mais de um ano de serviço efetivo exceto no ano civil de ingresso."
      ],
      "score": 10
    },
    {
      "question": "O segundo-sargento desempenha as funções de comando do:",
      "choices": [
        "Posto tipo C, adjunto de comando de posto tipo A, B e C, de comando de subunidades elementares operacionais, atividades de formação, administrativas, logísticas ou técnicas, e outras de natureza equivalente.",
        "Posto tipo B, adjunto de comando de posto tipo A, D e €, de comando de subunidades elementares operacionais, atividades de formação, administrativas, logísticas ou técnicas, e outras de natureza equivalente.",
        "Posto tipo C, adjunto de comando de posto tipo A, B e D, de comando de subunidades elementares operacionais, atividades de formação, administrativas, logísticas ou técnicas, e outras de natureza equivalente.",
        "Posto tipo A, adjunto de comando de posto tipo B, C e D, de comando de subunidades elementares operacionais. atividades de formação, administrativas, logísticas ou técnicas, e outras de natureza equivalente."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Posto tipo C, adjunto de comando de posto tipo A, B e C, de comando de subunidades elementares operacionais, atividades de formação, administrativas, logísticas ou técnicas, e outras de natureza equivalente."
      ],
      "score": 10
    },
    {
      "question": "As promoções aos postos da categoria de guardas realizam-se mediante as seguintes modalidades:",
      "choices": [
        "A guarda principal por antiguidade.",
        "A cabo por exceção.",
        "A cabo-chefe por proposta do Comandante de Unidade.",
        "A cabo-mor por distinção."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A guarda principal por antiguidade."
      ],
      "score": 10
    },

    //2020_1_2_3 (2)
    {
      "question": "O candidato a militar da Guarda, enquanto na frequência do curso de formação de guardas, é designado por:",
      "choices": [
        "Guarda.",
        "Guarda alistado.",
        "Guarda provisório.",
        "Soldado provisório."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Guarda provisório."
      ],
      "score": 10
    },
    {
      "question": "Constitui um dos deveres do militar da Guarda:",
      "choices": [
        "Comportar-se de acordo com a dignidade da sua função e da condição militar, mesmo fora dos atos de serviço.",
        "Comportar-se de acordo com as suas convicções pessoais na sua função e posto enquanto estiver ao serviço.",
        "Comportar-se de acordo com a dignidade da sua função e fora do serviço com as suas convicções pessoais.",
        "Comportar-se de acordo com as convicções que livremente escolheu, independentemente da condição de guarda nacional republicano."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Comportar-se de acordo com a dignidade da sua função e da condição militar, mesmo fora dos atos de serviço."
      ],
      "score": 10
    },
    {
      "question": "As funções do militar da Guarda classificam-se em:",
      "choices": [
        "Comando, direção ou chefia, estado-maior, chefia técnica e de execução.",
        "Comando, direção e de chefia.",
        "Comando, suporte administrativo e de execução.",
        "Comando e de execução."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Comando, direção ou chefia, estado-maior, chefia técnica e de execução."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda na situação de ativo ocupa um lugar na lista de antiguidade do quadro a que pertence, sendo inscrito no respetivo posto de ingresso por ordem decrescente: ",
      "choices": [
        "De classificação no respetivo curso.",
        "Da menor idade que tinha no fim do curso.",
        "Da maior habilitação literária no fim do curso.",
        "De ter menos tempo de serviço efetivo."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "De classificação no respetivo curso."
      ],
      "score": 10
    },
    {
      "question": "Considera-se na efetividade de serviço o militar da Guarda, no ativo, que se encontre:",
      "choices": [
        "Em comissão especial.",
        "No cumprimento de pena a que a legislação penal ou disciplinar atribua esse efeito.",
        "Suspenso de funções, enquanto medida administrativa do EMGNR.",
        "De licença sem direito a remuneração."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Suspenso de funções, enquanto medida administrativa do EMGNR."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda no ativo pode manter-se na situação de licença ilimitada sem cessação do vínculo pelo período máximo de: ",
      "choices": [
        "Seis anos seguidos ou interpolados.",
        "Seis anos seguidos, ou oito interpolados.",
        "Oito anos seguidos, ou seis interpolados.",
        "Oito anos seguidos ou interpolados."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Seis anos seguidos ou interpolados."
      ],
      "score": 10
    },
    {
      "question": "Com vista à sua valorização profissional e prestígio da instituição, o militar da Guarda pode frequentar qualquer curso complementar para a sua cultura geral ou especialização técnica, o mesmo é feito:",
      "choices": [
        "Com prejuízo do serviço.",
        "Os cursos com interesse para a Guarda implicam um aumento de 10% na remuneração base.",
        "Os cursos com interesse para a Guarda implicam um aumento de 10% no Suplemento Especial de Serviço.",
        "Sem prejuízo do serviço."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Sem prejuízo do serviço."
      ],
      "score": 10
    },
    {
      "question": "A licença sem remuneração para o exercício de funções em organismos internacionais é concedida por:",
      "choices": [
        "Despacho do comandante-geral, ouvido o Conselho Superior da Guarda.",
        "Despacho do comandante-geral, ouvido o Diretor de Recursos Humanos.",
        "Despacho do comandante-geral, ouvido o Comandante do CARI.",
        "Despacho do membro do Governo responsável pela área da administração interna, sob proposta do comandante-geral."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Despacho do membro do Governo responsável pela área da administração interna, sob proposta do comandante-geral."
      ],
      "score": 10
    },
    {
      "question": "Podem candidatar-se à frequência do curso de formação de sargentos os militares da categoria de guardas que satisfaçam as seguintes condições: ",
      "choices": [
        "Ter menos de 40 anos de idade em 31 de dezembro do ano de ingresso no curso.",
        "Ter menos de 30 anos de idade em 31 de dezembro do ano de ingresso no curso.",
        "Ter menos de 35 anos de idade em 31 de dezembro do ano de ingresso no curso.",
        "Ter menos de 45 anos de idade em 31 de dezembro do ano de ingresso no curso."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ter menos de 40 anos de idade em 31 de dezembro do ano de ingresso no curso."
      ],
      "score": 10
    },
    {
      "question": "É condição especial de promoção ao posto de guarda-principal ter o tempo mínimo de:",
      "choices": [
        "Oito anos de antiguidade no posto de guarda.",
        "Dez anos de antiguidade no posto de guarda.",
        "Dezoito anos de antiguidade no posto de guarda.",
        "Vinte anos de antiguidade no posto de guarda."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Oito anos de antiguidade no posto de guarda."
      ],
      "score": 10
    },
    {
      "question": "Sem prejuízo das condições gerais de admissão ao curso de formação inicial de guardas, as condições especiais de admissão são definidas por: ",
      "choices": [
        "Despacho do Secretário de Estado para as Guardas, na data de abertura do concurso.",
        "Despacho do Ministro da Tutela, na data de abertura do concurso.",
        "Despacho do comandante-geral, na data de abertura do concurso.",
        "Despacho do Secretário-geral, na data de abertura do concurso."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Despacho do comandante-geral, na data de abertura do concurso."
      ],
      "score": 10
    },
    {
      "question": "Os militares das Forças Armadas, em serviço na Guarda, regem-se pelo:",
      "choices": [
        "Estatuto dos Militares da Guarda, em exclusivo pois encontram-se ao serviço da Guarda.",
        "Estatuto dos Militares das Forças Armadas, em exclusivo pois pertencem às Forças Armadas.",
        "Estatuto dos Militares das Forças Armadas e pelo Estatuto dos Militares da Guarda, na parte aplicável.",
        "Estatuto dos Militares da Guarda e pelo Estatuto dos Militares das Forças Armadas, na parte aplicável."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Estatuto dos Militares das Forças Armadas e pelo Estatuto dos Militares da Guarda, na parte aplicável."
      ],
      "score": 10
    },


    //2020_1_2_3 (3)
    {
      "question": "Os militares da Guarda, após a frequência com aproveitamento nos cursos de acesso à respetiva categoria, prestam juramento de fidelidade, no caso dos guardas chama-se: ",
      "choices": [
        "Compromisso de vida.",
        "Compromisso de honra.",
        "Compromisso solene.",
        "Juramento de bandeira."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Compromisso de honra."
      ],
      "score": 10
    },
    {
      "question": "A antiguidade relativa entre militares pertencentes a quadros diferentes com o mesmo posto é determinada por:",
      "choices": [
        "Datas de antiguidade nesse posto.",
        "Despacho do Diretor de Recursos Internos.",
        "Média final do curso de antiguidades.",
        "Idade de cada militar no posto."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Datas de antiguidade nesse posto."
      ],
      "score": 10
    },
    {
      "question": "Para o ingresso na categoria de sargentos é exigido aproveitamento no curso de formação de sargentos da Guarda:",
      "choices": [
        "Ao qual é atribuído o nível 5 de qualificação do Sistema Nacional de Qualificações.",
        "Ao qual é atribuído o nível 3 de qualificação do Sistema Nacional de Qualificações.",
        "Ao qual é atribuído o nível 7 de qualificação do Sistema Nacional de Qualificações.",
        "Ao qual é atribuído o nível 4 de qualificação do Sistema Nacional de Qualificações."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ao qual é atribuído o nível 5 de qualificação do Sistema Nacional de Qualificações."
      ],
      "score": 10
    }
  ],
}

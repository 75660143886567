import React, { FC, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import axios from 'axios'; // Biblioteca para fazer requisições HTTP

import { AppLogo, Refresh } from '../../config/icons';
import { useQuiz } from '../../context/QuizContext';
import { device } from '../../styles/BreakPoints';
import { Flex, LogoContainer, ResizableBox } from '../../styles/Global';
import { refreshPage } from '../../utils/helpers';


import { Button as Button2 } from '@mui/material';
import Button from '../ui/Button';
import CodeSnippet from '../ui/CodeSnippet';
import QuizImage from '../ui/QuizImage';
import ResultOverview from './ResultOverview';
import RightAnswer from './RightAnswer';

import { FaMagic } from 'react-icons/fa';

import { auth, db } from '../../firebase/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

import { useNavigate } from 'react-router-dom';



// Painel de explicação estilizado
const ExplanationPanel = styled.div`
  background-color: ${({ theme }) => theme.colors.cardBackground};
  padding: 15px;
  border-radius: 8px;
  margin-top: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  color: ${({ theme }) => theme.colors.primaryText};
`;

const ResultScreenContainer = styled.div`
  max-width: 900px;
  margin: 30px auto;
  padding-top: 30px;
  @media ${device.md} {
    width: 90%;
    margin: 30px auto;
    padding-top: 300px;
  }
`;

const InnerContainer = styled.div`
  background: ${({ theme }) => theme.colors.cardBackground};
  border-radius: 4px;
  margin: 0 auto;
  margin-bottom: 40px;
  padding: 40px 90px 90px 90px;
  @media ${device.md} {
    padding: 15px;
  }
`;

const QuestionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  @media ${device.md} {
    flex-direction: column;
  }
`;

const QuestionNumber = styled.h6`
  font-size: clamp(16px, 5vw, 24px);
  font-weight: 500;
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.primaryText};
`;

const QuestionStyle = styled.span`
  font-size: clamp(16px, 5vw, 24px);
  font-weight: 500;
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.primaryText};
  margin-bottom: 20px;
  @media ${device.md} {
    margin-bottom: 10px;
  }
`;

interface AnswerProps {
  correct?: boolean;
  wrong?: boolean;
}

const Answer = styled.li<AnswerProps>`
  border: 1px solid ${({ theme }) => theme.colors.border};
  width: 90%;
  @media ${device.md} {
    width: 100%;
  }
  background: ${({ theme }) => theme.colors.answerBg};
  border-radius: 16px;
  font-size: clamp(16px, 5vw, 18px);
  font-weight: 600;
  padding: 15px;
  color: ${({ theme }) => theme.colors.secondaryText};
  margin-top: clamp(13px, calc(10px + 6 * ((100vw - 600px) / 1320)), 16px);

  ${({ correct }) =>
    correct &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.success};
      background-color: ${({ theme }) => theme.colors.successLight};
    `}

  ${({ wrong }) =>
    wrong &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.danger};
      background-color: ${({ theme }) => theme.colors.dangerLight};
    `}
`;

const Score = styled.span<{ right: boolean }>`
  font-weight: 500;
  font-size: 16px;
  color: ${({ right, theme }) =>
    right ? `${theme.colors.success}` : `${theme.colors.danger}`};
  margin-top: 4px;
  @media ${device.md} {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-right: 10px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

const MagicButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6c63ff; 
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 15px;
  border: none;
  margin-bottom: 10px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  i {
    margin-right: 8px; /* Espaçamento entre o ícone e o texto */
    font-size: 16px;
  }

  &:hover {
    background-color: #574bff; /* Alteração de cor ao hover */
  }

  &:active {
    background-color: #483aff;
  }
`;

const SmallButton = styled.button`
  font-size: 14px;
  font-weight: 600;
  padding: 10px 15px;
  border: none;
  margin-bottom: 10px;
  border-radius: 8px;
  cursor: pointer;;
  background-color: #f44336; /* Cor de alerta para report */
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d32f2f;
  }

  &:active {
    background-color: #c62828;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
`;

// Função para explicar a resposta com a API da OpenAI
const explainAnswerWithAI = async (question: string, correctAnswer: string) => {
  try {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-3.5-turbo',
        messages: [
          {
            role: 'system',
            content: 'Você é um assistente que ajuda a explicar questões em Portugues de portugal.',
          },
          {
            role: 'user',
            content: `A pergunta é:'${question}'. Faz-me uma breve explicação porque é que a resposta certa é: '${correctAnswer}'. Breve explicação, muito poucas palavras`,
          },
        ],
        max_tokens: 150,
        temperature: 0.7,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer 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`,
        },
      }
    );

    return response.data.choices[0].message.content.trim();
  } catch (error) {
    console.error('Erro ao explicar a resposta com AI:', error);
    return 'Não foi possível gerar a explicação no momento.';
  }
};

// Função para enviar o relatório de uma questão
const sendReport = async (
  question: string,
  choices: string[],
  correctAnswers: string[],
  selectedAnswer: string[]
) => {
  const formData = new FormData();
  formData.append('entry.1044558321', question); // Campo da pergunta
  formData.append('entry.1271381232', choices[0] || ''); // Campo Answer 1
  formData.append('entry.241690610', choices[1] || ''); // Campo Answer 2
  formData.append('entry.1024527538', choices[2] || ''); // Campo Answer 3
  formData.append('entry.1818667854', choices[3] || ''); // Campo Answer 4
  formData.append('entry.2010802445', correctAnswers.join(', ')); // Campo Correct Answer
  formData.append('entry.7890123456', selectedAnswer.join(', ')); // Campo Selected Answer

  try {
    const response = await fetch(
      'https://docs.google.com/forms/d/e/1FAIpQLSc27AYrMFbQVc84N8XccVu75g5HJjK-0nrPvU76qJ7uH9qSvQ/formResponse',
      {
        method: 'POST',
        body: formData,
        mode: 'no-cors',
      }
    );
    if (response) {
      alert('Report submitted successfully');
    }
  } catch (error) {
    console.error('Error submitting report', error);
  }
};

const ResultScreen: FC = () => {
  const { result } = useQuiz();
  const [explanations, setExplanations] = useState<{ [key: number]: string }>({});

  const handleExplain = async (index: number, question: string, correctAnswer: string) => {
    if (hasPurchased){
    const explanation = await explainAnswerWithAI(question, correctAnswer);
    setExplanations((prev) => ({
      ...prev,
      [index]: explanation,
    }));}
    else{

      setShowPurchaseReminder(true);
    }
  };

  const onClickRetry = () => {
    refreshPage();
  };

  const [hasPurchased, setHasPurchased] = useState<boolean | null>(null);
  

  const fetchPurchaseStatus = async () => {
    try {
      const userId = auth.currentUser?.uid;
      if (userId) {
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (userDoc.exists()) {
          setHasPurchased(userDoc.data().hasPurchased); // Verifica o campo correto 'hasPurchased'
        } else {
          setHasPurchased(false); // Se o documento do usuário não existir
        }
      } else {
        setHasPurchased(false); // Se o usuário não estiver logado
      }
    } catch (error) {
      console.error('Erro ao buscar o status de compra: ', error);
      setHasPurchased(false); // Em caso de erro, considera que não tem compra
    }
  };

  useEffect(() => {
    fetchPurchaseStatus();
  }, []);

  useEffect(() => {
    if (hasPurchased === false) {
      console.log("Free Account"); // Redireciona para a página de compra se não tiver realizado a compra
    }
  }, [hasPurchased]);

const navigate = useNavigate();
const [showPurchaseReminder, setShowPurchaseReminder] = useState(false);
const handlePurchaseNavigation = () => {
  setShowPurchaseReminder(false); // Fecha o modal
  navigate('/purchase');
};


  return (
    <ResultScreenContainer>
      <LogoContainer>
        <AppLogo />
      </LogoContainer>
      <InnerContainer>
        <ResultOverview result={result} />
        {result.map(
          (
            {
              question,
              choices,
              correctAnswers,
              selectedAnswer,
              score,
              isMatch,
            },
            index: number
          ) => {
            return (
              <QuestionContainer key={question}>
                <ResizableBox width="90%">
                  <Flex gap="4px">
                    <QuestionNumber>{`${index + 1}. `}</QuestionNumber>
                    <QuestionStyle>{question}</QuestionStyle>
                  </Flex>
                  <div>
                    <ul>
                      {choices.map((ans: string, idx: number) => {
                        const label = String.fromCharCode(65 + idx);
                        const correct =
                          selectedAnswer.includes(ans) &&
                          correctAnswers.includes(ans);
                        const wrong =
                          selectedAnswer.includes(ans) &&
                          !correctAnswers.includes(ans);

                        return (
                          <Answer key={ans} correct={correct} wrong={wrong}>
                            <span>{label}.</span>
                            {ans}
                            {wrong}
                          </Answer>
                        );
                      })}
                    </ul>
                    <>
  {!isMatch && (
    <RightAnswer
      correctAnswers={correctAnswers}
      choices={choices}
    />
  )}

  <FlexContainer>
    {/* Botão Explicar com AI alinhado à direita */}
    

    {/* Botão de Report alinhado à esquerda */}
    <SmallButton
      onClick={() =>
        sendReport(question, choices, correctAnswers, selectedAnswer)
      }
    >
      Report
    </SmallButton>

    <MagicButton onClick={() => handleExplain(index, question, correctAnswers[0])}>
      <FaMagic style={{ marginRight: '8px' }} /> Explicar com AI
    </MagicButton>

    {showPurchaseReminder && (
          <ModalOverlay>
            <ModalContent>
              <h2>Desbloqueia o Acesso Completo!</h2>
              <br />
              <p>
                Por apenas <strong> 3,99€</strong>, garante o acesso ilimitado a todas as provas e
                materiais. <br /><br />
                Prepara-te com o melhor conteúdo para as tuas provas!
              </p>

              <div style={{ marginTop: '15px'}}>
                <Button2
                  variant="contained"
                  color="primary"
                  onClick={handlePurchaseNavigation}
                  style={{ marginRight: '10px' }}
                >
                
                  Desbloquear por €3,99
                </Button2>
                </div>
                <div style={{ marginTop: '15px'}}>
                <Button2
                  variant="outlined"
                  color="secondary"
                  onClick={() => setShowPurchaseReminder(false)}
                >
                  Talvez mais tarde
                </Button2>
              </div>
            </ModalContent>
          </ModalOverlay>
        )}
    

  </FlexContainer>

  {/* Exibe a explicação abaixo do botão */}
  {explanations[index] && (
  <>
    <h3>OpenAI:</h3>
    <p>{explanations[index]}</p>
  </>
)}
</>
                  </div>
                </ResizableBox>
                <Score right={isMatch}>{`Pontos ${isMatch ? score : 0}`}</Score>
              </QuestionContainer>
            );
          }
        )}
      </InnerContainer>
      <Flex flxEnd>
        <Button
          text="Voltar"
          onClick={onClickRetry}
          icon={<Refresh />}
          iconPosition="left"
          bold
        />
      </Flex>
    </ResultScreenContainer>
  );
};

export default ResultScreen;

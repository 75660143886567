// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const crp_2: Topic = {
  topic: 'CRP (2)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "A Assembleia da República tem função legislativa?",
      "choices": [
        "Não, são os Tribunais",
        "Sim, é o órgão legislativo por excelência",
        "Assembleia da República e os Tribunais",
        "Não, é o órgão executivo"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Sim, é o órgão legislativo por excelência"
      ],
      "score": 10
    },
    {
      "question": "Os direitos dos cidadãos são assegurados:",
      "choices": [
        "Pela Assembleia da República e pelos Tribunais",
        "Pelos os Tribunais",
        "Pelo Governo",
        "Pelos órgãos de soberania"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Pelos os Tribunais"
      ],
      "score": 10
    },
    {
      "question": "O órgão de Soberania que garante o regular funcionamento das instituições democráticas é:",
      "choices": [
        "A Assembleia da República",
        "O Presidente da República",
        "O Governo e a Assembleia da República",
        "Os tribunais"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Presidente da República"
      ],
      "score": 10
    },
    {
      "question": "Os Órgãos de Soberania desempenham as suas funções de uma forma autónoma, não existindo entre eles vínculos hierárquicos?",
      "choices": [
        "Depende apenas do Presidente da República",
        "Não! Existem entre eles vínculos hierárquicos",
        "Sim, é o princípio da separação dos poderes",
        "Depende dos tribunais"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Sim, é o princípio da separação dos poderes"
      ],
      "score": 10
    },
    {
      "question": "Os Órgãos de Soberania que reprimem a violação da legalidade democrática são:",
      "choices": [
        "Os Tribunais e o a Assembleia da Republica",
        "Os Tribunais e o Presidente da República",
        "Os Tribunais",
        "A assembleia da República"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Os Tribunais"
      ],
      "score": 10
    },
    {
      "question": "A bandeira nacional, símbolo da soberania nacional, foi instaurada:",
      "choices": [
        "Pela Revolução de 5 de outubro de 1974",
        "Pela Revolução de 5 de outubro de 1910",
        "Pela Revolução do 25 de abril de 1974",
        "Pelo Conselho da Revolução em 1975"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Pela Revolução de 5 de outubro de 1910"
      ],
      "score": 10
    },
    {
      "question": "Quem pode ter a iniciativa de revisão constitucional?",
      "choices": [
        "Povo",
        "Presidente da república",
        "Deputados",
        "Ministros"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Deputados"
      ],
      "score": 10
    },
    {
      "question": "O que é um Habeas Corpus?",
      "choices": [
        "Pedido de indemnização por danos sofridos injustamente",
        "Pedido de prisão preventiva",
        "Pedido de reposição da garantia constitucional de liberdade",
        "Pedido de direitos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Pedido de reposição da garantia constitucional de liberdade"
      ],
      "score": 10
    },
    {
      "question": "Quem representa a República Portuguesa?",
      "choices": [
        "O presidente da República",
        "O presidente da assembleia da República",
        "Presidente do Tribunal Constitucional",
        "Primeiro-Ministro"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O presidente da República"
      ],
      "score": 10
    },
    {
      "question": "Por quantos juízes é composto o Tribunal Constitucional?",
      "choices": [
        "10",
        "6",
        "13",
        "14"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "13"
      ],
      "score": 10
    },
    {
      "question": "Quantos Representantes da República tem cada região autónoma?",
      "choices": [
        "2",
        "3",
        "4",
        "1"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "1"
      ],
      "score": 10
    },
    {
      "question": "Uma sessão legislativa tem a duração de:",
      "choices": [
        "1 ano",
        "1 ano e meio",
        "2 anos",
        "3 anos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "1 ano"
      ],
      "score": 10
    },
    {
      "question": "A Constituição da República Portuguesa pode ser revista, quantos anos após a data da publicação da última lei de revisão ordinária?",
      "choices": [
        "2 anos",
        "3 anos",
        "4 anos",
        "5 anos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "5 anos"
      ],
      "score": 10
    },
    {
      "question": "O conselho das forças armadas é presidido pelo:",
      "choices": [
        "Presidente da República e com membros eleitos pela Assembleia da república",
        "Ministro da defesa",
        "Ministro da administração Interna",
        "Pelo Governo"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Presidente da República e com membros eleitos pela Assembleia da república"
      ],
      "score": 10
    },
    {
      "question": "Em relação à soberania e legalidade:",
      "choices": [
        "A soberania, una e indivisível, reside no povo, que a exerce segundo as formas previstas na Constituição",
        "O Estado subordina-se à Constituição e funda-se na legalidade democrática",
        "A validade das leis e dos demais actos do Estado, das regiões autónomas, do poder local e de quaisquer outras entidades públicas depende da sua conformidade com a Constituição",
        "Todas as respostas anteriores estão corretas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as respostas anteriores estão corretas"
      ],
      "score": 10
    },
    {
      "question": "Qual/quais o(s) símbolo(s) da soberania Portuguesa?",
      "choices": [
        "Bandeira nacional e o hino",
        "O hino",
        "A bandeira nacional",
        "Nenhuma resposta anterior está correta"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A bandeira nacional"
      ],
      "score": 10
    },
    {
      "question": "Há a suspensão dos direitos dos cidadãos quando:",
      "choices": [
        "Os órgãos de soberania assim o declararem",
        "Quando altera o estado de emergência",
        "Quando o primeiro-ministro assim o entender",
        "Quando o cidadão não o merecer"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Quando altera o estado de emergência"
      ],
      "score": 10
    },
    {
      "question": "A prisão preventiva será submetida, no prazo máximo de:",
      "choices": [
        "48 horas",
        "24 horas",
        "72 horas",
        "96 horas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "48 horas"
      ],
      "score": 10
    },
    {
      "question": "Quais são os órgãos de soberania da República Portuguesa?",
      "choices": [
        "O Presidente da República, a Assembleia da República e o Governo",
        "O Presidente da República, a Assembleia da República, o Governo e os Deputados",
        "O Presidente da República, a Assembleia da República, o Governo e as Comissões",
        "O Presidente da República, a Assembleia da República, o Governo e os Tribunais"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Presidente da República, a Assembleia da República, o Governo e os Tribunais"
      ],
      "score": 10
    },
    {
      "question": "Os Deputados não respondem",
      "choices": [
        "criminal ou disciplinarmente pelos votos e opiniões que emitirem no exercício das suas funções",
        "disciplinarmente pelos votos e opiniões que emitirem no exercício das suas funções",
        "civil, criminal, contraordenacional ou disciplinarmente pelos votos e opiniões que emitirem no exercício das suas funções",
        "civil, criminal ou disciplinarmente pelos votos e opiniões que emitirem no exercício das suas funções"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "civil, criminal ou disciplinarmente pelos votos e opiniões que emitirem no exercício das suas funções"
      ],
      "score": 10
    }
  ],
}

// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const crp_dudh_eu_1: Topic = {
  topic: 'CRP, DUDH e UE (1)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "De acordo com a Constituição da República Portuguesa, o mandato do Presidente da República tem a duração de:",
      "choices": [
        "Seis anos.",
        "Cinco anos.",
        "Quatro anos.",
        "Três anos."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Cinco anos."
      ],
      "score": 10
    },
    {
      "question": "De acordo com a Constituição da República Portuguesa, durante o impedimento temporário do Presidente da República, bem como durante a vagatura do cargo até tomar posse o novo Presidente eleito, assumirá as funções:",
      "choices": [
        "O Presidente da Assembleia da República.",
        "O Primeiro-ministro.",
        "O Procurador Geral da República.",
        "O Ministro da Administração Interna."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Presidente da Assembleia da República."
      ],
      "score": 10
    },
    {
      "question": "De acordo com a Declaração Universal dos Direitos Humanos:",
      "choices": [
        "Todo o ser humano pode ser mantido em escravatura.",
        "Todo o ser humano pode ser mantido em servidão.",
        "Todo o ser humano tem direito à segurança pessoal.",
        "Todo o ser humano pode ser submetido a tortura ou, a penas e tratamentos cruéis"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todo o ser humano tem direito à segurança pessoal."
      ],
      "score": 10
    },
    {
      "question": "De acordo com a Declaração Universal dos Direitos Humanos:",
      "choices": [
        "Todo o ser humano pode ser arbitrariamente preso.",
        "Todo o ser humano pode ser arbitrariamente exilado.",
        "Todo o ser humano tem direito à vida.",
        "Todo o ser humano pode ser arbitrariamente detido."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todo o ser humano tem direito à vida."
      ],
      "score": 10
    },
    {
      "question": ". A defesa dos interesses gerais da UE, mediante a apresentação de propostas legislativas e a execução da legislação, das políticas e do orçamento da EU, é função de:",
      "choices": [
        "Comissão Europeia.",
        "Conselho da União Europeia.",
        "Parlamento Europeu.",
        "Conselho Europeu."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Comissão Europeia."
      ],
      "score": 10
    },
    {
      "question": "Enquanto uma das sete instituições oficiais da União Europeia, qual define as orientações e prioridades políticas gerais da União Europeia:",
      "choices": [
        "A Comissão Europeia.",
        "O Banco Central Europeu.",
        "O Parlamento Europeu.",
        "O Conselho Europeu."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Conselho Europeu."
      ],
      "score": 10
    },
    {
      "question": "Os membros do Conselho Europeu são:",
      "choices": [
        "Os Chefes de Estados ou de Governo dos Estados-Membros da EU, o presidente do Conselho da União Europeia e o Presidente da Comissão Europeia.",
        "Os Chefes de Estados ou de Governo dos Estados-Membros da EU, o presidente do Conselho Europeu e o Presidente da Comissão Europeia.",
        "Os Chefes de Estados ou de Governo dos Estados-Membros da EU, o presidente do Conselho da União Europeia, o Presidente do Conselho Europeu e o Presidente da Comissão Europeia.",
        "Os Chefes de Estados ou de Governo dos Estados-Membros da EU, o presidente do Conselho Europeu e o Presidente do Conselho da União Europeia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Os Chefes de Estados ou de Governo dos Estados-Membros da EU, o presidente do Conselho Europeu e o Presidente da Comissão Europeia."
      ],
      "score": 10
    },
    {
      "question": "O Tribunal de Contas Europeu:",
      "choices": [
        "Funciona como um órgão colegial formado por 27 Membros, um de cada Estado-Membro, designados pelo Conselho, após consulta do Parlamento Europeu, para um mandato de seis anos renovável. Os Membros elegem o Presidente de entre si, por um período de três anos renovável.",
        "Funciona como um órgão colegial formado por 25 Membros, um de cada Estado-Membro, designados pelo Conselho, após consulta do Parlamento Europeu, para um mandato de cinco anos renovável. Os Membros elegem o Presidente de entre si, por um período de dois anos renovável.",
        "Funciona como um órgão colegial formado por 26 Membros, um de cada Estado-Membro, designados pelo Conselho, após consulta do Parlamento Europeu, para um mandato de quatro anos renovável. Os Membros elegem o Presidente de entre si, por um período de dois anos renovável.",
        "Funciona como um órgão colegial formado por 23 Membros, um de cada Estado-Membro, designados pelo Conselho, após consulta do Parlamento Europeu, para um mandato de seis anos renovável. Os Membros elegem o Presidente de entre si, por um período de três anos renovável."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Funciona como um órgão colegial formado por 27 Membros, um de cada Estado-Membro, designados pelo Conselho, após consulta do Parlamento Europeu, para um mandato de seis anos renovável. Os Membros elegem o Presidente de entre si, por um período de três anos renovável."
      ],
      "score": 10
    },
    {
      "question": "As quatro principais instituições de decisão que dirigem a administração da UE são:",
      "choices": [
        "Parlamento Europeu, Conselho Europeu, Tribunal de Justiça da União Europeia e Comissão Europeia.",
        "Parlamento Europeu, Conselho Europeu, Conselho da União Europeia e Comissão Europeia.",
        "Banco Central Europeu, Conselho Europeu, Conselho da União Europeia e Comissão Europeia.",
        "Parlamento Europeu, Tribunal de Contas, Conselho da União Europeia e Comissão Europeia."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Parlamento Europeu, Conselho Europeu, Conselho da União Europeia e Comissão Europeia."
      ],
      "score": 10
    },
    {
      "question": "O Serviço Europeu para a Ação Externa:",
      "choices": [
        "Representa uma variedade de partes interessadas da sociedade civil de toda a Europa, incluindo empregadores, trabalhadores e representantes de outras organizações sociais, profissionais, económicas e culturais.",
        "É um organismo independente e imparcial que responsabiliza as instituições e agências da UE e promove a boa administração.",
        "É o serviço diplomático da União Europeia.",
        "Garante que todas as instituições e organismos da UE respeitam o direito à privacidade dos cidadãos quando processam os seus dados pessoais."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "É o serviço diplomático da União Europeia."
      ],
      "score": 10
    },
    {
      "question": "A entrada em circulação das moedas e notas de euro ocorreu em 01 de janeiro de 2002 em:",
      "choices": [
        "Dez países da União Europeia.",
        "Quinze países da União Europeia.",
        "Doze países da União Europeia.",
        "Oito países da União Europeia."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Doze países da União Europeia."
      ],
      "score": 10
    },
    {
      "question": "Os símbolos da União Europeia são:",
      "choices": [
        "O hino europeu, a bandeira europeia, o lema da União Europeia.",
        "O hino europeu, o Dia da Europa, a bandeira europeia, a divisa da União Europeia.",
        "O hino europeu, a constituição europeia, a bandeira europeia.",
        "O hino europeu, o Dia da Europa, a bandeira europeia, a constituição europeia."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O hino europeu, o Dia da Europa, a bandeira europeia, a divisa da União Europeia."
      ],
      "score": 10
    },
    {
      "question": "De acordo com a Declaração Universal dos Direitos Humanos:",
      "choices": [
        "Todo o ser humano pode ser condenado por acções ou omissões que, no momento da sua prática, não constituíam acto delituoso à face do direito interno ou internacional. ",
        "Todo o ser humano pode sofrer intromissões arbitrárias na sua vida privada, na sua família, no seu domicílio ou na sua correspondência. ",
        "Todo o ser humano pode ser obrigado a fazer parte de uma associação. ",
        "Todo o ser humano tem direito de acesso, em condições de igualdade, às funções públicas do seu país."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todo o ser humano tem direito de acesso, em condições de igualdade, às funções públicas do seu país."
      ],
      "score": 10
    },
    {
      "question": "O facto de todos os cidadãos gozarem dos direitos e estarem sujeitos aos deveres consignados na Constituição, corresponde ao seguinte princípio da Constituição da República Portuguesa: ",
      "choices": [
        "Princípio da Justiça.",
        "Princípio da Universalidade.",
        "Principio da Boa-Fé.",
        "Princípio da Igualdade."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Princípio da Universalidade."
      ],
      "score": 10
    },
    {
      "question": "A bandeira europeia é constituída por estrelas douradas dispostas em círculo sobre um fundo azul, correspondendo o seu número a: ",
      "choices": [
        "6 estrelas.",
        "12 estrelas.",
        "18 estrelas.",
        "27 estrelas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "12 estrelas."
      ],
      "score": 10
    },
    {
      "question": "O conselho europeu reúne geralmente:",
      "choices": [
        "Anualmente.",
        "Dois em dois anos.",
        "Duas vezes por ano.",
        "Quatro vezes por ano."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Quatro vezes por ano."
      ],
      "score": 10
    },
    {
      "question": "O principal órgão de decisão da União Europeia juntamente com o conselho da União Europeia é:",
      "choices": [
        "Comissão Europeia.",
        "Banco Central Europeu.",
        "Parlamento Europeu.",
        "Conselho Europeu."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Parlamento Europeu."
      ],
      "score": 10
    },
    {
      "question": "O órgão consultivo que representa as organizações de empregadores e de trabalhadores, bem como outros grupos de interesse é:",
      "choices": [
        "Comité das Regiões Europeu.",
        "Serviço das Publicações Oficiais.",
        "Comité Económico e Social Europeu.",
        "Serviço Europeu para a Acção Externa."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Comité Económico e Social Europeu."
      ],
      "score": 10
    },
    {
      "question": "O Tratado Europeu que em 13 de dezembro de 2007 alterou o tratado da União Europeia e que institui a Comunidade Europeia foi:",
      "choices": [
        "O Tratado de Maastricht.",
        "O Tratado de Amesterdão.",
        "O Tratado de Nice.",
        "O Tratado de Lisboa."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Tratado de Lisboa."
      ],
      "score": 10
    },
    {
      "question": "São objetivos da União Europeia no interior das suas fronteiras: ",
      "choices": [
        "Promover a paz, os seus valores e o bem-estar dos seus cidadãos.",
        "Combater a exclusão social e a discriminação.",
        "Promover a justiça e a proteção sociais, a igualdade entre homens e mulher e a proteção dos direitos da criança.",
        "Todas as anteriores estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as anteriores estão corretas."
      ],
      "score": 10
    }
    
  ],
}

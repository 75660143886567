// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const gnr_estatutos_4: Topic = {
  topic: 'GNR - Estatutos (4)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "Constituem direitos do militar da Guarda no cumprimento da sua missão, o possuir bilhete de identidade de militar da Guarda e:",
      "choices": [
        "Ter entrada livre, quando devidamente identificado quer em ato de serviço ou particular, em estabelecimentos e outros locais públicos ou abertos ao público para a realização de ações de interesse de serviço operacional, prevista em legislação especial.",
        "Ter entrada condicionada, mesmo se devidamente identificado e em ato ou missão de serviço, em estabelecimentos e outros locais públicos ou abertos ao público para a realização de ações de fiscalização derivado da natureza das competências dos espaços visados, desde que superiormente autorizados.",
        "Ter entrada livre, quando devidamente identificado, em estabelecimentos e outros locais públicos ou abertos ao público para a realização de ações que ajuíze concernentes à missão geral da Guarda, quando prevista e autorizada.",
        "Ter entrada livre, quando devidamente identificado e em ato ou missão de serviço, em estabelecimentos e outros locais públicos ou abertos ao público para a realização de ações de fiscalização ou de prevenção, superiormente autorizadas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ter entrada livre, quando devidamente identificado e em ato ou missão de serviço, em estabelecimentos e outros locais públicos ou abertos ao público para a realização de ações de fiscalização ou de prevenção, superiormente autorizadas."
      ],
      "score": 10
    },
    {
      "question": "A contagem da antiguidade do militar em cada posto reporta-se à:",
      "choices": [
        "Data fixada no respetivo documento oficial de promoção, considerando-se de menor antiguidade o promovido com data mais recente, salvo disposição em contrário prevista no presente Estatuto.",
        "Data fixada no respetivo documento oficial de promoção, considerando-se de maior antiguidade o promovido com data mais recente, salvo disposição em contrário prevista no presente Estatuto.",
        "Data fixada no respetivo documento oficial do concurso promocional, considerando-se de menor antiguidade o promovido com data mais recente, salvo disposição em contrário prevista no presente Estatuto.",
        "Data fixada no respetivo documento inicial de formação para promoção, considerando-se de menor antiguidade o promovido com data mais recente, salvo disposição em contrário prevista no presente Estatuto."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Data fixada no respetivo documento oficial de promoção, considerando-se de menor antiguidade o promovido com data mais recente, salvo disposição em contrário prevista no presente Estatuto."
      ],
      "score": 10
    },
    {
      "question": "A dispensa por iniciativa do comandante-geral, pode ter lugar sempre que:",
      "choices": [
        "O comportamento do militar indicie notórios desvios da condição de militar da Guarda, designadamente dos requisitos morais, éticos, militares ou técnico-profissionais exigidos pela sua qualidade e função.",
        "A avaliação do militar indicie incompatibilidades hierárquicas à condição de militar da Guarda, designadamente dos requisitos militares ou técnico-profissionais exigidos pela sua avaliação.",
        "O comportamento do militar indicie certos desvios da condição de militar da Guarda, designadamente dos requisitos sociais, exigidos pela sua particularidade e missão.",
        "A avaliação do militar demonstre desvios atinentes à sua condição militar da Guarda, designadamente dos técnico-profissionais exigidos aquando da avaliação extraordinária pelas suas funções."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O comportamento do militar indicie notórios desvios da condição de militar da Guarda, designadamente dos requisitos morais, éticos, militares ou técnico-profissionais exigidos pela sua qualidade e função."
      ],
      "score": 10
    },
    {
      "question": "A hierarquia militar tem por finalidade estabelecer, em todas as circunstâncias:",
      "choices": [
        "Relações de reciprocidade e relação entre os militares determinada pelos postos, também designados por posições, antiguidades e precedências previstas na lei, devendo se respeitar o mesmo fora do desempenho das funções institucionais.",
        "Relações de autoridade e subordinação entre os militares e é determinada pelos postos, também designados por patentes, antiguidades e precedências previstas na lei, a respeitar mesmo fora do desempenho das funções.",
        "Relações de autoridade entre os militares determinada pelos semelhantes no mesmo posto, também designados por patentes, antiguidades e com precedências previstas na lei.",
        "Relações de domínio e insurreição entre os militares que é determinada pelos postos, também designados por patentes, antiguidades e precedências previstas na lei, a respeitar mesmo fora do desempenho das funções institucionais."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Relações de autoridade e subordinação entre os militares e é determinada pelos postos, também designados por patentes, antiguidades e precedências previstas na lei, a respeitar mesmo fora do desempenho das funções."
      ],
      "score": 10
    },
    {
      "question": "O exercício de funções policiais pelos militares da Guarda atende a um horário de referência. A prestação de serviço para além do período normal de exercício de funções é compensada pela atribuição de:",
      "choices": [
        "Crédito horário, nos termos a definir por despacho do comandante-geral, sem qualquer redução da remuneração.",
        "Atribuição folga mensal, nos termos a definir por despacho do comandante-geral, sem qualquer redução da remuneração.",
        "Crédito horário, nos termos a definir por despacho do Ministro da Tutela, sem qualquer redução da remuneração.",
        "Por subsídio de horas extraordinárias, nos termos a definir por despacho do comandante-geral, sem aumento da remuneração base."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Crédito horário, nos termos a definir por despacho do comandante-geral, sem qualquer redução da remuneração."
      ],
      "score": 10
    },


    // PC 2022 (1ª chamada)

    {
      "question": "O Estatuto dos Militares da Guarda Nacional Republicana aplica-se: ",
      "choices": [
        "Aos oficiais, sargentos e guardas, em qualquer situação.",
        "Apenas aos oficiais, sargentos, guardas e funcionários civis que prestam serviço na GNR, que se encontrem na situação de ativo.",
        "Aos oficiais, sargentos, guardas e funcionários civis que prestam serviço na GNR, em qualquer situação.",
        "Apenas aos oficiais, sargentos e guardas que se encontrem na situação de ativo."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Aos oficiais, sargentos e guardas, em qualquer situação."
      ],
      "score": 10
    },
    {
      "question": "O documento de encarte, para guardas, designa-se:",
      "choices": [
        "Carta-patente.",
        "Carta-encarte.",
        "Diploma de encarte.",
        "Certificado de encarte."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Certificado de encarte."
      ],
      "score": 10
    },
    {
      "question": "A realização das ações praticadas pelos militares da Guarda Nacional Republicana integrados em comandos, forças, unidades, estabelecimentos e órgãos, tendo em vista a preparação, o apoio e o cumprimento das atribuições da Guarda Nacional Republicana, traduz a função de:",
      "choices": [
        "Execução.",
        "Comando.",
        "Direção ou Chefia.",
        "Estado-maior."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Execução."
      ],
      "score": 10
    },
    {
      "question": "O desenvolvimento da carreira de militar da Guarda Nacional Republicana orienta-se pela transparência dos métodos e critérios a aplicar, que corresponde ao princípio:",
      "choices": [
        "Do Equilíbrio.",
        "Da Credibilidade.",
        "Da Universalidade.",
        "Da Compatibilidade."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Da Credibilidade."
      ],
      "score": 10
    },
    {
      "question": "Os militares da Guarda Nacional Republicana são designados pelo:",
      "choices": [
        "Quadro a que pertencem.",
        "Posto.",
        "Número de matrícula e nome.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "Designam-se por guardas provisórios:",
      "choices": [
        "Os candidatos admitidos ao curso de formação de sargentos.",
        "Os candidatos admitidos ao curso de formação de guardas.",
        "Os candidatos admitidos ao curso de formação de oficiais.",
        "Os candidatos admitidos ao curso de formação de agentes."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Os candidatos admitidos ao curso de formação de guardas."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda Nacional Republicana está sujeito, a todo o tempo:",
      "choices": [
        "Aos riscos inerentes ao cumprimento das respetivas missões, que enfrenta sem coragem fisica e moral.",
        "Aos riscos inerentes ao cumprimento das respetivas missões, que enfrenta com coragem física e sem moral.",
        "Aos riscos inerentes ao cumprimento das respetivas missões, que enfrenta com coragem física e moral.",
        "Ao cumprimento das respetivas missões, que não enfrenta com coragem física e moral."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Aos riscos inerentes ao cumprimento das respetivas missões, que enfrenta com coragem física e moral."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda Nacional Republicana tem o dever:",
      "choices": [
        "De obediência, estando subordinado à indisciplina e à hierarquia.",
        "De desobediência, estando subordinado à disciplina e à hierarquia.",
        "De obediência, não estando subordinado à disciplina e à hierarquia.",
        "De obediência, estando subordinado à disciplina e à hierarquia."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "De obediência, estando subordinado à disciplina e à hierarquia."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda Nacional Republicana está permanentemente disponível para o serviço:",
      "choices": [
        "Ainda que sem o sacrifício dos interesses pessoais.",
        "Ainda que com o sacrifício dos interesses pessoais.",
        "Ainda que com o sacrifício das relações pessoais.",
        "Todas as alíneas estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ainda que com o sacrifício dos interesses pessoais."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda Nacional Republicana deve acudir:",
      "choices": [
        "Com rapidez e prestar auxílio em situações de catástrofe ou calamidade pública.",
        "Manifestar todo o empenho no socorro dos sinistrados e na atenuação dos danos.",
        "Promover a informação adequada à entidade de que depende.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "Em especial, o Regulamento de Disciplina da Guarda Nacional Republicana caracteriza os seguintes deveres:",
      "choices": [
        "Dever de obediência.",
        "Dever de lealdade.",
        "Dever de zelo.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "Compete ainda ao militar da Guarda Nacional Republicana:",
      "choices": [
        "Comportar-se de acordo com a dignidade da sua função e da condição militar, mesmo fora dos atos de serviço.",
        "Comportar-se de acordo com a dignidade da sua função e da condição civil, mesmo fora dos atos de serviço.",
        "Não comprovar oportunamente a sua identidade, sempre que solicitada ou as circunstâncias do serviço o exijam.",
        "Não comunicar ao superior hierárquico imediato a sua detenção ou constituição como arguido, independentemente da natureza do processo-crime."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Comportar-se de acordo com a dignidade da sua função e da condição militar, mesmo fora dos atos de serviço."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda Nacional Republicana tem direito ao uso e porte de armas e munições:",
      "choices": [
        "De qualquer classificação, desde que distribuídas pelo Estado, e não está sujeito a um plano de formação.",
        "De qualquer classificação, desde que não sejam distribuídas pelo Estado.",
        "De qualquer classificação, desde que distribuídas pelo Estado, e está sujeito a um plano de formação.",
        "Desde que distribuidas pelo Estado, e não está sujeito a um plano de formação."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "De qualquer classificação, desde que distribuídas pelo Estado, e está sujeito a um plano de formação."
      ],
      "score": 10
    },
    {
      "question": "A hierarquia militar tem por finalidade:",
      "choices": [
        "Estabelecer, em todas as circunstâncias, relações de autoridade e insubordinação entre os militares.",
        "Estabelecer, em algumas as circunstâncias, relações de autoridade e subordinação entre os militares.",
        "Estabelecer, em todas as circunstâncias, relações de autoridade e subordinação entre os militares.",
        "Não estabelecer relações de autoridade e subordinação entre os militares."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Estabelecer, em todas as circunstâncias, relações de autoridade e subordinação entre os militares."
      ],
      "score": 10
    },
    {
      "question": "A categoria de Guardas compreende os postos de:",
      "choices": [
        "Alferes, Cabo-chefe, Cabo, Guarda-principal e Guarda.",
        "Cabo-chefe, Cabo, Guarda-principal, Guarda-Florestal e Guarda.",
        "Cabo-mor, Cabo-chefe, Cabo, Guarda-principal e Guarda.",
        "Mestre-Florestal, Cabo-chefe, Guarda-principal e Guarda."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Cabo-mor, Cabo-chefe, Cabo, Guarda-principal e Guarda."
      ],
      "score": 10
    }
  ],
}

// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const cg_6: Topic = {
  topic: 'Cultura Geral (6)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "Fez furor a adaptação ao cinema do herói 'Homem-Aranha'; quem foi o criador da personagem, inicialmente para B.D.:",
      "choices": [
        "Peter Parker",
        "Stan Lee",
        "Tim Burton",
        "Jackson Perez"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Stan Lee"
      ],
      "score": 10
    },
    {
      "question": "'Pasion' é um álbum da autoria de:",
      "choices": [
        "Rodrigo Leão",
        "Madredeus",
        "Ciganos d'Ouro",
        "Xutos e Pontapés"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Rodrigo Leão"
      ],
      "score": 10
    },
    {
      "question": "Qual era a cor que Piet Mondrian nunca usava nos seus quadros?",
      "choices": [
        "Vermelho",
        "Amarelo",
        "Verde",
        "Preto"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verde"
      ],
      "score": 10
    },
    {
      "question": "Qual dos seguintes existencialistas recusou o prémio Nobel da Literatura em 1964?",
      "choices": [
        "Saint-John Perse",
        "Jean-Paul Sartre",
        "Saul Bellow",
        "Albert Einstein"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Jean-Paul Sartre"
      ],
      "score": 10
    },
    {
      "question": "Quantos filmes de Charlie Chaplin ganharam o OSCAR para melhor filme?",
      "choices": [
        "6",
        "2",
        "4",
        "0"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "0"
      ],
      "score": 10
    },
    {
      "question": "Quem foi o primeiro vocalista dos Pink Floyd?",
      "choices": [
        "Roger Waters",
        "Syd Barret",
        "Nick Mason",
        "Robert Peaze"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Syd Barret"
      ],
      "score": 10
    },
    {
      "question": "Qual é a empresa cinematográfica de mais longa duração na história do cinema norte-americano?",
      "choices": [
        "Paramount Studios",
        "Universal Studios",
        "20th Century Fox",
        "Polyscope Company"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Paramount Studios"
      ],
      "score": 10
    },
    {
      "question": "Quantos livros publicou em vida Fernando Pessoa?",
      "choices": [
        "0",
        "2",
        "12",
        "7"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "0"
      ],
      "score": 10
    },
    {
      "question": "'Os Cus de Judas', 'Memória de Elefante' e 'Exortação aos Crocodilos' são romances da autoria de:",
      "choices": [
        "José Saramago",
        "Fernando Pessoa",
        "António Lobo Antunes",
        "David Mourão-Ferreira"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "António Lobo Antunes"
      ],
      "score": 10
    },
    {
      "question": "A trilogia cinematográfica 'O Senhor dos Anéis' baseia-se na igual trilogia literária de que autor?",
      "choices": [
        "J. R. R. Tolkien",
        "Sir John Paul III",
        "Geoffrey Chaucer",
        "J. K. Rowling"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "J. R. R. Tolkien"
      ],
      "score": 10
    },
    {
      "question": "Normalmente, quantos litros de sangue uma pessoa tem?",
      "choices": [
        "Tem entre 2 a 4 litros",
        "Tem entre 4 a 6 litros",
        "Tem 10 litros",
        "Tem 7 litros"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Tem entre 4 a 6 litros"
      ],
      "score": 10
    },
    {
      "question": "De quem é a famosa frase 'Penso, logo existo'?",
      "choices": [
        "Platão",
        "Galileu Galilei",
        "Descartes",
        "Sócrates"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Descartes"
      ],
      "score": 10
    },
    {
      "question": "Em que país foi inventado o chuveiro elétrico?",
      "choices": [
        "França",
        "Inglaterra",
        "Brasil",
        "Austrália"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Brasil"
      ],
      "score": 10
    },
    {
      "question": "Quais o menor e o maior país do mundo?",
      "choices": [
        "Vaticano e Rússia",
        "Nauru e China",
        "Mónaco e Canadá",
        "Malta e Estados Unidos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Vaticano e Rússia"
      ],
      "score": 10
    },
    {
      "question": "Qual o livro mais vendido no mundo a seguir à Bíblia?",
      "choices": [
        "O Senhor dos Anéis",
        "Dom Quixote",
        "O Pequeno Príncipe",
        "Ela, a Feiticeira"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Dom Quixote"
      ],
      "score": 10
    },
    {
      "question": "Atualmente, quantos elementos químicos a tabela periódica possui?",
      "choices": [
        "113",
        "109",
        "118",
        "92"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "118"
      ],
      "score": 10
    },
    {
      "question": "Quem pintou 'Guernica'?",
      "choices": [
        "Paul Cézanne",
        "Pablo Picasso",
        "Diego Rivera",
        "Salvador Dalí"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Pablo Picasso"
      ],
      "score": 10
    },
    {
      "question": "Quanto tempo a luz do Sol demora para chegar à Terra?",
      "choices": [
        "12 minutos",
        "1 dia",
        "12 horas",
        "8 minutos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "8 minutos"
      ],
      "score": 10
    },
    {
      "question": "Qual a nacionalidade de Che Guevara?",
      "choices": [
        "Cubana",
        "Peruana",
        "Boliviana",
        "Argentina"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Argentina"
      ],
      "score": 10
    },
    {
      "question": "Em que período da pré-história o fogo foi descoberto?",
      "choices": [
        "Neolítico",
        "Paleolítico",
        "Período da Pedra Polida",
        "Idade Média"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Paleolítico"
      ],
      "score": 10
    }
  ],
}

// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const gnr_leiOrganica_5: Topic = {
  topic: 'GNR - Lei Orgânica (5)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "Constituem atribuições da Guarda Nacional Republicana:",
      "choices": [
        "Garantir as condições de segurança que permitam o exercício dos direitos e liberdades e o respeito pelas garantias dos cidadãos.",
        "Participar exclusivamente no controlo de saida de pessoas e bens no território internacional.",
        "Prevenir a sinistralidade rodoviária em geral, em coordenação com as forças armadas.",
        "Todas as alíneas anteriores estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Garantir as condições de segurança que permitam o exercício dos direitos e liberdades e o respeito pelas garantias dos cidadãos."
      ],
      "score": 10
    },
    {
      "question": "São consideradas autoridades de polícia:",
      "choices": [
        "O comandante-geral.",
        "0 2º comandante de Posto Territorial.",
        "O comandante do Posto Territorial.",
        "Todas as alíneas anteriores estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O comandante-geral."
      ],
      "score": 10
    },
    {
      "question": "A categoria profissional de guardas compreende os seguintes postos:",
      "choices": [
        "Cabo-adjunto.",
        "Primeiro-cabo.",
        "Segundo-cabo.",
        "Todas as alíneas estão incorretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão incorretas."
      ],
      "score": 10
    },
    {
      "question": "Na Guarda existem as seguintes unidades:",
      "choices": [
        "O Comando-Geral.",
        "De representação, a Unidade de Segurança e Honras de Estado.",
        "Teritoriais, os comandos territoriais.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "Constituem atribuições da Guarda Nacional Republicana:",
      "choices": [
        "Prevenir situações de tráfico e consumo de estupefacientes ou outras substâncias proibidas.",
        "Detectar situações de tráfico e consumo de estupefacientes ou outras substâncias proibidas.",
        "Prevenir e detectar situações de tráfico e consumo de estupefacientes ou outras substâncias proibidas.",
        "Todas as alíneas estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Prevenir e detectar situações de tráfico e consumo de estupefacientes ou outras substâncias proibidas."
      ],
      "score": 10
    },
    {
      "question": "Constituem atribuições da Guarda Nacional Republicana:",
      "choices": [
        "Assegurar a vigilância, patrulhamento e intercepção terrestre, marítima e aérea, em toda a costa e mar territorial do continente.",
        "Assegurar a vigilância, patrulhamento e intercepção terrestre e marítima, em toda a costa e mar territorial do continente e das Regiões Autónomas.",
        "Assegurar a vigilância, patrulhamento e intercepção terrestre e marítima, no mar territorial e zona contígua continental.",
        "Todas as alíneas estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Assegurar a vigilância, patrulhamento e intercepção terrestre e marítima, em toda a costa e mar territorial do continente e das Regiões Autónomas."
      ],
      "score": 10
    },
    {
      "question": "A Guarda Nacional Republicana não pode dirimir conflitos de natureza privada, devendo, nesses casos, limitar a sua acção à: ",
      "choices": [
        "Manutenção da ordem pública.",
        "Manutenção da justiça.",
        "Manutenção da paz pública.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Manutenção da ordem pública."
      ],
      "score": 10
    },
    {
      "question": "Os militares da Guarda Nacional Republicana no exercício do comando de forças têm a categoria de:",
      "choices": [
        "Chefe de força pública.",
        "Gestor de força pública.",
        "Comandantes de força pública.",
        "Todas as alíneas estão incorretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Comandantes de força pública."
      ],
      "score": 10
    },
    {
      "question": "A Guarda Nacional Republicana e as suas unidades, incluindo as unidades constituídas para actuar fora do território nacional e o estabelecimento de ensino, têm direito ao uso:",
      "choices": [
        "Estandarte nacional.",
        "Galhardete nacional.",
        "Mascote nacional.",
        "Todas as alíneas estão incorretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Estandarte nacional."
      ],
      "score": 10
    },

    // PC 2023


    {
      "question": "Os militares da Guarda Nacional Republicana no exercício do comando de forças têm a categoria de comandantes de força pública e considera-se força pública: ",
      "choices": [
        "O efetivo mínimo de dois militares em missão de serviço.",
        "O efetivo mínimo de três militares em missão de serviço.",
        "O efetivo máximo de dois militares em missão de serviço.",
        "O efetivo máximo de três militares em missão de serviço."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O efetivo mínimo de dois militares em missão de serviço."
      ],
      "score": 10
    },
    {
      "question": "São consideradas autoridades de polícia:",
      "choices": [
        "O Comandante-Geral.",
        "O 2.º Comandante de Posto Territorial.",
        "O Comandante do Posto Territorial.",
        "Todas as alíneas anteriores estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Comandante-Geral."
      ],
      "score": 10
    },
    {
      "question": "Para efeitos do Código de Processo Penal, consideram-se órgãos de polícia criminal:",
      "choices": [
        "Todos os guardas, exceto os músicos e militares do serviço de saúde.",
        "Apenas os oficiais da Guarda, quando no exercício de funções de comando ou chefia operacional.",
        "Os militares da Guarda incumbidos de realizar quaisquer atos ordenados por autoridade judiciária ou determinados pelo Código de Processo Penal.",
        "Os oficiais da Guarda, quando no exercício de funções de comando operacional de âmbito tributário."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Os militares da Guarda incumbidos de realizar quaisquer atos ordenados por autoridade judiciária ou determinados pelo Código de Processo Penal."
      ],
      "score": 10
    },
    {
      "question": "Os militares da Guarda agrupam-se hierarquicamente nas categorias profissionais de:",
      "choices": [
        "Chefes, sub-chefes e comissários.",
        "Comissários, sargentos e guardas.",
        "Oficiais; chefes e sub-chefes.",
        "Oficiais, sargentos e guardas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Oficiais, sargentos e guardas."
      ],
      "score": 10
    },
    {
      "question": "Na Guarda existem as seguintes unidades especializadas:",
      "choices": [
        "As Unidades Territoriais.",
        "A Unidade de Intervenção e a Escola da Guarda.",
        "A Unidade de Combate ao Crime e a Unidade Costeira Marítima.",
        "A Unidade de Controlo Costeiro e de Fronteiras, a Unidade de Ação Fiscal e a Unidade Nacional de Trânsito."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A Unidade de Controlo Costeiro e de Fronteiras, a Unidade de Ação Fiscal e a Unidade Nacional de Trânsito."
      ],
      "score": 10
    },
    {
      "question": "A Unidade de representação existente na Guarda é a:",
      "choices": [
        "Unidade de Controlo Costeiro.",
        "Unidade de Intervenção.",
        "Unidade de Segurança e Honras de Estado.",
        "Unidade de Protocolo e Relações Internacionais."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Unidade de Segurança e Honras de Estado."
      ],
      "score": 10
    },
    {
      "question": "O Comandante-Geral tem o posto de:",
      "choices": [
        "Tenente-Coronel.",
        "Capitão.",
        "Tenente-General.",
        "Major."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Tenente-General."
      ],
      "score": 10
    },
    {
      "question": "A Região Autónoma da Açores tem o seguinte dispositivo:",
      "choices": [
        "Uma Unidade de Controlo Costeiro.",
        "Uma Unidade de Marinha às ordens do Comandante-Operacional.",
        "Um Comando Territorial com sede em Ponta Delgada.",
        "Uma Unidade composta por meios aéreos, navais e de socorro."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Um Comando Territorial com sede em Ponta Delgada."
      ],
      "score": 10
    },
    {
      "question": "As subunidades operacionais dos comandos territoriais são os destacamentos, que se articulam localmente em subdestacamentos ou postos. O destacamento é comandado por:",
      "choices": [
        "Tenente-Coronel ou Sargento.",
        "1º Sargento ou Cabo.",
        "Cabo-Chefe ou Sargento-Chefe.",
        "Major ou Capitão."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Major ou Capitão."
      ],
      "score": 10
    },
    {
      "question": "A criação e extinção de centros de formação da Escola da Guarda são aprovadas por portaria do:",
      "choices": [
        "Ministro da tutela.",
        "Secretário-geral do sistema de segurança interna.",
        "Ministro da justiça.",
        "Ministro das finanças. "
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ministro da tutela."
      ],
      "score": 10
    },
    {
      "question": "Quem define o brasão de armas e outros símbolos da GNR?",
      "choices": [
        "Primeiro-Ministro",
        "Presidente da República",
        "Ministro da Administração Interna",
        "Comandante-Geral"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ministro da Administração Interna"
      ],
      "score": 10
    }
    
  ],
}



import { Topic } from '..'

import a1  from '../../../assets/images/psicotecnicos/Raciocinio_Espacial/a1.png'
import a2  from '../../../assets/images/psicotecnicos/Raciocinio_Espacial/a2.png'
import a3  from '../../../assets/images/psicotecnicos/Raciocinio_Espacial/a3.png'
import a4  from '../../../assets/images/psicotecnicos/Raciocinio_Espacial/a4.png'
import a5  from '../../../assets/images/psicotecnicos/Raciocinio_Espacial/a5.png'
import a6  from '../../../assets/images/psicotecnicos/Raciocinio_Espacial/a6.png'
import a7  from '../../../assets/images/psicotecnicos/Raciocinio_Espacial/a7.png'
import a8  from '../../../assets/images/psicotecnicos/Raciocinio_Espacial/a8.png'
import a9  from '../../../assets/images/psicotecnicos/Raciocinio_Espacial/a9.png'
import a10  from '../../../assets/images/psicotecnicos/Raciocinio_Espacial/a10.png'







export const raciocinio_espacial_1: Topic = {
  topic: 'Raciocínio Espacial (1)',
  level: 'Advanced',
  totalQuestions: 10,
  totalScore: 100,
  totalTime: 600,
  questions: [
    {
      "question": "Assinale a resposta correta.",
      image: a1,
      "choices": [
      "A",
      "B",
      "C",
      "D",
      "E"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "A"
      ],
      "score": 10
  },
  {
    "question": "Assinale a resposta correta.",
    image: a2,
    "choices": [
    "A",
    "B",
    "C",
    "D",
    "E"
    ],
    "type": "MCQs",
    "correctAnswers": [
    "C"
    ],
    "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: a3,
  "choices": [
  "A",
  "B",
  "C",
  "D",
  "E"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "E"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: a4,
  "choices": [
  "A",
  "B",
  "C",
  "D",
  "E"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "A"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: a5,
  "choices": [
  "A",
  "B",
  "C",
  "D",
  "E"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "A"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: a6,
  "choices": [
  "A",
  "B",
  "C",
  "D",
  "E"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "E"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: a7,
  "choices": [
  "A",
  "B",
  "C",
  "D",
  "E"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "C"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: a8,
  "choices": [
  "A",
  "B",
  "C",
  "D",
  "E"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "B"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: a9,
  "choices": [
  "A",
  "B",
  "C",
  "D",
  "E"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "C"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: a10,
  "choices": [
  "A",
  "B",
  "C",
  "D",
  "E"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "C"
  ],
  "score": 10
},
  
  
  
  ],
}

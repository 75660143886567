// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const cg_3: Topic = {
  topic: 'Cultura Geral (3)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "Que nome tem o tratado que criou oficialmente a União Europeia?",
      "choices": [
        "Tratado de Roma",
        "Tratado de Estrasburgo",
        "Tratado de Maastricht",
        "Tratado de Lisboa"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Tratado de Maastricht"
      ],
      "score": 10
    },
    {
      "question": "Por quantas estrelas é composta a bandeira da União Europeia?",
      "choices": [
        "8",
        "9",
        "10",
        "12"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "12"
      ],
      "score": 10
    },
    {
      "question": "Qual destes países não compõe o Reino Unido?",
      "choices": [
        "Inglaterra",
        "Escócia",
        "País de Gales",
        "República da Irlanda"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "República da Irlanda"
      ],
      "score": 10
    },
    {
      "question": "Qual é o maior planeta do sistema solar?",
      "choices": [
        "Mercúrio",
        "Saturno",
        "Júpiter",
        "Úrano"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Júpiter"
      ],
      "score": 10
    },
    {
      "question": "Qual destes países não é fundador da União Europeia?",
      "choices": [
        "Alemanha",
        "Itália",
        "Luxemburgo",
        "Espanha"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Espanha"
      ],
      "score": 10
    },
    {
      "question": "O tema do famoso discurso 'Eu Tenho um Sonho', de Martin Luther King é:",
      "choices": [
        "Igualdade das raças.",
        "Justiça para os menos favorecidos.",
        "Intolerância religiosa.",
        "Prémio Nobel da Paz."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Igualdade das raças."
      ],
      "score": 10
    },
    {
      "question": "O tratado que estabeleceu a criação do Euro como moeda única europeia denomina-se como:",
      "choices": [
        "Tratado de Maastricht",
        "Tratado de Nice.",
        "Tratado de Schengen.",
        "Tratado de Lisboa."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Tratado de Maastricht"
      ],
      "score": 10
    },
    {
      "question": "A autoria da obra 'Felizmente há Luar!' pertence a:",
      "choices": [
        "Luís Vaz de Camões.",
        "Almeida Garrett.",
        "Fernando Pessoa.",
        "Luís de Sttau Monteiro."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Luís de Sttau Monteiro."
      ],
      "score": 10
    },
    {
      "question": "Os principais cromossomos que determinam o sexo masculino são:",
      "choices": [
        "Os V.",
        "Os X.",
        "Os Y.",
        "Os W."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Os Y."
      ],
      "score": 10
    },
    {
      "question": "O nome dado a um campo de castanheiros é:",
      "choices": [
        "Castanhal.",
        "Pinhal",
        "Souto.",
        "Pomar."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Souto."
      ],
      "score": 10
    },
    {
      "question": "As casas decimais do número pi são:",
      "choices": [
        "Centenas",
        "Infinitas",
        "Cem",
        "Milhares"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Infinitas"
      ],
      "score": 10
    },
    {
      "question": "O autor de 'O Príncipe' é:",
      "choices": [
        "Maquiavel",
        "Antoine de Saint-Exupéry",
        "Montesquieu",
        "Rousseau"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Maquiavel"
      ],
      "score": 10
    },
    {
      "question": "A data em que foi assinado o Tratado de Zamora, que estabeleceu a independência de Portugal, é:",
      "choices": [
        "10 de outubro de 1125",
        "5 de outubro de 1143",
        "10 de junho de 1143",
        "24 de maio de 1125"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "5 de outubro de 1143"
      ],
      "score": 10
    },
    {
      "question": "A obra 'A Arte da Guerra' foi escrita por:",
      "choices": [
        "Confúcio",
        "Gandhi",
        "Sun Tzu",
        "Mao Tsé Tung"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Sun Tzu"
      ],
      "score": 10
    },
    {
      "question": "A cordilheira de montanhas mais extensa do mundo é:",
      "choices": [
        "Cordilheira dos Himalaias.",
        "Cordilheira dos Montes Urais",
        "Cordilheira dos Andes.",
        "Cordilheira do Cáucaso."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Cordilheira dos Andes."
      ],
      "score": 10
    },
    {
      "question": "Em que região de Portugal Continental se encontra a zona mais ocidental da Europa:",
      "choices": [
        "Algarve",
        "Alentejo",
        "Açores",
        "Estremadura"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Estremadura"
      ],
      "score": 10
    },
    {
      "question": "A capital da Hungria é:",
      "choices": [
        "Bucareste",
        "Praga",
        "Budapeste",
        "Varsóvia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Budapeste"
      ],
      "score": 10
    },
    {
      "question": "Relativo à Segunda Guerra Mundial, o Dia da Vitória na Europa é celebrado a:",
      "choices": [
        "8 de maio",
        "13 de maio",
        "6 de junho",
        "13 de junho"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "8 de maio"
      ],
      "score": 10
    },
    {
      "question": "Relativamente a um vulcão, o canal que liga a câmara magmática ao exterior chama-se:",
      "choices": [
        "Cratera vulcânica",
        "Chaminé vulcânica",
        "Cone vulcânico",
        "Fumarola"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Chaminé vulcânica"
      ],
      "score": 10
    },
    {
      "question": "A Escala Mercalli mede:",
      "choices": [
        "A intensidade dos ventos",
        "A intensidade sísmica de acordo com os danos provocados",
        "A resistência dos minerais",
        "A magnitude de um terremoto"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A intensidade sísmica de acordo com os danos provocados"
      ],
      "score": 10
    }
  ],
}

// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'



export const raciocinio_verbal_3: Topic = {
  topic: 'Raciocínio Verbal (3)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
        "question": "Cirurgião está para Operação como Professor está para:",
        "choices": [
            "ensino",
            "aula",
            "estudante",
            "escola",
            "conhecimento"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "aula"
        ],
        "score": 10
    },
    {
        "question": "Rio está para Água como Estrada está para:",
        "choices": [
            "carro",
            "trânsito",
            "viagem",
            "pavimento",
            "caminho"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "pavimento"
        ],
        "score": 10
    },
    {
        "question": "Cão está para Matilha como Peixe está para:",
        "choices": [
            "cardume",
            "oceano",
            "nado",
            "maré",
            "tanque"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "cardume"
        ],
        "score": 10
    },
    {
        "question": "Revolução está para Mudança como Descoberta está para:",
        "choices": [
            "invenção",
            "conquista",
            "evolução",
            "novidade",
            "inovação"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "novidade"
        ],
        "score": 10
    },
    {
        "question": "Esfera está para Redondo como Cubo está para:",
        "choices": [
            "ângulo",
            "liso",
            "quadrado",
            "volume",
            "triângulo"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "quadrado"
        ],
        "score": 10
    },
    {
        "question": "Insecto está para Aracnídeo como Peixe está para:",
        "choices": [
            "tubarão",
            "mamífero",
            "anfíbio",
            "crustáceo",
            "ave"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "anfíbio"
        ],
        "score": 10
    },
    {
        "question": "Cadeado está para Chave como Enigma está para:",
        "choices": [
            "solução",
            "resposta",
            "problema",
            "pista",
            "decifrar"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "solução"
        ],
        "score": 10
    },
    {
        "question": "Nutriente está para Alimento como Combustível está para:",
        "choices": [
            "carro",
            "gás",
            "energia",
            "máquina",
            "motor"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "energia"
        ],
        "score": 10
    },
    {
        "question": "Atleta está para Corrida como Músico está para:",
        "choices": [
            "nota",
            "partitura",
            "concerto",
            "ensaio",
            "orquestra"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "concerto"
        ],
        "score": 10
    },
    {
        "question": "Chuva está para Guarda-chuva como Sol está para:",
        "choices": [
            "sombra",
            "boné",
            "óculos de sol",
            "calor",
            "vento"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "óculos de sol"
        ],
        "score": 10
    },
    {
        "question": "Estudante está para Diploma como Atleta está para:",
        "choices": [
            "medalha",
            "treinador",
            "competição",
            "treino",
            "técnica"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "medalha"
        ],
        "score": 10
    },
    {
        "question": "Relógio está para Tempo como Termómetro está para:",
        "choices": [
            "calor",
            "temperatura",
            "frio",
            "clima",
            "ambiente"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "temperatura"
        ],
        "score": 10
    },
    {
        "question": "Tijolo está para Construção como Palavra está para:",
        "choices": [
            "escrita",
            "frase",
            "letra",
            "discurso",
            "parágrafo"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "frase"
        ],
        "score": 10
    },
    {
        "question": "Semente está para Planta como Fundação está para:",
        "choices": [
            "estrutura",
            "coluna",
            "edifício",
            "cimento",
            "andaime"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "edifício"
        ],
        "score": 10
    },
    {
        "question": "Biblioteca está para Livros como Arquivo está para:",
        "choices": [
            "documentos",
            "papéis",
            "registos",
            "história",
            "sala"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "documentos"
        ],
        "score": 10
    },
    {
        "question": "Sol está para Luz como Fogo está para:",
        "choices": [
            "brilho",
            "calor",
            "chama",
            "chama",
            "brasa"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "calor"
        ],
        "score": 10
    },
    {
        "question": "Pintura está para Tela como Escultura está para:",
        "choices": [
            "pedra",
            "arte",
            "metal",
            "escultor",
            "mármore"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "mármore"
        ],
        "score": 10
    },
    {
        "question": "Piloto está para Avião como Marinheiro está para:",
        "choices": [
            "mar",
            "barco",
            "oceano",
            "navio",
            "porto"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "navio"
        ],
        "score": 10
    },
    {
        "question": "Ferro está para Força como Pássaro está para:",
        "choices": [
            "canto",
            "liberdade",
            "asa",
            "ninho",
            "altura"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "liberdade"
        ],
        "score": 10
    },
    {
        "question": "Médico está para Paciente como Advogado está para:",
        "choices": [
            "cliente",
            "juiz",
            "caso",
            "testemunha",
            "tribunal"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "cliente"
        ],
        "score": 10
    }

    
  
  ],
}

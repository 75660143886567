// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const linguaPortuguesa_1: Topic = {
  topic: 'Língua Portugesa (1)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "Qual das frases está corretamente pontuada?",
      "choices": [
        "Ele disse: que ia chegar tarde.",
        "Ele disse que: ia chegar tarde.",
        "Ele disse que ia chegar tarde.",
        "Ele disse, que ia chegar tarde."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ele disse que ia chegar tarde."
      ],
      "score": 10
    },
    {
      "question": "Qual das seguintes opções é um exemplo de oração subordinada adjetiva relativa restritiva?",
      "choices": [
        "O livro que me emprestaste é muito interessante.",
        "Embora chovesse, fomos ao parque.",
        "O Pedro, que é meu amigo, ajudou-me nos estudos.",
        "Tenho medo de que chova hoje."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O livro que me emprestaste é muito interessante."
      ],
      "score": 10
    },
    {
      "question": "Em qual das frases o verbo está no futuro do conjuntivo?",
      "choices": [
        "Quando fores a Lisboa, visita-me.",
        "Se eu fosse rico, comprava uma casa.",
        "Vou a Lisboa no próximo fim de semana.",
        "Ele tinha ido ao mercado."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Quando fores a Lisboa, visita-me."
      ],
      "score": 10
    },
    {
      "question": "Que figura de estilo está presente na frase: 'O silêncio gritava na sala'?",
      "choices": [
        "Metáfora",
        "Hipérbole",
        "Personificação",
        "Eufemismo"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Personificação"
      ],
      "score": 10
    },
    {
      "question": "Qual das seguintes frases contém uma perífrase verbal?",
      "choices": [
        "Ele está a estudar para o exame.",
        "Eu estudo todos os dias.",
        "Eles viajaram no verão passado.",
        "Ela estuda há muitas horas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ele está a estudar para o exame."
      ],
      "score": 10
    },
    {
      "question": "Qual é o sujeito da frase: 'Chegaram atrasados ao concerto'?",
      "choices": [
        "Concerto",
        "Atrasados",
        "Eles",
        "Não tem sujeito"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Eles"
      ],
      "score": 10
    },
    {
      "question": "Na frase 'A casa foi destruída pelo fogo', o termo 'pelo fogo' desempenha a função de:",
      "choices": [
        "Sujeito",
        "Objeto direto",
        "Adjunto adverbial de modo",
        "Agente da passiva"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Agente da passiva"
      ],
      "score": 10
    },
    {
      "question": "Qual das seguintes palavras é uma forma verbal no infinitivo pessoal?",
      "choices": [
        "Comer",
        "Comermos",
        "Comendo",
        "Comia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Comermos"
      ],
      "score": 10
    },
    {
      "question": "Qual das seguintes opções é um exemplo de frase complexa?",
      "choices": [
        "Estudei muito para o exame.",
        "Gosto de ler e de ouvir música.",
        "Cheguei cedo ao trabalho.",
        "Ele está cansado."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Gosto de ler e de ouvir música."
      ],
      "score": 10
    },
    {
      "question": "Em qual das frases a crase está corretamente utilizada?",
      "choices": [
        "Vou à festa amanhã.",
        "Gostaria de ir à a reunião.",
        "Eles foram à praia ao domingo.",
        "Ele está à estudar."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Vou à festa amanhã."
      ],
      "score": 10
    },
    {
      "question": "Identifique o tipo de predicado na frase: 'O João comprou um carro novo.'",
      "choices": [
        "Predicado nominal",
        "Predicado verbal",
        "Predicado verbo-nominal",
        "Predicado simples"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Predicado verbo-nominal"
      ],
      "score": 10
    },
    {
      "question": "Qual das opções é um exemplo de conjunção subordinativa causal?",
      "choices": [
        "Porque",
        "Mas",
        "E",
        "Embora"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Porque"
      ],
      "score": 10
    },
    {
      "question": "Na frase 'Eles foram ao cinema, mas o filme já tinha começado', a palavra 'mas' é uma:",
      "choices": [
        "Conjunção coordenativa aditiva",
        "Conjunção subordinativa concessiva",
        "Conjunção coordenativa adversativa",
        "Conjunção subordinativa causal"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Conjunção coordenativa adversativa"
      ],
      "score": 10
    },
    {
      "question": "Qual das frases a seguir contém uma preposição corretamente empregada?",
      "choices": [
        "Vou de Lisboa para Porto.",
        "Fui à casa do meu amigo.",
        "Ele está à espera de uma oportunidade.",
        "Este livro é a de minha mãe."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ele está à espera de uma oportunidade."
      ],
      "score": 10
    },
    {
      "question": "Em qual das frases abaixo há um erro na utilização do modo verbal?",
      "choices": [
        "Se eu soubesse, dir-te-ia.",
        "Quando chegares, avisa-me.",
        "Espero que tu faças o trabalho.",
        "Se eu vim mais cedo, teria acabado tudo."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Se eu vim mais cedo, teria acabado tudo."
      ],
      "score": 10
    },
    {
      "question": "Identifique o complemento verbal na frase: 'Ele ofereceu um presente ao amigo.'",
      "choices": [
        "Um presente",
        "Ao amigo",
        "Ele",
        "Ofereceu"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Um presente"
      ],
      "score": 10
    },
    {
      "question": "Na frase 'A professora corrigiu o teste ontem', o termo 'ontem' é um:",
      "choices": [
        "Objeto direto",
        "Adjunto adnominal",
        "Adjunto adverbial de tempo",
        "Predicativo do sujeito"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Adjunto adverbial de tempo"
      ],
      "score": 10
    },
    {
      "question": "Qual é a função sintática do termo 'compreensiva' na frase: 'A professora era muito compreensiva'?",
      "choices": [
        "Objeto direto",
        "Predicativo do sujeito",
        "Adjunto adnominal",
        "Sujeito"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Predicativo do sujeito"
      ],
      "score": 10
    },
    {
      "question": "Qual dos exemplos a seguir corresponde a uma oração subordinada substantiva completiva?",
      "choices": [
        "Espero que venhas ao jantar.",
        "O João, que é meu primo, chegou cedo.",
        "Fui ao cinema porque estava aborrecido.",
        "Mesmo que chova, vamos sair."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Espero que venhas ao jantar."
      ],
      "score": 10
    },
    {
      "question": "Em qual das frases há um erro de regência verbal?",
      "choices": [
        "Ele assiste televisão todos os dias.",
        "O livro a que me referi é este.",
        "Respondi ao questionário imediatamente.",
        "Concordo com a tua opinião."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ele assiste televisão todos os dias."
      ],
      "score": 10
    }
  ]
}
// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'
import a1 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/a1.png'

import d5 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/d5.png'
import d6 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/d6.png'
import d7 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/d7.png'
import d8 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/d8.png'
import d9 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/d9.png'
import d10 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/d10.png'
import d11 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/d11.png'
import d12 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/d12.png'
import e1 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/e1.png'
import e2 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/e2.png'
import e3 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/e3.png'
import e4 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/e4.png'
import e5 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/e5.png'
import e6 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/e6.png'
import e7 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/e7.png'
import e8 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/e8.png'
import e9 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/e9.png'
import e10 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/e10.png'
import e11 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/e11.png'
import e12 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/e12.png'





export const matrizes_progressivas_3: Topic = {
  topic: 'Matrizes Progressivas (3)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "Assinale a resposta correta.",
      image: d5,
      "choices": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "8"
      ],
      "score": 10
    },
    {
      "question": "Assinale a resposta correta.",
      image: d6,
      "choices": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "6"
      ],
      "score": 10
    },
    {
      "question": "Assinale a resposta correta.",
      image: d7,
      "choices": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "5"
      ],
      "score": 10
    },
    {
      "question": "Assinale a resposta correta.",
      image: d8,
      "choices": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "4"
      ],
      "score": 10
    },
    {
      "question": "Assinale a resposta correta.",
      image: d9,
      "choices": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "1"
      ],
      "score": 10
    },
    {
      "question": "Assinale a resposta correta.",
      image: d10,
      "choices": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "2"
      ],
      "score": 10
    },
    {
      "question": "Assinale a resposta correta.",
      image: d11,
      "choices": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "5"
      ],
      "score": 10
    },
    {
      "question": "Assinale a resposta correta.",
      image: d12,
      "choices": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "6"
      ],
      "score": 10
    },
    {
      "question": "Assinale a resposta correta.",
      image: e1,
      "choices": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "7"
      ],
      "score": 10
    },
    {
      "question": "Assinale a resposta correta.",
      image: e2,
      "choices": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "6"
      ],
      "score": 10
    },
    {
      "question": "Assinale a resposta correta.",
      image: e3,
      "choices": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "8"
      ],
      "score": 10
    },
    {
      "question": "Assinale a resposta correta.",
      image: e4,
      "choices": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "2"
      ],
      "score": 10
    },
    {
      "question": "Assinale a resposta correta.",
      image: e5,
      "choices": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "1"
      ],
      "score": 10
    },
    {
      "question": "Assinale a resposta correta.",
      image: e6,
      "choices": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "5"
      ],
      "score": 10
    },
    {
      "question": "Assinale a resposta correta.",
      image: e7,
      "choices": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "2"
      ],
      "score": 10
    },
    {
      "question": "Assinale a resposta correta.",
      image: e8,
      "choices": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "4"
      ],
      "score": 10
    },
    {
      "question": "Assinale a resposta correta.",
      image: e9,
      "choices": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "1"
      ],
      "score": 10
    },
    {
      "question": "Assinale a resposta correta.",
      image: e10,
      "choices": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "6"
      ],
      "score": 10
    },
    {
      "question": "Assinale a resposta correta.",
      image: e11,
      "choices": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "3"
      ],
      "score": 10
    },
    {
      "question": "Assinale a resposta correta.",
      image: e12,
      "choices": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "5"
      ],
      "score": 10
    },
  
  ],
}

// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const gnr_leiOrganica_3: Topic = {
  topic: 'GNR - Lei Orgânica (3)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "Os militares da Guarda são considerados:",
      "choices": [
        "Agentes da força pública e de autoridade quando lhes não deva ser atribuída qualidade Superior.",
        "Oficiais da força pública e de autoridade quando lhes não deva ser atribuída qualidade superior.",
        "Agentes da força pública e de imposição da lei quando lhes não deva ser atribuída qualidade superior.",
        "Oficiais da força pública e de imposição da lei quando lhes não deva ser atribuída qualidade superior."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Agentes da força pública e de autoridade quando lhes não deva ser atribuída qualidade Superior."
      ],
      "score": 10
    },
    {
      "question": "O Comandante-Geral da Guarda é um Tenente-General nomeado por:",
      "choices": [
        "Despacho conjunto do Presidente da República, do Primeiro-Ministro do membro do Governo responsável pela área da defesa nacional, ouvido o Conselho de Chefes de Estado-Maior se a nomeação recair em Oficial General das Forças Armadas.",
        "Despacho do Primeiro-Ministro, ouvido o Conselho de Chefes de Estado-Maior se a nomeação recair em Oficial General das Forças Armadas.",
        "Despacho do Ministro da tutela e do membro do Governo responsável pela área da defesa nacional, ouvido o Conselho de Chefes de Estado-Maior se a nomeação recair em Oficial General das Forças Armadas.",
        "Despacho conjunto do Primeiro-Ministro, do Ministro da tutela e do membro do Governo responsável pela área da defesa nacional, ouvido o Conselho de Chefes de Estado-Maior se a nomeação recair em Oficial General das Forças Armadas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Despacho conjunto do Primeiro-Ministro, do Ministro da tutela e do membro do Governo responsável pela área da defesa nacional, ouvido o Conselho de Chefes de Estado-Maior se a nomeação recair em Oficial General das Forças Armadas."
      ],
      "score": 10
    },
    {
      "question": "O Conselho Superior da Guarda em composição restrita é constituído por:",
      "choices": [
        "Comandante-Geral, que preside, 2º Comandante-Geral, inspetor da Guarda, comandantes dos órgãos superiores de comando e direção, comandantes das unidades territoriais, das unidades especializadas, de representação e de reserva e do estabelecimento de ensino.",
        "Comandante-Geral, que preside, 2º Comandante-Geral, inspetor da Guarda, comandantes dos órgãos superiores de comando e direção, chefe da Secretaria Geral da Guarda, representantes das categorias profissionais de oficiais, sargentos e guardas, eleitos nos termos a definir por portaria do ministro da tutela.",
        "Comandante-Geral, que preside, 2º Comandante-Geral, inspetor da Guarda, comandantes dos órgãos superiores de comando e direção e pelo comandante da Escola da Guarda.",
        "Comandante-Geral, que preside, 2º Comandante-Geral, inspetor da Guarda e comandantes dos órgãos superiores de comando e direção."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Comandante-Geral, que preside, 2º Comandante-Geral, inspetor da Guarda, comandantes dos órgãos superiores de comando e direção e pelo comandante da Escola da Guarda."
      ],
      "score": 10
    },
    {
      "question": "O Comando Territorial é responsável pelo cumprimento da missão da Guarda na área de responsabilidade que lhe for atribuída, na dependência direta:",
      "choices": [
        "Do Comandante Operacional.",
        "Do Comandante-Geral.",
        "Do Comandante Operacional ouvido o Comandante-Geral.",
        "Do Comandante-Geral ouvido o Ministro da Administração Interna."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Do Comandante-Geral."
      ],
      "score": 10
    },
    {
      "question": "A unidade especializada responsável pelo cumprimento da missão da Guarda em toda a extensão da costa e no mar territorial, com competências específicas de vigilância, patrulhamento e interceção terrestre ou marítima em toda a costa e mar territorial do continente e das Regiões Autónomas, competindo-lhe, ainda, gerir e operar o Sistema Integrado de Vigilância, Comando e Controlo (SIVICC), distribuído ao longo da orla marítima é:",
      "choices": [
        "A Unidade Nacional de Trânsito.",
        "A Unidade de Ação Fiscal. ",
        "A Unidade de Controlo Costeiro.",
        "A Unidade de Intervenção. "
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A Unidade de Controlo Costeiro."
      ],
      "score": 10
    },
    {
      "question": "A criação, extinção e o funcionamento dos serviços das Unidades territoriais e do estabelecimento de ensino são aprovados:",
      "choices": [
        "Por portaria conjunta dos ministros responsáveis pela tutela da administração interna e da defesa nacional.",
        "Por portaria do Ministro da tutela.",
        "Por despacho do Comandante-Geral, sob proposta do Conselho Superior da Guarda em composição restrita.",
        "Por despacho do Comandante-Geral, sob proposta do Conselho Superior da Guarda em composição alargada"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Por portaria do Ministro da tutela."
      ],
      "score": 10
    },
    {
      "question": "A Guarda depende:",
      "choices": [
        "Do membro do Governo responsável pela área da justiça.",
        "Do membro do Governo responsável pela área da administração interna.",
        "Do membro do Governo responsável pela área das finanças.",
        "Do membro do Governo responsável pela área da saúde."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Do membro do Governo responsável pela área da administração interna."
      ],
      "score": 10
    },
    {
      "question": "Os militares da Guarda agrupam-se hierarquicamente nas seguintes categorias profissionais de:",
      "choices": [
        "Oficiais generais, oficiais superiores, capitães e oficiais subalternos.",
        "Oficiais generais, oficiais superiores e oficiais capitães e subalternos.",
        "Oficiais, sargentos e soldados.",
        "Oficiais, sargentos e guardas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Oficiais, sargentos e guardas."
      ],
      "score": 10
    },
    {
      "question": "O responsável pelo cumprimento da missão da Guarda na área de responsabilidade que lhe for atribuída, na dependência direta do comandante-geral é:",
      "choices": [
        "O Comando da Doutrina e Formação.",
        "O Comando Territorial.",
        "O Estabelecimento de Ensino.",
        "O Comando Operacional."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Comando Territorial."
      ],
      "score": 10
    },

    //PC 2022 ( 1ªchamada)

    {
      "question": "A Guarda Nacional Republicana tem por missão, no âmbito dos sistemas nacionais de segurança e proteção:",
      "choices": [
        "Não garantir a segurança interna e os direitos dos cidadãos.",
        "Garantir a segurança externa e os direitos dos cidadãos.",
        "Garantir a segurança interna e os direitos dos cidadãos.",
        "Todas as alíneas anteriores estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Garantir a segurança interna e os direitos dos cidadãos."
      ],
      "score": 10
    },
    {
      "question": "A Guarda Nacional Republicana é:",
      "choices": [
        "Uma força de segurança de natureza militar.",
        "Uma força de segurança de natureza civil.",
        "Uma força de defesa de natureza militar.",
        "Todas as alíneas anteriores estão erradas. "
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Uma força de segurança de natureza militar."
      ],
      "score": 10
    },
    {
      "question": "A Guarda Nacional Republicana tem direito a:",
      "choices": [
        "Brasão de armas, bandeira heráldica, hino, marcha, selo branco e condecoração privativa.",
        "Brasão de armas, bandeira heráldica, hino, marcha, selo azul e condecoração privativa.",
        "Brasão de armas, estandarte, banda de música, marcha, selo azul e condecoração pública.",
        "Todas as alíneas anteriores estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Brasão de armas, bandeira heráldica, hino, marcha, selo branco e condecoração privativa."
      ],
      "score": 10
    },
    {
      "question": "São consideradas autoridades de polícia:",
      "choices": [
        "O comandante-geral.",
        "O 2.º comandante-geral.",
        "O comandante do Comando Operacional da Guarda Nacional Republicana.",
        "Todas as alíneas anteriores estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas anteriores estão corretas."
      ],
      "score": 10
    },
    {
      "question": "No âmbito das suas atribuições, a Guarda Nacional Republicana utiliza as medidas de polícia legalmente previstas:",
      "choices": [
        "Nas condições e termos da Constituição e da lei de segurança interna.",
        "Nas condições e termos da Constituição e da lei de segurança externa.",
        "Nas condições e termos do orçamento de estado e da lei de segurança interna.",
        "Nas condições e termos do orçamento de estado."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Nas condições e termos da Constituição e da lei de segurança interna."
      ],
      "score": 10
    },
    {
      "question": "O Posto Territorial é comandado por militar da categoria de:",
      "choices": [
        "Oficial.",
        "Guarda.",
        "Sargento.",
        "Guarda-Florestal."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Sargento."
      ],
      "score": 10
    },
    {
      "question": "O Comando Territorial é responsável pelo cumprimento da missão da Guarda Nacional Republicana na área de responsabilidade que lhe for atribuída: ",
      "choices": [
        "Na dependência indireta do Comandante Operacional.",
        "Na dependência direta do Comandante-Geral.",
        "Na dependência direta do Comando da Administração de Recursos Internos.",
        "Na dependência indireta do Comando de Doutrina e Formação."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Na dependência direta do Comandante-Geral."
      ],
      "score": 10
    },
    {
      "question": "O Comandante-Geral tem o posto de:",
      "choices": [
        "Tenente-coronel.",
        "Capitão.",
        "Tenente-general.",
        "Major."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Tenente-general."
      ],
      "score": 10
    },
    {
      "question": "Na Guarda Nacional Republicana existem as seguintes Unidades:",
      "choices": [
        "Comando Geral, Territoriais, Especializadas, Estabelecimento de Ensino, de Representação e de Intervenção e Reserva.",
        "Especialistas, Representação e de Intervenção.",
        "Comando, Especializadas e Estabelecimentos de Ensino.",
        "Todas as anteriores estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Comando Geral, Territoriais, Especializadas, Estabelecimento de Ensino, de Representação e de Intervenção e Reserva."
      ],
      "score": 10
    },
    {
      "question": "As atribuições da Guarda são prosseguidas em:",
      "choices": [
        "Todo o território nacional e no mar territorial.",
        "Todo o território nacional, exceto as ilhas, e no mat territorial.",
        "Todo o território nacional, exceto o mar territorial.",
        "Todas as anteriores estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todo o território nacional e no mar territorial."
      ],
      "score": 10
    },
    {
      "question": "A Guarda Nacional Republicana depende:",
      "choices": [
        "Do membro do Governo responsável pela área da administração interna.",
        "Do Presidente da República.",
        "Do Ministro das Finanças.",
        "Todas as alíneas anteriores estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Do membro do Governo responsável pela área da administração interna."
      ],
      "score": 10
    }
  ],
}

// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const gnr_provaCompleta_2: Topic = {
  topic: 'GNR - Prova Completa (2)',
  level: 'Advanced',
  totalQuestions: 50,
  totalScore: 500,
  totalTime: 1800,
  questions: [
    {
      "question": "O militar da Guarda no ativo pode manter-se na situação de licença ilimitada sem cessação do vínculo pelo período máximo de:",
      "choices": [
        "Seis anos seguidos ou interpolados.",
        "Seis anos seguidos, ou oito interpolados.",
        "Oito anos seguidos, ou seis interpolados.",
        "Oito anos seguidos ou interpolados."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Seis anos seguidos ou interpolados."
      ],
      "score": 10
    },
    {
      "question": "A reclamação de um ato administrativo é facultativa, individual, dirigida por escrito, através das vias competentes, ao autor do ato, no prazo de:",
      "choices": [
        "30 dias, contados a partir do seu conhecimento pelo reclamante.",
        "15 dias, contados a partir do despacho.",
        "30 dias, contados a partir do despacho.",
        "15 dias, contados a partir do seu conhecimento pelo reclamante."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "15 dias, contados a partir do seu conhecimento pelo reclamante."
      ],
      "score": 10
    },
    {
      "question": "O ingresso na categoria de guardas faz-se no posto de guarda:",
      "choices": [
        "No dia seguinte à conclusão, com falta de aproveitamento, do respetivo curso de formação.",
        "No dia seguinte à conclusão, com aproveitamento, do respetivo curso de formação.",
        "No dia anterior à conclusão, com aproveitamento, do respetivo curso de formação.",
        "Nos 5 dias seguintes à conclusão, com aproveitamento, do respetivo curso de formação."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "No dia seguinte à conclusão, com aproveitamento, do respetivo curso de formação."
      ],
      "score": 10
    },
    {
      "question": "As funções do militar da Guarda Nacional Republicana classificam-se em:",
      "choices": [
        "Comando.",
        "Direção ou chefia.",
        "Execução.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "A realização das ações praticadas pelos militares da Guarda Nacional Republicana integrados em comandos, forças, unidades, estabelecimentos e órgãos, tendo em vista a preparação, o apoio e o cumprimento atribuições da Guarda Nacional Republicana, traduz a função de:",
      "choices": [
        "Execução.",
        "Comando.",
        "Direção ou Chefia.",
        "Estado-maior."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Execução."
      ],
      "score": 10
    },
    {
      "question": "Compete ao militar da Guarda Nacional Republicana:",
      "choices": [
        "Cumprir prontamente todas as missões ou atos de serviço.",
        "Cumprir algumas missões ou atos de serviço.",
        "Comunicar ao inferior hierárquico a sua constituição como arguido.",
        "Prestar auxílio em qualquer diligência em matéria ilegal."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Cumprir prontamente todas as missões ou atos de serviço."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda Nacional Republicana cumpre as missões que lhe forem cometidas:",
      "choices": [
        "Pelos legítimos inferiores, para defesa da Pátria, se necessário com o sacrifício da própria vida.",
        "Pelos legítimos superiores, para defesa da Pátria, se necessário com o sacrifício da própria vida.",
        "Pelos legitimos inferiores, para defesa da Pátria, sem o sacrifício da própria vida.",
        "Pelos legítimos superiores, para defesa da Pátria, sem o sacrifício da própria vida."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Pelos legítimos superiores, para defesa da Pátria, se necessário com o sacrifício da própria vida."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda Nacional Republicana encontra-se numa das seguintes situações:",
      "choices": [
        "Ativo, inativo ou reforma.",
        "Ativo, de doença ou reforma.",
        "Ativo, reserva ou reforma.",
        "Ativo ou inativo."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ativo, reserva ou reforma."
      ],
      "score": 10
    },
    {
      "question": "Designam-se por guardas provisórios:",
      "choices": [
        "Os candidatos admitidos ao curso de formação de sargentos.",
        "Os candidatos admitidos ao curso de formação de guardas.",
        "Os candidatos admitidos ao curso de formação de oficiais.",
        "Os candidatos admitidos ao curso de formação de agentes."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Os candidatos admitidos ao curso de formação de guardas."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda Nacional Republicana está sujeito, a todo o tempo:",
      "choices": [
        "Aos riscos inerentes ao cumprimento das respetivas missões, que enfrenta sem coragem fisica e moral.",
        "Aos riscos inerentes ao cumprimento das respetivas missões, que enfrenta com coragem física e sem moral.",
        "Aos riscos inerentes ao cumprimento das respetivas missões, que enfrenta com coragem física e moral.",
        "Ao cumprimento das respetivas missões, que não enfrenta com coragem física e moral."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Aos riscos inerentes ao cumprimento das respetivas missões, que enfrenta com coragem física e moral."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda Nacional Republicana tem o dever:",
      "choices": [
        "De obediência, estando subordinado à indisciplina e à hierarquia.",
        "De desobediência, estando subordinado à disciplina e à hierarquia.",
        "De obediência, não estando subordinado à disciplina e à hierarquia.",
        "De obediência, estando subordinado à disciplina e à hierarquia."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "De obediência, estando subordinado à disciplina e à hierarquia."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda Nacional Republicana está permanentemente disponível para o serviço:",
      "choices": [
        "Ainda que sem o sacrifício dos interesses pessoais.",
        "Ainda que com o sacrifício dos interesses pessoais.",
        "Ainda que com o sacrifício das relações pessoais.",
        "Todas as alíneas estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ainda que com o sacrifício dos interesses pessoais."
      ],
      "score": 10
    },
    {
      "question": "O documento de encarte, na categoria de guarda, designa-se:",
      "choices": [
        "Distintivo profissional, para todas as categorias.",
        "Carta-marear, para os militares do serviço marítimo.",
        "Carta-Brevet, para os militares do GIPS.",
        "Certificado de encarte, para os guardas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Certificado de encarte, para os guardas."
      ],
      "score": 10
    },
    {
      "question": "A adequação, necessidade e proporcionalidade do uso da força significa que:",
      "choices": [
        "Deve ser usada sempre que possível para manter o respeito e a dignidade dos polícias.",
        "Usa os meios coercivos adequados e necessários à reposição da legalidade e da ordem, segurança e tranquilidade públicas quando estes se mostrem indispensáveis, necessários e suficientes ao bom cumprimento das suas funções e estejam esgotados os meios de persuasão.",
        "Os meliantes estavam a pedir uma boa dose de educação que lhes tem faltado e que os polícias devem dar para resolver a coisa.",
        "À falta de melhor solução, resta atuar em conformidade com os padrões morais adequados à boa educação geral."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Usa os meios coercivos adequados e necessários à reposição da legalidade e da ordem, segurança e tranquilidade públicas quando estes se mostrem indispensáveis, necessários e suficientes ao bom cumprimento das suas funções e estejam esgotados os meios de persuasão."
      ],
      "score": 10
    },
    {
      "question": "Constitui um dos direitos do militar da Guarda:",
      "choices": [
        "Identificar-se mediante a exibição do bilhete de identidade militar da Guarda, documento que, no entanto, não substituí, o bilhete de identidade de cidadão nacional.",
        "Identificar-se mediante a exibição do bilhete de identidade militar da Guarda, documento que substitui, para todos os efeitos legais, fora de território nacional, o bilhete de identidade de cidadão nacional.",
        "Identificar-se mediante a exibição do bilhete de identidade militar da Guarda, documento que substitui, para todos os efeitos legais, em território nacional, o bilhete de identidade de cidadão nacional.",
        "Identificar-se mediante a exibição do bilhete de identidade militar da Guarda, documento que substitui, para todos os efeitos legais o bilhete de identidade de cidadão nacional, sempre que quiser assistir gratuitamente a espetáculos desportivos."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Identificar-se mediante a exibição do bilhete de identidade militar da Guarda, documento que substitui, para todos os efeitos legais, em território nacional, o bilhete de identidade de cidadão nacional."
      ],
      "score": 10
    },
    {
      "question": "Os comandos territoriais articulam-se em:",
      "choices": [
        "Estado-maior e subunidades.",
        "Comando e Destacamentos Territoriais.",
        "Comando, serviços e subunidades operacionais.",
        "Estado-maior, serviços e subunidades operacionais."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Comando, serviços e subunidades operacionais."
      ],
      "score": 10
    },
    {
      "question": "Nos termos da Lei Orgânica, a Guarda tem direito a:",
      "choices": [
        "A bandeira heráldica, Banda de Música, selo branco e condecoração pública.",
        "A bandeira heráldica, Banda de Música, hino, marcha, selo branco e condecoração privativa.",
        "Brasão de armas, bandeira heráldica, hino, marcha, selo branco e condecoração pública.",
        "Brasão de armas, bandeira heráldica, hino, marcha, selo branco e condecoração privativa."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Brasão de armas, bandeira heráldica, hino, marcha, selo branco e condecoração privativa."
      ],
      "score": 10
    },
    {
      "question": "Para efeitos do Código de Processo Penal, consideram-se órgãos de polícia criminal:",
      "choices": [
        "Todos os guardas, exceto os músicos e militares do serviço de saúde.",
        "Apenas os oficiais da Guarda, quando no exercício de funções de comando ou chefia operacional.",
        "Os militares da Guarda incumbidos de realizar quaisquer atos ordenados por autoridade judiciária ou determinados pelo Código de Processo Penal.",
        "Os oficiais da Guarda, quando no exercício de funções de comando operacional de âmbito tributário."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Os militares da Guarda incumbidos de realizar quaisquer atos ordenados por autoridade judiciária ou determinados pelo Código de Processo Penal."
      ],
      "score": 10
    },
    {
      "question": "A Guarda Nacional Republicana é:",
      "choices": [
        "Uma força de segurança de natureza militar.",
        "Uma força de segurança de natureza civil.",
        "Uma força de defesa de natureza militar.",
        "Todas as alíneas anteriores estão erradas. "
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Uma força de segurança de natureza militar."
      ],
      "score": 10
    },
    {
      "question": "A Guarda Nacional Republicana tem direito a:",
      "choices": [
        "Brasão de armas, bandeira heráldica, hino, marcha, selo branco e condecoração privativa.",
        "Brasão de armas, bandeira heráldica, hino, marcha, selo azul e condecoração privativa.",
        "Brasão de armas, estandarte, banda de música, marcha, selo azul e condecoração pública.",
        "Todas as alíneas anteriores estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Brasão de armas, bandeira heráldica, hino, marcha, selo branco e condecoração privativa."
      ],
      "score": 10
    },
    {
      "question": "São consideradas autoridades de polícia:",
      "choices": [
        "O comandante-geral.",
        "O 2.º comandante-geral.",
        "O comandante do Comando Operacional da Guarda Nacional Republicana.",
        "Todas as alíneas anteriores estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas anteriores estão corretas."
      ],
      "score": 10
    },
    {
      "question": "O Comando Geral é comandado pelo:",
      "choices": [
        "Comandante operacional",
        "Chefe da secretaria-geral da Guarda",
        "Comandante-geral",
        "2º Comandante-geral"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Chefe da secretaria-geral da Guarda"
      ],
      "score": 10
    },
    {
      "question": "Podem ser destacadas ou colocadas com carácter permanente, forças da Unidade de Intervenção na dependência Orgânica dos Comandos Territoriais:",
      "choices": [
        "Por determinação do Comandante operacional",
        "Por decreto regulamentar do Ministro da Tutela",
        "Por determinação do Comandante-geral",
        "Por despacho do Ministro da Tutela"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Por despacho do Ministro da Tutela"
      ],
      "score": 10
    },
    {
      "question": "As atribuições da Guarda são prosseguidas em todo o território nacional e no mar territorial. No caso de atribuições cometidas simultaneamente à Polícia de Segurança Pública, a área de responsabilidade da Guarda é definida por portaria do:",
      "choices": [
        "Ministro da Tutela",
        "Ministro da Justiça",
        "Ministro das Finanças",
        "Ministro do Mar"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ministro da Tutela"
      ],
      "score": 10
    },
    {
      "question": "O Dia da Guarda é comemorado a:",
      "choices": [
        "10 de junho, em evocação da lei que criou a atual instituição nacional, em 1911",
        "25 de abril, em evocação da lei que criou a atual instituição nacional, em 1921",
        "3 de maio, em evocação da lei que criou a atual instituição nacional, em 1911",
        "5 de outubro, em evocação da lei que criou a atual instituição nacional, em 1911"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "3 de maio, em evocação da lei que criou a atual instituição nacional, em 1911"
      ],
      "score": 10
    },
    {
      "question": "As promoções aos postos da categoria de guardas realizam-se mediante as seguintes modalidades:",
      "choices": [
        "A Guarda por habilitação com curso.",
        "A Cabo-chefe por habilitação com curso adequado.",
        "A Cabo-mor por antiguidade.",
        "A Guarda-principal por escolha."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A Guarda por habilitação com curso."
      ],
      "score": 10
    },

  // PC 2023 (1 chamada)

    {
      "question": "Designam-se por guardas provisórios:",
      "choices": [
        "Os candidatos admitidos ao curso de formação de praças.",
        "Os candidatos admitidos ao curso de formação de guardas.",
        "Os candidatos admitidos ao curso de promoção de guardas provisórios.",
        "Os formandos admitidos ao curso de formação de agentes."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Os candidatos admitidos ao curso de formação de guardas."
      ],
      "score": 10
    },
    {
      "question": "Ao militar da Guarda Nacional Republicana, na efetividade do serviço, é atribuído:",
      "choices": [
        "Bilhete de identidade de militar da Guarda Nacional Republicana.",
        "Distintivo profissional.",
        "O documento de encarte.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "Uma das atribuições da Guarda Nacional Republicana é:",
      "choices": [
        "Garantir a segurança e a proteção das pessoas e dos bens.",
        "Garantir a ordem.",
        "Garantir a tranquilidade pública.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "O Dia da Guarda Nacional Republicana é comemorado a:",
      "choices": [
        "13 de maio.",
        "3 de maio.",
        "13 de março.",
        "3 de março."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "3 de maio."
      ],
      "score": 10
    },
    {
      "question": "O que mudou na personagem Lucky Luke em 1983?",
      "choices": [
        "Começou a usar óculos",
        "Tornou-se vegetariano",
        "Deixou de fumar",
        "Deixou de usar armas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Deixou de fumar"
      ],
      "score": 10
    },
    {
      "question": "Qual foi a primeira moeda a ser usada em toda a Europa Ocidental?",
      "choices": [
        "ECU",
        "Euro",
        "Sestércio",
        "Dracma"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Sestércio"
      ],
      "score": 10
    },
    {
      "question": "As ilhas selvagens pertencem a que país?",
      "choices": [
        "Espanha",
        "Portugal",
        "Marrocos",
        "Senegal"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Portugal"
      ],
      "score": 10
    },
    {
      "question": "Quantos deputados tem o Parlamento Europeu?",
      "choices": [
        "751",
        "732",
        "705",
        "700"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "705"
      ],
      "score": 10
    },
    {
      "question": "Qual a capital do Canadá?",
      "choices": [
        "Toronto",
        "Ottawa",
        "Montreal",
        "Vancouver"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ottawa"
      ],
      "score": 10
    },
    {
      "question": "De acordo com a Declaração Universal dos Direitos Humanos:",
      "choices": [
        "Todo o ser humano pode ser condenado por acções ou omissões que, no momento da sua prática, não constituíam acto delituoso à face do direito interno ou internacional. ",
        "Todo o ser humano pode sofrer intromissões arbitrárias na sua vida privada, na sua família, no seu domicílio ou na sua correspondência. ",
        "Todo o ser humano pode ser obrigado a fazer parte de uma associação. ",
        "Todo o ser humano tem direito de acesso, em condições de igualdade, às funções públicas do seu país."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todo o ser humano tem direito de acesso, em condições de igualdade, às funções públicas do seu país."
      ],
      "score": 10
    },
    {
      "question": "O facto de todos os cidadãos gozarem dos direitos e estarem sujeitos aos deveres consignados na Constituição, corresponde ao seguinte princípio da Constituição da República Portuguesa: ",
      "choices": [
        "Princípio da Justiça.",
        "Princípio da Universalidade.",
        "Principio da Boa-Fé.",
        "Princípio da Igualdade."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Princípio da Universalidade."
      ],
      "score": 10
    },
    {
      "question": "O órgão consultivo que representa as organizações de empregadores e de trabalhadores, bem como outros grupos de interesse é:",
      "choices": [
        "Comité das Regiões Europeu.",
        "Serviço das Publicações Oficiais.",
        "Comité Económico e Social Europeu.",
        "Serviço Europeu para a Acção Externa."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Comité Económico e Social Europeu."
      ],
      "score": 10
    },
    {
      "question": "Quantos são os Estados-Membros da União Europeia?",
      "choices": [
        "7 Países.",
        "17 Países.",
        "27 Países.",
        "37 Países."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "27 Países."
      ],
      "score": 10
    },
    {
      "question": "Segundo a Constituição da República Portuguesa, todos os cidadãos:",
      "choices": [
        "Podem ser privilegiados em razão da lingua.",
        "Podem ser beneficiados em razão da raça.",
        "Tem a mesma dignidade social e são iguais perante a lei.",
        "Podem ser privados em razão do sexo."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Tem a mesma dignidade social e são iguais perante a lei."
      ],
      "score": 10
    },
    {
      "question": "Na frase 'O aluno estudou bastante para o exame', a palavra 'bastante' é um:",
      "choices": [
        "Adjetivo",
        "Advérbio",
        "Pronome",
        "Preposição"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Advérbio"
      ],
      "score": 10
    },
    {
      "question": "Qual das palavras abaixo é uma forma de verbo no pretérito perfeito composto?",
      "choices": [
        "Tinha estudado",
        "Tenho estudado",
        "Estudava",
        "Estudei"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Tenho estudado"
      ],
      "score": 10
    },
    {
      "question": "Identifique a voz verbal na frase: 'O livro foi lido pelo João.'",
      "choices": [
        "Voz ativa",
        "Voz passiva",
        "Voz reflexiva",
        "Voz recíproca"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Voz passiva"
      ],
      "score": 10
    },
    {
      "question": "Qual das seguintes frases contém uma oração subordinada causal?",
      "choices": [
        "Vou ao mercado porque preciso de comprar pão.",
        "Se fores ao mercado, compra pão.",
        "Ele comprou pão, mas não o comeu.",
        "Embora tenha comprado pão, não o comeu."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Vou ao mercado porque preciso de comprar pão."
      ],
      "score": 10
    },
    {
      "question": "Qual das opções apresenta um verbo no condicional?",
      "choices": [
        "Eu viajaria pelo mundo, se pudesse.",
        "Ele viajou pelo mundo.",
        "Ele viaja pelo mundo todos os anos.",
        "Vou viajar pelo mundo."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Eu viajaria pelo mundo, se pudesse."
      ],
      "score": 10
    },
    {
      "question": "Na frase 'Os alunos estavam cansados após o exame', o termo 'cansados' é:",
      "choices": [
        "Predicativo do sujeito",
        "Adjunto adverbial",
        "Objeto direto",
        "Objeto indireto"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Predicativo do sujeito"
      ],
      "score": 10
    },
    {
      "question": "Qual das frases abaixo contém um advérbio de tempo?",
      "choices": [
        "Ele correu rapidamente.",
        "Eles chegaram ontem.",
        "Ela fala muito bem.",
        "Ele mora longe."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Eles chegaram ontem."
      ],
      "score": 10
    },
    {
      "question": "Qual das seguintes frases está no modo imperativo?",
      "choices": [
        "Faz o teu trabalho agora!",
        "Eu faço o meu trabalho todos os dias.",
        "Ele fez o trabalho ontem.",
        "Eles fariam o trabalho se tivessem tempo."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Faz o teu trabalho agora!"
      ],
      "score": 10
    },
    {
      "question": "Qual das seguintes frases apresenta um sujeito indeterminado?",
      "choices": [
        "Choveu muito ontem.",
        "Fala-se muito sobre esse tema.",
        "Os alunos estudam todos os dias.",
        "O João foi ao mercado."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Fala-se muito sobre esse tema."
      ],
      "score": 10
    },
    {
      "question": "Qual é o valor semântico da conjunção em: 'Estudo todos os dias, embora esteja cansado'?",
      "choices": [
        "Causa",
        "Condição",
        "Concessão",
        "Finalidade"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Concessão"
      ],
      "score": 10
    }
  ],
}

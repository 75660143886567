import React, { useState } from 'react';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../firebase/firebaseConfig';
import { useNavigate, Link } from 'react-router-dom';
import { Container, TextField, Button, Typography, Paper, Alert, Box } from '@mui/material';
import { styled } from '@mui/system';
import { AppLogo } from '../config/icons';
import { LogoContainer } from '../styles/Global';

const StyledPaper = styled(Paper)({
  padding: '2rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%', // Ensure the form takes full width inside the container
});

const CenteredContainer = styled(Container)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  flexDirection: 'column', // This ensures the logo and form are stacked vertically
});

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      console.error("Error logging in: ", error);
      alert_message();
    }
  };

  const [alert, setAlert] = useState({ visible: false, severity: '', message: '' });

  function alert_message(){
    setAlert({ visible: true, severity: 'error', message: 'Falha de Login!' });
    setTimeout(() => {
      setAlert({ visible: false, severity: '', message: '' });
    }, 3000);
  }

  return (
    <> 
      {/* ALERT MSG */}
      {alert.visible && (
        <Box sx={{ width: '50%', position: 'fixed', top: 100, left: '50%', transform: 'translateX(-50%)', zIndex: 1200 }}>
          <Alert severity={alert.severity} variant="filled">
            {alert.message}
          </Alert>
        </Box>
      )}
      {/* ALERT MSG */}
          
      <CenteredContainer maxWidth="sm">
        {/* Logo above the form */}
        <LogoContainer style={{ marginBottom: '20px' }}>
          <AppLogo />
        </LogoContainer>

        <StyledPaper>
          <Typography variant="h4" gutterBottom>
            Login
          </Typography>
          <form onSubmit={handleLogin} style={{ width: '100%' }}>
            <TextField
              label="Email"
              type="email"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Password"
              type="password"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Login
            </Button>
          </form>
          <Typography variant="body2" style={{ marginTop: '20px' }}>
            Não tens conta? <Link to="/register">Regista-te aqui</Link>
          </Typography>
        </StyledPaper>
      </CenteredContainer>
    </>
  );
};

export default Login;

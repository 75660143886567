// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const cg_5: Topic = {
  topic: 'Cultura Geral (5)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "Quem comandou a segunda invasão napoleónica em Portugal?",
      "choices": [
        "Napoleão",
        "Junot",
        "Wellington",
        "Soult"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Soult"
      ],
      "score": 10
    },
    {
      "question": "Qual é a corrente filosófica defendida por Martin Heidegger?",
      "choices": [
        "Positivismo",
        "Niilismo",
        "Abstracionismo",
        "Existencialismo"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Existencialismo"
      ],
      "score": 10
    },
    {
      "question": "Que corpo militar de elite foi criado em França por Luís XIII como guarda pessoal no século XVII existiu até 1815?",
      "choices": [
        "Guarda Suíça",
        "Rangers",
        "Escuteiros",
        "Mosqueteiros"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Mosqueteiros"
      ],
      "score": 10
    },
    {
      "question": "O que mudou na personagem Lucky Luke em 1983?",
      "choices": [
        "Começou a usar óculos",
        "Tornou-se vegetariano",
        "Deixou de fumar",
        "Deixou de usar armas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Deixou de fumar"
      ],
      "score": 10
    },
    {
      "question": "Qual foi a primeira moeda a ser usada em toda a Europa Ocidental?",
      "choices": [
        "ECU",
        "Euro",
        "Sestércio",
        "Dracma"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Sestércio"
      ],
      "score": 10
    },
    {
      "question": "Quem escreveu A Arte da Guerra?",
      "choices": [
        "Confúcio",
        "Gandhi",
        "Sun Tzu",
        "Mao Tsé Tung"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Sun Tzu"
      ],
      "score": 10
    },
    {
      "question": "Que expressão náutica foi sugerida por Alexander Bell para ser usada quando se atende o telefone?",
      "choices": [
        "Ahoy",
        "Over",
        "Hi",
        "All aboard"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ahoy"
      ],
      "score": 10
    },
    {
      "question": "Que palavra de origem inuíte significa casa?",
      "choices": [
        "Quiosque",
        "Caiaque",
        "Anoraque",
        "Iglu"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Iglu"
      ],
      "score": 10
    },
    {
      "question": "Qual é o símbolo químico da prata?",
      "choices": [
        "PT",
        "PA",
        "AG",
        "PG"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "AG"
      ],
      "score": 10
    },
    {
      "question": "Quantos jogadores formam uma equipa de pólo aquático?",
      "choices": [
        "6",
        "7",
        "8",
        "5"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "7"
      ],
      "score": 10
    },
    {
      "question": "Quantos corações tem um polvo?",
      "choices": [
        "1",
        "2",
        "3",
        "4"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "3"
      ],
      "score": 10
    },
    {
      "question": "Onde está exposto o quadro 'Mona Lisa' de Leonardo DaVinci?",
      "choices": [
        "Museu do Prado",
        "Museu do Louvre",
        "Capela Sistina",
        "Vaticano"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Museu do Louvre"
      ],
      "score": 10
    },
    {
      "question": "Onde podemos visitar o monumento Stonehenge?",
      "choices": [
        "EUA",
        "Reino Unido",
        "Canadá",
        "Brasil"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Reino Unido"
      ],
      "score": 10
    },
    {
      "question": "Em que ano terminou a 2ª Guerra Mundial?",
      "choices": [
        "1945",
        "1946",
        "1954",
        "1942"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "1945"
      ],
      "score": 10
    },
    {
      "question": "De que poeta é a frase: 'Tudo vale a pena se a alma não é pequena'?",
      "choices": [
        "Fernando Pessoa",
        "Bocage",
        "Eça de Queiroz",
        "Luís de Camões"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Fernando Pessoa"
      ],
      "score": 10
    },
    {
      "question": "Quem é o autor de 'Um estranho em Goa'?",
      "choices": [
        "José Eduardo Agualusa",
        "José Eduardo Abreu",
        "José-Augusto França",
        "José Eduardo França"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "José Eduardo Agualusa"
      ],
      "score": 10
    },
    {
      "question": "Mozart era?",
      "choices": [
        "Húngaro",
        "Alemão",
        "Austriaco",
        "Russo"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Austriaco"
      ],
      "score": 10
    },
    {
      "question": "Que guerra está retratada em 'Guernica' de Picasso?",
      "choices": [
        "Guerra Civil Espanhola",
        "Primeira Guerra Mundial",
        "Segunda Guerra Mundial",
        "Guerra dos 100 anos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Guerra Civil Espanhola"
      ],
      "score": 10
    },
    {
      "question": "Quem a autora de 'O Princípio da Incerteza'?",
      "choices": [
        "Agustina Bessa Luis",
        "Clara Pinto Correia",
        "Maria Velho da Costa",
        "Josefa Almeida"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Agustina Bessa Luis"
      ],
      "score": 10
    },
    {
      "question": "Craveirinha foi um conceituado Poeta de:",
      "choices": [
        "Moçambique",
        "Angola",
        "Portugal",
        "Guiné"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Moçambique"
      ],
      "score": 10
    }
  ],
}

// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const gnr_estatutos_3: Topic = {
  topic: 'GNR - Estatutos (3)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "Considera-se na efetividade de serviço o militar do ativo que se encontre:",
      "choices": [
        "Suspenso de funções.",
        "Em comissão especial.",
        "No cumprimento de pena a que a legislação penal ou disciplinar atribua esse efeito.",
        "De licença sem direito a remuneração."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Suspenso de funções."
      ],
      "score": 10
    },
    {
      "question": "O tempo mínimo de serviço efetivo para dispensa do serviço por iniciativa do militar, após a frequência do curso de formação inicial de guardas, que habilitam ao ingresso nos quadros na Guarda, são:",
      "choices": [
        "6 anos.",
        "12 anos.",
        "5 anos.",
        "9 anos."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "5 anos."
      ],
      "score": 10
    },
    {
      "question": "O ingresso na categoria de Guardas da Guarda Nacional Republicana faz-se após conclusão com aproveitamento do curso de formação de guardas, havendo lugar a um período probatório, após a conclusão do curso, com a duração:",
      "choices": [
        "De nove meses e a forma de avaliação é fixada por despacho do diretor-geral.",
        "De um ano e a forma de avaliação é fixada por despacho do ministro da administração interna.",
        "De um ano e a forma de avaliação é fixada por despacho do comandante-geral.",
        "De seis meses e a forma de avaliação é fixada por despacho do ministro da administração interna sob proposta do diretor-geral."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "De um ano e a forma de avaliação é fixada por despacho do comandante-geral."
      ],
      "score": 10
    },
    {
      "question": "A promoção que se efetua por ordem de cursos e, dentro do mesmo curso, por ordem decrescente de classificação obtida neste, é uma promoção por:",
      "choices": [
        "Habilitação por formação.",
        "Distinção.",
        "Mérito escolar.",
        "Habilitação com curso adequado."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Habilitação com curso adequado."
      ],
      "score": 10
    },
    {
      "question": "São condições especiais de promoção:",
      "choices": [
        "Avaliação do desempenho efetuada, em regra, pelos superiores hierárquicos imediatos, nos moldes previstos no presente Estatuto.",
        "Atos heroicos que fortaleçam a imagem da Guarda.",
        "Tempo mínimo de antiguidade no posto.",
        "Ficha curricular com registos distintos dos demais."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Tempo mínimo de antiguidade no posto."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda pode desistir da frequência de curso de promoção para que tenha sido nomeado ou que se encontre a frequentar, não podendo, porém, ser novamente nomeado, salvo quando se verifiquem razões de força maior atendíveis, nomeadamente as seguintes: ",
      "choices": [
        "A declaração de que é voluntário para o curso imediatamente a seguir.",
        "Declarar que precisa de uma pausa para ultrapassar o medo que tem de servir algo maior do que o próprio.",
        "Falecimento de cônjuge, ou equiparado, ascendente ou descendente no 1.º grau ou adotado.",
        "Assumir que pensava que era capaz de ser um Guarda, mas afinal precisa de se preparar melhor como pessoa."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falecimento de cônjuge, ou equiparado, ascendente ou descendente no 1.º grau ou adotado."
      ],
      "score": 10
    },
    {
      "question": "Na avaliação do desempenho do militar da Guarda intervêm:",
      "choices": [
        "O superior hierárquico imediato, os pares e os subordinados.",
        "Dois avaliadores, sendo um deles, por norma, o superior hierárquico imediato.",
        "O superior hierárquico imediato, os pares, apenas indicativo, e os subordinados, para aferição de eventuais anomalias.",
        "O superior hierárquico imediato, os pares, para aferição de eventuais anomalias, e os subordinados, apenas indicativo."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Dois avaliadores, sendo um deles, por norma, o superior hierárquico imediato."
      ],
      "score": 10
    },
    {
      "question": "Só se podem candidatar à frequência do curso de formação de sargentos os militares da categoria de guardas que satisfaçam as seguintes condições:",
      "choices": [
        "Ter menos de 28 anos de idade em 31 de dezembro do ano de ingresso no curso.",
        "Ter menos de 30 anos de idade em 31 de dezembro do ano de ingresso no curso.",
        "Ter menos de 24 anos de idade em 31 de dezembro do ano de ingresso no curso.",
        "Ter menos de 40 anos de idade em 31 de dezembro do ano de ingresso no curso."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ter menos de 40 anos de idade em 31 de dezembro do ano de ingresso no curso."
      ],
      "score": 10
    },
    {
      "question": "É condição especial de promoção ao posto de cabo-mor:",
      "choices": [
        "Ter o tempo mínimo de quatro anos de antiguidade no posto de cabo-chefe.",
        "Ter o tempo mínimo de oito anos de antiguidade no posto de cabo-chefe.",
        "Ter o tempo mínimo de seis anos de antiguidade no posto de cabo-chefe.",
        "Ter o tempo mínimo de cinco anos de antiguidade no posto de cabo-chefe."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ter o tempo mínimo de cinco anos de antiguidade no posto de cabo-chefe."
      ],
      "score": 10
    },

    //202 1  chamada

    {
      "question": "O livrete de saúde destina-se a:",
      "choices": [
        "Ter o registo das vacinas em dia, criado por causa da COVID-19 pelo Ministério da Administração Interna em 2021.",
        "Registo de baixas para constar na redução da remuneração mensal.",
        "Acompanhamento das baixas dos militares para efeitos de separação de serviço.",
        "Registo dos factos de índole sanitária de cada militar na efetividade do serviço e constitui documento de natureza classificada."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Registo dos factos de índole sanitária de cada militar na efetividade do serviço e constitui documento de natureza classificada."
      ],
      "score": 10
    },
    {
      "question": ". O militar da Guarda na situação de ativo ocupa um lugar na lista de antiguidade do quadro a que pertence, sendo inscrito no respetivo posto de ingresso por ordem decrescente:",
      "choices": [
        "Da menor idade que tinha no fim do curso.",
        "Da maior habilitação literária no fim do curso.",
        "De ter maior antiguidade militar nas Forças Armadas.",
        "De classificação no respetivo curso."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "De classificação no respetivo curso."
      ],
      "score": 10
    },
    {
      "question": "O tempo mínimo de serviço efetivo para dispensa do serviço por iniciativa do militar, após a frequência do curso de formação inicial de guardas, que habilitam ao ingresso nos quadros na Guarda, são:",
      "choices": [
        "8 anos.",
        "5 anos.",
        "10 anos.",
        "3 anos."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "5 anos."
      ],
      "score": 10
    },
    {
      "question": "Para o ingresso na categoria de sargentos é exigido aproveitamento no curso de formação de sargentos da Guarda, ao qual é atribuído o:",
      "choices": [
        "Nível 5 de qualificação do Sistema Nacional de Qualificações.",
        "Nível 10 de qualificação do Sistema Nacional de Qualificações.",
        "Nível 7 de qualificação do Sistema Nacional de Qualificações.",
        "Nível 3 de qualificação do Sistema Nacional de Qualificações."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Nível 5 de qualificação do Sistema Nacional de Qualificações."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda que se encontra em formação ou em período experimental em organismo externo à Guarda com vista ao ingresso no mesmo, considera-se em:",
      "choices": [
        "Inatividade temporária",
        "Comissão especial.",
        "Licença sem remuneração.",
        "Comissão excecional."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Comissão especial."
      ],
      "score": 10
    },
    {
      "question": "A nomeação e a colocação de militares obedecem aos seguintes princípios:",
      "choices": [
        "Primado dos interesses pessoais aos do serviço.",
        "Satisfação das necessidades ou o interesse do serviço.",
        "Despacho do Diretor Nacional, após proposta do Chefe de Pessoal.",
        "Despacho do Diretor Nacional, após consulta dos testes psicotécnicos."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Satisfação das necessidades ou o interesse do serviço."
      ],
      "score": 10
    },
    {
      "question": "As condições gerais de promoção, comuns a todos os militares são, entre outras, as seguintes:",
      "choices": [
        "Cumprimento dos deveres que lhes competem, aptidão física e psíquica adequadas e estar colocado na primeira ou segunda classe de comportamento.",
        "Desempenho com eficiência das funções do seu posto, qualidades e capacidades pessoais, intelectuais e profissionais requeridas para o posto imediato e estar colocado na primeira, segunda ou terceira classe de comportamento.",
        "Qualidades e capacidades pessoais, intelectuais e profissionais requeridas para o posto imediato, aptidão física e psíquica adequadas e estar colocado na terceira ou quarta classe de comportamento.",
        "Desempenho com eficiência das funções do seu posto, qualidades e capacidades pessoais, intelectuais e profissionais requeridas para o posto imediato e estar colocado na segunda ou terceira classe de comportamento."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Cumprimento dos deveres que lhes competem, aptidão física e psíquica adequadas e estar colocado na primeira ou segunda classe de comportamento."
      ],
      "score": 10
    },
    {
      "question": "O ensino na Guarda integra-se nos sistemas educativos e formativos nacionais, tendo como finalidade a habilitação profissional e o acesso a conhecimentos adequados. Os cursos de promoção são:",
      "choices": [
        "Pagos pelo próprio, na forma de redução da remuneração mensal por cada ano letivo tido.",
        "Pagos pelo próprio, na forma de uma remuneração mensal por cada ano letivo.",
        "Remunerados ao formando como se estivesse a trabalhar e por isso é pago para melhorar as suas competências.",
        "Remunerados ao formando como se estivesse a trabalhar, podendo ser reduzido a metade sempre que não tenha um desempenho escolar adequado, porque isso serve de exemplo para se portar bem."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Remunerados ao formando como se estivesse a trabalhar e por isso é pago para melhorar as suas competências."
      ],
      "score": 10
    },

    // 2021_2022 2 chamada

    {
      "question": "O militar da Guarda no âmbito da função policial, só deve recorrer ao uso de arma de fogo:",
      "choices": [
        "Como medida necessária, quando tal se afigure o seu uso, adequado, e exista possível perigosidade para a sua vida.",
        "Como medida prevenção, quando tal se afigure absolutamente necessário para esse fim, de forma adequada e sempre proporcional e exista comprovadamente perigo para a sua vida ou de terceiros, e nos demais casos previstos na lei.",
        "Como medida extrema, quando tal se afigure absolutamente necessário, adequado, proporcional e exista comprovadamente perigo para a sua vida ou de terceiros, e nos demais casos previstos na lei.",
        "Como medida extrema, quando tal se afigure absolutamente necessário, e exista comprovadamente perigo para a vida de terceiros, e nos demais casos previstos na lei."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Como medida extrema, quando tal se afigure absolutamente necessário, adequado, proporcional e exista comprovadamente perigo para a sua vida ou de terceiros, e nos demais casos previstos na lei."
      ],
      "score": 10
    },
    {
      "question": "A autorização da acumulação de outras funções ou atividades fora da Guarda não pode ser concedida, quando verificada as seguintes circunstâncias:",
      "choices": [
        "For legalmente considerada compatível ou não comprometa a isenção e a imparcialidade exigidas, e não prejudique a capacidade física e disponibilidade parcial do militar para o serviço.",
        "For considerada compatível ou então não comprometa a isenção e a imparcialidade exigidas. Que não provoque prejuízo para o interesse público.",
        "Que não provoque prejuízo para o interesse público e seja compatível com seus interesses públicos.",
        "For legalmente considerada incompatível ou comprometa a isenção e a imparcialidade exigidas, que possa vir a afetar a capacidade física e disponibilidade permanente do militar para o serviço."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "For legalmente considerada incompatível ou comprometa a isenção e a imparcialidade exigidas, que possa vir a afetar a capacidade física e disponibilidade permanente do militar para o serviço."
      ],
      "score": 10
    },
    {
      "question": "Na sua formação e progressão na carreira o militar da Guarda tem direito a:",
      "choices": [
        "Receber treino e formação geral, cívica, científica, técnica, militar e profissional, inicial e contínua, adequados ao pleno exercício das funções e atribuições que lhe sejam cometidas, tendo em vista a sua valorização humana e profissional, bem como à sua progressão na carreira.",
        "Ter formação geral, científica, técnica, militar, inicial, adequados ao pleno exercício das funções e atribuições acometidas, visando a sua valorização profissional, bem como à sua progressão na carreira no posto.",
        "Receber treino e formação geral, cívica, científica, técnica, inicial e contínua, para o exercício das funções e atribuições que lhe sejam atribuídas, tendo em vista a sua valorização profissional, bem como à sua progressão na carreira.",
        "Ter formação geral, militar e profissional, inicial e contínua, adequados ao pleno exercício das funções e atribuições que lhe sejam cometidas, tendo em vista a sua valorização no posto."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Receber treino e formação geral, cívica, científica, técnica, militar e profissional, inicial e contínua, adequados ao pleno exercício das funções e atribuições que lhe sejam cometidas, tendo em vista a sua valorização humana e profissional, bem como à sua progressão na carreira."
      ],
      "score": 10
    }
  ],
}

import { generalKnowledge } from './generalKnowledge'
import { javascript } from './javascript'
import { python } from './python'
import { react } from './react'
import { crp } from './crp'
import { cg } from './cg'
import { dudh } from './dudh'
import { psp_estatutos } from './psp_estatutos'
import { psp_leiOrganica } from './psp_leiOrganica'
import { psp_testeGeral_1 } from './psp_testeGeral_1'
import { psp_testeGeral_2 } from './psp_testeGeral_2'
import { psp_testeGeral_3 } from './psp_testeGeral_3'


// GERAL

import { cg_1 } from './Geral/cg_1'
import { cg_2 } from './Geral/cg_2'
import { cg_3 } from './Geral/cg_3'
import { cg_4 } from './Geral/cg_4'
import { cg_5 } from './Geral/cg_5'
import { cg_6 } from './Geral/cg_6'
import { cg_7 } from './Geral/cg_7_ai'
import { cg_8 } from './Geral/cg_8_ai'

import { crp_1 } from './Geral/crp_1'
import { crp_2 } from './Geral/crp_2'
import { crp_3 } from './Geral/crp_3'

import { dudh_1 } from './Geral/dudh_1'
import { dudh_2 } from './Geral/dudh_2'
import { dudh_3 } from './Geral/dudh_3'

import { crp_dudh_eu_1 } from './Geral/crp_dudh_ue_1'
import { crp_dudh_eu_2 } from './Geral/crp_dudh_ue_2'
import { crp_dudh_eu_3 } from './Geral/crp_dudh_ue_3'

import { linguaPortuguesa_1 } from './Geral/linguaPotuguesa_1'
import { linguaPortuguesa_2 } from './Geral/linguaPotuguesa_2'
import { linguaPortuguesa_3 } from './Geral/linguaPotuguesa_3'
import { linguaPortuguesa_4 } from './Geral/linguaPotuguesa_4'
import { linguaPortuguesa_5 } from './Geral/linguaPotuguesa_5'

import { matrizes_progressivas_1 } from './Psicotecnicos/matrizes_progressivas_1'
import { matrizes_progressivas_2 } from './Psicotecnicos/matrizes_progressivas_2'
import { matrizes_progressivas_3 } from './Psicotecnicos/matrizes_progressivas_3'

import { raciocinio_verbal_1 } from './Psicotecnicos/raciocinio_verbal_1'
import { raciocinio_verbal_2 } from './Psicotecnicos/raciocinio_verbal_2'
import { raciocinio_verbal_3 } from './Psicotecnicos/raciocinio_verbal_3'

import { raciocinio_abstrato_1 } from './Psicotecnicos/raciocinio_abstrato_1'
import { raciocinio_abstrato_2 } from './Psicotecnicos/raciocinio_abstrato_2'

import { raciocinio_espacial_1 } from './Psicotecnicos/raciocinio_espacial_1'
import { raciocinio_espacial_2 } from './Psicotecnicos/raciocinio_espacial_2'






//GNR

import { gnr_estatutos_1 } from './GNR/gnr_estatutos_1'
import { gnr_estatutos_2 } from './GNR/gnr_estatutos_2'
import { gnr_estatutos_3 } from './GNR/gnr_estatutos_3'
import { gnr_estatutos_4 } from './GNR/gnr_estatutos_4'
import { gnr_estatutos_5 } from './GNR/gnr_estatutos_5'
import { gnr_estatutos_6 } from './GNR/gnr_estatutos_6'
import { gnr_estatutos_7 } from './GNR/gnr_estatutos_7'

import { gnr_leiOrganica_1 } from './GNR/gnr_leiOrganica_1'
import { gnr_leiOrganica_2 } from './GNR/gnr_leiOrganica_2'
import { gnr_leiOrganica_3 } from './GNR/gnr_leiOrganica_3'
import { gnr_leiOrganica_4 } from './GNR/gnr_leiOrganica_4'
import { gnr_leiOrganica_5 } from './GNR/gnr_leiOrganica_5'

import { gnr_provaCompleta_1 } from './GNR/gnr_provaCompleta_1'
import { gnr_provaCompleta_2 } from './GNR/gnr_provaCompleta_2'
import { gnr_provaCompleta_3 } from './GNR/gnr_provaCompleta_3'
import { gnr_provaCompleta_4 } from './GNR/gnr_provaCompleta_4'
import { gnr_provaCompleta_5 } from './GNR/gnr_provaCompleta_5'










// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

type Choice = string
type CorrectAnswers = string[]

export type Question = {
  question: string
  choices: Choice[]
  type: 'MCQs' | 'MAQs' | 'boolean'
  correctAnswers: CorrectAnswers
  score: number
  code?: string
  image?: string
}

export type Topic = {
  topic: string
  level: string
  totalQuestions: number
  totalScore: number
  totalTime: number
  questions: Question[]
}

export const quiz: Record<string, Topic> = {
  JavaScript: javascript,
  React: react,
  Python: python,
  CRP: crp,
  'Cultura Geral': cg,
  DUDH: dudh,





  ///PSP
  'Estatutos - PSP': psp_estatutos,
  'Lei Orgânica - PSP': psp_leiOrganica,
  'Teste Geral (1) - PSP' :psp_testeGeral_1,
  'Teste Geral (2) - PSP' :psp_testeGeral_2,
  'Teste Geral (3) - PSP' :psp_testeGeral_3,
  'Javascript' : javascript,


  ///GNR
  'GNR - Estatutos (1)': gnr_estatutos_1,
  'GNR - Estatutos (2)': gnr_estatutos_2,
  'GNR - Estatutos (3)': gnr_estatutos_3,
  'GNR - Estatutos (4)': gnr_estatutos_4,
  'GNR - Estatutos (5)': gnr_estatutos_5,
  'GNR - Estatutos (6)': gnr_estatutos_6,
  'GNR - Estatutos (7)': gnr_estatutos_7,

  
  'GNR - Lei Orgânica (1)': gnr_leiOrganica_1,
  'GNR - Lei Orgânica (2)': gnr_leiOrganica_2,
  'GNR - Lei Orgânica (3)': gnr_leiOrganica_3,
  'GNR - Lei Orgânica (4)': gnr_leiOrganica_4,
  'GNR - Lei Orgânica (5)': gnr_leiOrganica_5,

  'GNR - Prova Completa (1)': gnr_provaCompleta_1,
  'GNR - Prova Completa (2)': gnr_provaCompleta_2,
  'GNR - Prova Completa (3)': gnr_provaCompleta_3,
  'GNR - Prova Completa (4)': gnr_provaCompleta_4,
  'GNR - Prova Completa (5)': gnr_provaCompleta_5,





  ///GERAL
  'Cultura Geral (1)': cg_1,
  'Cultura Geral (2)': cg_2,
  'Cultura Geral (3)': cg_3,
  'Cultura Geral (4)': cg_4,
  'Cultura Geral (5)': cg_5,
  'Cultura Geral (6)': cg_6,



  //'Cultura Geral (7)': cg_7,
  //'Cultura Geral (8)': cg_8,

  'CRP (1)': crp_1,
  'CRP (2)': crp_2,
  'CRP (3)': crp_3,

  'DUDH (1)': dudh_1,
  'DUDH (2)': dudh_2,
  'DUDH (3)': dudh_3,

  'CRP, DUDH e UE (1)': crp_dudh_eu_1,
  'CRP, DUDH e UE (2)': crp_dudh_eu_2,
  'CRP, DUDH e UE (3)': crp_dudh_eu_3,

  'Língua Portugesa (1)': linguaPortuguesa_1,
  'Língua Portugesa (2)': linguaPortuguesa_2,
  'Língua Portugesa (3)': linguaPortuguesa_3,
  'Língua Portugesa (4)': linguaPortuguesa_4,
  'Língua Portugesa (5)': linguaPortuguesa_5,

  'General Knowledge': generalKnowledge,

  'Matrizes Progressivas (1)':matrizes_progressivas_1,
  'Matrizes Progressivas (2)':matrizes_progressivas_2,
  'Matrizes Progressivas (3)':matrizes_progressivas_3,

  'Raciocínio Verbal (1)':raciocinio_verbal_1,
  'Raciocínio Verbal (2)':raciocinio_verbal_2,
  'Raciocínio Verbal (3)':raciocinio_verbal_3,

  'Raciocínio Abstrato (1)':raciocinio_abstrato_1,
  'Raciocínio Abstrato (2)':raciocinio_abstrato_2,

  'Raciocínio espacial (1)':raciocinio_espacial_1,
  'Raciocínio espacial (2)':raciocinio_espacial_2







}

// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const gnr_provaCompleta_3: Topic = {
  topic: 'GNR - Prova Completa (3)',
  level: 'Advanced',
  totalQuestions: 50,
  totalScore: 500,
  totalTime: 1800,
  questions: [
    {
      "question": "A idade geral de passagem à reserva é de:",
      "choices": [
        "Satisfação das necessidades ou o interesse do serviço.",
        "Satisfação das condições individuais de promoção.",
        "Aproveitamento da capacidade intelectual em função da experiência revelada.",
        "Primado dos interesses pessoais aos do serviço."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Satisfação das necessidades ou o interesse do serviço."
      ],
      "score": 10
    },
    {
      "question": "A idade geral de passagem à reserva é de:",
      "choices": [
        "55 anos.",
        "60 anos.",
        "65 anos.",
        "68 anos."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "55 anos."
      ],
      "score": 10
    },
    {
      "question": "A promoção que se efetua por ordem de cursos e, dentro do mesmo curso, por ordem decrescente de classificação obtida neste, é uma promoção por:",
      "choices": [
        "Antiguidade.",
        "Habilitação com curso adequado.",
        "Distinção.",
        "Escolha dos melhores."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Habilitação com curso adequado."
      ],
      "score": 10
    },
    {
      "question": "É condição especial de promoção ao posto de guarda-principal ter o tempo mínimo de:",
      "choices": [
        "Oito anos de antiguidade no posto de guarda.",
        "Dez anos de antiguidade no posto de guarda.",
        "Dezoito anos de antiguidade no posto de guarda.",
        "Vinte anos de antiguidade no posto de guarda."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Oito anos de antiguidade no posto de guarda."
      ],
      "score": 10
    },
    {
      "question": "Sem prejuízo das condições gerais de admissão ao curso de formação inicial de guardas, as condições especiais de admissão são definidas por: ",
      "choices": [
        "Despacho do Secretário de Estado para as Guardas, na data de abertura do concurso.",
        "Despacho do Ministro da Tutela, na data de abertura do concurso.",
        "Despacho do comandante-geral, na data de abertura do concurso.",
        "Despacho do Secretário-geral, na data de abertura do concurso."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Despacho do comandante-geral, na data de abertura do concurso."
      ],
      "score": 10
    },
    {
      "question": "Os militares da Guarda, após a frequência com aproveitamento nos cursos de acesso à respetiva categoria, prestam juramento de fidelidade, no caso dos guardas chama-se: ",
      "choices": [
        "Compromisso de vida.",
        "Compromisso de honra.",
        "Compromisso solene.",
        "Juramento de bandeira."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Compromisso de honra."
      ],
      "score": 10
    },
    {
      "question": "Constitui um dos deveres do militar da Guarda:",
      "choices": [
        "Comunicar todas as alterações à sua evolução relativamente a habilitações académicas que obtenha ou cursos técnicos e superiores que complete.",
        "Comunicar as formações e seminários que frequente com aproveitamento.",
        "Comunicar, se quiser, todas as alterações à sua evolução relativamente a habilitações académicas que obtenha ou cursos técnicos e superiores que complete.",
        "Comunicar à Direção de Registo da Formação todas as alterações à sua evolução relativamente a habilitações académicas que obtenha ou cursos técnicos e superiores que complete."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Comunicar todas as alterações à sua evolução relativamente a habilitações académicas que obtenha ou cursos técnicos e superiores que complete."
      ],
      "score": 10
    },
    {
      "question": "A categoria de Guardas compreende os postos de:",
      "choices": [
        "Alferes, Cabo-Chefe, Cabo, Guarda-Principal e Guarda.",
        "Cabo-Chefe, Cabo, Guarda-Principal, Guarda e Guarda-Florestal.",
        "Guarda, Guarda-Principal, Cabo, Cabo-Chefe e Cabo-Mor.",
        "Sargento-Chefe, Cabo, Guarda-Principal, Cabo-Mor e Guarda."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Guarda, Guarda-Principal, Cabo, Cabo-Chefe e Cabo-Mor."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda Nacional Republicana encontra-se numa das seguintes situações:",
      "choices": [
        "Ativo, inativo ou reforma.",
        "Ativo, de doença ou reforma.",
        "Ativo, reserva ou reforma.",
        "Ativo ou inativo."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ativo, reserva ou reforma."
      ],
      "score": 10
    },
    {
      "question": "Compete ao militar da Guarda Nacional Republicana:",
      "choices": [
        "Cumprir prontamente todas as missões ou atos de serviço.",
        "Cumprir algumas missões ou atos de serviço.",
        "Comunicar ao inferior hierárquico a sua constituição como arguido.",
        "Prestar auxílio em qualquer diligência em matéria ilegal."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Cumprir prontamente todas as missões ou atos de serviço."
      ],
      "score": 10
    },
    {
      "question": "É condição especial de promoção ao posto de cabo-mor:",
      "choices": [
        "Ter o tempo mínimo de quatro anos de antiguidade no posto de cabo-chefe.",
        "Ter o tempo mínimo de oito anos de antiguidade no posto de cabo-chefe.",
        "Ter o tempo mínimo de seis anos de antiguidade no posto de cabo-chefe.",
        "Ter o tempo mínimo de cinco anos de antiguidade no posto de cabo-chefe."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ter o tempo mínimo de cinco anos de antiguidade no posto de cabo-chefe."
      ],
      "score": 10
    },

    //202 1  chamada

    {
      "question": "O livrete de saúde destina-se a:",
      "choices": [
        "Ter o registo das vacinas em dia, criado por causa da COVID-19 pelo Ministério da Administração Interna em 2021.",
        "Registo de baixas para constar na redução da remuneração mensal.",
        "Acompanhamento das baixas dos militares para efeitos de separação de serviço.",
        "Registo dos factos de índole sanitária de cada militar na efetividade do serviço e constitui documento de natureza classificada."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Registo dos factos de índole sanitária de cada militar na efetividade do serviço e constitui documento de natureza classificada."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda, para ser promovido, tem de satisfazer as condições gerais e especiais de promoção. São, entre outras, condições gerais de promoção, comuns a todos os militares:",
      "choices": [
      "Aptidão física e psíquica adequadas; Estar colocado na primeira ou segunda classe de comportamento",
      "Aptidão física e psíquica adequadas; Estar colocado na primeira, na segunda ou na terceira classe de comportamento",
      "Desempenho com eficiência das funções do seu posto; Estar colocado na primeira, na segunda ou na terceira classe de comportamento",
      "Desempenho com eficiência das funções do seu posto; Dedicação ao serviço"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "Aptidão física e psíquica adequadas; Estar colocado na primeira ou segunda classe de comportamento"
      ],
      "score": 10
  },
  {
    "question": "São condições especiais de promoção:",
    "choices": [
      "Avaliação do desempenho efetuada, em regra, pelos superiores hierárquicos imediatos, nos moldes previstos no presente Estatuto.",
      "Atos heroicos que fortaleçam a imagem da Guarda.",
      "Tempo mínimo de antiguidade no posto.",
      "Ficha curricular com registos distintos dos demais."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Tempo mínimo de antiguidade no posto."
    ],
    "score": 10
  },
  {
    "question": "A concessão de licença de férias obedece às seguintes regras:",
    "choices": [
      "Ter mais de um ano de serviço efetivo exceto no ano civil de ingresso.",
      "Pode sobrepor-se à frequência de estágios.",
      "Pode prejudicar a tramitação de processo disciplinar ou criminal em curso.",
      "Não pode ser interrompida por imperiosa e imprevista necessidade do serviço."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Ter mais de um ano de serviço efetivo exceto no ano civil de ingresso."
    ],
    "score": 10
  },
  {
    "question": "Ao 2.º comandante-geral compete: Coadjuvar o comandante-geral no exercício das suas funções; exercer as competências que lhe forem delegadas ou subdelegadas pelo comandante -geral; substituir o comandante-geral nas suas ausências ou impedimentos e é um:",
    "choices": [
      "Diretor Superior proveniente dos quadros da PGR.",
      "Tenente-General ou Almirante.",
      "Diretor Intermédio qualificado com curso superior em administração pública.",
      "Tenente-General nomeado pelo ministro da tutela, sob a proposta do comandante-geral da Guarda."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Tenente-General nomeado pelo ministro da tutela, sob a proposta do comandante-geral da Guarda."
    ],
    "score": 10
  },
  {
    "question": "O Conselho de Ética, Deontologia e Disciplina na área de recursos humanos é representado pela:",
    "choices": [
      "Direção de Recursos Financeiros.",
      "Direção de Recursos Humanos.",
      "Chefia de Serviço de Finanças.",
      "Direção de Justiça."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Direção de Recursos Humanos."
    ],
    "score": 10
  },
  {
    "question": "O Comando da Administração dos Recursos Internos:",
    "choices": [
      "Assegura o comando de toda a atividade operacional da Guarda.",
      "É o órgão a que compete julgar o grau de capacidade para o serviço de oficiais, sargentos e guardas que, por ordem do comandante-geral, lhe forem presentes, bem como emitir parecer sobre os recursos relativos a decisões baseadas em pareceres formulados pelas juntas médicas da Guarda.",
      "Assegura o comando e direção de toda a atividade da Guarda nos domínios da administração dos recursos humanos, materiais e financeiros.",
      "Assegura o comando e direção de toda a atividade da Guarda nos domínios da doutrina e formação do efetivo da Guarda."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Assegura o comando e direção de toda a atividade da Guarda nos domínios da administração dos recursos humanos, materiais e financeiros."
    ],
    "score": 10
  },
  {
    "question": "Nos termos da Lei Orgânica, a Guarda tem direito a:",
    "choices": [
      "A bandeira heráldica, Banda de Música, selo branco e condecoração pública.",
      "A bandeira heráldica, Banda de Música, hino, marcha, selo branco e condecoração privativa.",
      "Brasão de armas, bandeira heráldica, hino, marcha, selo branco e condecoração pública.",
      "Brasão de armas, bandeira heráldica, hino, marcha, selo branco e condecoração privativa."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Brasão de armas, bandeira heráldica, hino, marcha, selo branco e condecoração privativa."
    ],
    "score": 10
  },
  {
    "question": "O Dia da Guarda é comemorado a:",
    "choices": [
      "10 de junho, em evocação da lei que criou a atual instituição nacional, em 1911.",
      "25 de abril, em evocação da transformação ocorrida na terceira república, em 1974.",
      "3 de maio, em evocação da lei que criou a atual instituição nacional, em 1911.",
      "5 de outubro, em evocação da instauração da primeira república, em 1910."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "3 de maio, em evocação da lei que criou a atual instituição nacional, em 1911."
    ],
    "score": 10
  },
  {
    "question": "O Comandante da Unidade de Controlo Costeiro tem o posto de:",
    "choices": [
      "Tenente-General ou Major-General.",
      "Vice-Almirante ou Contra-Almirante.",
      "Tenente-General ou Vice-Almirante.",
      "Major-General ou Contra-Almirante."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Major-General ou Contra-Almirante."
    ],
    "score": 10
  },
  {
    "question": "USHE é uma unidade de representação responsável pela protecção e segurança às instalações dos órgãos de soberania e de outras entidades que lhe sejam confiadas e pela prestação de honras de Estado. Articula-se em:",
    "choices": [
      "Esquadrão Presidencial, Esquadrão da Assembleia da República e Esquadrão de Intervenção aero-naval.",
      "Esquadrão Presidencial, Esquadrão da Assembleia da República e Esquadrão Motorizado.",
      "Esquadrão Presidencial, Esquadrão da Assembleia da República e Esquadrão deReconhecimento.",
      "Esquadrão Presidencial, subunidade de honras de Estado e subunidade de segurança."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Esquadrão Presidencial, subunidade de honras de Estado e subunidade de segurança."
    ],
    "score": 10
  },
  {
    "question": "Constituem atribuições da Guarda Nacional Republicana:",
    "choices": [
      "Garantir as condições de segurança que permitam o exercício dos direitos e liberdades e o respeito pelas garantias dos cidadãos.",
      "Participar exclusivamente no controlo de saida de pessoas e bens no território internacional.",
      "Prevenir a sinistralidade rodoviária em geral, em coordenação com as forças armadas.",
      "Todas as alíneas anteriores estão erradas."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Garantir as condições de segurança que permitam o exercício dos direitos e liberdades e o respeito pelas garantias dos cidadãos."
    ],
    "score": 10
  },
  {
    "question": "São consideradas autoridades de polícia:",
    "choices": [
      "O comandante-geral.",
      "0 2º comandante de Posto Territorial.",
      "O comandante do Posto Territorial.",
      "Todas as alíneas anteriores estão corretas."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "O comandante-geral."
    ],
    "score": 10
  },
  {
    "question": "A categoria profissional de guardas compreende os seguintes postos:",
    "choices": [
      "Cabo-adjunto.",
      "Primeiro-cabo.",
      "Segundo-cabo.",
      "Todas as alíneas estão incorretas."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Todas as alíneas estão incorretas."
    ],
    "score": 10
  },
  {
    "question": "As subunidades operacionais dos comandos territoriais são os destacamentos, que se articulam localmente em subdestacamentos ou postos. O destacamento é comandado por:",
    "choices": [
      "Tenente-Coronel ou Sargento.",
      "1º Sargento ou Cabo.",
      "Cabo-Chefe ou Sargento-Chefe.",
      "Major ou Capitão."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Major ou Capitão."
    ],
    "score": 10
  },
  {
    "question": "O Posto Territorial é comandado por militar da categoria de:",
    "choices": [
      "Oficial.",
      "Guarda.",
      "Sargento.",
      "Guarda-Florestal."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Sargento."
    ],
    "score": 10
  },
  {
    "question": "O Comando Territorial é responsável pelo cumprimento da missão da Guarda Nacional Republicana na área de responsabilidade que lhe for atribuída: ",
    "choices": [
      "Na dependência indireta do Comandante Operacional.",
      "Na dependência direta do Comandante-Geral.",
      "Na dependência direta do Comando da Administração de Recursos Internos.",
      "Na dependência indireta do Comando de Doutrina e Formação."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Na dependência direta do Comandante-Geral."
    ],
    "score": 10
  },
  {
    "question": "Os militares da Guarda agrupam-se hierarquicamente em categorias profissionais, subcategorias e postos. A categoria profissional de guardas compreende os postos de:",
    "choices": [
      "Cabo-mor, cabo-chefe, cabo-ajudante, primeiro-cabo, segundo-cabo, guarda-principal e guarda",
      "Cabo-mor, cabo-chefe, cabo, guarda principal e guarda",
      "Alferes-mor, alferes-chefe, alferes-ajudante, guarda-principal e guarda",
      "Cabo-mor, cabo-chefe, cabo, guarda-principal, guarda e guarda-provisório"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Cabo-mor, cabo-chefe, cabo, guarda principal e guarda"
    ],
    "score": 10
  },
  {
    "question": "A estrutura de comando da Guarda compreende:",
    "choices": [
      "O comandante-geral, o 2.º comandante-geral e o órgão de inspeção",
      "O Comando Operacional, o Comando da Administração dos Recursos Internos e o Comando da Doutrina e Formação",
      "O órgão de inspeção, os órgãos de conselho e a Secretaria-geral",
      "O Comando da Guarda e os órgãos superiores de comando e direção"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "O Comando da Guarda e os órgãos superiores de comando e direção"
    ],
    "score": 10
  },
  {
    "question": "Segundo o tratado assinado em 1971, que região do planeta ficou interdita a armas nucleares?",
    "choices": [
      "Sara",
      "África",
      "Antártida",
      "Bahamas"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Antártida"
    ],
    "score": 10
  },
  {
    "question": "Por que nome se tornou mais conhecido o escritor norte-americano Samuel Langhorne Clemens?",
    "choices": [
      "Paul Auster",
      "Mark Twain",
      "Paulo Coelho",
      "Nicolau Gogol"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Mark Twain"
    ],
    "score": 10
  },
  {
    "question": "Que expressão alemã significa 'guerra-relâmpago'?",
    "choices": [
      "Sauerkraut",
      "Glasnost",
      "Reichstag",
      "Blitzkrieg"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Blitzkrieg"
    ],
    "score": 10
  },
  {
    "question": "Quantos distritos compõem Portugal Continental?",
    "choices": [
      "16",
      "17",
      "18",
      "19"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "18"
    ],
    "score": 10
  },
  {
    "question": "Onde decorreu o mundial de 2022 de Futebol?",
    "choices": [
      "EUA",
      "Qatar",
      "Arábia Saudita",
      "Emirados Árabes Unidos"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Qatar"
    ],
    "score": 10
  },
  {
    "question": "Qual o estreito que limita o Continente Europeu do Asiático na Turquia?",
    "choices": [
      "Estreito de Dardanelos",
      "Estreito de Bósforo",
      "Estreito de Messina",
      "Estreito de Gibraltar"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Estreito de Bósforo"
    ],
    "score": 10
  },
  {
    "question": "Onde se situa Auschwitz?",
    "choices": [
      "Áustria",
      "Alemanha",
      "Polónia",
      "Eslováquia"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Polónia"
    ],
    "score": 10
  },
  {
    "question": "De acordo com a Constituição da República Portuguesa, durante o impedimento temporário do Presidente da República, bem como durante a vagatura do cargo até tomar posse o novo Presidente eleito, assumirá as funções:",
    "choices": [
      "O Presidente da Assembleia da República.",
      "O Primeiro-ministro.",
      "O Procurador Geral da República.",
      "O Ministro da Administração Interna."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "O Presidente da Assembleia da República."
    ],
    "score": 10
  },
  {
    "question": "De acordo com a Declaração Universal dos Direitos Humanos:",
    "choices": [
      "Todo o ser humano pode ser mantido em escravatura.",
      "Todo o ser humano pode ser mantido em servidão.",
      "Todo o ser humano tem direito à segurança pessoal.",
      "Todo o ser humano pode ser submetido a tortura ou, a penas e tratamentos cruéis"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Todo o ser humano tem direito à segurança pessoal."
    ],
    "score": 10
  },
  {
    "question": "De acordo com a Declaração Universal dos Direitos Humanos:",
    "choices": [
      "Todo o ser humano pode ser arbitrariamente preso.",
      "Todo o ser humano pode ser arbitrariamente exilado.",
      "Todo o ser humano tem direito à vida.",
      "Todo o ser humano pode ser arbitrariamente detido."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Todo o ser humano tem direito à vida."
    ],
    "score": 10
  },
  {
    "question": "Na frase 'Eu gosto de estudar à noite', o termo 'de estudar' desempenha a função de:",
    "choices": [
      "Complemento direto",
      "Objeto indireto",
      "Complemento oblíquo",
      "Adjunto adverbial"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Complemento oblíquo"
    ],
    "score": 10
  },
  {
    "question": "Qual é o valor semântico da conjunção 'para que' na frase: 'Ela estuda para que passe no exame'?",
    "choices": [
      "Causa",
      "Condição",
      "Finalidade",
      "Concessão"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Finalidade"
    ],
    "score": 10
  },
  {
    "question": "Em qual das frases o verbo está no pretérito imperfeito do indicativo?",
    "choices": [
      "Eu corria todos os dias.",
      "Eu corri ontem.",
      "Eu terei corrido amanhã.",
      "Eu correria se pudesse."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Eu corria todos os dias."
    ],
    "score": 10
  },
  {
    "question": "Qual é a função sintática do termo 'feliz' na frase: 'Ela parece feliz.'?",
    "choices": [
      "Predicativo do sujeito",
      "Sujeito",
      "Complemento direto",
      "Adjunto adverbial"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Predicativo do sujeito"
    ],
    "score": 10
  },
  {
    "question": "Em qual das frases há uma oração subordinada adverbial condicional?",
    "choices": [
      "Se ele vier, vamos à praia.",
      "Embora estivesse cansado, foi ao trabalho.",
      "Vou ao cinema porque gosto de filmes.",
      "Ela estuda tanto quanto ele."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Se ele vier, vamos à praia."
    ],
    "score": 10
  },
  {
    "question": "Qual das seguintes palavras é um advérbio?",
    "choices": [
      "Felizmente",
      "Feliz",
      "Felicidade",
      "Felicitar"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Felizmente"
    ],
    "score": 10
  },
  {
    "question": "Qual das opções apresenta uma oração subordinada adjetiva explicativa?",
    "choices": [
      "O carro, que está estacionado, é meu.",
      "O carro que comprei é vermelho.",
      "Vou ao cinema se terminar o trabalho.",
      "Embora esteja cansado, vou sair."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "O carro, que está estacionado, é meu."
    ],
    "score": 10
  },
  {
    "question": "Qual das seguintes opções apresenta um sujeito composto?",
    "choices": [
      "O Pedro e o João foram ao cinema.",
      "Ela estuda todos os dias.",
      "Nós fomos ao parque.",
      "Ele foi à escola."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "O Pedro e o João foram ao cinema."
    ],
    "score": 10
  },
  {
    "question": "Identifique o objeto direto na frase: 'O João leu o livro.'",
    "choices": [
      "O João",
      "Leu",
      "O livro",
      "Nenhum"
    ],
    "type": "MCQs",
    "correctAnswers": [
      "O livro"
    ],
    "score": 10
  },
  {
    "question": "Qual das frases está no futuro do indicativo?",
    "choices": [
      "Eu irei à festa amanhã.",
      "Eu fui à festa ontem.",
      "Eu iria à festa, se pudesse.",
      "Eu tinha ido à festa."
    ],
    "type": "MCQs",
    "correctAnswers": [
      "Eu irei à festa amanhã."
    ],
    "score": 10
  }

  ],
}

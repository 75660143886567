// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const linguaPortuguesa_4: Topic = {
  topic: 'Língua Portugesa (4)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "Identifique a função sintática do termo 'todos os dias' na frase: 'Eu estudo todos os dias.'",
      "choices": [
        "Objeto direto",
        "Adjunto adverbial de tempo",
        "Predicativo do sujeito",
        "Complemento oblíquo"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Adjunto adverbial de tempo"
      ],
      "score": 10
    },
    {
      "question": "Qual das frases está no modo subjuntivo?",
      "choices": [
        "Se eu tivesse dinheiro, compraria um carro.",
        "Eu tenho dinheiro para comprar um carro.",
        "Vou comprar um carro amanhã.",
        "Eu comprei um carro."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Se eu tivesse dinheiro, compraria um carro."
      ],
      "score": 10
    },
    {
      "question": "Qual é a classificação da oração 'que ela seja feliz' na frase: 'Espero que ela seja feliz.'?",
      "choices": [
        "Oração subordinada substantiva completiva",
        "Oração subordinada adverbial condicional",
        "Oração subordinada adjetiva restritiva",
        "Oração subordinada adverbial final"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Oração subordinada substantiva completiva"
      ],
      "score": 10
    },
    {
      "question": "Qual é a função sintática do termo 'de leitura' na frase: 'Ele gosta de leitura.'?",
      "choices": [
        "Objeto direto",
        "Objeto indireto",
        "Complemento oblíquo",
        "Adjunto adnominal"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Complemento oblíquo"
      ],
      "score": 10
    },

    {
      "question": "Qual das seguintes palavras é um pronome relativo?",
      "choices": [
        "Que",
        "Ele",
        "Nosso",
        "Aquele"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Que"
      ],
      "score": 10
    },
    {
      "question": "Em qual das frases há um erro de regência nominal?",
      "choices": [
        "Tenho necessidade de apoio.",
        "Estou orgulhoso do meu trabalho.",
        "Ele é responsável por tudo.",
        "Ele é indiferente à opinião dos outros."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Estou orgulhoso do meu trabalho."
      ],
      "score": 10
    },
    {
      "question": "Qual é o tempo verbal da frase: 'Se eu soubesse, teria ajudado.'?",
      "choices": [
        "Pretérito perfeito do indicativo",
        "Pretérito imperfeito do subjuntivo",
        "Futuro do presente do indicativo",
        "Pretérito mais-que-perfeito do indicativo"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Pretérito imperfeito do subjuntivo"
      ],
      "score": 10
    },
    {
      "question": "Na frase 'Eles chegaram a tempo', o termo 'a tempo' exerce a função de:",
      "choices": [
        "Objeto direto",
        "Adjunto adverbial de tempo",
        "Complemento nominal",
        "Predicativo do sujeito"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Adjunto adverbial de tempo"
      ],
      "score": 10
    },
    {
      "question": "Qual das frases contém uma locução adverbial?",
      "choices": [
        "Ele estudou com afinco.",
        "Ele corre rapidamente.",
        "Ela estuda muito.",
        "Nós lemos o livro."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ele estudou com afinco."
      ],
      "score": 10
    },
    {
      "question": "Em qual das frases o termo sublinhado é um adjunto adnominal?",
      "choices": [
        "O livro _do Pedro_ está em cima da mesa.",
        "Ela _chegou cedo_ à festa.",
        "Fizemos o trabalho _com cuidado_.",
        "Ele comprou _um carro novo_."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O livro _do Pedro_ está em cima da mesa."
      ],
      "score": 10
    },
    {
      "question": "Qual é a classificação da oração em destaque na frase: 'Eles disseram _que iam ao cinema_'?",
      "choices": [
        "Oração subordinada adverbial causal",
        "Oração subordinada substantiva objetiva direta",
        "Oração subordinada adjetiva restritiva",
        "Oração subordinada adverbial condicional"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Oração subordinada substantiva objetiva direta"
      ],
      "score": 10
    },
    {
      "question": "Em qual das frases o verbo 'ter' está no pretérito mais-que-perfeito do indicativo?",
      "choices": [
        "Eu tivera um sonho estranho.",
        "Tenho tido sorte.",
        "Terei um novo emprego.",
        "Eu tive uma boa notícia ontem."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Eu tivera um sonho estranho."
      ],
      "score": 10
    },
    {
      "question": "Qual das opções contém uma metáfora?",
      "choices": [
        "O tempo é ouro.",
        "Correu como o vento.",
        "A árvore estava cheia de frutos.",
        "Ele estuda bastante."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O tempo é ouro."
      ],
      "score": 10
    },
    {
      "question": "Em qual das frases há um sujeito indeterminado?",
      "choices": [
        "Vive-se bem aqui.",
        "Eles vivem bem.",
        "O João vive aqui.",
        "Os amigos vivem longe."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Vive-se bem aqui."
      ],
      "score": 10
    },
    {
      "question": "Qual das seguintes frases contém uma oração subordinada adverbial condicional?",
      "choices": [
        "Se eu soubesse, não teria ido.",
        "Ele trabalha porque precisa de dinheiro.",
        "Embora estivesse cansado, foi à festa.",
        "Ele fez o trabalho de modo a agradar o chefe."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Se eu soubesse, não teria ido."
      ],
      "score": 10
    },
    {
      "question": "Em qual das frases o sujeito é simples?",
      "choices": [
        "Os alunos e os professores organizaram o evento.",
        "As folhas caíram com o vento.",
        "Fomos ao cinema ontem.",
        "Correram rápido para não se atrasarem."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "As folhas caíram com o vento."
      ],
      "score": 10
    },
    {
      "question": "Identifique a função sintática do termo 'ao amigo' na frase: 'Ela entregou o presente ao amigo.'",
      "choices": [
        "Objeto direto",
        "Objeto indireto",
        "Adjunto adverbial",
        "Sujeito"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Objeto indireto"
      ],
      "score": 10
    },
    {
      "question": "Em qual das frases o verbo está no presente do subjuntivo?",
      "choices": [
        "Espero que ele venha amanhã.",
        "Ele veio à festa ontem.",
        "Se ele viesse, eu ficaria contente.",
        "Eu vim à escola todos os dias."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Espero que ele venha amanhã."
      ],
      "score": 10
    },
    {
      "question": "Em qual das frases há um erro de concordância nominal?",
      "choices": [
        "Os meninos estavam alegres.",
        "As alunas são dedicadas.",
        "As casas era grandes.",
        "Eles estavam preparados."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "As casas era grandes."
      ],
      "score": 10
    },
    {
      "question": "Qual é a função do termo 'ao parque' na frase: 'Ela foi ao parque.'?",
      "choices": [
        "Objeto direto",
        "Objeto indireto",
        "Complemento nominal",
        "Adjunto adverbial de lugar"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Adjunto adverbial de lugar"
      ],
      "score": 10
    }
  ],
}

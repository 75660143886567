// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const linguaPortuguesa_2: Topic = {
  topic: 'Língua Portugesa (2)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "Identifique a figura de estilo presente na frase: 'Ela é tão rápida como um raio.'",
      "choices": [
        "Metáfora",
        "Comparação",
        "Hipérbole",
        "Eufemismo"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Comparação"
      ],
      "score": 10
    },
    {
      "question": "Em qual das frases o advérbio está corretamente utilizado?",
      "choices": [
        "Ela fala baixo.",
        "O filme é muito bom.",
        "Ela sempre está atrasada.",
        "A aluna leu bem o texto."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ela fala baixo."
      ],
      "score": 10
    },
    {
      "question": "Qual dos verbos abaixo está na forma de infinitivo impessoal?",
      "choices": [
        "Comer",
        "Comermos",
        "Comendo",
        "Comerem"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Comer"
      ],
      "score": 10
    },
    {
      "question": "Identifique a oração subordinada causal na frase:",
      "choices": [
        "Ele chegou cedo porque acordou cedo.",
        "Embora esteja cansado, vou trabalhar.",
        "Tenho medo de que chova hoje.",
        "Se chovesse, não iríamos à praia."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ele chegou cedo porque acordou cedo."
      ],
      "score": 10
    },
      {
        "question": "Qual é a função sintática do termo 'ao amigo' na frase: 'Ele entregou o presente ao amigo'?",
        "choices": [
          "Sujeito",
          "Objeto direto",
          "Objeto indireto",
          "Adjunto adnominal"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "Objeto indireto"
        ],
        "score": 10
      },
      {
        "question": "Na frase 'O aluno estudou bastante para o exame', a palavra 'bastante' é um:",
        "choices": [
          "Adjetivo",
          "Advérbio",
          "Pronome",
          "Preposição"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "Advérbio"
        ],
        "score": 10
      },
      {
        "question": "Qual das palavras abaixo é uma forma de verbo no pretérito perfeito composto?",
        "choices": [
          "Tinha estudado",
          "Tenho estudado",
          "Estudava",
          "Estudei"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "Tenho estudado"
        ],
        "score": 10
      },
      {
        "question": "Identifique a voz verbal na frase: 'O livro foi lido pelo João.'",
        "choices": [
          "Voz ativa",
          "Voz passiva",
          "Voz reflexiva",
          "Voz recíproca"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "Voz passiva"
        ],
        "score": 10
      },
      {
        "question": "Qual das seguintes frases contém uma oração subordinada causal?",
        "choices": [
          "Vou ao mercado porque preciso de comprar pão.",
          "Se fores ao mercado, compra pão.",
          "Ele comprou pão, mas não o comeu.",
          "Embora tenha comprado pão, não o comeu."
        ],
        "type": "MCQs",
        "correctAnswers": [
          "Vou ao mercado porque preciso de comprar pão."
        ],
        "score": 10
      },
      {
        "question": "Qual das opções apresenta um verbo no condicional?",
        "choices": [
          "Eu viajaria pelo mundo, se pudesse.",
          "Ele viajou pelo mundo.",
          "Ele viaja pelo mundo todos os anos.",
          "Vou viajar pelo mundo."
        ],
        "type": "MCQs",
        "correctAnswers": [
          "Eu viajaria pelo mundo, se pudesse."
        ],
        "score": 10
      },
      {
        "question": "Na frase 'Os alunos estavam cansados após o exame', o termo 'cansados' é:",
        "choices": [
          "Predicativo do sujeito",
          "Adjunto adverbial",
          "Objeto direto",
          "Objeto indireto"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "Predicativo do sujeito"
        ],
        "score": 10
      },
      {
        "question": "Qual das frases abaixo contém um advérbio de tempo?",
        "choices": [
          "Ele correu rapidamente.",
          "Eles chegaram ontem.",
          "Ela fala muito bem.",
          "Ele mora longe."
        ],
        "type": "MCQs",
        "correctAnswers": [
          "Eles chegaram ontem."
        ],
        "score": 10
      },
      {
        "question": "Qual das seguintes frases está no modo imperativo?",
        "choices": [
          "Faz o teu trabalho agora!",
          "Eu faço o meu trabalho todos os dias.",
          "Ele fez o trabalho ontem.",
          "Eles fariam o trabalho se tivessem tempo."
        ],
        "type": "MCQs",
        "correctAnswers": [
          "Faz o teu trabalho agora!"
        ],
        "score": 10
      },
      {
        "question": "Qual das seguintes frases apresenta um sujeito indeterminado?",
        "choices": [
          "Choveu muito ontem.",
          "Fala-se muito sobre esse tema.",
          "Os alunos estudam todos os dias.",
          "O João foi ao mercado."
        ],
        "type": "MCQs",
        "correctAnswers": [
          "Fala-se muito sobre esse tema."
        ],
        "score": 10
      },
      {
        "question": "Qual é o valor semântico da conjunção em: 'Estudo todos os dias, embora esteja cansado'?",
        "choices": [
          "Causa",
          "Condição",
          "Concessão",
          "Finalidade"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "Concessão"
        ],
        "score": 10
      },
      {
        "question": "Identifique o pronome oblíquo na frase: 'O professor entregou-me o trabalho corrigido.'",
        "choices": [
          "Professor",
          "Entregou",
          "Me",
          "Corrigido"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "Me"
        ],
        "score": 10
      },
      {
        "question": "Em qual das frases há um erro de concordância verbal?",
        "choices": [
          "As meninas brincam no parque.",
          "Os alunos estão cansados.",
          "Ele e ela viajou para o Algarve.",
          "Nós fomos ao cinema ontem."
        ],
        "type": "MCQs",
        "correctAnswers": [
          "Ele e ela viajou para o Algarve."
        ],
        "score": 10
      },
      {
        "question": "Qual das seguintes frases contém uma oração subordinada adverbial consecutiva?",
        "choices": [
          "Ele correu tanto que ficou exausto.",
          "Ela correu para a escola.",
          "Eu corro todas as manhãs.",
          "Corro para não chegar tarde."
        ],
        "type": "MCQs",
        "correctAnswers": [
          "Ele correu tanto que ficou exausto."
        ],
        "score": 10
      },
      {
        "question": "Qual das seguintes opções contém um verbo no presente do indicativo?",
        "choices": [
          "Eu estudo todos os dias.",
          "Eu estudaria se tivesse tempo.",
          "Eu estudei ontem à noite.",
          "Eu estava a estudar."
        ],
        "type": "MCQs",
        "correctAnswers": [
          "Eu estudo todos os dias."
        ],
        "score": 10
      },
      {
        "question": "Na frase 'Ele chegou tarde à reunião', o termo 'à reunião' é:",
        "choices": [
          "Objeto direto",
          "Objeto indireto",
          "Adjunto adverbial de lugar",
          "Predicativo do sujeito"
        ],
        "type": "MCQs",
        "correctAnswers": [
          "Adjunto adverbial de lugar"
        ],
        "score": 10
      }
  ],
}

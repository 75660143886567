// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const cg_1: Topic = {
  topic: 'Cultura Geral (1)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "O menor e o maior país do mundo, em extensão territorial, são, respetivamente:",
      "choices": [
        "Malta e Austrália",
        "Mónaco e Índia",
        "Suíça e Estados Unidos da América",
        "Vaticano e Rússia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Vaticano e Rússia"
      ],
      "score": 10
    },
    {
      "question": "O órgão da União Europeia cuja principal função é gerir o euro, manter a estabilidade dos preços e conduzir a política económica e monetária da UE, é:",
      "choices": [
        "O Banco Europeu",
        "O Tribunal Europeu",
        "A Comissão Europeia",
        "O Banco Central Europeu"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Banco Central Europeu"
      ],
      "score": 10
    },
    {
      "question": "O espaço sem fronteiras internas no interior do qual os cidadãos europeus e muitos nacionais de países que não pertencem à União Europeia podem circular livremente, em turismo ou por motivos de trabalho, sem serem sujeitos a controlos fronteiriços, é designado de:",
      "choices": [
        "Espaço Schengen",
        "Espaço de Livre Circulação",
        "Espaço Euro Livre",
        "Espaço Central Europeu"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Espaço Schengen"
      ],
      "score": 10
    },
    {
      "question": "O vencedor da volta à França em bicicleta 2018, também designada de Tour, que decorreu de 7 a 29 de julho de 2018, foi:",
      "choices": [
        "O britânico Geraint Thomas, da equipa SKY",
        "O britânico Chris Froome, da equipa SKY",
        "O britânico Chris Froome, da equipa Movistar",
        "O britânico Geraint Thomas, da equipa Movistar"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O britânico Geraint Thomas, da equipa SKY"
      ],
      "score": 10
    },
    {
      "question": "O país da América do Sul que faz fronteira com o Brasil e tem como capital Assunção é:",
      "choices": [
        "A Bolívia",
        "O Paraguai",
        "O Panamá",
        "O Chile"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Paraguai"
      ],
      "score": 10
    },
    {
      "question": "O Prémio Nobel da Medicina ou Fisiologia de 2018 foi atribuído a dois investigadores pelas descobertas relacionadas com a capacidade do sistema imunitário na luta contra o cancro. Esses investigadores são:",
      "choices": [
        "James P. Allison e Tasuku Honjo",
        "Jeffrey C. Hall e Michael Rosbash",
        "Yoshinori Ohsumi e Chingi Xicori",
        "Don Henley e Don Felder"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "James P. Allison e Tasuku Honjo"
      ],
      "score": 10
    },
    {
      "question": "Se há algumas décadas as cidades cresciam na horizontal, hoje crescem na vertical. Os edifícios estão cada vez mais altos, os países competem entre si e a cada ano surge um edifício mais alto que o anterior. Atualmente o edifício mais alto do mundo é:",
      "choices": [
        "A Torre de Shangai, na China",
        "O Burj Khalifa, no Dubai",
        "A One World Trade Center, nos Estados Unidos",
        "A Petronas Twin Towers, na Malásia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Burj Khalifa, no Dubai"
      ],
      "score": 10
    },
    {
      "question": "A via navegável artificial que permite que navios viajem entre o Mar Mediterrâneo e o Mar Vermelho, reduzindo assim a distância da viagem marítima entre o continente europeu e a Índia em cerca de 7 mil quilómetros, é:",
      "choices": [
        "O Canal da Mancha",
        "O Canal do Suez",
        "O Canal do Panamá",
        "O Canal do Ruez"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Canal do Suez"
      ],
      "score": 10
    },
    {
      "question": "O acrónimo ICNF significa:",
      "choices": [
        "Instituto da Conservação Natural das Florestas",
        "Instituto de Conservação Natural e Florestal",
        "Instituto Conservatório da Natureza Florestal",
        "Instituto da Conservação da Natureza e das Florestas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Instituto da Conservação da Natureza e das Florestas"
      ],
      "score": 10
    },
    {
      "question": "Os órgãos de soberania da República Portuguesa, de acordo com a Constituição da República Portuguesa, são:",
      "choices": [
        "O Presidente da República, as Assembleias Municipais, o Governo e os Tribunais",
        "O Presidente da República, a Assembleia da República, o Governo e as Autarquias",
        "O Presidente da República, as Assembleias Municipais, o Governo e os Partidos Políticos",
        "O Presidente da República, a Assembleia da República, o Governo e os Tribunais"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Presidente da República, a Assembleia da República, o Governo e os Tribunais"
      ],
      "score": 10
    },
    {
      "question": "Por que nome se tornou mais conhecido o escritor norte-americano Samuel Langhorne Clemens?",
      "choices": [
        "Paul Auster",
        "Mark Twain",
        "Paulo Coelho",
        "Nicolau Gogol"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Mark Twain"
      ],
      "score": 10
    },
    {
      "question": "O Presidente da República Portuguesa é o responsável pela condução da política geral do país e oórgão superior da administração pública.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "O que mudou na personagem Lucky Luke em 1983?",
      "choices": [
        "Começou a usar óculos",
        "Tornou-se vegetariano",
        "Deixou de fumar",
        "Deixou de usar armas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Deixou de fumar"
      ],
      "score": 10
    },
    {
      "question": "O Reino Unido é constituído por três países: Inglaterra, Escócia e Irlanda do Norte.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "A Assembleia da República pode votar moções de censura ao Governo sobre a execução do seu programa ou assunto relevante de interesse nacional, por iniciativa de um quarto dos Deputados em efetividade de funções ou de qualquer grupo parlamentar.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Qual o maior rio europeu?",
      "choices": [
        "Danúbio",
        "Nilo",
        "Volga",
        "Reno"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Volga"
      ],
      "score": 10
    },
    {
      "question": "Onde se situa o Banco Central Europeu?",
      "choices": [
        "Bruxelas",
        "Estrasburgo",
        "Genebra",
        "Frankfurt"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Frankfurt"
      ],
      "score": 10
    },
    {
      "question": "Em que país se situa a sede da ONU?",
      "choices": [
        "Bruxelas",
        "Genebra",
        "Paris",
        "Nova Iorque"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Nova Iorque"
      ],
      "score": 10
    },
    {
      "question": "Em que país se situa a sede da NATO?",
      "choices": [
        "Bruxelas",
        "Genebra",
        "Paris",
        "Nova Iorque"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Bruxelas"
      ],
      "score": 10
    },
    {
      "question": "Quem faz parte do grupo dos cinco?",
      "choices": [
        "Brasil, EUA, China, Japão, África do Sul",
        "Brasil, México, China, Índia, África do Sul",
        "Brasil, EUA, China, Japão, Rússia",
        "Brasil, EUA, China, Índia, Rússia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Brasil, México, China, Índia, África do Sul"
      ],
      "score": 10
    }
  ],
}

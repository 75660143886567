// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { DoDisturbAlt } from '@mui/icons-material'
import { Topic } from '..'

export const gnr_estatutos_7: Topic = {
  topic: 'GNR - Estatutos (7)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "As condições gerais de promoção, comuns a todos os militares são, entre outras, às seguintes:",
      "choices": [
        "Cumprimento dos deveres que lhes competem, aptidão física e psíquica adequadas e estar colocado na primeira ou segunda classe de comportamento.",
        "Desempenho com eficiência das funções do seu posto, qualidades e capacidades pessoais, intelectuais e profissionais requeridas para o posto imediato e estar colocado na primeira, segunda ou terceira classe de comportamento.",
        "Qualidades é capacidades pessoais, intelectuais e profissionais requeridas para o posto imediato, aptidão física e psíquica adequadas e estar colocado na terceira ou quarta classe de comportamento. ",
        "Desempenho com eficiência das funções do seu posto, qualidades e capacidades pessoais, intelectuais e profissionais requeridas para o posto imediato e estar colocado na segunda ou terceira classe de comportamento."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Cumprimento dos deveres que lhes competem, aptidão física e psíquica adequadas e estar colocado na primeira ou segunda classe de comportamento."
      ],
      "score": 10
    },
    {
      "question": "São condições especiais de promoção:",
      "choices": [
        "Avaliação do desempenho efetuada, em regra, pelos superiores hierárquicos imediatos, nos moldes previstos no presente Estatuto.",
        "Atos heroicos que fortaleçam a imagem da Guarda.",
        "Tempo mínimo de antiguidade no posto.",
        "Ficha curricular com registos distintos dos demais."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Tempo mínimo de antiguidade no posto."
      ],
      "score": 10
    },
    {
      "question": "A concessão de licença de férias obedece às seguintes regras:",
      "choices": [
        "Ter mais de um ano de serviço efetivo exceto no ano civil de ingresso.",
        "Pode sobrepor-se à frequência de estágios.",
        "Pode prejudicar a tramitação de processo disciplinar ou criminal em curso.",
        "Não pode ser interrompida por imperiosa e imprevista necessidade do serviço."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ter mais de um ano de serviço efetivo exceto no ano civil de ingresso."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda no ativo pode manter-se na situação de licença ilimitada sem cessação do vínculo pelo período máximo de:",
      "choices": [
        "Seis anos seguidos ou interpolados.",
        "Seis anos seguidos, ou oito interpolados.",
        "Oito anos seguidos, ou seis interpolados.",
        "Oito anos seguidos ou interpolados."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Seis anos seguidos ou interpolados."
      ],
      "score": 10
    },
    {
      "question": "A reclamação de um ato administrativo é facultativa, individual, dirigida por escrito, através das vias competentes, ao autor do ato, no prazo de:",
      "choices": [
        "30 dias, contados a partir do seu conhecimento pelo reclamante.",
        "15 dias, contados a partir do despacho.",
        "30 dias, contados a partir do despacho.",
        "15 dias, contados a partir do seu conhecimento pelo reclamante."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "15 dias, contados a partir do seu conhecimento pelo reclamante."
      ],
      "score": 10
    },
    {
      "question": "O ingresso na categoria de guardas faz-se no posto de guarda:",
      "choices": [
        "No dia seguinte à conclusão, com falta de aproveitamento, do respetivo curso de formação.",
        "No dia seguinte à conclusão, com aproveitamento, do respetivo curso de formação.",
        "No dia anterior à conclusão, com aproveitamento, do respetivo curso de formação.",
        "Nos 5 dias seguintes à conclusão, com aproveitamento, do respetivo curso de formação."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "No dia seguinte à conclusão, com aproveitamento, do respetivo curso de formação."
      ],
      "score": 10
    },
    {
      "question": "O recrutamento para a categoria de guardas é feito entre:",
      "choices": [
        "Os cidadãos que satisfaçam as condições gerais de admissão, mediante requerimento dirigido ao Comandante-Geral, ou procedimento de natureza equivalente.",
        "Os cidadãos que não satisfaçam as condições gerais de admissão, mediante requerimento dirigido ao Comandante-Geral, ou procedimento de natureza equivalente.",
        "Os cidadãos que não satisfaçam as condições de admissão, mediante requerimento dirigido ao Comandante de Posto.",
        "Os cidadãos que não satisfaçam as condições de admissão, mediante inquérito dirigido ao Comandante-Geral, ou outro procedimento."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Os cidadãos que satisfaçam as condições gerais de admissão, mediante requerimento dirigido ao Comandante-Geral, ou procedimento de natureza equivalente."
      ],
      "score": 10
    },
    {
      "question": "Os militares da Guarda Nacional Republicana são designados pelo:",
      "choices": [
        "Quadro a que pertencem.",
        "Posto.",
        "Número de matrícula e nome.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "Designam-se por guardas provisórios:",
      "choices": [
        "Os candidatos admitidos ao curso de formação de sargentos.",
        "Os candidatos admitidos ao curso de formação de guardas.",
        "Os candidatos admitidos ao curso de formação de oficiais.",
        "Os candidatos admitidos ao curso de formação de agentes."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Os candidatos admitidos ao curso de formação de guardas."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda Nacional Republicana está sujeito, a todo o tempo:",
      "choices": [
        "Aos riscos inerentes ao cumprimento das respetivas missões, que enfrenta sem coragem fisica e moral.",
        "Aos riscos inerentes ao cumprimento das respetivas missões, que enfrenta com coragem física e sem moral.",
        "Aos riscos inerentes ao cumprimento das respetivas missões, que enfrenta com coragem física e moral.",
        "Ao cumprimento das respetivas missões, que não enfrenta com coragem física e moral."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Aos riscos inerentes ao cumprimento das respetivas missões, que enfrenta com coragem física e moral."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda pode desistir da frequência de curso de promoção para que tenha sido nomeado ou que se encontre a frequentar, não podendo, porém, ser novamente nomeado, salvo quando se verifiquem razões de força maior atendíveis, nomeadamente as seguintes: ",
      "choices": [
        "A declaração de que é voluntário para o curso imediatamente a seguir.",
        "Declarar que precisa de uma pausa para ultrapassar o medo que tem de servir algo maior do que o próprio.",
        "Falecimento de cônjuge, ou equiparado, ascendente ou descendente no 1.º grau ou adotado.",
        "Assumir que pensava que era capaz de ser um Guarda, mas afinal precisa de se preparar melhor como pessoa."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falecimento de cônjuge, ou equiparado, ascendente ou descendente no 1.º grau ou adotado."
      ],
      "score": 10
    },
    {
      "question": "Na avaliação do desempenho do militar da Guarda intervêm:",
      "choices": [
        "O superior hierárquico imediato, os pares e os subordinados.",
        "Dois avaliadores, sendo um deles, por norma, o superior hierárquico imediato.",
        "O superior hierárquico imediato, os pares, apenas indicativo, e os subordinados, para aferição de eventuais anomalias.",
        "O superior hierárquico imediato, os pares, para aferição de eventuais anomalias, e os subordinados, apenas indicativo."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Dois avaliadores, sendo um deles, por norma, o superior hierárquico imediato."
      ],
      "score": 10
    },
    {
      "question": "Só se podem candidatar à frequência do curso de formação de sargentos os militares da categoria de guardas que satisfaçam as seguintes condições:",
      "choices": [
        "Ter menos de 28 anos de idade em 31 de dezembro do ano de ingresso no curso.",
        "Ter menos de 30 anos de idade em 31 de dezembro do ano de ingresso no curso.",
        "Ter menos de 24 anos de idade em 31 de dezembro do ano de ingresso no curso.",
        "Ter menos de 40 anos de idade em 31 de dezembro do ano de ingresso no curso."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ter menos de 40 anos de idade em 31 de dezembro do ano de ingresso no curso."
      ],
      "score": 10
    },
    {
      "question": "É condição especial de promoção ao posto de cabo-mor:",
      "choices": [
        "Ter o tempo mínimo de quatro anos de antiguidade no posto de cabo-chefe.",
        "Ter o tempo mínimo de oito anos de antiguidade no posto de cabo-chefe.",
        "Ter o tempo mínimo de seis anos de antiguidade no posto de cabo-chefe.",
        "Ter o tempo mínimo de cinco anos de antiguidade no posto de cabo-chefe."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ter o tempo mínimo de cinco anos de antiguidade no posto de cabo-chefe."
      ],
      "score": 10
    },

    //202 1  chamada

    {
      "question": "O livrete de saúde destina-se a:",
      "choices": [
        "Ter o registo das vacinas em dia, criado por causa da COVID-19 pelo Ministério da Administração Interna em 2021.",
        "Registo de baixas para constar na redução da remuneração mensal.",
        "Acompanhamento das baixas dos militares para efeitos de separação de serviço.",
        "Registo dos factos de índole sanitária de cada militar na efetividade do serviço e constitui documento de natureza classificada."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Registo dos factos de índole sanitária de cada militar na efetividade do serviço e constitui documento de natureza classificada."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda, para ser promovido, tem de satisfazer as condições gerais e especiais de promoção. São, entre outras, condições gerais de promoção, comuns a todos os militares:",
      "choices": [
      "Aptidão física e psíquica adequadas; Estar colocado na primeira ou segunda classe de comportamento",
      "Aptidão física e psíquica adequadas; Estar colocado na primeira, na segunda ou na terceira classe de comportamento",
      "Desempenho com eficiência das funções do seu posto; Estar colocado na primeira, na segunda ou na terceira classe de comportamento",
      "Desempenho com eficiência das funções do seu posto; Dedicação ao serviço"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "Aptidão física e psíquica adequadas; Estar colocado na primeira ou segunda classe de comportamento"
      ],
      "score": 10
  },
  {
      "question": "O militar da Guarda pode ser excluído temporariamente da promoção, ficando na situação de demorado ou preterido. A demora na promoção tem lugar quando o militar da Guarda:",
      "choices": [
      "Aguardar decisão do comandante-geral sobre parecer do órgão de conselho respetivo.",
      "Aguardar decisão do comandante do comando da administração dos recursos internos sobre parecer do órgão de conselho respetivo",
      "Aguardar decisão do comandante operacional sobre parecer do comandante do comando da administração dos recursos internos",
      "Aguardar decisão do ministro da tutela sobre parecer do comandante do comando da administração dos recursos internos"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "Aguardar decisão do comandante-geral sobre parecer do órgão de conselho respetivo."
      ],
      "score": 10
  },
  {
      "question": "A reclamação de um ato administrativo é facultativa, individual, dirigida por escrito, através das vias competentes, ao autor do ato, no prazo de:",
      "choices": [
      "30 dias, contados a partir do seu conhecimento pelo reclamante",
      "25 dias, contados a partir do seu conhecimento pelo reclamante",
      "20 dias, contados a partir do seu conhecimento pelo reclamante",
      "15 dias, contados a partir do seu conhecimento pelo reclamante"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "15 dias, contados a partir do seu conhecimento pelo reclamante"
      ],
      "score": 10
  },
  {
      "question": "O comandante-geral pode autorizar o adiamento ou suspensão da frequência do curso de promoção:",
      "choices": [
      "Por exigências de serviço, devidamente fundamentadas, e com a anuência do militar, por três vezes",
      "Por exigências de serviço, devidamente fundamentadas, e com a anuência do militar, por duas vezes",
      "A requerimento do interessado, por motivos de ordem pessoal, por duas vezes",
      "A requerimento do interessado, por motivos de ordem pessoal, por três vezes"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "Por exigências de serviço, devidamente fundamentadas, e com a anuência do militar, por duas vezes"
      ],
      "score": 10
  },
  {
      "question": "Sempre que o curso de formação inicial tenha uma duração inferior a três anos, o militar é sujeito a avaliação a ter lugar em período probatório logo após a conclusão do curso. O período probatório tem a duração de:",
      "choices": [
      "Um ano e a forma de avaliação é fixada por despacho do ministro da tutela",
      "Um ano e a forma de avaliação é fixada por despacho do comandante-geral",
      "Dois anos e a forma de avaliação é fixada por despacho do comandante-geral",
      "Dois anos e a forma de avaliação é fixada por despacho do ministro da tutela sob proposta do comandante-geral"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "Um ano e a forma de avaliação é fixada por despacho do comandante-geral"
      ],
      "score": 10
  }
  ],
}

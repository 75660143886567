import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './TrainingPlanPage.css'; // Arquivo CSS externo
import styled from 'styled-components';
import { auth, db } from '../../firebase/firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { redirect } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

let planodetreino = '';
let x_treino_calculado: number;

interface FormData {
  gender: 'Masculino' | 'Feminino';
  age: number;
  height: number;
  weight: number;
  currentPullups: number;
  currentPushups: number;
  currentSitups: number;
  currentCooper: number;
  trainingFrequency: number;
}
const MagicButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6c63ff; 
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 15px;
  border: none;
  margin-bottom: 10px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  i {
    margin-right: 8px; /* Espaçamento entre o ícone e o texto */
    font-size: 16px;
  }

  &:hover {
    background-color: #574bff; /* Alteração de cor ao hover */
  }

  &:active {
    background-color: #483aff;
  }
`;

let uid = ''; // Use 'let' para permitir reatribuição




const TrainingPlanPage: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    gender: 'Masculino',
    age: 0,
    height: 0,
    weight: 0,
    currentPullups: 0,
    currentPushups: 0,
    currentSitups: 0,
    currentCooper: 0,
    trainingFrequency: 0,
  });

  const [trainingPlan, setTrainingPlan] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);
  const [uid, setUid] = useState<string | null>(null); // Para armazenar o UID
  const [hasPurchased, setHasPurchased] = useState<boolean | null>(null);
  const navigate = useNavigate();

  const fetchPurchaseStatus = async () => {
    try {
      const userId = auth.currentUser?.uid;
      if (userId) {
        const userDocRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data(); // Acessa os dados do documento
          setUid(userId); // Armazena o UID no estado
          setTrainingPlan(userData.treino);
          setHasPurchased(userDoc.data().hasPurchased);
          
          if (userData.treino_calculado) {
            console.log(userData.treino_calculado);
            x_treino_calculado = userData.treino_calculado;
          } else {
            console.log("Sem treino calculado");
            x_treino_calculado = 0;
          }
        } else {
          navigate('/purchase');
          console.log("Documento de usuário não existe");
        }
      } else {
        console.log("Usuário não está logado");
      }
    } catch (error) {
      console.error('Erro ao buscar o status de compra: ', error);
    } finally {
      setLoading(false);
    }
    
  } ;

  useEffect(() => {
    if (hasPurchased === false) {
      navigate('/purchase'); // Redireciona para a página de compra se não tiver realizado a compra
    }
  }, [hasPurchased, navigate]);

  // Chama fetchPurchaseStatus dentro de useEffect
  useEffect(() => {
    setLoading(true); // Define como carregando
    fetchPurchaseStatus();
  }, []); // Array vazio para garantir que o efeito é chamado apenas uma vez

  const gravar = async (planodetreino: any) => {
    const userId = auth.currentUser?.uid;
    
    if (uid) {
      try {
        const userDocRef = doc(db, 'users', uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if(userData.treino_calculado){
            x_treino_calculado = userData.treino_calculado;
            await updateDoc(userDocRef, {
              
              treino_calculado: (x_treino_calculado + 1)
              
              })
            }
            else{
              await updateDoc(userDocRef, {
                "treino_calculado": 1
                
                })
            }
          };
        await updateDoc(userDocRef, {
          "treino": planodetreino,
          
        });
        console.log('Plano de treino gravado com sucesso');
      } catch (error) {
        console.error('Erro ao gravar o plano de treino: ', error);
      }
    } else {
      console.log('UID não está definido');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === 'gender' ? value : Number(value),
    });
  };
  
  const calculateTrainingPlan = async () => {
    setLoading(true);
    try {
      const content = `
      Os meus dados: Tenho ${formData.age} anos, ${formData.height} cm de altura, peso ${formData.weight} kg e sou do sexo ${formData.gender}.
      Neste momento estou a concorrer para umas provas fisicas. Preciso de conseguir fazer os seguintes exercicios:
      - Transposição de um muro sem apoio (com corrida, saltar um muro de 0,90 metros de altura, sem tocar nele);
      - 2 Flexões de braços na trave (para candidatos masculinos apenas);
      - 26 Abdominais (em 1 minuto) para o sexo masculino, 21 abdominais para o sexo feminino;
      - 11 flexões de braços no solo (apenas para candidatos femininos) 
      - Corrida de 12 minutos (Teste de Cooper)  2400 metros para o sexo masculino, 2000 metros para o sexo feminino;

      Neste momento consigo fazer:
      Flexões: ${formData.currentPushups},
      Abdominais: ${formData.currentSitups},
      Pull-ups: ${formData.currentPullups},
      Distância Cooper em 12 minutos: ${formData.currentCooper} metros.

      Quero treinar: ${formData.trainingFrequency} por semana
      
      Por favor, gera um plano de treino personalizado de para me ajudar a atingir os requisitos físicos do concurso. Super detalhado, como se fosse um Personal Trainer. 
      Quero tudo detalhado, passo a passo.
      Tens que preparar o candidato para todos os exercicios da prova fisica.


      devolver em json com esta estrutura se o sexo for Masculino:
      {
        "plano_treino": {
          "Sexo": "",
          "objetivo": "", //pode ser perda de peso, ganho de massa muscular, ganho de resistencia, etc
          "duração": "",
          "frequencia_treino": "", // aqui vamos organizar um treino diferente para cada dia da semana
          "intensidade": "",
          "exercicios_da_prova": [
            {
              "nome": "Transposição de um muro sem apoio",
              "objetivo": "Transposição sem contacto",
              "treino_personalizado": {
                "serie": "",
                "repeticoes": "",
                "descanso": "",
                "dica": "",
                
                "exercicio_especifico_1": {
                  "nome_exercicio": "",
                  "serie": "",
                  "repeticoes": "",
                  "descanso": ""
                },
                "exercicio_especifico_2": { 
                  "nome_exercicio": "",
                  "serie": "",
                  "repeticoes": "",
                  "descanso": ""
                }
                "exercicio_especifico_3": { 
                  "nome_exercicio": "",
                  "serie": "",
                  "repeticoes": "",
                  "descanso": ""
                }
                }
              }
            },
            {
              "nome": "Flexões de braços na trave (Pull-ups)", //pull-ups
              "objetivo": "2 flexões",
              "treino_personalizado": {
                "serie": "",
                "repeticoes": "",
                "descanso": "",
                "dica": "",
                // atribuir exercicios especifico consoante "frequencia_treino", ou seja, por exemplo, se so treinar 2x por semana, apenas dois exercicios especificos. Estes exercicios são gerados para treino de ginasio
                "exercicio_especifico_1": {
                  "nome_exercicio": "",
                  "serie": "",
                  "repeticoes": "",
                  "descanso": ""
                },
                "exercicio_especifico_2": { 
                  "nome_exercicio": "",
                  "serie": "",
                  "repeticoes": "",
                  "descanso": ""
                }
                "exercicio_especifico_3": { 
                  "nome_exercicio": "",
                  "serie": "",
                  "repeticoes": "",
                  "descanso": ""
                }
              }
            },
            {
              "nome": "Abdominais",
              "objetivo": "26 abdominais",
              "tempomaximo": "1 minuto",
              "treino_personalizado": {
                "serie": "",
                "repeticoes": "",
                "descanso": "",
                "dica": "",
                // atribuir exercicios especifico consoante "frequencia_treino", ou seja, por exemplo, se so treinar 2x por semana, apenas dois exercicios especificos. Estes exercicios são gerados para treino de ginasio
                "exercicio_especifico_1": {
                  "nome_exercicio": "",
                  "serie": "",
                  "repeticoes": "",
                  "descanso": ""
                },
                "exercicio_especifico_2": { 
                  "nome_exercicio": "",
                  "serie": "",
                  "repeticoes": "",
                  "descanso": ""
                }
                "exercicio_especifico_3": { 
                  "nome_exercicio": "",
                  "serie": "",
                  "repeticoes": "",
                  "descanso": ""
                }
              }
            },
            {
              "nome": "Corrida",
              "objetivo": "2400 metros",
              "tempomaximo": "12 minutos",
              "treino_personalizado": {
                "serie": "",
                "repeticoes": "",
                "descanso": "",
                "dica": "",
                // atribuir exercicios especifico consoante "frequencia_treino", ou seja, por exemplo, se so treinar 2x por semana, apenas dois exercicios especificos. Estes exercicios são gerados para treino de ginasio
                "exercicio_especifico_1": {
                  "nome_exercicio": "",
                  "serie": "",
                  "repeticoes": "",
                  "descanso": ""
                },
                "exercicio_especifico_2": { 
                  "nome_exercicio": "",
                  "serie": "",
                  "repeticoes": "",
                  "descanso": ""
                }
                "exercicio_especifico_3": { 
                  "nome_exercicio": "",
                  "serie": "",
                  "repeticoes": "",
                  "descanso": ""
                }
              }
            }
          ]
        },
        "plano_alimentar": {
          "objetivo": "",
          "calorias_diarias": "",
          "refeicoes": [
            {
              "refeicao": "Pequeno Almoço",
              "alimentos_alternativa_1": ["", "", ""],
              "alimentos_alternativa_2": ["", "", ""],
              "alimentos_alternativa_3": ["", "", ""]
            },
            {
              "refeicao": "Almoço",
              "alimentos_alternativa_1": ["", "", ""],
              "alimentos_alternativa_2": ["", "", ""],
              "alimentos_alternativa_3": ["", "", ""]
            },
            {
              "refeicao": "Lanche",
              "alimentos_alternativa_1": ["", "", ""],
              "alimentos_alternativa_2": ["", "", ""],
              "alimentos_alternativa_3": ["", "", ""]
            },
            {"refeicao": "Jantar",
              "alimentos_alternativa_1": ["", "", ""],
              "alimentos_alternativa_2": ["", "", ""],
              "alimentos_alternativa_3": ["", "", ""]
            }
          ],
          "hidratacao": ""
        }
      }
      exporta somente o json, nao quero mais texto alem do json
      
      
      devolver em json com esta estrutura se o sexo for Feminino:
      {
        "plano_treino": {
          "Sexo": "",
          "objetivo": "", //pode ser perda de peso, ganho de massa muscular, ganho de resistencia, etc
          "duração": "",
          "frequencia_treino": "", // aqui vamos organizar um treino diferente para cada dia da semana
          "intensidade": "",
          "exercicios_da_prova": [
            {
              "nome": "Transposição de um muro sem apoio",
              "objetivo": "Transposição sem contacto",
              "treino_personalizado": {
                "serie": "",
                "repeticoes": "",
                "descanso": "",
                "dica": "",
                
                "exercicio_especifico_1": {
                  "nome_exercicio": "",
                  "serie": "",
                  "repeticoes": "",
                  "descanso": ""
                },
                "exercicio_especifico_2": { 
                  "nome_exercicio": "",
                  "serie": "",
                  "repeticoes": "",
                  "descanso": ""
                }
                "exercicio_especifico_3": { 
                  "nome_exercicio": "",
                  "serie": "",
                  "repeticoes": "",
                  "descanso": ""
                }
                }
              }
            },
            {
              "nome": "Flexões de braços no solo", 
              "objetivo": "11 flexões",
              "treino_personalizado": {
                "serie": "",
                "repeticoes": "",
                "descanso": "",
                "dica": "",
                // atribuir exercicios especifico consoante "frequencia_treino", ou seja, por exemplo, se so treinar 2x por semana, apenas dois exercicios especificos. Estes exercicios são gerados para treino de ginasio
                "exercicio_especifico_1": {
                  "nome_exercicio": "",
                  "serie": "",
                  "repeticoes": "",
                  "descanso": ""
                },
                "exercicio_especifico_2": { 
                  "nome_exercicio": "",
                  "serie": "",
                  "repeticoes": "",
                  "descanso": ""
                }
                "exercicio_especifico_3": { 
                  "nome_exercicio": "",
                  "serie": "",
                  "repeticoes": "",
                  "descanso": ""
                }
              }
            },
            {
              "nome": "Abdominais",
              "objetivo": "21 abdominais",
              "tempomaximo": "1 minuto",
              "treino_personalizado": {
                "serie": "",
                "repeticoes": "",
                "descanso": "",
                "dica": "",
                // atribuir exercicios especifico consoante "frequencia_treino", ou seja, por exemplo, se so treinar 2x por semana, apenas dois exercicios especificos. Estes exercicios são gerados para treino de ginasio
                "exercicio_especifico_1": {
                  "nome_exercicio": "",
                  "serie": "",
                  "repeticoes": "",
                  "descanso": ""
                },
                "exercicio_especifico_2": { 
                  "nome_exercicio": "",
                  "serie": "",
                  "repeticoes": "",
                  "descanso": ""
                }
                "exercicio_especifico_3": { 
                  "nome_exercicio": "",
                  "serie": "",
                  "repeticoes": "",
                  "descanso": ""
                }
              }
            },
            {
              "nome": "Corrida",
              "objetivo": "2000 metros",
              "tempomaximo": "12 minutos",
              "treino_personalizado": {
                "serie": "",
                "repeticoes": "",
                "descanso": "",
                "dica": "",
                // atribuir exercicios especifico consoante "frequencia_treino", ou seja, por exemplo, se so treinar 2x por semana, apenas dois exercicios especificos. Estes exercicios são gerados para treino de ginasio
                "exercicio_especifico_1": {
                  "nome_exercicio": "",
                  "serie": "",
                  "repeticoes": "",
                  "descanso": ""
                },
                "exercicio_especifico_2": { 
                  "nome_exercicio": "",
                  "serie": "",
                  "repeticoes": "",
                  "descanso": ""
                }
                "exercicio_especifico_3": { 
                  "nome_exercicio": "",
                  "serie": "",
                  "repeticoes": "",
                  "descanso": ""
                }
              }
            }
          ]
        },
        "plano_alimentar": {
          "objetivo": "",
          "calorias_diarias": "",
          "refeicoes": [
            {
              "refeicao": "Pequeno Almoço",
              "alimentos_alternativa_1": ["", "", ""],
              "alimentos_alternativa_2": ["", "", ""],
              "alimentos_alternativa_3": ["", "", ""]
            },
            {
              "refeicao": "Almoço",
              "alimentos_alternativa_1": ["", "", ""],
              "alimentos_alternativa_2": ["", "", ""],
              "alimentos_alternativa_3": ["", "", ""]
            },
            {
              "refeicao": "Lanche",
              "alimentos_alternativa_1": ["", "", ""],
              "alimentos_alternativa_2": ["", "", ""],
              "alimentos_alternativa_3": ["", "", ""]
            },
            {"refeicao": "Jantar",
              "alimentos_alternativa_1": ["", "", ""],
              "alimentos_alternativa_2": ["", "", ""],
              "alimentos_alternativa_3": ["", "", ""]
            }
          ],
          "hidratacao": ""
        }
      }
      exporta somente o json, nao quero mais texto alem do json`;

      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [{ role: 'user', content }],
          max_tokens: 4000,
          temperature: 0.7,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer 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`,
          },
        }
      );

      const generatedPlan = response.data.choices[0].message.content;
      setTrainingPlan(JSON.parse(generatedPlan));
      planodetreino = JSON.parse(generatedPlan);
      window.location.reload();
      gravar(planodetreino);

    } catch (error) {
      console.error('Erro ao gerar plano de treino:', error);
      setTrainingPlan(null);
    }
    setLoading(false);
  };

  return (<>
    <br />
    <br />
    <br />
    <br />
    <br />

    <div className="container">
      <h1 className="title">Cálculo de Plano de Treino</h1>
      <form className="form">
        <div className="form-group">
          <label>Género:</label>
          <select name="gender" value={formData.gender} onChange={handleChange}>
            <option value="Masculino">Masculino</option>
            <option value="Feminino">Feminino</option>
          </select>
        </div>
  
        <div className="form-group">
          <label>Idade:</label>
          <input type="number" name="age" value={formData.age} onChange={handleChange} />
        </div>
  
        <div className="form-group">
          <label>Altura (cm):</label>
          <input type="number" name="height" value={formData.height} onChange={handleChange} />
        </div>
  
        <div className="form-group">
          <label>Peso (kg):</label>
          <input type="number" name="weight" value={formData.weight} onChange={handleChange} />
        </div>
  
        <div className="form-group">
          <label>Flexões na trave:</label>
          <input type="number" name="currentPullups" value={formData.currentPullups} onChange={handleChange} />
        </div>
  
        <div className="form-group">
          <label>Flexões Atuais:</label>
          <input type="number" name="currentPushups" value={formData.currentPushups} onChange={handleChange} />
        </div>
  
        <div className="form-group">
          <label>Abdominais Atuais (em 1 minuto):</label>
          <input type="number" name="currentSitups" value={formData.currentSitups} onChange={handleChange} />
        </div>
  
        <div className="form-group">
          <label>Distância Cooper Atual (metros):</label>
          <input type="number" name="currentCooper" value={formData.currentCooper} onChange={handleChange} />
        </div>
  
        <div className="form-group">
          <label>Frequência de Treino (dias/semana):</label>
          <input type="number" name="trainingFrequency" value={formData.trainingFrequency} onChange={handleChange} />
        </div>
  
        <button 
          type="button" 
          onClick={calculateTrainingPlan} 
          className={x_treino_calculado > 5 ? '' : 'submit-btn'} 
          disabled={loading || x_treino_calculado > 5} // Mantém o botão desativado em caso de limite atingido
        >
          {loading ? 'Calculando...' : (x_treino_calculado > 5 ? 'Limite de uso atingido' : 'Calcular Plano de Treino com AI')}
        </button>
        <br />
        <p>Uso: {x_treino_calculado} / 5 </p>
      </form>
  
      {trainingPlan && (
  <div className="training-plan">
    <h2>Plano de Treino</h2>
    <br />
    <ul>
      <li><p><b>Sexo: </b>{trainingPlan.plano_treino?.Sexo ?? null}</p></li>
      <li><p><b>Objetivo: </b>{trainingPlan.plano_treino?.objetivo ?? null}</p></li>
      <li><p><b>Duração: </b>{trainingPlan.plano_treino?.duração ?? null}</p></li>
      <li><p><b>Frequência de Treino: </b>{trainingPlan.plano_treino?.frequencia_treino ?? null}</p></li>
      <li><p><b>Intensidade: </b>{trainingPlan.plano_treino?.intensidade ?? null}</p></li>
    </ul>
    <br />

    {trainingPlan.plano_treino?.exercicios_da_prova?.map((exercicio: any, index: number) => (
      <div key={index} className='tabe'>
        <h3>{exercicio?.nome ?? null}</h3>
        <table>
          <thead>
            <tr>
              <th>Exercício</th>
              <th>Série</th>
              <th>Repetições</th>
              <th>Descanso</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{exercicio?.treino_personalizado?.exercicio_especifico_1?.nome_exercicio ?? null}</td>
              <td>{exercicio?.treino_personalizado?.exercicio_especifico_1?.serie ?? null}</td>
              <td>{exercicio?.treino_personalizado?.exercicio_especifico_1?.repeticoes ?? null}</td>
              <td>{exercicio?.treino_personalizado?.exercicio_especifico_1?.descanso ?? null}</td>
            </tr>
            <tr>
              <td>{exercicio?.treino_personalizado?.exercicio_especifico_2?.nome_exercicio ?? null}</td>
              <td>{exercicio?.treino_personalizado?.exercicio_especifico_2?.serie ?? null}</td>
              <td>{exercicio?.treino_personalizado?.exercicio_especifico_2?.repeticoes ?? null}</td>
              <td>{exercicio?.treino_personalizado?.exercicio_especifico_2?.descanso ?? null}</td>
            </tr>
          </tbody>
        </table>
        <p><b>Dica: </b>{exercicio?.treino_personalizado?.dica ?? null}</p>
      </div>
    ))}
    <br />
    <h2>Plano Alimentar</h2>
    <br />
    <p><b>Objetivo: </b>{trainingPlan.plano_alimentar?.objetivo ?? null}</p>
    <p><b>Calorias Diárias: </b>{trainingPlan.plano_alimentar?.calorias_diarias ?? null}</p>

    {trainingPlan.plano_alimentar?.refeicoes?.map((refeicao: any, index: number) => (
      <div key={index} className='tabe'>
        <h3>{refeicao?.refeicao ?? null}</h3>
        <br />
        <ul>
          <li><b>Alternativa 1: </b>{refeicao?.alimentos_alternativa_1?.join(', ') ?? null}</li>
          <li><b>Alternativa 2: </b>{refeicao?.alimentos_alternativa_2?.join(', ') ?? null}</li>
          <li><b>Alternativa 3: </b>{refeicao?.alimentos_alternativa_3?.join(', ') ?? null}</li>
        </ul>
        <br />
      </div>



    ))}
          <br />
          <p><b>Hidratação: </b>{trainingPlan.plano_alimentar.hidratacao}</p>
          <br />

        </div>


      )}  
    </div>
    </>
  );
};

export default TrainingPlanPage;

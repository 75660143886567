// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const gnr_leiOrganica_4: Topic = {
  topic: 'GNR - Lei Orgânica (4)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "A Guarda Nacional Republicana é:",
      "choices": [
        "Uma força de segurança de natureza militar.",
        "Uma força de paz com caráter público.",
        "Uma força de manutenção.",
        "Uma força desmilitarizada."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Uma força de segurança de natureza militar."
      ],
      "score": 10
    },
    {
      "question": "Uma das atribuições da Guarda Nacional Republicana é:",
      "choices": [
        "Garantir a segurança e a proteção das pessoas e dos bens.",
        "Garantir a ordem.",
        "Garantir a tranquilidade pública.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "O Dia da Guarda Nacional Republicana é comemorado a:",
      "choices": [
        "13 de maio.",
        "3 de maio.",
        "13 de março.",
        "3 de março."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "3 de maio."
      ],
      "score": 10
    },
    {
      "question": "A categoria profissional de guardas compreende os seguintes postos:",
      "choices": [
        "Cabo-mor.",
        "Cabo-chefe e cabo.",
        "Guarda-principal e guarda.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "A estrutura de comando da Guarda Nacional Republicana compreende:",
      "choices": [
        "O Comando da Guarda, os órgãos superiores de comando e direção.",
        "Apenas as unidades e os Postos.",
        "Os destacamentos e os postos.",
        "As unidades especializadas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Comando da Guarda, os órgãos superiores de comando e direção."
      ],
      "score": 10
    },
    {
      "question": "Na Guarda Nacional Republicana existem as seguintes unidades especializadas:",
      "choices": [
        "A Unidade de Policiamento e Honras de Estado e a Unidade Apoio Geral.",
        "Os Postos Territoriais.",
        "A Unidade de Controlo Costeiro e Marítimo, a Unidade de Ação Fiscal e a Brigada de Trânsito.",
        "A Unidade de Controlo Costeiro e de Fronteiras, a Unidade de Ação Fiscal e a Unidade Nacional de Trânsito."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A Unidade de Controlo Costeiro e de Fronteiras, a Unidade de Ação Fiscal e a Unidade Nacional de Trânsito."
      ],
      "score": 10
    },
    {
      "question": "A Escola da Guarda é uma unidade especialmente vocacionada para:",
      "choices": [
        "A formação cultural.",
        "A formação militar e técnico-profissional dos militares da Guarda Nacional Republicana.",
        "A formação moral.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "A Guarda Nacional Republicana, sem prejuízo das prioridades legais da sua atuação, coopera:",
      "choices": [
        "Com as demais forças.",
        "Com as autoridades públicas.",
        "Com os serviços de segurança.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "As subunidades operacionais dos comandos territoriais são:",
      "choices": [
        "Os destacamentos, que se articulam localmente em subdestacamentos ou postos.",
        "Os destacamentos, que se articulam localmente em superesquadras ou postos.",
        "Os grupos, que se articulam localmente em subdestacamentos ou postos.",
        "Os destaçamentos, que se articulam localmente em subdestacamentos ou esquadras."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Os destacamentos, que se articulam localmente em subdestacamentos ou postos."
      ],
      "score": 10
    },

    // PC 2023 (1 chamada)

    {
      "question": "Constitui atribuição da Guarda Nacional Republicana:",
      "choices": [
        "Garantir a ordem e a tranquilidade públicas, a insegurança, e a protecção das pessoas e dos animais.",
        "Permitir a prática de actos contrários à lei e aos regulamentos.",
        "Participar no controlo da entrada e saída de pessoas no território internacional.",
        "Vigiar, fiscalizar e controlar as fronteiras marítimas e terrestres, assim como a circulação de pessoas nos postos de fronteira autorizados."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Vigiar, fiscalizar e controlar as fronteiras marítimas e terrestres, assim como a circulação de pessoas nos postos de fronteira autorizados."
      ],
      "score": 10
    },
    {
      "question": "A Guarda Nacional Republicana tem por missão, no âmbito dos sistemas nacionais de segurança e protecção:",
      "choices": [
        "Assegurar a legalidade democrática.",
        "Garantir a segurança intema e os direitos dos cidadãos.",
        "Colaborar na execução da política de defesa nacional.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "Os militares da Guarda Nacional Republicana no exercício do comando de forças têm a categoria de comandantes de força pública e considera-se força pública:",
      "choices": [
        "O efetivo mínimo de dois militares em missão de serviço.",
        "O efetivo mínimo de três militares em missão de serviço.",
        "O efetivo máximo de dois militares em missão de serviço.",
        "O efetivo máximo de três militares em missão de serviço."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O efetivo mínimo de dois militares em missão de serviço."
      ],
      "score": 10
    },
    {
      "question": "A Guarda Nacional Republicana é:",
      "choices": [
        "Uma força de segurança de natureza militarizada.",
        "Uma força de segurança de natureza militar.",
        "Uma força de proteção de natureza militar.",
        "Todas as alíneas estão erradas. "
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Uma força de segurança de natureza militar."
      ],
      "score": 10
    },
    {
      "question": "A Guarda Nacional Republicana depende do membro do Governo responsável pela área da:",
      "choices": [
        "Administração Interna.",
        "Justiça.",
        "Negócios Estrangeiro.",
        "Todas as alíneas estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Administração Interna."
      ],
      "score": 10
    },
    {
      "question": "A A Guarda Nacional Republicana tem direito a:",
      "choices": [
        "Brasão de armas, bandeira heráldica, hino, marcha, selo azul e condecoração privativa.",
        "Brasão de armas, bandeira heráldica, hino, marcha, selo azul e condecoração pública.",
        "Brasão de armas, bandeira heráldica, hino, marcha, selo branco e condecoração privativa.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Brasão de armas, bandeira heráldica, hino, marcha, selo branco e condecoração privativa."
      ],
      "score": 10
    },
    {
      "question": "O dia da Guarda Nacional Republicana é comemorado a:",
      "choices": [
        "13 de maio.",
        "23 de maio.",
        "3 de março.",
        "3 de maio."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "3 de maio."
      ],
      "score": 10
    },
    {
      "question": "A Guarda Nacional Republicana e as suas unidades, incluindo as unidades constituídas para actuar fora do território nacional e o estabelecimento de ensino, têm direito ao uso:",
      "choices": [
        "Estandarte nacional.",
        "Galhardete nacional.",
        "Mascote nacional.",
        "Todas as alíneas estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Estandarte nacional."
      ],
      "score": 10
    },
    {
      "question": "A Unidade de representação existente na Guarda Nacional Republicana é a:",
      "choices": [
        "Unidade de Controlo Fiscal.",
        "Unidade de Intervenção.",
        "Unidade de Segurança e Honras de Estado.",
        "Comando Local de Trânsito."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Unidade de Segurança e Honras de Estado."
      ],
      "score": 10
    },
    {
      "question": "Na Guarda Nacional Republicana existem as seguintes unidades:",
      "choices": [
        "O Comando-Geral.",
        "De representação, a Unidade de Segurança e Honras de Estado.",
        "Territoriais, os comandos territoriais.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },

    // PC 2023 (2 chamada)


    {
      "question": "A Guarda Nacional Republicana tem por missão, no âmbito dos sistemas nacionais de segurança e proteção:",
      "choices": [
        "Garantir a segurança interna e os deveres dos cidadãos.",
        "Garantir apenas a segurança externa.",
        "Garantir a segurança interna e os direitos dos cidadãos.",
        "Todas as alíneas anteriores estão certas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Garantir a segurança interna e os direitos dos cidadãos."
      ],
      "score": 10
    }
    
  ],
}

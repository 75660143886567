// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const gnr_estatutos_6: Topic = {
  topic: 'GNR - Estatutos (6)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "O militar da Guarda Nacional Republicana só recorre ao uso da força nos casos expressamente previstos na lei, e: ",
      "choices": [
        "Quando este se revele necessário ao objetivo visado.",
        "Quando este se revele legítimo ao objetivo visado.",
        "Quando este se revele proporcional ao objetivo visado.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "Durante a frequência do curso de formação de guardas, o guarda provisório:",
      "choices": [
        "Está obrigado a comunicar aos inferiores hierárquicos a sua constituição como arguido em processo-crime.",
        "Está obrigado a comunicar aos superiores hierárquicos a sua constituição como arguido em processo-crime.",
        "Não está obrigado a comunicar aos superiores hierárquicos a sua constituição como arguido em processo-crime.",
        "Todas as alíneas estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Está obrigado a comunicar aos superiores hierárquicos a sua constituição como arguido em processo-crime."
      ],
      "score": 10
    },
    {
      "question": "Compete ao militar da Guarda Nacional Republicana:",
      "choices": [
        "Cumprir prontamente todas as missões ou atos de serviço.",
        "Cumprir algumas missões ou atos de serviço.",
        "Comunicar ao inferior hierárquico a sua constituição como arguido.",
        "Prestar auxílio em qualquer diligência em matéria ilegal."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Cumprir prontamente todas as missões ou atos de serviço."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda Nacional Republicana cumpre as missões que lhe forem cometidas:",
      "choices": [
        "Pelos legítimos inferiores, para defesa da Pátria, se necessário com o sacrifício da própria vida.",
        "Pelos legítimos superiores, para defesa da Pátria, se necessário com o sacrifício da própria vida.",
        "Pelos legitimos inferiores, para defesa da Pátria, sem o sacrifício da própria vida.",
        "Pelos legítimos superiores, para defesa da Pátria, sem o sacrifício da própria vida."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Pelos legítimos superiores, para defesa da Pátria, se necessário com o sacrifício da própria vida."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda Nacional Republicana encontra-se numa das seguintes situações:",
      "choices": [
        "Ativo, inativo ou reforma.",
        "Ativo, de doença ou reforma.",
        "Ativo, reserva ou reforma.",
        "Ativo ou inativo."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ativo, reserva ou reforma."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda Nacional Republicana, no exercício das suas funções está:",
      "choices": [
        "Exclusivamente ao serviço do interesse público.",
        "Ao serviço de interesses corporativos.",
        "Ao serviço de interesses particulares.",
        "Exclusivamente ao serviço do interesse privado."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Exclusivamente ao serviço do interesse público."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda Nacional Republicana tem o dever:",
      "choices": [
        "De obediência, estando subordinado apenas à hierarquia.",
        "De obediência, não estando subordinado à disciplina e à hierarquia.",
        "De obediência, estando subordinado à disciplina e à hierarquia.",
        "Todas as alíneas estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "De obediência, estando subordinado à disciplina e à hierarquia."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda Nacional Republicana tem direito ao uso e porte de armas e munições:",
      "choices": [
        "De qualquer classificação, desde que distribuídas pelo Estado, e não está sujeito a um plano de formação.",
        "De qualquer classificação, desde que não sejam distribuídas pelo Estado.",
        "De qualquer classificação, desde que distribuídas pelo Estado, e está sujeito a um plano de formação.",
        "Desde que distribuídas pelo Estado, e não está sujeito a um plano de formação."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "De qualquer classificação, desde que distribuídas pelo Estado, e está sujeito a um plano de formação."
      ],
      "score": 10
    },
    {
      "question": "A promoção ao posto de Guarda processa-se por: ",
      "choices": [
        "Habilitação com curso adequado.",
        "Antiguidade.",
        "Escolha.",
        "Concurso público."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Habilitação com curso adequado."
      ],
      "score": 10
    },

    //PC 2023 com correção


    {
      "question": "O candidato a militar da Guarda, enquanto na frequência do curso de formação de guardas, é designado por:",
      "choices": [
        "Guarda eventual.",
        "Guarda alistado.",
        "Soldado provisório.",
        "Guarda provisório."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Guarda provisório."
      ],
      "score": 10
    },
    {
      "question": "Ao militar da Guarda Nacional Republicana são aplicáveis:",
      "choices": [
        "A Lei de Bases Gerais do Estatuto da Condição Civil.",
        "A Lei de Defesa internacional.",
        "A Lei de Segurança externa.",
        "O Código de Justiça Militar."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Código de Justiça Militar."
      ],
      "score": 10
    },
    {
      "question": "Compete ao militar da Guarda Nacional Republicana:",
      "choices": [
        "Comportar-se de acordo com a dignidade da sua função e da condição militar, mesmo fora dos atos de serviço.",
        "Comportar-se de acordo com a dignidade da sua função e da condição civil, mesmo fora dos atos de serviço.",
        "Não comprovar oportunamente a sua identidade, sempre que solicitada ou as circunstâncias do serviço o exijam.",
        "Não comunicar ao superior hierárquico imediato a sua detenção ou constituição como arguido, independentemente da natureza do processo-crime."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Comportar-se de acordo com a dignidade da sua função e da condição militar, mesmo fora dos atos de serviço."
      ],
      "score": 10
    },
    {
      "question": "Constitui um dos deveres do militar da Guarda:",
      "choices": [
        "Comunicar todas as alterações à sua evolução relativamente a habilitações académicas que obtenha ou cursos técnicos e superiores que complete.",
        "Comunicar as formações e seminários que frequente com aproveitamento.",
        "Comunicar, se quiser, todas as alterações à sua evolução relativamente a habilitações académicas que obtenha ou cursos técnicos e superiores que complete.",
        "Comunicar à Direção de Registo da Formação todas as alterações à sua evolução relativamente a habilitações académicas que obtenha ou cursos técnicos e superiores que complete."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Comunicar todas as alterações à sua evolução relativamente a habilitações académicas que obtenha ou cursos técnicos e superiores que complete."
      ],
      "score": 10
    },
    {
      "question": "A categoria de Guardas compreende os postos de:",
      "choices": [
        "Alferes, Cabo-Chefe, Cabo, Guarda-Principal e Guarda.",
        "Cabo-Chefe, Cabo, Guarda-Principal, Guarda e Guarda-Florestal.",
        "Guarda, Guarda-Principal, Cabo, Cabo-Chefe e Cabo-Mor.",
        "Sargento-Chefe, Cabo, Guarda-Principal, Cabo-Mor e Guarda."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Guarda, Guarda-Principal, Cabo, Cabo-Chefe e Cabo-Mor."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda pode ser transferido para quadro diferente daquele a que pertence por:",
      "choices": [
        "Necessidade de serviço.",
        "Ingresso em categoria diferente.",
        "Insuficiente aptidão física ou psíquica.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "Consideram-se cargos do militar da Guarda Nacional Republicana os lugares fixados na estrutura orgânica da Guarda Nacional Republicana que correspondem:",
      "choices": [
        "Ao exercício de funções legalmente definidas.",
        "Ao exercício de funções de natureza militar.",
        "Ao exercício de funções de natureza policial.",
        "Ao exercício de funções de natureza militar ou policial."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ao exercício de funções legalmente definidas."
      ],
      "score": 10
    },
    {
      "question": "As funções do militar da Guarda Nacional Republicana classificam-se em:",
      "choices": [
        "Comando.",
        "Direção ou chefia.",
        "Execução.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "A realização das ações praticadas pelos militares da Guarda Nacional Republicana integrados em comandos, forças, unidades, estabelecimentos e órgãos, tendo em vista a preparação, o apoio e o cumprimento atribuições da Guarda Nacional Republicana, traduz a função de:",
      "choices": [
        "Execução.",
        "Comando.",
        "Direção ou Chefia.",
        "Estado-maior."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Execução."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda Nacional Republicana encontra-se numa das seguintes situações:",
      "choices": [
        "Ativo, inativo ou reforma.",
        "Ativo, de doença ou reforma.",
        "Ativo, reserva ou reforma.",
        "Ativo ou inativo."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ativo, reserva ou reforma."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda que se encontra em formação ou em período experimental em organismo externo à Guarda com vista ao ingresso no mesmo, considera-se em: ",
      "choices": [
        "Inatividade temporária.",
        "Comissão especial.",
        "Licença sem remuneração.",
        "Comissão excecional."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Comissão especial."
      ],
      "score": 10
    }
  ],
}

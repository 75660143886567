// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '../'

export const gnr_leiOrganica_1: Topic = {
  topic: 'GNR - Lei Orgânica (1)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "O comando territorial é responsável pelo cumprimento da missão da Guarda na área de responsabilidade que lhe for atribuída, na dependência direta:",
      "choices": [
        "Do Comandante-geral",
        "Do Comandante operacional",
        "Do Comandante operacional ouvido o Comandante-geral",
        "Do Comandante-geral ouvido o Ministro da Administração Interna"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Do Comandante-geral"
      ],
      "score": 10
    },
    {
      "question": "O Comando Geral é comandado pelo:",
      "choices": [
        "Comandante operacional",
        "Chefe da secretaria-geral da Guarda",
        "Comandante-geral",
        "2º Comandante-geral"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Chefe da secretaria-geral da Guarda"
      ],
      "score": 10
    },
    {
      "question": "Podem ser destacadas ou colocadas com carácter permanente, forças da Unidade de Intervenção na dependência Orgânica dos Comandos Territoriais:",
      "choices": [
        "Por determinação do Comandante operacional",
        "Por decreto regulamentar do Ministro da Tutela",
        "Por determinação do Comandante-geral",
        "Por despacho do Ministro da Tutela"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Por despacho do Ministro da Tutela"
      ],
      "score": 10
    },
    {
      "question": "As atribuições da Guarda são prosseguidas em todo o território nacional e no mar territorial. No caso de atribuições cometidas simultaneamente à Polícia de Segurança Pública, a área de responsabilidade da Guarda é definida por portaria do:",
      "choices": [
        "Ministro da Tutela",
        "Ministro da Justiça",
        "Ministro das Finanças",
        "Ministro do Mar"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ministro da Tutela"
      ],
      "score": 10
    },
    {
      "question": "O Dia da Guarda é comemorado a:",
      "choices": [
        "10 de junho, em evocação da lei que criou a atual instituição nacional, em 1911",
        "25 de abril, em evocação da lei que criou a atual instituição nacional, em 1921",
        "3 de maio, em evocação da lei que criou a atual instituição nacional, em 1911",
        "5 de outubro, em evocação da lei que criou a atual instituição nacional, em 1911"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "3 de maio, em evocação da lei que criou a atual instituição nacional, em 1911"
      ],
      "score": 10
    },
    {
      "question": "Os militares da Guarda agrupam-se hierarquicamente em categorias profissionais, subcategorias e postos. A categoria profissional de guardas compreende os postos de:",
      "choices": [
        "Cabo-mor, cabo-chefe, cabo-ajudante, primeiro-cabo, segundo-cabo, guarda-principal e guarda",
        "Cabo-mor, cabo-chefe, cabo, guarda principal e guarda",
        "Alferes-mor, alferes-chefe, alferes-ajudante, guarda-principal e guarda",
        "Cabo-mor, cabo-chefe, cabo, guarda-principal, guarda e guarda-provisório"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Cabo-mor, cabo-chefe, cabo, guarda principal e guarda"
      ],
      "score": 10
    },
    {
      "question": "A estrutura de comando da Guarda compreende:",
      "choices": [
        "O comandante-geral, o 2.º comandante-geral e o órgão de inspeção",
        "O Comando Operacional, o Comando da Administração dos Recursos Internos e o Comando da Doutrina e Formação",
        "O órgão de inspeção, os órgãos de conselho e a Secretaria-geral",
        "O Comando da Guarda e os órgãos superiores de comando e direção"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Comando da Guarda e os órgãos superiores de comando e direção"
      ],
      "score": 10
    },
    {
      "question": "O Comandante da Unidade de Controlo Costeiro tem o posto de:",
      "choices": [
        "Tenente-General ou Major-General",
        "Vice-Almirante ou Contra-Almirante",
        "Tenente-General ou Vice-Almirante",
        "Major-General ou Contra-Almirante"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Major-General ou Contra-Almirante"
      ],
      "score": 10
    },
    {
      "question": "As atribuições da Guarda:",
      "choices": [
        "Não podem ser prosseguidas fora do território nacional.",
        "São prosseguidas em todos os municípios com menos de 100 mil habitantes.",
        "São prosseguidas em todos os municípios com mais de 100 mil habitantes.",
        "São prosseguidas em todo o território nacional e no mar territorial"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "São prosseguidas em todo o território nacional e no mar territorial"
      ],
      "score": 10
    },
    {
      "question": "Nos termos da Lei Orgânica da GNR e no âmbito territorial, fora da sua área de responsabilidade, a intervenção da Guarda depende:",
      "choices": [
        "Do pedido de outra força de segurança; de ordem autárquica; ou de imposição legal.",
        "De imposição estatutária; de ordem especial; ou de imposição legal.",
        "Do pedido de outra força de segurança; de ordem especial; ou de imposição legal.",
        "De ordem ordinária; de ordem especial; ou de imposição legal."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Do pedido de outra força de segurança; de ordem especial; ou de imposição legal."
      ],
      "score": 10
    },
    {
      "question": "Os militares da Guarda são considerados:",
      "choices": [
        "Oficiais da força pública e de autoridade quando lhes não deva ser atribuída qualidade superior.",
        "Agentes da força pública e de imposição da lei quando lhes não deva ser atribuída qualidade superior.",
        "Agentes da força pública e de autoridade quando lhes não deva ser atribuída qualidade superior.",
        "Oficiais da força pública e de imposição da lei quando lhes não deva ser atribuída qualidade superior."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Agentes da força pública e de autoridade quando lhes não deva ser atribuída qualidade superior."
      ],
      "score": 10
    },
    {
      "question": "A UNT é a unidade especializada, no âmbito da fiscalização ordenamento e disciplina do trânsito e é comandada por:",
      "choices": [
        "Comandada por um tenente-general e coadjuvado por um diretor de formação.",
        "Comandada por um oficial general superior e coadjuvado por um 2º comandante.",
        "Comandada por coronel, coadjuvado por um 2.º comandante.",
        "Comandada por um major-general, coadjuvado por um diretor de formação de trânsito."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Comandada por coronel, coadjuvado por um 2.º comandante."
      ],
      "score": 10
    },
    {
      "question": "São órgãos superiores de comando e direção:",
      "choices": [
        "Comando Operacional, o Comando da Administração dos Recursos Internos e o Comando da Doutrina e Formação.",
        "O Comando Operacional, o Comando da Administração dos Recursos Internos, o Comando da Doutrina e Formação e a Escola da Guarda.",
        "O Comando Operacional, o Comando da Administração dos Recursos Internos, o Comando da Doutrina e Formação e a Unidade de Serviços e Honras do Estado.",
        "O Comando Operacional, o Comando da Administração dos Recursos Internos, o Comando da Doutrina e Formação e as Unidades especializadas, de representação e de intervenção e reserva."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Comando Operacional, o Comando da Administração dos Recursos Internos, o Comando da Doutrina e Formação e as Unidades especializadas, de representação e de intervenção e reserva."
      ],
      "score": 10
    },
    {
      "question": "Ao 2.º comandante-geral compete: Coadjuvar o comandante-geral no exercício das suas funções; exercer as competências que lhe forem delegadas ou subdelegadas pelo comandante -geral; substituir o comandante-geral nas suas ausências ou impedimentos e é um:",
      "choices": [
        "Diretor Superior proveniente dos quadros da PGR.",
        "Tenente-General ou Almirante.",
        "Diretor Intermédio qualificado com curso superior em administração pública.",
        "Tenente-General nomeado pelo ministro da tutela, sob a proposta do comandante-geral da Guarda."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Tenente-General nomeado pelo ministro da tutela, sob a proposta do comandante-geral da Guarda."
      ],
      "score": 10
    },
    {
      "question": "O Conselho de Ética, Deontologia e Disciplina na área de recursos humanos é representado pela:",
      "choices": [
        "Direção de Recursos Financeiros.",
        "Direção de Recursos Humanos.",
        "Chefia de Serviço de Finanças.",
        "Direção de Justiça."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Direção de Recursos Humanos."
      ],
      "score": 10
    },
    {
      "question": "O Comando da Administração dos Recursos Internos:",
      "choices": [
        "Assegura o comando de toda a atividade operacional da Guarda.",
        "É o órgão a que compete julgar o grau de capacidade para o serviço de oficiais, sargentos e guardas que, por ordem do comandante-geral, lhe forem presentes, bem como emitir parecer sobre os recursos relativos a decisões baseadas em pareceres formulados pelas juntas médicas da Guarda.",
        "Assegura o comando e direção de toda a atividade da Guarda nos domínios da administração dos recursos humanos, materiais e financeiros.",
        "Assegura o comando e direção de toda a atividade da Guarda nos domínios da doutrina e formação do efetivo da Guarda."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Assegura o comando e direção de toda a atividade da Guarda nos domínios da administração dos recursos humanos, materiais e financeiros."
      ],
      "score": 10
    },
    {
      "question": "O Comando-Geral é comandado pelo:",
      "choices": [
        "Comandante operacional.",
        "Chefe da secretaria-geral da Guarda.",
        "Comandante-geral.",
        "2º Comandante-geral."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Chefe da secretaria-geral da Guarda."
      ],
      "score": 10
    },
    {
      "question": "Região Autónoma dos Açores tem o seguinte dispositivo:",
      "choices": [
        "Uma Unidade Naval Ligeira.",
        "Uma Unidade de Intervenção Aérea, às ordens do Comandante-Geral.",
        "Uma Unidade Territorial com sede em Ponta Delgada.",
        "Uma Unidade composta por meios aéreos. navais e de ambiente."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Uma Unidade Territorial com sede em Ponta Delgada."
      ],
      "score": 10
    },
    {
      "question": "O Comandante da Unidade de Controlo Costeiro tem o posto de:",
      "choices": [
        "Tenente-General ou Major-General.",
        "Vice-Almirante ou Contra-Almirante.",
        "Tenente-General ou Vice-Almirante.",
        "Major-General ou Contra-Almirante."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Major-General ou Contra-Almirante."
      ],
      "score": 10
    },
    {
      "question": "USHE é uma unidade de representação responsável pela protecção e segurança às instalações dos órgãos de soberania e de outras entidades que lhe sejam confiadas e pela prestação de honras de Estado. Articula-se em:",
      "choices": [
        "Esquadrão Presidencial, Esquadrão da Assembleia da República e Esquadrão de Intervenção aero-naval.",
        "Esquadrão Presidencial, Esquadrão da Assembleia da República e Esquadrão Motorizado.",
        "Esquadrão Presidencial, Esquadrão da Assembleia da República e Esquadrão deReconhecimento.",
        "Esquadrão Presidencial, subunidade de honras de Estado e subunidade de segurança."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Esquadrão Presidencial, subunidade de honras de Estado e subunidade de segurança."
      ],
      "score": 10
    }
  ],
}

// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const crp_dudh_eu_2: Topic = {
  topic: 'CRP, DUDH e UE (2)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "Faz parte das instituições da união europeia:",
      "choices": [
        "O Parlamento Europeu.",
        "A Organização das Nações Unidas.",
        "A Organização do Tratado Atlântico Norte.",
        "A Organização para a Cooperação e Desenvolvimento Económico,"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Parlamento Europeu."
      ],
      "score": 10
    },
    {
      "question": "Quantos são os Estados-Membros da União Europeia?",
      "choices": [
        "7 Países.",
        "17 Países.",
        "27 Países.",
        "37 Países."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "27 Países."
      ],
      "score": 10
    },
    {
      "question": "Em Portugal, a autoridade que visa a promoção da melhoria das condições de trabalho em todo o território continental através do controlo do cumprimento do normativo laboral no âmbito das relações laborais privadas € pela promoção da segurança e saúde no trabalho em todos os sectores de atividade públicos e privados é: ",
      "choices": [
        "Autoridade Nacional de Emergência e Proteção Civil.",
        "Policia Judiciária.",
        "Guarda Nacional Republicana.",
        "Autoridade para as Condições do Trabalho."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Autoridade para as Condições do Trabalho."
      ],
      "score": 10
    },
    {
      "question": "Atualmente, em Portugal continental existem:",
      "choices": [
        "16 distritos.",
        "18 distritos.",
        "20 distritos.",
        "22 distritos."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "18 distritos."
      ],
      "score": 10
    },
    {
      "question": "Segundo a Constituição da República Portuguesa, o Estado subordina-se: ",
      "choices": [
        "Aos partidos e funda-se na legalidade democrática.",
        "À constituição e funda-se na legalidade democrática.",
        "À constituição e funda-se na regularidade financeira.",
        "Todas as alíneas estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "À constituição e funda-se na legalidade democrática."
      ],
      "score": 10
    },
    {
      "question": "São órgãos de soberania:",
      "choices": [
        "O Presidente da República e da Assembleia da República, o Governo e as Câmaras Municipais.",
        "O Presidente da República e da Assembleia da República, o Primeiro-Ministro e os Tribunais.",
        "O Presidente da República, os Tribunais, a Assembleia da República e o Governo.",
        "O Presidente da Assembleia da República, o Governo, o Primeiro-Ministro e as Câmaras Municipais."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Presidente da República, os Tribunais, a Assembleia da República e o Governo."
      ],
      "score": 10
    },
    {
      "question": "Segundo a Constituição da República Portuguesa, são símbolos nacionais:",
      "choices": [
        "A Bandeira Nacional e a Constituição Portuguesa.",
        "A Bandeira Nacional e o Hino Nacional.",
        "A Bandeira Nacional e a língua Portuguesa.",
        "O Hino Nacional e a língua Portuguesa."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A Bandeira Nacional e o Hino Nacional."
      ],
      "score": 10
    },
    {
      "question": "Segundo a Constituição da República Portuguesa, são tarefas fundamentais do Estado:",
      "choices": [
        "Garantir apenas o respeito pelos princípios de Estado de direito.",
        "Garantir os direitos e liberdades fundamentais e o respeito pelos princípios do Estado de direito.",
        "Garantir apenas os direitos e deveres principais.",
        "Todas as alíneas estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Garantir os direitos e liberdades fundamentais e o respeito pelos princípios do Estado de direito."
      ],
      "score": 10
    },
    {
      "question": "Segundo a Constituição da República Portuguesa, todos os cidadãos:",
      "choices": [
        "Podem ser privilegiados em razão da lingua.",
        "Podem ser beneficiados em razão da raça.",
        "Tem a mesma dignidade social e são iguais perante a lei.",
        "Podem ser privados em razão do sexo."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Tem a mesma dignidade social e são iguais perante a lei."
      ],
      "score": 10
    },
    {
      "question": "Segundo a Constituição da República Portuguesa:",
      "choices": [
        "Todos os cidadãos têm direito à Liberdade e Segurança.",
        "Toda a pessoa privada da liberdade deve ser informada previamente e de forma compreensível das razões da sua prisão ou detenção e dos seus direitos.",
        "A privação da sua liberdade contra o disposto na Constituição e na lei constitui o Estado no dever de indemnizar o lesado nos termos que a lei estabelecer.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "Segundo a Constituição da República Portuguesa, são órgãos de soberania:",
      "choices": [
        "Apenas o Presidente da República e o Governo.",
        "Apenas o Presidente da República e a Assembleia da República.",
        "O Presidente da República, Assembleia da República, Govemo e Tribunais.",
        "O Presidente da República e os Presidentes das Regiões Autónomas dos Açores e Madeira."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Presidente da República, Assembleia da República, Govemo e Tribunais."
      ],
      "score": 10
    },
    {
      "question": "Segundo a Constituição da República Portuguesa, todos os cidadãos:",
      "choices": [
        "Gozam dos direitos e estão sujeitos aos deveres consignados na Constituição.",
        "Gozam dos direitos em razão da raça.",
        "Gozam dos direitos e estão sujeitos aos deveres compatíveis com a sua natureza.",
        "Gozam dos direitos em razão do sexo."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Gozam dos direitos e estão sujeitos aos deveres consignados na Constituição."
      ],
      "score": 10
    },
    {
      "question": "Segundo a Declaração Universal dos Direitos Humanos, todos os seres humanos podem invocar os direitos e as liberdades, com distinção em razão:",
      "choices": [
        "Da raça.",
        "Da cor.",
        "Do sexo.",
        "Todas as alíneas estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão erradas."
      ],
      "score": 10
    },
    {
      "question": "Ao abrigo da Declaração Universal dos Direitos Humanos, todo o individuo tem direito:",
      "choices": [
        "À segurança pessoal.",
        "À vida.",
        "À liberdade.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "A Declaração Universal dos Direitos Humanos destaca-se por:",
      "choices": [
        "Não reconhecer a dignidade inerente a todos os membros da família humana.",
        "Defender os interesses particulares, em detrimento dos interesses coletivos.",
        "Defender a dignidade de grupos sociais em prol do género e da raça.",
        "Todas as afirmações estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as afirmações estão erradas."
      ],
      "score": 10
    },
    {
      "question": "Segundo a Declaração Universal dos Direitos Humanos, ninguém pode ser arbitrariamente:",
      "choices": [
        "Preso.",
        "Detido.",
        "Exilado.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "O Tratado que em 1951 instituiu a Comunidade Europeia do Carvão e do Aço foi:",
      "choices": [
        "O Tratado de Maastricht.",
        "O Tratado de Lisboa.",
        "O Tratado de Amesterdão.",
        "O Tratado de Paris."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Tratado de Paris."
      ],
      "score": 10
    },
    {
      "question": "São objetivos da União Europeia no interior das suas fronteiras:",
      "choices": [
        "Promover a paz, os seus valores e o bem-estar dos seus cidadãos.",
        "Combater a exclusão social e a discriminação.",
        "Promover a justiça e a proteção sociais, a igualdade entre homens e mulher e a proteção dos direitos da criança. ",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "Uma das instituições da União Europeia é:",
      "choices": [
        "A Comissão Europeia.",
        "A Organização das Nações Unidas.",
        "A Organização do Tratado Atlântico Norte.",
        "A Organização Mundial de Saúde."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A Comissão Europeia."
      ],
      "score": 10
    },
    {
      "question": "Quantos são os Estados-Membros da União Europeia? ",
      "choices": [
        "7 Países.",
        "17 Países.",
        "37 países.",
        "Todas as alíneas estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão erradas."
      ],
      "score": 10
    }
    
  ],
}

// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const cg_4: Topic = {
  topic: 'Cultura Geral (4)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "'A Odisseia', de 2016, é um filme de Jérôme Salle cuja narrativa se centra em:",
      "choices": [
        "Stephen Hawking",
        "Jacques-Yves Cousteau",
        "Neil Armstrong",
        "John Nash"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Jacques-Yves Cousteau"
      ],
      "score": 10
    },
    {
      "question": "Os diamantes são constituídos por:",
      "choices": [
        "Grafite",
        "Berílio",
        "Óxido de alumínio",
        "Carbono"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Carbono"
      ],
      "score": 10
    },
    {
      "question": "Em 2021, o vencedor português do Prémio da União Europeia para a Literatura foi:",
      "choices": [
        "João Pinto Coelho",
        "Ana Pinto Magalhães",
        "Frederico Pedreira",
        "Elísio Amaral"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Frederico Pedreira"
      ],
      "score": 10
    },
    {
      "question": "Que matemático e geógrafo grego calculou o perímetro da Terra no ano 240 a.C.?",
      "choices": [
        "Descartes",
        "Sócrates",
        "Sófocles",
        "Eratóstenes"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Eratóstenes"
      ],
      "score": 10
    },
    {
      "question": "Segundo o tratado assinado em 1971, que região do planeta ficou interdita a armas nucleares?",
      "choices": [
        "Sara",
        "África",
        "Antártida",
        "Bahamas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Antártida"
      ],
      "score": 10
    },
    {
      "question": "Por que nome se tornou mais conhecido o escritor norte-americano Samuel Langhorne Clemens?",
      "choices": [
        "Paul Auster",
        "Mark Twain",
        "Paulo Coelho",
        "Nicolau Gogol"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Mark Twain"
      ],
      "score": 10
    },
    {
      "question": "Que expressão alemã significa 'guerra-relâmpago'?",
      "choices": [
        "Sauerkraut",
        "Glasnost",
        "Reichstag",
        "Blitzkrieg"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Blitzkrieg"
      ],
      "score": 10
    },
    {
      "question": "Como se chamou o Sporting Club de Portugal entre 1904 e 1906?",
      "choices": [
        "Sporting de Portugal",
        "Não teve nome",
        "Leões da Estrela",
        "Campo Grande Football Club"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Campo Grande Football Club"
      ],
      "score": 10
    },
    {
      "question": "Junto a que cidade do Novo México se julga ter caído um OVNI em 1947?",
      "choices": [
        "Tijuana",
        "Las Vegas",
        "Roswell",
        "Chihuahua"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Roswell"
      ],
      "score": 10
    },
    {
      "question": "Em Portugal, a que cidadãos foi retirado o direito de voto em 1913?",
      "choices": [
        "Mulheres",
        "Analfabetos",
        "Emigrantes",
        "Idosos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Analfabetos"
      ],
      "score": 10
    },
    {
      "question": "Qual é o mais famoso detetive criado por Raymond Chandler?",
      "choices": [
        "Hercule Poirot",
        "Sherlock Holmes",
        "Philip Marlowe",
        "Agatha Christie"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Philip Marlowe"
      ],
      "score": 10
    },
    {
      "question": "Em que atividade se destacou Le Corbusier?",
      "choices": [
        "Cinema",
        "Teatro",
        "Literatura",
        "Arquitetura"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Arquitetura"
      ],
      "score": 10
    },
    {
      "question": "Que parque natural na região de Bragança foi criado em 1979?",
      "choices": [
        "Peneda-Gerês",
        "Alto Douro",
        "Montesinho",
        "Arrábida"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Montesinho"
      ],
      "score": 10
    },
    {
      "question": "Como se chama a personagem principal do filme Touro Enraivecido?",
      "choices": [
        "Sugar Ray Robinson",
        "Belarmino",
        "Muhamad Ali",
        "Jake La Motta"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Jake La Motta"
      ],
      "score": 10
    },
    {
      "question": "Quanto tempo demora a Lua a dar a volta à Terra (período orbital)?",
      "choices": [
        "27 dias e 8 horas",
        "25 dias",
        "30 dias",
        "29 dias e 12 horas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "27 dias e 8 horas"
      ],
      "score": 10
    },
    {
      "question": "Quem recebeu um prémio Nobel devido ao desenvolvimento da encefalografia arterial?",
      "choices": [
        "Júlio de Matos",
        "Egas Moniz",
        "Michael Faraday",
        "Sousa Martins"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Egas Moniz"
      ],
      "score": 10
    },
    {
      "question": "Qual o eletrodoméstico que foi criado por Murray Spangler em 1907 e que ainda hoje é vendido praticamente inalterado?",
      "choices": [
        "Esquentador",
        "Torradeira",
        "Micro-ondas",
        "Aspirador"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Aspirador"
      ],
      "score": 10
    },
    {
      "question": "Qual modalidade desportiva foi introduzida em Portugal Continental em 1888 por Guilherme Pinto Basto?",
      "choices": [
        "Futebol",
        "Corfebol",
        "Andebol",
        "Ténis"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Futebol"
      ],
      "score": 10
    },
    {
      "question": "Qual é o objeto de estudo do puericultor?",
      "choices": [
        "Crianças",
        "Coelhos",
        "Abelhas",
        "Galinhas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Crianças"
      ],
      "score": 10
    },
    {
      "question": "Qual país sul-americano é o maior produtor de vinho?",
      "choices": [
        "Brasil",
        "Venezuela",
        "Canadá",
        "Argentina"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Argentina"
      ],
      "score": 10
    }
  ],
}

// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'



export const raciocinio_verbal_2: Topic = {
  topic: 'Raciocínio Verbal (2)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
        "question": "Vento está para Furacão como Chuva está para:",
        "choices": [
            "trovoada",
            "tempestade",
            "gelo",
            "inundação",
            "granizo"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "tempestade"
        ],
        "score": 10
    },
    {
        "question": "Oxigénio está para Respiração como Energia está para:",
        "choices": [
            "movimento",
            "força",
            "vida",
            "alimentação",
            "combustível"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "movimento"
        ],
        "score": 10
    },
    {
        "question": "Palavra está para Frase como Letra está para:",
        "choices": [
            "alfabeto",
            "símbolo",
            "poesia",
            "parágrafo",
            "linguagem"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "alfabeto"
        ],
        "score": 10
    },
    {
        "question": "Fotografia está para Lembrança como Espelho está para:",
        "choices": [
            "imagem",
            "verdade",
            "reflexo",
            "janela",
            "ilusao"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "reflexo"
        ],
        "score": 10
    },
    {
        "question": "Impressão está para Dedos como ADN está para:",
        "choices": [
            "corpo",
            "célula",
            "genética",
            "identidade",
            "biologia"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "identidade"
        ],
        "score": 10
    },
    {
        "question": "Ciência está para Conhecimento como Arte está para:",
        "choices": [
            "expressão",
            "imaginação",
            "técnica",
            "beleza",
            "emoção"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "expressão"
        ],
        "score": 10
    },
    {
        "question": "Início está para Prólogo como Fim está para:",
        "choices": [
            "final",
            "clímax",
            "resumo",
            "epílogo",
            "cena"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "epílogo"
        ],
        "score": 10
    },
    {
        "question": "Arco-Íris está para Luz como Eco está para:",
        "choices": [
            "som",
            "vibração",
            "reflexo",
            "resposta",
            "voz"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "som"
        ],
        "score": 10
    },
    {
        "question": "Semente está para Crescimento como Problema está para:",
        "choices": [
            "solução",
            "desafio",
            "lição",
            "prevenção",
            "superação"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "solução"
        ],
        "score": 10
    },
    {
        "question": "Raiz está para Árvore como Fundação está para:",
        "choices": [
            "prédio",
            "construção",
            "estrutura",
            "edifício",
            "futuro"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "edifício"
        ],
        "score": 10
    },
    {
        "question": "Compositor está para Sinfonia como Pintor está para:",
        "choices": [
            "cor",
            "pintura",
            "música",
            "escultura",
            "paleta"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "pintura"
        ],
        "score": 10
    },
    {
        "question": "Abelha está para Colmeia como Soldado está para:",
        "choices": [
            "campo",
            "regimento",
            "arma",
            "fortaleza",
            "comandante"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "regimento"
        ],
        "score": 10
    },
    {
        "question": "Inverno está para Primavera como Crepúsculo está para:",
        "choices": [
            "noite",
            "madrugada",
            "tarde",
            "aurora",
            "sol"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "aurora"
        ],
        "score": 10
    },
    {
        "question": "Autor está para Livro como Capitão está para:",
        "choices": [
            "navio",
            "tripulação",
            "porto",
            "ilha",
            "bandeira"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "navio"
        ],
        "score": 10
    },
    {
        "question": "Paciente está para Médico como Cliente está para:",
        "choices": [
            "loja",
            "mercado",
            "empregado",
            "empresa",
            "fornecedor"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "empregado"
        ],
        "score": 10
    },
    {
        "question": "Rei está para Coroa como Presidente está para:",
        "choices": [
            "poder",
            "autoridade",
            "governo",
            "banda",
            "faixa"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "faixa"
        ],
        "score": 10
    },
    {
        "question": "Base está para Pirâmide como Raiz está para:",
        "choices": [
            "tronco",
            "folha",
            "árvore",
            "semente",
            "fruto"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "árvore"
        ],
        "score": 10
    },
    {
        "question": "Coração está para Sangue como Pulmão está para:",
        "choices": [
            "oxigénio",
            "respiração",
            "ar",
            "pulso",
            "corpo"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "oxigénio"
        ],
        "score": 10
    },
    {
        "question": "Astronauta está para Espaço como Escafandrista está para:",
        "choices": [
            "profundidade",
            "oceano",
            "barco",
            "terra",
            "pressão"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "oceano"
        ],
        "score": 10
    },
    {
        "question": "Ar está para Atmosfera como Água está para:",
        "choices": [
            "oceano",
            "nuvem",
            "mar",
            "hidrosfera",
            "solo"
        ],
        "type": "MCQs",
        "correctAnswers": [
            "hidrosfera"
        ],
        "score": 10
    }
  
    
  
  ],
}

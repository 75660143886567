import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Button, IconButton, Drawer, List, ListItem, ListItemText, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ToggleTheme from './ui/ToggleTheme';
import LogoutButton from './LogoutButton';
import { auth, db } from '../firebase/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

interface NavBarProps {
  toggleTheme: (e: React.ChangeEvent<HTMLInputElement>) => void;
  currentTheme: string;
  handlePurchaseNavigation: () => void; // Adicionando a prop
}

const NavBar: React.FC<NavBarProps> = ({ toggleTheme, currentTheme, handlePurchaseNavigation }) => {
  const [hasPurchased, setHasPurchased] = useState<boolean | null>(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const fetchPurchaseStatus = async () => {
    let purchased = false;
    try {
      const userId = auth.currentUser?.uid;
      if (userId) {
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (userDoc.exists()) {
          purchased = userDoc.data().hasPurchased;
        }
      }
    } catch (error) {
      console.error('Error fetching purchase status: ', error);
    }
    setHasPurchased(purchased);
    // alert(hasPurchased);
  };

  useEffect(() => {
    fetchPurchaseStatus();
  }, []);

  useEffect(() => {
    setMobileOpen(false);
  }, [location.pathname]);

  const openSupport = () => {
    const whatsappUrl = `https://wa.me/351913450846`;
    window.open(whatsappUrl, '_blank'); // Abre o link em uma nova aba
  };

  const drawer = (
    <div>
      <List>
        <ListItem button onClick={() => navigate('/')}>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button onClick={() => navigate('/profile')}>
          <ListItemText primary="Perfil" />
        </ListItem>
        <ListItem button onClick={() => navigate('/psp')}>
          <ListItemText primary="PSP" />
        </ListItem>
        <ListItem button onClick={() => navigate('/gnr')}>
          <ListItemText primary="GNR" />
        </ListItem>

        {!hasPurchased && (
          <ListItem button onClick={() => navigate('/purchase')}>
            <ListItemText primary="*Premium*" sx={{ color: 'gold', fontWeight: 'bold' }} />
          </ListItem>
        )}

        {hasPurchased && (
          <ListItem button onClick={openSupport}>
            <ListItemText primary="Suporte" sx={{ color: 'gold', fontWeight: 'bold' }} />
          </ListItem>
        )}

        <ListItem button onClick={() => navigate('/changelog')}>
          <ListItemText primary="changelog" />
        </ListItem>
        
        <ListItem>
          <LogoutButton />
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <AppBar position="fixed" sx={{ bgcolor: '#333' }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Button color="inherit" onClick={() => navigate('/')} sx={{ display: { xs: 'none', sm: 'block' } }}>
            Home
          </Button>
          <Button color="inherit" onClick={() => navigate('/profile')} sx={{ display: { xs: 'none', sm: 'block' } }}>
            Perfil
          </Button>
          <Button color="inherit" onClick={() => navigate('/psp')} sx={{ display: { xs: 'none', sm: 'block' } }}>
            PSP
          </Button>
          <Button color="inherit" onClick={() => navigate('/gnr')} sx={{ display: { xs: 'none', sm: 'block' } }}>
            GNR
          </Button>
          
          {!hasPurchased && (
            <Button
              color="inherit"
              onClick={() => navigate('/purchase')}
              sx={{ color: 'gold', fontWeight: 'bold', display: { xs: 'none', sm: 'block' } }}
            >
              *Premium*
            </Button>
          )}

          {hasPurchased && (
            <Button
              color="inherit"
              onClick={openSupport}
              sx={{ color: 'gold', fontWeight: 'bold', display: { xs: 'none', sm: 'block' } }}
            >
              Suporte
            </Button>
          )}

          <Button color="inherit" onClick={() => navigate('/changelog')} sx={{ display: { xs: 'none', sm: 'block' } }}>
            Changelog
          </Button>

          <ToggleTheme
            onChange={toggleTheme}
            currentTheme={currentTheme}
            checked={currentTheme === 'dark'}
            id="toggleTheme"
            value="theme"
          />
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <LogoutButton />
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </>
  );
};

export default NavBar;

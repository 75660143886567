// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const dudh_2: Topic = {
  topic: 'DUDH (2)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "O que significa o direito à liberdade de pensamento, consciência e religião na DUDH?",
      "choices": [
        "Que toda pessoa pode mudar de religião e praticá-la livremente",
        "Que todas as religiões devem ser aprovadas pelo governo",
        "Que apenas certas religiões têm proteção legal",
        "Que as pessoas devem manter a religião de seus pais"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Que toda pessoa pode mudar de religião e praticá-la livremente"
      ],
      "score": 10
    },
    {
      "question": "O que significa o direito à igualdade de direitos para homens e mulheres?",
      "choices": [
        "Que homens e mulheres têm os mesmos direitos em todas as áreas da vida",
        "Que as mulheres têm mais direitos para compensar desigualdades",
        "Que os homens têm mais direitos em questões legais",
        "Que homens e mulheres têm direitos diferentes em algumas áreas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Que homens e mulheres têm os mesmos direitos em todas as áreas da vida"
      ],
      "score": 10
    },
    {
      "question": "De acordo com a DUDH, o que é o direito ao desenvolvimento?",
      "choices": [
        "O direito de todas as pessoas e nações ao progresso econômico, social e cultural",
        "O direito de apenas os países ricos se desenvolverem",
        "O direito de focar no desenvolvimento econômico acima de tudo",
        "O direito de desenvolvimento apenas para indivíduos com diplomas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O direito de todas as pessoas e nações ao progresso econômico, social e cultural"
      ],
      "score": 10
    },
    {
      "question": "O que significa o direito à participação política na DUDH?",
      "choices": [
        "Que todos têm o direito de votar e de se candidatar a cargos públicos",
        "Que apenas cidadãos ricos podem votar",
        "Que o voto é obrigatório para todos",
        "Que apenas pessoas instruídas podem participar da política"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Que todos têm o direito de votar e de se candidatar a cargos públicos"
      ],
      "score": 10
    },
    {
      "question": "O que é o direito ao acesso à informação segundo a DUDH?",
      "choices": [
        "O direito de acessar qualquer informação relevante para a sociedade",
        "O direito de acessar apenas as informações aprovadas pelo governo",
        "O direito de acessar apenas informações científicas",
        "O direito de acessar informações que não afetam a segurança nacional"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O direito de acessar qualquer informação relevante para a sociedade"
      ],
      "score": 10
    },
    {
      "question": "Qual é a importância da solidariedade na DUDH?",
      "choices": [
        "A cooperação entre as nações para garantir direitos humanos para todos",
        "A ajuda dos ricos aos pobres",
        "O apoio das comunidades locais aos seus líderes",
        "A importância de ajudar apenas os necessitados em tempos de crise"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A cooperação entre as nações para garantir direitos humanos para todos"
      ],
      "score": 10
    },
    {
      "question": "Segundo a DUDH, o que significa o direito à liberdade pessoal?",
      "choices": [
        "Que ninguém pode ser arbitrariamente preso, detido ou exilado",
        "Que as pessoas podem ser presas por motivos políticos",
        "Que a prisão é permitida sem julgamento justo",
        "Que o governo pode deter pessoas sem motivo claro"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Que ninguém pode ser arbitrariamente preso, detido ou exilado"
      ],
      "score": 10
    },
    {
      "question": "O que significa o direito à proteção contra a escravidão na DUDH?",
      "choices": [
        "Que ninguém pode ser mantido em escravidão ou servidão",
        "Que o trabalho forçado é permitido em certos casos",
        "Que a escravidão moderna ainda pode ser tolerada",
        "Que as crianças podem ser obrigadas a trabalhar em tempos de guerra"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Que ninguém pode ser mantido em escravidão ou servidão"
      ],
      "score": 10
    },
    {
      "question": "O que significa o direito a formar e participar de sindicatos?",
      "choices": [
        "Que todos têm o direito de formar e participar de sindicatos para proteger seus interesses",
        "Que apenas os trabalhadores do governo podem formar sindicatos",
        "Que sindicatos podem ser criados, mas sem poderes legais",
        "Que os sindicatos são permitidos apenas em empresas privadas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Que todos têm o direito de formar e participar de sindicatos para proteger seus interesses"
      ],
      "score": 10
    },
    {
      "question": "Qual é o significado do direito à proteção social na DUDH?",
      "choices": [
        "O direito a ser amparado em situações de vulnerabilidade, como desemprego, doença ou velhice",
        "O direito de todos terem uma renda fixa garantida pelo governo",
        "O direito de ter uma casa própria sem pagar por ela",
        "O direito de ter assistência financeira em qualquer situação"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O direito a ser amparado em situações de vulnerabilidade, como desemprego, doença ou velhice"
      ],
      "score": 10
    },
    {
      "question": "De acordo com a DUDH, o que é o direito à liberdade de trânsito?",
      "choices": [
        "O direito de se movimentar livremente dentro e fora do país",
        "O direito de viajar apenas com autorização do governo",
        "O direito de se movimentar apenas dentro da sua cidade",
        "O direito de mudar de país sem controle de fronteiras"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O direito de se movimentar livremente dentro e fora do país"
      ],
      "score": 10
    },
    {
      "question": "O que significa o direito à proteção da lei na DUDH?",
      "choices": [
        "Que todas as pessoas têm o direito de serem protegidas pela lei, independentemente de sua origem",
        "Que apenas cidadãos têm proteção total da lei",
        "Que a proteção da lei é garantida apenas em tempos de paz",
        "Que os estrangeiros têm menos proteção legal"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Que todas as pessoas têm o direito de serem protegidas pela lei, independentemente de sua origem"
      ],
      "score": 10
    },
    {
      "question": "Segundo a DUDH, o que é o direito à saúde?",
      "choices": [
        "O direito de todas as pessoas terem acesso a serviços de saúde adequados",
        "O direito de ser tratado em hospitais apenas em emergências",
        "O direito de cuidar da própria saúde sem assistência",
        "O direito de acessar serviços de saúde apenas se puder pagar"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O direito de todas as pessoas terem acesso a serviços de saúde adequados"
      ],
      "score": 10
    },
    {
      "question": "O que significa o direito à integridade física e mental na DUDH?",
      "choices": [
        "Que todas as pessoas têm o direito de viver livres de abusos físicos e psicológicos",
        "Que apenas abusos físicos são proibidos",
        "Que os governos podem decidir o que é considerado abuso",
        "Que o abuso mental não é reconhecido"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Que todas as pessoas têm o direito de viver livres de abusos físicos e psicológicos"
      ],
      "score": 10
    },
    {
      "question": "O que significa o direito à liberdade de profissão na DUDH?",
      "choices": [
        "O direito de escolher e exercer qualquer profissão sem discriminação",
        "O direito de ser promovido independentemente de desempenho",
        "O direito de trabalhar em qualquer profissão sem qualificação",
        "O direito de escolher uma profissão apenas se houver vagas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O direito de escolher e exercer qualquer profissão sem discriminação"
      ],
      "score": 10
    },
    {
      "question": "O que significa o direito ao casamento e à constituição de família segundo a DUDH?",
      "choices": [
        "Que toda pessoa tem o direito de casar-se livremente e formar uma família",
        "Que o casamento deve ser arranjado pelas famílias",
        "Que apenas homens podem escolher suas esposas",
        "Que o casamento é permitido apenas dentro da mesma religião"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Que toda pessoa tem o direito de casar-se livremente e formar uma família"
      ],
      "score": 10
    },
    {
      "question": "De acordo com a DUDH, o que é o direito à proteção contra detenções arbitrárias?",
      "choices": [
        "O direito de não ser preso sem uma razão válida ou julgamento",
        "O direito de ser preso apenas por ofensas graves",
        "O direito de ser preso apenas por opinião política",
        "O direito de ser detido sem motivo, se o governo decidir"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O direito de não ser preso sem uma razão válida ou julgamento"
      ],
      "score": 10
    },
    {
      "question": "O que significa o direito à liberdade de expressão artística?",
      "choices": [
        "Que todos têm o direito de expressar sua criatividade sem censura",
        "Que a arte deve ser regulada pelo governo",
        "Que apenas artistas famosos têm esse direito",
        "Que a liberdade artística deve ser limitada a certos temas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Que todos têm o direito de expressar sua criatividade sem censura"
      ],
      "score": 10
    },
    {
      "question": "O que é o direito à participação nas decisões públicas?",
      "choices": [
        "O direito de participar de decisões que afetam a vida pública e política do país",
        "O direito de votar apenas nas eleições municipais",
        "O direito de votar apenas em assuntos econômicos",
        "O direito de participar de decisões apenas se for convidado"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O direito de participar de decisões que afetam a vida pública e política do país"
      ],
      "score": 10
    },
    {
      "question": "O que significa o direito à proteção contra o desemprego?",
      "choices": [
        "Que toda pessoa tem direito a apoio em caso de desemprego",
        "Que as pessoas nunca podem ser demitidas",
        "Que o governo deve oferecer emprego para todos",
        "Que apenas certas profissões são protegidas contra desemprego"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Que toda pessoa tem direito a apoio em caso de desemprego"
      ],
      "score": 10
    }
  ],
}

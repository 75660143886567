import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { auth } from '../firebase/firebaseConfig';
import updatePurchaseStatus from '../firebase/updatePurchaseStatus';
import { Container, Typography, Button, Collapse } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; // Importa o ícone do WhatsApp
import PayPalIcon from '@mui/icons-material/AccountBalanceWallet'; // Aqui, pode-se usar um ícone genérico de carteira ou SVG
import mbway from '../assets/images/mbway.png'


const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  min-height: 100vh;
  margin-top: 80px;
`;

const StyledTypography = styled(Typography)`
  margin-bottom: 20px;
`;

const FeatureTable = styled.table`
  width: 80%;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  border-collapse: collapse;

  th, td {
    padding: 10px;
    border: 1px solid #ddd;
  }

  th {
    font-weight: bold;
  }

  td {
    text-align: left;
  }
`;

const PayPalButtonWrapper = styled.div`
  margin-top: 20px;
`;

// Novo estilo para o botão de ajuda
const WhatsAppButton = styled(Button)`
  background-color: #25D366 !important;  /* Verde do WhatsApp */
  color: white !important;
  &:hover {
    background-color: #22bb5c !important;  /* Cor mais escura ao passar o mouse */
  }
`;

const WhatsAppButton2 = styled(Button)`
  background-color: #D4AF37 !important;  /* Verde do WhatsApp */
  color: white !important;
  &:hover {
    background-color: #D4AF37 !important;  /* Cor mais escura ao passar o mouse */
  }
`;

const PayPalButton = styled(Button)`
  background-color: #FFC439 !important; /* Cor do PayPal */
  color: #003087 !important; /* Azul do texto PayPal */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold !important;
  padding: 10px 20px;
  &:hover {
    background-color: #FFB320 !important; /* Cor mais escura para o hover */
  }
`;

const PayPalSVG = () => (
  <svg width="40px" height="40px" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="24" cy="24" r="20" fill="#0070BA"/>
<path d="M32.3305 18.0977C32.3082 18.24 32.2828 18.3856 32.2542 18.5351C31.2704 23.5861 27.9046 25.331 23.606 25.331H21.4173C20.8916 25.331 20.4486 25.7127 20.3667 26.2313L19.2461 33.3381L18.9288 35.3527C18.8755 35.693 19.1379 36 19.4815 36H23.3634C23.8231 36 24.2136 35.666 24.286 35.2127L24.3241 35.0154L25.055 30.3772L25.1019 30.1227C25.1735 29.6678 25.5648 29.3338 26.0245 29.3338H26.6051C30.3661 29.3338 33.3103 27.8068 34.1708 23.388C34.5303 21.5421 34.3442 20.0008 33.393 18.9168C33.1051 18.59 32.748 18.3188 32.3305 18.0977Z" fill="white" fill-opacity="0.6"/>
<path d="M31.3009 17.6871C31.1506 17.6434 30.9955 17.6036 30.8364 17.5678C30.6766 17.5328 30.5127 17.5018 30.3441 17.4748C29.754 17.3793 29.1074 17.334 28.4147 17.334H22.5676C22.4237 17.334 22.2869 17.3666 22.1644 17.4254C21.8948 17.5551 21.6944 17.8104 21.6459 18.1229L20.402 26.0013L20.3662 26.2311C20.4481 25.7126 20.8911 25.3308 21.4168 25.3308H23.6055C27.9041 25.3308 31.2699 23.5851 32.2537 18.5349C32.2831 18.3854 32.3078 18.2398 32.33 18.0975C32.0811 17.9655 31.8115 17.8525 31.5212 17.7563C31.4496 17.7324 31.3757 17.7094 31.3009 17.6871Z" fill="white" fill-opacity="0.8"/>
<path d="M21.6461 18.1231C21.6946 17.8105 21.895 17.5552 22.1646 17.4264C22.2879 17.3675 22.4239 17.3349 22.5678 17.3349H28.4149C29.1077 17.3349 29.7542 17.3803 30.3444 17.4757C30.513 17.5027 30.6768 17.5338 30.8367 17.5687C30.9957 17.6045 31.1508 17.6443 31.3011 17.688C31.3759 17.7103 31.4498 17.7334 31.5222 17.7564C31.8125 17.8527 32.0821 17.9664 32.331 18.0976C32.6237 16.231 32.3287 14.9601 31.3194 13.8093C30.2068 12.5424 28.1986 12 25.629 12H18.169C17.6441 12 17.1963 12.3817 17.1152 12.9011L14.0079 32.5969C13.9467 32.9866 14.2473 33.3381 14.6402 33.3381H19.2458L20.4022 26.0014L21.6461 18.1231Z" fill="white"/>
</svg>
);


const Purchase = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [paypalExpanded, setPaypalExpanded] = useState(false);
  const [stripeExpanded, setStripeExpanded] = useState(false);

  useEffect(() => {
    const isPaymentSuccess = new URLSearchParams(location.search).get("success");

    if (isPaymentSuccess === "true") {
      activatePremium();
    }
  }, [location]);

  const activatePremium = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
        await updatePurchaseStatus(userId);
        alert("Compra realizada com sucesso! Status premium ativado.");
        navigate('/dashboard');
      } else {
        alert("Usuário não autenticado. Por favor, faça login novamente.");
        navigate('/login');
      }
    } catch (error) {
      console.error("Erro ao processar a compra: ", error);
      alert("Ocorreu um erro ao ativar o status premium.");
    }
  };

  const handleStripePayment = () => {
    window.location.href = "https://buy.stripe.com/9AQaHYceK2GXfS04gg";
  };

  const handlePayPalApprove = async (data, actions) => {
    try {
      const details = await actions.order.capture();
      const userId = auth.currentUser.uid;

      await updatePurchaseStatus(userId);

      alert("Compra realizada com sucesso via PayPal!");
      navigate('/dashboard');
    } catch (error) {
      console.error("Erro ao processar a compra: ", error);
    }
  };

  const handleBackToHome = () => {
    navigate('/');
  };

  return (
    <StyledContainer>
      <StyledTypography variant="h4" gutterBottom>
        Acesso Premium
      </StyledTypography>
      <br />
      <WhatsAppButton
        variant="contained"
        startIcon={<WhatsAppIcon />}
        href="https://wa.me/351913450846"  // Substitua pelo link real de contato no WhatsApp
        target="_blank"
      >
        Suporte
      </WhatsAppButton>
      <br />
      <FeatureTable>
        
        <thead>
          <tr>
            <th>Recursos</th>
            <th>Gratuito</th>
            <th>Premium</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Custo (Pagamento único)</td>
            <td>-</td>
            <td>3,99 €</td>
          </tr>
          <tr>
            <td>Acesso ao conteúdo de áudio</td>
            <td>❌</td>
            <td>✔️</td>
          </tr>
          <tr>
            <td>Acesso ao conteúdo básico</td>
            <td>✔️</td>
            <td>✔️</td>
          </tr>
          <tr>
            <td>Relatórios detalhados</td>
            <td>✔️</td>
            <td>✔️</td>
          </tr>
          <tr>
            <td>Ajuda à continuidade da plataforma</td>
            <td>❌</td>
            <td>✔️</td>
          </tr>
          <tr>
            <td>Acesso ao conteúdo Prova de Conhecimentos</td>
            <td>❌</td>
            <td>✔️</td>
          </tr>
          <tr>
            <td>Acesso ao conteúdo Prova de Aptidão Física</td>
            <td>❌</td>
            <td>✔️</td>
          </tr>
          <tr>
            <td>Acesso ao conteúdo Testes psicotécnicos</td>
            <td>❌</td>
            <td>✔️</td>
          </tr>
          <tr>
            <td>Acesso ao conteúdo completo</td>
            <td>❌</td>
            <td>✔️</td>
          </tr>
          <tr>
            <td>Suporte</td>
            <td>❌</td>
            <td>✔️</td>
          </tr>
          <tr>
            <td>Sem anúncios</td>
            <td>❌</td>
            <td>✔️</td>
          </tr>
        </tbody>
      </FeatureTable>

      
      <br />
      <br />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <a href="/purchase/mbway">
          <img style={{ width: '100px' }} src={mbway} alt="" />
        </a>
      </div>
      <br />
      
      {/* PayPal Section */}
      <PayPalButton 
        onClick={() => setPaypalExpanded(!paypalExpanded)} 
        style={{ marginBottom: '10px' }}
        startIcon={<PayPalSVG />}  // Usa o ícone do PayPal no botão
      >
        {paypalExpanded ? 'Cancelar' : '3,99€ com PayPal'}
      </PayPalButton>
      <Collapse in={paypalExpanded}>
        <PayPalButtonWrapper>
          <PayPalScriptProvider options={{ "client-id": "AeIo__Xzy2mK7oJ4bCNfF4xHXYIxzSrE5xkZGiii0PXv0UnOiuw0_m7zUP8EZhRVKpxC8VnTjOkaZq3T" }}>
            <PayPalButtons
              createOrder={(data, actions) => {
                return actions.order.create({
                  purchase_units: [{
                    amount: {
                      value: "4.40"
                    }
                  }]
                });
              }}
              onApprove={handlePayPalApprove}
              onError={(err) => {
                console.error("Erro no pagamento: ", err);
                alert("Ocorreu um erro ao processar seu pagamento. Por favor, tente novamente.");
              }}
            />
          </PayPalScriptProvider>
        </PayPalButtonWrapper>
      </Collapse>

      {/* Stripe Section */}
      {/* <Button variant="contained" color="secondary" onClick={handleStripePayment} style={{ marginTop: '20px' }}>
        Pagar com Stripe
      </Button> */}
      <br />
      
      <br />
      <Button onClick={handleBackToHome} style={{ marginTop: '50px' }}>
        Cancelar
      </Button>
    </StyledContainer>
  );
};

export default Purchase;

// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const linguaPortuguesa_5: Topic = {
  topic: 'Língua Portugesa (5)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "Qual das frases apresenta uma voz passiva analítica?",
      "choices": [
        "O trabalho foi concluído pelo aluno.",
        "O aluno concluiu o trabalho.",
        "Ele está a concluir o trabalho.",
        "Concluíram o trabalho rapidamente."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O trabalho foi concluído pelo aluno."
      ],
      "score": 10
    },
    {
      "question": "Qual das frases contém uma oração subordinada adjetiva restritiva?",
      "choices": [
        "O livro que eu comprei é muito interessante.",
        "O Pedro, que é meu amigo, chegou ontem.",
        "Embora chova, vamos sair.",
        "Ele estuda para que passe no exame."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O livro que eu comprei é muito interessante."
      ],
      "score": 10
    },
    {
      "question": "Em qual das frases há uma preposição?",
      "choices": [
        "Ele foi ao cinema.",
        "O João é alto.",
        "Eles estudam muito.",
        "Ela corre rapidamente."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ele foi ao cinema."
      ],
      "score": 10
    },
    {
      "question": "Qual das palavras é um substantivo abstrato?",
      "choices": [
        "Amor",
        "Casa",
        "Mesa",
        "Cidade"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Amor"
      ],
      "score": 10
    },
    {
      "question": "Qual é o tipo de sujeito na frase: 'Fala-se muito sobre esse assunto.'?",
      "choices": [
        "Sujeito indeterminado",
        "Sujeito simples",
        "Sujeito composto",
        "Sujeito oculto"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Sujeito indeterminado"
      ],
      "score": 10
    },
    {
      "question": "Em qual das frases o verbo está no pretérito perfeito do indicativo?",
      "choices": [
        "Ontem, eu estudei bastante.",
        "Eu estudava todos os dias.",
        "Eu terei estudado quando ele chegar.",
        "Estudaria mais, se tivesse tempo."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ontem, eu estudei bastante."
      ],
      "score": 10
    },
    {
      "question": "Na frase 'Ele entregou o relatório ao professor', o termo 'ao professor' desempenha a função de:",
      "choices": [
        "Objeto direto",
        "Objeto indireto",
        "Adjunto adnominal",
        "Complemento nominal"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Objeto indireto"
      ],
      "score": 10
    },
    {
      "question": "Em qual das frases o verbo está no presente do indicativo?",
      "choices": [
        "Eu estudo todos os dias.",
        "Eu estudarei amanhã.",
        "Eu estudei ontem.",
        "Eu estudaria se tivesse tempo."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Eu estudo todos os dias."
      ],
      "score": 10
    },
    {
      "question": "Qual das opções contém uma oração subordinada adverbial causal?",
      "choices": [
        "Ele foi promovido porque se dedicou muito.",
        "Se eu fosse rico, viajaria mais.",
        "Embora cansado, ele continuou a trabalhar.",
        "Ela correu tanto que ficou exausta."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ele foi promovido porque se dedicou muito."
      ],
      "score": 10
    },
    {
      "question": "Identifique a função sintática de 'de Pedro' na frase: 'O carro de Pedro quebrou.'",
      "choices": [
        "Adjunto adnominal",
        "Objeto direto",
        "Predicativo do sujeito",
        "Complemento nominal"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Adjunto adnominal"
      ],
      "score": 10
    },
    {
      "question": "Em qual das frases há um erro de concordância verbal?",
      "choices": [
        "Eles foram ao cinema.",
        "Os alunos estuda muito.",
        "Nós saímos cedo ontem.",
        "Ela corre todos os dias."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Os alunos estuda muito."
      ],
      "score": 10
    },
    {
      "question": "Qual das palavras é um advérbio de intensidade?",
      "choices": [
        "Muito",
        "Bem",
        "Ali",
        "Ontem"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Muito"
      ],
      "score": 10
    },
    {
      "question": "Na frase 'Os alunos fizeram o trabalho com cuidado', o termo 'com cuidado' é um:",
      "choices": [
        "Objeto direto",
        "Objeto indireto",
        "Adjunto adverbial de modo",
        "Complemento nominal"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Adjunto adverbial de modo"
      ],
      "score": 10
    },
    {
      "question": "Qual das seguintes frases contém uma oração subordinada adjetiva restritiva?",
      "choices": [
        "O carro que eu comprei é vermelho.",
        "Aquele livro, que li ontem, é excelente.",
        "Ele saiu porque estava atrasado.",
        "Se ele chegar, vamos sair."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O carro que eu comprei é vermelho."
      ],
      "score": 10
    },
    {
      "question": "Em qual das frases o termo sublinhado é um objeto direto?",
      "choices": [
        "Ele escreveu _uma carta_.",
        "O João foi _ao cinema_.",
        "Ele falou _com ela_.",
        "Eles estudam _todos os dias_."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ele escreveu _uma carta_."
      ],
      "score": 10
    },
    {
      "question": "Qual das frases está no pretérito imperfeito do indicativo?",
      "choices": [
        "Eu corria todas as manhãs.",
        "Eu corri ontem.",
        "Eu correria se tivesse tempo.",
        "Eu terei corrido amanhã."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Eu corria todas as manhãs."
      ],
      "score": 10
    },
    {
      "question": "Em qual das frases há uma comparação?",
      "choices": [
        "Ele é tão alto como o pai.",
        "Ele é alto.",
        "Ele corre todos os dias.",
        "Ele trabalha muito bem."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ele é tão alto como o pai."
      ],
      "score": 10
    },
    {
      "question": "Na frase 'Eles chegaram cedo ao evento', o termo 'cedo' é:",
      "choices": [
        "Advérbio de modo",
        "Advérbio de lugar",
        "Advérbio de tempo",
        "Adjetivo"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Advérbio de tempo"
      ],
      "score": 10
    },
    {
      "question": "Qual das frases apresenta um sujeito oculto?",
      "choices": [
        "Estou cansado.",
        "Os alunos estudam muito.",
        "O professor explicou a matéria.",
        "Nós viajamos juntos."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Estou cansado."
      ],
      "score": 10
    },
    {
      "question": "Identifique o sujeito da frase: 'Há muitas pessoas na rua.'",
      "choices": [
        "Muitas pessoas",
        "Há",
        "Na rua",
        "Não tem sujeito"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Não tem sujeito"
      ],
      "score": 10
    }
  ],
}

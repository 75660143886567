// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const gnr_provaCompleta_5: Topic = {
  topic: 'GNR - Prova Completa (5)',
  level: 'Advanced',
  totalQuestions: 50,
  totalScore: 500,
  totalTime: 1800,
  questions: [
    {
      "question": "O candidato a militar da Guarda, enquanto na frequência do curso de formação de guardas, é designado por:",
      "choices": [
        "Guarda eventual.",
        "Guarda alistado.",
        "Soldado provisório.",
        "Guarda provisório."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Guarda provisório."
      ],
      "score": 10
    },
    {
      "question": "Ao militar da Guarda Nacional Republicana são aplicáveis:",
      "choices": [
        "A Lei de Bases Gerais do Estatuto da Condição Civil.",
        "A Lei de Defesa internacional.",
        "A Lei de Segurança externa.",
        "O Código de Justiça Militar."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Código de Justiça Militar."
      ],
      "score": 10
    },
    {
      "question": "Compete ao militar da Guarda Nacional Republicana:",
      "choices": [
        "Comportar-se de acordo com a dignidade da sua função e da condição militar, mesmo fora dos atos de serviço.",
        "Comportar-se de acordo com a dignidade da sua função e da condição civil, mesmo fora dos atos de serviço.",
        "Não comprovar oportunamente a sua identidade, sempre que solicitada ou as circunstâncias do serviço o exijam.",
        "Não comunicar ao superior hierárquico imediato a sua detenção ou constituição como arguido, independentemente da natureza do processo-crime."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Comportar-se de acordo com a dignidade da sua função e da condição militar, mesmo fora dos atos de serviço."
      ],
      "score": 10
    },
    {
      "question": "As funções do militar da Guarda Nacional Republicana classificam-se em:",
      "choices": [
        "Comando.",
        "Direção ou chefia.",
        "Execução.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "A realização das ações praticadas pelos militares da Guarda Nacional Republicana integrados em comandos, forças, unidades, estabelecimentos e órgãos, tendo em vista a preparação, o apoio e o cumprimento atribuições da Guarda Nacional Republicana, traduz a função de:",
      "choices": [
        "Execução.",
        "Comando.",
        "Direção ou Chefia.",
        "Estado-maior."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Execução."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda Nacional Republicana encontra-se numa das seguintes situações:",
      "choices": [
        "Ativo, inativo ou reforma.",
        "Ativo, de doença ou reforma.",
        "Ativo, reserva ou reforma.",
        "Ativo ou inativo."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ativo, reserva ou reforma."
      ],
      "score": 10
    },
    {
      "question": "A dispensa por iniciativa do comandante-geral, pode ter lugar sempre que:",
      "choices": [
        "O comportamento do militar indicie notórios desvios da condição de militar da Guarda, designadamente dos requisitos morais, éticos, militares ou técnico-profissionais exigidos pela sua qualidade e função.",
        "A avaliação do militar indicie incompatibilidades hierárquicas à condição de militar da Guarda, designadamente dos requisitos militares ou técnico-profissionais exigidos pela sua avaliação.",
        "O comportamento do militar indicie certos desvios da condição de militar da Guarda, designadamente dos requisitos sociais, exigidos pela sua particularidade e missão.",
        "A avaliação do militar demonstre desvios atinentes à sua condição militar da Guarda, designadamente dos técnico-profissionais exigidos aquando da avaliação extraordinária pelas suas funções."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O comportamento do militar indicie notórios desvios da condição de militar da Guarda, designadamente dos requisitos morais, éticos, militares ou técnico-profissionais exigidos pela sua qualidade e função."
      ],
      "score": 10
    },
    {
      "question": "A hierarquia militar tem por finalidade estabelecer, em todas as circunstâncias:",
      "choices": [
        "Relações de reciprocidade e relação entre os militares determinada pelos postos, também designados por posições, antiguidades e precedências previstas na lei, devendo se respeitar o mesmo fora do desempenho das funções institucionais.",
        "Relações de autoridade e subordinação entre os militares e é determinada pelos postos, também designados por patentes, antiguidades e precedências previstas na lei, a respeitar mesmo fora do desempenho das funções.",
        "Relações de autoridade entre os militares determinada pelos semelhantes no mesmo posto, também designados por patentes, antiguidades e com precedências previstas na lei.",
        "Relações de domínio e insurreição entre os militares que é determinada pelos postos, também designados por patentes, antiguidades e precedências previstas na lei, a respeitar mesmo fora do desempenho das funções institucionais."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Relações de autoridade e subordinação entre os militares e é determinada pelos postos, também designados por patentes, antiguidades e precedências previstas na lei, a respeitar mesmo fora do desempenho das funções."
      ],
      "score": 10
    },
    {
      "question": "O exercício de funções policiais pelos militares da Guarda atende a um horário de referência. A prestação de serviço para além do período normal de exercício de funções é compensada pela atribuição de:",
      "choices": [
        "Crédito horário, nos termos a definir por despacho do comandante-geral, sem qualquer redução da remuneração.",
        "Atribuição folga mensal, nos termos a definir por despacho do comandante-geral, sem qualquer redução da remuneração.",
        "Crédito horário, nos termos a definir por despacho do Ministro da Tutela, sem qualquer redução da remuneração.",
        "Por subsídio de horas extraordinárias, nos termos a definir por despacho do comandante-geral, sem aumento da remuneração base."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Crédito horário, nos termos a definir por despacho do comandante-geral, sem qualquer redução da remuneração."
      ],
      "score": 10
    },
    {
      "question": "Na avaliação do desempenho do militar da Guarda intervêm:",
      "choices": [
        "O superior hierárquico imediato, os pares e os subordinados.",
        "Dois avaliadores, sendo um deles, por norma, o superior hierárquico imediato.",
        "O superior hierárquico imediato, os pares, apenas indicativo, e os subordinados, para aferição de eventuais anomalias.",
        "O superior hierárquico imediato, os pares, para aferição de eventuais anomalias, e os subordinados, apenas indicativo."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Dois avaliadores, sendo um deles, por norma, o superior hierárquico imediato."
      ],
      "score": 10
    },
    {
      "question": "0 Estatuto dos Militares da GNR (EMGNR) aplica-se: ",
      "choices": [
        "Apenas aos oficiais, sargentos, guardas e funcionários civis que prestam serviço na GNR, que se encontrem na situação de ativo.",
        "Aos oficiais, sargentos, guardas, mestres-florestais e funcionários civis que prestam serviço na GNR, em qualquer situação.",
        "Apenas aos oficiais, sargentos e guardas-florestais que se encontrem na situação de ativo.",
        "Aos oficiais, sargentos e guardas, em qualquer situação."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Aos oficiais, sargentos e guardas, em qualquer situação."
      ],
      "score": 10
    },
    {
      "question": "O militar da Guarda Nacional Republicana deve desenvolver permanentemente:",
      "choices": [
        "As qualidades pessoais, aptidões fisica e psíquica.",
        "As competências necessárias ao pleno exercício das funções.",
        "As competências necessárias ao cumprimento das missões atribuídas.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "Os militares das Forças Armadas, em serviço na Guarda, regem-se pelo:",
      "choices": [
        "Estatuto dos Militares da Guarda, em exclusivo pois encontram-se ao serviço da Guarda.",
        "Estatuto dos Militares das Forças Armadas, em exclusivo pois pertencem às Forças Armadas.",
        "Estatuto dos Militares das Forças Armadas e pelo Estatuto dos Militares da Guarda, na parte aplicável.",
        "Estatuto dos Militares da Guarda e pelo Estatuto dos Militares das Forças Armadas, na parte aplicável."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Estatuto dos Militares das Forças Armadas e pelo Estatuto dos Militares da Guarda, na parte aplicável."
      ],
      "score": 10
    },


    //2020_1_2_3 (3)
    {
      "question": "Os militares da Guarda, após a frequência com aproveitamento nos cursos de acesso à respetiva categoria, prestam juramento de fidelidade, no caso dos guardas chama-se: ",
      "choices": [
        "Compromisso de vida.",
        "Compromisso de honra.",
        "Compromisso solene.",
        "Juramento de bandeira."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Compromisso de honra."
      ],
      "score": 10
    },
    {
      "question": "A antiguidade relativa entre militares pertencentes a quadros diferentes com o mesmo posto é determinada por:",
      "choices": [
        "Datas de antiguidade nesse posto.",
        "Despacho do Diretor de Recursos Internos.",
        "Média final do curso de antiguidades.",
        "Idade de cada militar no posto."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Datas de antiguidade nesse posto."
      ],
      "score": 10
    },
    {
      "question": "Ao 2.º comandante-geral compete: Coadjuvar o comandante-geral no exercício das suas funções; exercer as competências que lhe forem delegadas ou subdelegadas pelo comandante -geral; substituir o comandante-geral nas suas ausências ou impedimentos e é um:",
      "choices": [
        "Diretor Superior proveniente dos quadros da PGR.",
        "Tenente-General ou Almirante.",
        "Diretor Intermédio qualificado com curso superior em administração pública.",
        "Tenente-General nomeado pelo ministro da tutela, sob a proposta do comandante-geral da Guarda."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Tenente-General nomeado pelo ministro da tutela, sob a proposta do comandante-geral da Guarda."
      ],
      "score": 10
    },
    {
      "question": "O Conselho de Ética, Deontologia e Disciplina na área de recursos humanos é representado pela:",
      "choices": [
        "Direção de Recursos Financeiros.",
        "Direção de Recursos Humanos.",
        "Chefia de Serviço de Finanças.",
        "Direção de Justiça."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Direção de Recursos Humanos."
      ],
      "score": 10
    },
    {
      "question": "O Comando da Administração dos Recursos Internos:",
      "choices": [
        "Assegura o comando de toda a atividade operacional da Guarda.",
        "É o órgão a que compete julgar o grau de capacidade para o serviço de oficiais, sargentos e guardas que, por ordem do comandante-geral, lhe forem presentes, bem como emitir parecer sobre os recursos relativos a decisões baseadas em pareceres formulados pelas juntas médicas da Guarda.",
        "Assegura o comando e direção de toda a atividade da Guarda nos domínios da administração dos recursos humanos, materiais e financeiros.",
        "Assegura o comando e direção de toda a atividade da Guarda nos domínios da doutrina e formação do efetivo da Guarda."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Assegura o comando e direção de toda a atividade da Guarda nos domínios da administração dos recursos humanos, materiais e financeiros."
      ],
      "score": 10
    },
    {
      "question": "O Conselho Superior da Guarda é o órgão máximo de consulta do comandante-geral. Em composição restrita é presidido pelo:",
      "choices": [
        "Diretor-Geral Comandante.",
        "Juiz ou Procurador.",
        "Comandante-geral.",
        "Pessoa de reconhecida idoneidade, licenciada em direito, sociologia ou antropologia."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Comandante-geral."
      ],
      "score": 10
    },
    {
      "question": "O Comando-Geral é comandado pelo:",
      "choices": [
        "Comandante-geral. ",
        "Comandante operacional. ",
        "Chefe da Secretaria-Geral da Guarda",
        "2º Comandante-geral. "
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Chefe da Secretaria-Geral da Guarda"
      ],
      "score": 10
    },
    {
      "question": "A categoria profissional de guardas compreende os seguintes postos:",
      "choices": [
        "Cabo-adjunto.",
        "Primeiro-cabo.",
        "Segundo-cabo.",
        "Todas as alíneas estão incorretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão incorretas."
      ],
      "score": 10
    },
    {
      "question": "Na Guarda existem as seguintes unidades:",
      "choices": [
        "O Comando-Geral.",
        "De representação, a Unidade de Segurança e Honras de Estado.",
        "Teritoriais, os comandos territoriais.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "Constituem atribuições da Guarda Nacional Republicana:",
      "choices": [
        "Prevenir situações de tráfico e consumo de estupefacientes ou outras substâncias proibidas.",
        "Detectar situações de tráfico e consumo de estupefacientes ou outras substâncias proibidas.",
        "Prevenir e detectar situações de tráfico e consumo de estupefacientes ou outras substâncias proibidas.",
        "Todas as alíneas estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Prevenir e detectar situações de tráfico e consumo de estupefacientes ou outras substâncias proibidas."
      ],
      "score": 10
    },
    {
      "question": "A Guarda Nacional Republicana e as suas unidades, incluindo as unidades constituídas para actuar fora do território nacional e o estabelecimento de ensino, têm direito ao uso:",
      "choices": [
        "Estandarte nacional.",
        "Galhardete nacional.",
        "Mascote nacional.",
        "Todas as alíneas estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Estandarte nacional."
      ],
      "score": 10
    },
    {
      "question": "A Unidade de representação existente na Guarda Nacional Republicana é a:",
      "choices": [
        "Unidade de Controlo Fiscal.",
        "Unidade de Intervenção.",
        "Unidade de Segurança e Honras de Estado.",
        "Comando Local de Trânsito."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Unidade de Segurança e Honras de Estado."
      ],
      "score": 10
    },
    {
      "question": "A categoria profissional de guardas compreende os seguintes postos:",
      "choices": [
        "Cabo-mor.",
        "Cabo-chefe e cabo.",
        "Guarda-principal e guarda.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "A estrutura de comando da Guarda Nacional Republicana compreende:",
      "choices": [
        "O Comando da Guarda, os órgãos superiores de comando e direção.",
        "Apenas as unidades e os Postos.",
        "Os destacamentos e os postos.",
        "As unidades especializadas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Comando da Guarda, os órgãos superiores de comando e direção."
      ],
      "score": 10
    },
    {
      "question": "Na Guarda existem as seguintes unidades:",
      "choices": [
        "O Comando-Geral.",
        "De representação, a Unidade de Segurança e Honras de Estado.",
        "Teritoriais, os comandos territoriais.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "Constituem atribuições da Guarda Nacional Republicana:",
      "choices": [
        "Prevenir situações de tráfico e consumo de estupefacientes ou outras substâncias proibidas.",
        "Detectar situações de tráfico e consumo de estupefacientes ou outras substâncias proibidas.",
        "Prevenir e detectar situações de tráfico e consumo de estupefacientes ou outras substâncias proibidas.",
        "Todas as alíneas estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Prevenir e detectar situações de tráfico e consumo de estupefacientes ou outras substâncias proibidas."
      ],
      "score": 10
    },
    {
      "question": "Quanto tempo demora a Lua a dar a volta à Terra (período orbital)?",
      "choices": [
        "27 dias e 8 horas",
        "25 dias",
        "30 dias",
        "29 dias e 12 horas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "27 dias e 8 horas"
      ],
      "score": 10
    },
    {
      "question": "O menor e o maior país do mundo, em extensão territorial, são, respetivamente:",
      "choices": [
        "Malta e Austrália",
        "Mónaco e Índia",
        "Suíça e Estados Unidos da América",
        "Vaticano e Rússia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Vaticano e Rússia"
      ],
      "score": 10
    },
    {
      "question": "O órgão da União Europeia cuja principal função é gerir o euro, manter a estabilidade dos preços e conduzir a política económica e monetária da UE, é:",
      "choices": [
        "O Banco Europeu",
        "O Tribunal Europeu",
        "A Comissão Europeia",
        "O Banco Central Europeu"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Banco Central Europeu"
      ],
      "score": 10
    },
    {
      "question": "Quantos jogadores formam uma equipa de pólo aquático?",
      "choices": [
        "6",
        "7",
        "8",
        "5"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "7"
      ],
      "score": 10
    },
    {
      "question": "Quantos corações tem um polvo?",
      "choices": [
        "1",
        "2",
        "3",
        "4"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "3"
      ],
      "score": 10
    },
    {
      "question": "Atualmente, quantos elementos químicos a tabela periódica possui?",
      "choices": [
        "113",
        "109",
        "118",
        "92"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "118"
      ],
      "score": 10
    },
    {
      "question": "Quantos são os Estados-Membros da União Europeia? ",
      "choices": [
        "7 Países.",
        "17 Países.",
        "37 países.",
        "Todas as alíneas estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão erradas."
      ],
      "score": 10
    },
    {
      "question": "Segundo a Constituição da República Portuguesa, os cidadãos:",
      "choices": [
        "Têm o direito de se reunir, pacificamente e sem armas, mesmo em lugares abertos ao público, com a devida autorização.",
        "Têm o direito de se reunir, pacificamente e sem armas, mesmo em lugares abertos ao público, sem necessidade de qualquer autorização.",
        "Têm o direito de se reunir, com armas, mesmo em lugares abertos ao público, com a devida autorização.",
        "Não têm o direito de se reunir, pacificamente e sem armas, mesmo em lugares abertos ao público."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Têm o direito de se reunir, pacificamente e sem armas, mesmo em lugares abertos ao público, com a devida autorização."
      ],
      "score": 10
    },
    {
      "question": "Segundo a Constituição da República Portuguesa, são órgãos de soberania:",
      "choices": [
        "Apenas o Presidente da República e o Governo.",
        "Apenas o Presidente da República e a Assembleia da República.",
        "O Presidente da República, Assembleia da República, Governo e Tribunais.",
        "O Presidente da República e os Presidentes das Regiões Autónomas dos Açores e Madeira."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Presidente da República, Assembleia da República, Governo e Tribunais."
      ],
      "score": 10
    },
    {
      "question": "As campanhas eleitorais regem-se pelos seguintes princípios:",
      "choices": [
        "Liberdade de propaganda.",
        "Igualdade de oportunidades e de tratamento das diversas candidaturas.",
        "Imparcialidade das entidades públicas perante as candidaturas.",
        "Todas as anteriores estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as anteriores estão corretas."
      ],
      "score": 10
    },
    {
      "question": "Segundo a Constituição da República Portuguesa:",
      "choices": [
        "Todos os cidadãos têm direito à Liberdade e Segurança.",
        "Toda a pessoa privada da liberdade deve ser informada previamente e de forma compreensível das razões da sua prisão ou detenção e dos seus direitos.",
        "A privação da sua liberdade contra o disposto na Constituição e na lei constitui o Estado no dever de indemnizar o lesado nos termos que a lei estabelecer.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "Qual das palavras é um substantivo abstrato?",
      "choices": [
        "Amor",
        "Casa",
        "Mesa",
        "Cidade"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Amor"
      ],
      "score": 10
    },
    {
      "question": "Qual é o tipo de sujeito na frase: 'Fala-se muito sobre esse assunto.'?",
      "choices": [
        "Sujeito indeterminado",
        "Sujeito simples",
        "Sujeito composto",
        "Sujeito oculto"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Sujeito indeterminado"
      ],
      "score": 10
    },
    {
      "question": "Em qual das frases o verbo está no pretérito perfeito do indicativo?",
      "choices": [
        "Ontem, eu estudei bastante.",
        "Eu estudava todos os dias.",
        "Eu terei estudado quando ele chegar.",
        "Estudaria mais, se tivesse tempo."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ontem, eu estudei bastante."
      ],
      "score": 10
    },
    {
      "question": "Na frase 'Ele entregou o relatório ao professor', o termo 'ao professor' desempenha a função de:",
      "choices": [
        "Objeto direto",
        "Objeto indireto",
        "Adjunto adnominal",
        "Complemento nominal"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Objeto indireto"
      ],
      "score": 10
    },
    {
      "question": "Em qual das frases o verbo está no presente do indicativo?",
      "choices": [
        "Eu estudo todos os dias.",
        "Eu estudarei amanhã.",
        "Eu estudei ontem.",
        "Eu estudaria se tivesse tempo."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Eu estudo todos os dias."
      ],
      "score": 10
    },
    {
      "question": "Qual das opções contém uma oração subordinada adverbial causal?",
      "choices": [
        "Ele foi promovido porque se dedicou muito.",
        "Se eu fosse rico, viajaria mais.",
        "Embora cansado, ele continuou a trabalhar.",
        "Ela correu tanto que ficou exausta."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ele foi promovido porque se dedicou muito."
      ],
      "score": 10
    },
    {
      "question": "Identifique a função sintática de 'de Pedro' na frase: 'O carro de Pedro quebrou.'",
      "choices": [
        "Adjunto adnominal",
        "Objeto direto",
        "Predicativo do sujeito",
        "Complemento nominal"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Adjunto adnominal"
      ],
      "score": 10
    },
    {
      "question": "Em qual das frases há um erro de concordância verbal?",
      "choices": [
        "Eles foram ao cinema.",
        "Os alunos estuda muito.",
        "Nós saímos cedo ontem.",
        "Ela corre todos os dias."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Os alunos estuda muito."
      ],
      "score": 10
    },
    {
      "question": "Qual das palavras é um advérbio de intensidade?",
      "choices": [
        "Muito",
        "Bem",
        "Ali",
        "Ontem"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Muito"
      ],
      "score": 10
    },
    {
      "question": "Na frase 'Os alunos fizeram o trabalho com cuidado', o termo 'com cuidado' é um:",
      "choices": [
        "Objeto direto",
        "Objeto indireto",
        "Adjunto adverbial de modo",
        "Complemento nominal"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Adjunto adverbial de modo"
      ],
      "score": 10
    }

  ],
}

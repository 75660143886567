// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const cg_2: Topic = {
  topic: 'Cultura Geral (2)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "Qual a capital do Canadá?",
      "choices": [
        "Toronto",
        "Ottawa",
        "Montreal",
        "Vancouver"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ottawa"
      ],
      "score": 10
    },
    {
      "question": "Gronelândia pertence a que país?",
      "choices": [
        "Dinamarca",
        "Noruega",
        "Canadá",
        "Islândia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Dinamarca"
      ],
      "score": 10
    },
    {
      "question": "As ilhas selvagens pertencem a que país?",
      "choices": [
        "Espanha",
        "Portugal",
        "Marrocos",
        "Senegal"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Portugal"
      ],
      "score": 10
    },
    {
      "question": "Quantos deputados tem o Parlamento Europeu?",
      "choices": [
        "751",
        "732",
        "705",
        "700"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "705"
      ],
      "score": 10
    },
    {
      "question": "Qual a capital dos EUA?",
      "choices": [
        "Nova Iorque",
        "Washington DC",
        "Miami",
        "Las Vegas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Washington DC"
      ],
      "score": 10
    },
    {
      "question": "Qual a capital da Austrália?",
      "choices": [
        "Sidney",
        "Melbourne",
        "Camberra",
        "Brisbane"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Camberra"
      ],
      "score": 10
    },
    {
      "question": "Onde se iniciou o surto de Covid?",
      "choices": [
        "Pequim",
        "Xangai",
        "Wuhan",
        "Tianjin"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Wuhan"
      ],
      "score": 10
    },
    {
      "question": "Em que ano Portugal recebeu o festival da Euro-canção?",
      "choices": [
        "2016",
        "2017",
        "2018",
        "2019"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "2018"
      ],
      "score": 10
    },
    {
      "question": "Quem escreveu o livro 'O Crime do Padre Amaro'?",
      "choices": [
        "José Saramago",
        "Eça de Queirós",
        "Fernando Pessoa",
        "Miguel Torga"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Eça de Queirós"
      ],
      "score": 10
    },
    {
      "question": "Em que ano foram detonadas as bombas de Nagasaki e Hiroshima?",
      "choices": [
        "1943",
        "1944",
        "1945",
        "1946"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "1945"
      ],
      "score": 10
    },
    {
      "question": "Quantas ilhas compõem o arquipélago dos Açores?",
      "choices": [
        "8",
        "9",
        "10",
        "11"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "9"
      ],
      "score": 10
    },
    {
      "question": "Faixa de Gaza faz fronteira com que país?",
      "choices": [
        "Afeganistão",
        "Líbano",
        "Israel",
        "Iraque"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Israel"
      ],
      "score": 10
    },
    {
      "question": "Qual o país mais pequeno do mundo?",
      "choices": [
        "São Marino",
        "Vaticano",
        "Liechtenstein",
        "Mónaco"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Vaticano"
      ],
      "score": 10
    },
    {
      "question": "Quantos distritos compõem Portugal Continental?",
      "choices": [
        "16",
        "17",
        "18",
        "19"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "18"
      ],
      "score": 10
    },
    {
      "question": "Onde decorreu o mundial de 2022 de Futebol?",
      "choices": [
        "EUA",
        "Qatar",
        "Arábia Saudita",
        "Emirados Árabes Unidos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Qatar"
      ],
      "score": 10
    },
    {
      "question": "Qual o estreito que limita o Continente Europeu do Asiático na Turquia?",
      "choices": [
        "Estreito de Dardanelos",
        "Estreito de Bósforo",
        "Estreito de Messina",
        "Estreito de Gibraltar"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Estreito de Bósforo"
      ],
      "score": 10
    },
    {
      "question": "Onde se situa Auschwitz?",
      "choices": [
        "Áustria",
        "Alemanha",
        "Polónia",
        "Eslováquia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Polónia"
      ],
      "score": 10
    },
    {
      "question": "Onde nasceu Adolf Hitler?",
      "choices": [
        "Alemanha",
        "Áustria",
        "Polónia",
        "Eslováquia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Áustria"
      ],
      "score": 10
    },
    {
      "question": "Em que Ilha Açoriana se situa a Base das Lages?",
      "choices": [
        "Ilha Terceira",
        "Ilha do Pico",
        "Ilha do Corvo",
        "Ilha São Miguel"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ilha Terceira"
      ],
      "score": 10
    },
    {
      "question": "Em que ano foi assinado o Tratado de Lisboa?",
      "choices": [
        "2005",
        "2006",
        "2007",
        "2008"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "2007"
      ],
      "score": 10
    }
    
  ],
}

// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

import b9 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/b9.png'
import b10 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/b10.png'
import b11 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/b11.png'
import b12 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/b12.png'
import c1 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/c1.png'
import c2 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/c2.png'
import c3 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/c3.png'
import c4 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/c4.png'
import c5 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/c5.png'
import c6 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/c6.png'
import c7 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/c7.png'
import c8 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/c8.png'
import c9 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/c9.png'
import c10 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/c10.png'
import c11 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/c11.png'
import c12 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/c12.png'
import d1 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/d1.png'
import d2 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/d2.png'
import d3 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/d3.png'
import d4 from '../../../assets/images/psicotecnicos/Matrizes_Progressivas/d4.png'





export const matrizes_progressivas_2: Topic = {
  topic: 'Matrizes Progressivas (2)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "Assinale a resposta correta.",
      image: b9,
      "choices": [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6"
      ],
      "type": "MCQs",
      "correctAnswers": [
      "4"
      ],
      "score": 10
  },
  {
    "question": "Assinale a resposta correta.",
    image: b10,
    "choices": [
    "1",
      "2",
      "3",
      "4",
      "5",
      "6"
    ],
    "type": "MCQs",
    "correctAnswers": [
    "3"
    ],
    "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: b11,
  "choices": [
  "1",
      "2",
      "3",
      "4",
      "5",
      "6"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "4"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: b12,
  "choices": [
  "1",
      "2",
      "3",
      "4",
      "5",
      "6"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "5"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: c1,
  "choices": [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "8"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: c2,
  "choices": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "2"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: c3,
  "choices": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "3"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: c4,
  "choices": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "8"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: c5,
  "choices": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "7"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: c6,
  "choices": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "4"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: c7,
  "choices": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "5"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: c8,
  "choices": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "1"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: c9,
  "choices": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "7"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: c10,
  "choices": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "6"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: c11,
  "choices": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "1"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: c12,
  "choices": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "2"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: d1,
  "choices": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "3"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: d2,
  "choices": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "4"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: d3,
  "choices": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "3"
  ],
  "score": 10
},
{
  "question": "Assinale a resposta correta.",
  image: d4,
  "choices": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8"
  ],
  "type": "MCQs",
  "correctAnswers": [
  "7"
  ],
  "score": 10
},

  
  ],
}

// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '..'

export const crp_dudh_eu_3: Topic = {
  topic: 'CRP, DUDH e UE (3)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "Em Portugal, a autoridade que tem por missão o planeamento e coordenação a nível nacional de apoio à política do Governo em matéria de segurança rodoviária, bem como a aplicação do direito contraordenacional rodoviário é:",
      "choices": [
        "Autoridade Nacional de Emergência e Proteção Civil.",
        "Guarda Nacional Republicana.",
        "Autoridade Nacional de Segurança Rodoviária.",
        "Polícia Judiciária."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Autoridade Nacional de Segurança Rodoviária."
      ],
      "score": 10
    },
    {
      "question": "O aniversário da Guarda Nacional Republicana é celebrado no dia: ",
      "choices": [
        "25 de abril.",
        "01 de maio.",
        "10 de junho.",
        "Todas as alíneas estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão erradas."
      ],
      "score": 10
    },
    {
      "question": "Em Portugal, no dia 5 de outubro, celebra-se: ",
      "choices": [
        "O Dia de Portugal.",
        "A Implantação da República.",
        "A Restauração da Dependência.",
        "Dia da Liberdade."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A Implantação da República."
      ],
      "score": 10
    },
    {
      "question": "Segundo a Constituição da República Portuguesa, Portugal como Estado de direito democrático é baseado:",
      "choices": [
        "Organização política democrática.",
        "Pluralismo de expressão.",
        "Separação e interdependência de poderes.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "Segundo a Constituição da República Portuguesa, os estrangeiros e os apátridas que se encontrem ou residam em Portugal: ",
      "choices": [
        "Gozam dos direitos e estão sujeitos aos deveres do cidadão português.",
        "Não gozam dos direitos e não estão sujeitos aos deveres do cidadão português.",
        "Apenas gozam dos direitos e deveres do seu país de origem.",
        "Não gozam dos direitos, mas estão sujeitos aos deveres do cidadão português."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Gozam dos direitos e estão sujeitos aos deveres do cidadão português."
      ],
      "score": 10
    },
    {
      "question": "Segundo a Constituição da República Portuguesa:",
      "choices": [
        "Todos os cidadãos têm direito à liberdade e segurança.",
        "Toda a pessoa privada da liberdade deve ser informada previamente e de forma compreensível das razões da sua prisão ou detenção e dos seus direitos.",
        "A privação da sua liberdade contra o disposto na Constituição e na lei constitui o Estado no dever de indemnizar o lesado nos termos que a lei estabelecer.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "Segundo a Constituição da República Portuguesa, os cidadãos:",
      "choices": [
        "Têm o direito de se reunir, pacificamente e sem armas, mesmo em lugares abertos ao público, com a devida autorização.",
        "Têm o direito de se reunir, pacificamente e sem armas, mesmo em lugares abertos ao público, sem necessidade de qualquer autorização.",
        "Têm o direito de se reunir, com armas, mesmo em lugares abertos ao público, com a devida autorização.",
        "Não têm o direito de se reunir, pacificamente e sem armas, mesmo em lugares abertos ao público."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Têm o direito de se reunir, pacificamente e sem armas, mesmo em lugares abertos ao público, com a devida autorização."
      ],
      "score": 10
    },
    {
      "question": "Segundo a Constituição da República Portuguesa, são órgãos de soberania:",
      "choices": [
        "Apenas o Presidente da República e o Governo.",
        "Apenas o Presidente da República e a Assembleia da República.",
        "O Presidente da República, Assembleia da República, Governo e Tribunais.",
        "O Presidente da República e os Presidentes das Regiões Autónomas dos Açores e Madeira."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Presidente da República, Assembleia da República, Governo e Tribunais."
      ],
      "score": 10
    },
    {
      "question": "As campanhas eleitorais regem-se pelos seguintes princípios:",
      "choices": [
        "Liberdade de propaganda.",
        "Igualdade de oportunidades e de tratamento das diversas candidaturas.",
        "Imparcialidade das entidades públicas perante as candidaturas.",
        "Todas as anteriores estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as anteriores estão corretas."
      ],
      "score": 10
    },
    {
      "question": "De acordo com a Constituição da República Portuguesa, na administração da justiça incumbe aos tribunais:",
      "choices": [
        "A prossecução do interesse público, no respeito pelos direitos e interesses legalmente protegidos dos cidadãos.",
        "Participar na execução da política criminal definida pelos órgãos de soberania, exercer a ação penal orientada pelo princípio da legalidade e defender a legalidade democrática.",
        "Assegurar a defesa dos direitos e interesses legalmente protegidos dos cidadãos, reprimir a violação da legalidade democrática e dirimir os conflitos de interesses públicos e privados.",
        "Administrar a justiça em matérias de natureza jurídico -constitucional."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Assegurar a defesa dos direitos e interesses legalmente protegidos dos cidadãos, reprimir a violação da legalidade democrática e dirimir os conflitos de interesses públicos e privados."
      ],
      "score": 10
    },
    {
      "question": "De acordo com a Constituição da República Portuguesa, a prevenção dos crimes, incluindo a dos crimes contra a segurança do Estado, só pode fazer-se:",
      "choices": [
        "Com observância das regras gerais sobre polícia e com respeito pelos direitos, liberdades e garantias dos cidadãos.",
        "Com observância das regras gerais sobre medidas cautelares de polícia e com respeito pelos deveres, liberdades e garantias dos cidadãos.",
        "Com observância das regras gerais sobre medidas cautelares de polícia e com respeito pelos direitos, liberdades e garantias dos cidadãos.",
        "Com observância das regras gerais sobre medidas cautelares de polícia, tendo em conta os deveres dos cidadãos consagrados na Constituição da República Portuguesa."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Com observância das regras gerais sobre polícia e com respeito pelos direitos, liberdades e garantias dos cidadãos."
      ],
      "score": 10
    },
    {
      "question": "Segundo a Declaração Universal dos Direitos Humanos, todos os seres humanos podem invocar os direitos e as liberdades, com distinção alguma em razão:",
      "choices": [
        "Da raça.",
        "Da cor.",
        "Do sexo.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "De acordo com a Declaração Universal dos Direitos Humanos, ninguém será condenado por ações ou omissões que:",
      "choices": [
        "No momento da sua prática, constituíam acto delituoso à face do direito interno. Do mesmo modo, não será infligida pena mais grave do que a que era aplicável no momento em que o acto delituoso foi cometido.",
        "No momento da sua prática, não constituíam acto delituoso à face do direito interno ou internacional. Do mesmo modo, não será infligida pena mais grave do que a que era aplicável no momento em que o acto delituoso foi cometido.",
        "No momento da sua prática, constituíam acto delituoso à face do direito interno. Do mesmo modo, não será infligida pena mais leve do que a que era aplicável no momento em que o acto delituoso foi cometido.",
        "No momento da sua prática, não constituíam acto delituoso à face do direito interno ou internacional. Do mesmo modo, não será infligida pena mais leve do que a que era aplicável no momento em que o acto delituoso foi cometido. "
      ],
      "type": "MCQs",
      "correctAnswers": [
        "No momento da sua prática, não constituíam acto delituoso à face do direito interno ou internacional. Do mesmo modo, não será infligida pena mais grave do que a que era aplicável no momento em que o acto delituoso foi cometido"
      ],
      "score": 10
    },
    {
      "question": "De acordo com a Declaração Universal dos Direitos Humanos, toda a pessoa tem o direito de:",
      "choices": [
        "Circular livremente.",
        "Escolher a sua residência no interior de um Estado.",
        "Abandonar o país em que se encontra.",
        "Todas as alíneas estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as alíneas estão corretas."
      ],
      "score": 10
    },
    {
      "question": "De acordo com a Declaração Universal dos Direitos Humanos:",
      "choices": [
        "Todo o ser humano pode ser condenado por acções ou omissões que, no momento da sua prática, não constituíam acto delituoso à face do direito interno ou internacional.",
        "Todo o ser humano pode sofrer intromissões arbitrárias na sua vida privada, na sua família, no seu domicílio ou na sua correspondência.",
        "Todo o ser humano pode ser obrigado a fazer parte de uma associação.",
        "Todo o ser humano tem direito de acesso, em condições de igualdade, às funções públicas do seu país."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todo o ser humano tem direito de acesso, em condições de igualdade, às funções públicas do seu país."
      ],
      "score": 10
    },
    {
      "question": "Segundo a Constituição da República Portuguesa, o Estado subordina-se: ",
      "choices": [
        "Aos partidos e funda-se na legalidade democrática.",
        "À constituição e funda-se na legalidade democrática.",
        "À constituição e funda-se na regularidade financeira.",
        "Todas as alíneas estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "À constituição e funda-se na legalidade democrática."
      ],
      "score": 10
    },
    {
      "question": "São órgãos de soberania:",
      "choices": [
        "O Presidente da República e da Assembleia da República, o Governo e as Câmaras Municipais.",
        "O Presidente da República e da Assembleia da República, o Primeiro-Ministro e os Tribunais.",
        "O Presidente da República, os Tribunais, a Assembleia da República e o Governo.",
        "O Presidente da Assembleia da República, o Governo, o Primeiro-Ministro e as Câmaras Municipais."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Presidente da República, os Tribunais, a Assembleia da República e o Governo."
      ],
      "score": 10
    },
    {
      "question": "O Tratado Europeu que em 13 de dezembro de 2007 alterou o tratado da União Europeia e que institui a Comunidade Europeia foi:",
      "choices": [
        "O Tratado de Maastricht.",
        "O Tratado de Amesterdão.",
        "O Tratado de Nice.",
        "O Tratado de Lisboa."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Tratado de Lisboa."
      ],
      "score": 10
    },
    {
      "question": "São objetivos da União Europeia no interior das suas fronteiras: ",
      "choices": [
        "Promover a paz, os seus valores e o bem-estar dos seus cidadãos.",
        "Combater a exclusão social e a discriminação.",
        "Promover a justiça e a proteção sociais, a igualdade entre homens e mulher e a proteção dos direitos da criança.",
        "Todas as anteriores estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as anteriores estão corretas."
      ],
      "score": 10
    },
    {
      "question": "De acordo com a Declaração Universal dos Direitos Humanos:",
      "choices": [
        "Todo o ser humano pode ser mantido em escravatura.",
        "Todo o ser humano pode ser mantido em servidão.",
        "Todo o ser humano tem direito à segurança pessoal.",
        "Todo o ser humano pode ser submetido a tortura ou, a penas e tratamentos cruéis"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todo o ser humano tem direito à segurança pessoal."
      ],
      "score": 10
    }
    
  ],
}

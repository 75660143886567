import React from 'react';
import styled from 'styled-components';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  min-height: 100vh;
`;

const ChangelogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ChangelogItem = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.cardBackground};
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  width: 80%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const VersionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const Version = styled.h2`
  margin: 0;
  font-size: 1.5rem;
`;

const Date = styled.span`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors};
`;

const Log = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const LogItem = styled.li`
  margin-bottom: 10px;
`;

const Changelog = () => {
  const changelogData = [
    {
      version: '1.0.6',
      date: '28/09/2024',
      logs: [
        'Adicionado conteudo para a Prova Fisica da GNR',
        'Adicionado "Gerar Plano de Treino com AI"'
      ],
    },
    {
      version: '1.0.5',
      date: '24/09/2024',
      logs: [
        'Adicionada "Explicar com AI", sistema ligado à OpenAI para explicar as perguntas/respostas no "Result Screen"',
        'Adicionada Materia em Áudio',
        'Adicionada Função de Colapsar Menu'
      ],
    },
    {
      version: '1.0.4',
      date: '23/09/2024',
      logs: [
        'Correção a conteúdo errático.',
      ],
    },
    {
      version: '1.0.3',
      date: '20/09/2024',
      logs: [
        'Adicionada matéria das PC da GNR desde 2018 até 2023.',
        'Adicionado sistema Fremium (Free + Premium).',
      ],
    },
    {
      version: '1.0.2',
      date: '01/09/2024',
      logs: [
        'Corrigido bug no sistema de login.',
        'Adicionada funcionalidade de criação de conta.',
      ],
    },
    {
      version: '1.0.1',
      date: '15/08/2024',
      logs: ['Melhorias no layout da página inicial.', 'Otimização de performance.'],
    },
    {
      version: '1.0.0',
      date: '10/08/2024',
      logs: ['Lançamento inicial da plataforma Web Eu Quero Entrar.'],
    },
  ];

  return (
    <MainContainer>
      <ChangelogContainer>
        <h1>Changelog</h1>
        {changelogData.map((item, index) => (
          <ChangelogItem key={index}>
            <VersionHeader>
              <Version>Versão {item.version}</Version>
              <Date>{item.date}</Date>
            </VersionHeader>
            <Log>
              {item.logs.map((logItem, logIndex) => (
                <LogItem key={logIndex}>- {logItem}</LogItem>
              ))}
            </Log>
          </ChangelogItem>
        ))}
      </ChangelogContainer>
    </MainContainer>
  );
};

export default Changelog;
